import { Slot } from '@radix-ui/react-slot';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMedia } from 'react-use';

import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from './sheet';

type DetailsSidebarContext = {
  alwaysOverlay: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  toggleSidebar: () => void;
  variant: 'fixed' | 'overlay';
};

const DetailsSidebarContext = createContext<DetailsSidebarContext | null>(null);

function useDetailsSidebar() {
  const context = useContext(DetailsSidebarContext);
  if (!context) {
    throw new Error(
      'useDetailsSidebar must be used within a DetailsSidebarProvider.',
    );
  }

  return context;
}

function DetailsSidebarProvider({
  children,
  overlayOnly = false,
}: {
  children: ReactNode;
  overlayOnly?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const toggleSidebar = useCallback(() => {
    setOpen((open) => !open);
  }, [setOpen]);

  const canBeFixed = useMedia('(min-width: 64rem)');
  const variant = overlayOnly || !canBeFixed ? 'overlay' : 'fixed';

  useEffect(() => {
    if (variant === 'fixed') {
      setOpen(false);
    }
  }, [variant]);

  const contextValue = useMemo<DetailsSidebarContext>(
    () => ({
      alwaysOverlay: overlayOnly,
      open,
      setOpen,
      toggleSidebar,
      variant,
    }),
    [overlayOnly, open, setOpen, toggleSidebar, variant],
  );

  return (
    <DetailsSidebarContext.Provider value={contextValue}>
      {children}
    </DetailsSidebarContext.Provider>
  );
}

function DetailsSidebarTrigger({ children }: { children: ReactNode }) {
  const { toggleSidebar, variant } = useDetailsSidebar();

  if (variant === 'fixed') {
    return null;
  }

  return <Slot onClick={() => toggleSidebar()}>{children}</Slot>;
}

function DetailsSidebar({
  children,
  title,
}: {
  children: ReactNode;
  title: string;
}) {
  const { alwaysOverlay, open: isOpen, setOpen, variant } = useDetailsSidebar();

  if (variant === 'fixed') {
    return (
      <aside className="col-start-2 row-span-2 row-start-2 h-full w-80 self-stretch overflow-auto border-l border-borderPrimary">
        {children}
      </aside>
    );
  }

  return (
    <Sheet modal={!alwaysOverlay} open={isOpen} onOpenChange={setOpen}>
      <SheetContent
        className={alwaysOverlay ? 'w-full md:w-144' : 'w-80'}
        hasOverlay={!alwaysOverlay}
        onInteractOutside={(event) => {
          if (alwaysOverlay) {
            event.preventDefault();
          }
        }}
      >
        <SheetClose />
        <SheetHeader>
          <SheetTitle>{title}</SheetTitle>
        </SheetHeader>
        {children}
      </SheetContent>
    </Sheet>
  );
}

export {
  DetailsSidebar,
  DetailsSidebarProvider,
  DetailsSidebarTrigger,
  useDetailsSidebar,
};
