import { Text, View } from '@react-pdf/renderer';

import { Block } from '@eluve/llm-outputs';

import { tw } from '../pdf-tw';
import { commonStyles } from '../styles';
import { PdfAmendment } from '../types';

import { PdfParagraph } from './PdfParagraph';
import { TextAmendments } from './TextAmendments';

export const PdfTextField: React.FC<{
  block: Block;
  amendments: PdfAmendment[];
}> = ({ block, amendments }) => {
  if (block.type !== 'text') return null;
  const text = block.text;
  if (!text) return <Text style={tw(commonStyles.text())}>No content</Text>;

  const paragraphs = text.split('\n').filter(Boolean);

  return (
    <View key={block.key}>
      {block.label && (
        <Text style={tw(commonStyles.headingText())}>{block.label}</Text>
      )}
      <View style={tw(commonStyles.content())}>
        <View>
          {paragraphs.map((paragraph, i) => (
            <PdfParagraph paragraph={paragraph} key={i} />
          ))}
        </View>
        <TextAmendments blockPath={block.key} amendments={amendments} />
      </View>
    </View>
  );
};
