import addMinutes from 'date-fns/addMinutes';
import differenceInHours from 'date-fns/differenceInHours';
import subDays from 'date-fns/subDays';
import chunk from 'lodash/chunk';
import isNumber from 'lodash/isNumber';
import toNumber from 'lodash/toNumber';
import { z } from 'zod';

import { DynamicSummary } from '@eluve/llm-outputs';

import { ERRORS } from '../../errors';
import { AppointmentModel } from '../../models/appointment';
import { PatientType } from '../../models/patient';
import { isDynamicSummary } from '../utils';
import {
  CanSyncToEhrArgs,
  VendorDataMapper,
  VendorSyncConfig,
} from '../vendor-data-mapper';

import { logo } from './logo';
import { convertMovementXDataToAppointmentModel } from './movement-x.appointment';
import { Appointment, appointmentSchema, patientSchema } from './types';

const appointmentData = z.object({
  appointments: z.array(z.unknown()),
});
export class MovementXVendorDataMapper implements VendorDataMapper {
  getChart = (data: unknown) => {
    throw new Error('Method not implemented.');
  };

  getAppointments = (data: unknown): AppointmentModel[] => {
    const rawAppointments = appointmentData.parse(data);

    const appointments: AppointmentModel[] = [];
    for (const appointment of rawAppointments.appointments) {
      const appointmentParseResult = appointmentSchema.safeParse(appointment);

      if (!appointmentParseResult.success) {
        throw new Error(
          `Invalid appointment data from 3rd party EHR: ${appointmentParseResult.error}`,
        );
      }
      const appointmentModel = convertMovementXDataToAppointmentModel(
        appointmentParseResult.data,
      );

      // MovementX sometimes returns appointments with an incorrect end time
      // Related issue: https://linear.app/eluve/issue/ELU-2683
      //
      // Validate the start/end of the appointment is reasonable (less than 2 hrs apart),
      // and if it's not, calculate the end time based on the duration from the raw data
      const startTime = new Date(appointmentModel.startTime);
      const endTime = new Date(appointmentModel.endTime);

      if (differenceInHours(endTime, startTime) > 2) {
        // If the appointment is longer than 2 hours, there's a chance it's incorrect
        const durationInMinutes = toNumber(
          appointmentModel.rawEhrData['duration'],
        );

        if (isNumber(durationInMinutes)) {
          const correctEndTime = addMinutes(startTime, durationInMinutes);
          appointmentModel.endTime = correctEndTime.toISOString();
        }
      }

      appointments.push(appointmentModel);
    }

    return appointments;
  };

  getPatient = (data: unknown): PatientType | null => {
    const parsedPatient = patientSchema.parse(data);

    return {
      externalPatientId: `${parsedPatient.id}`,
      firstName: parsedPatient.user?.first_name ?? '',
      lastName: parsedPatient.user?.last_name ?? '',
      dateOfBirth: parsedPatient.date_birth ?? null,
      email: parsedPatient?.user?.email ?? null,
      homePhone: null,
      cellPhone: parsedPatient?.phone ?? null,
      workPhone: null,
      rawData: data as Record<string, unknown>,
    };
  };

  getPatientEhrUrl = ({
    domain,
    externalPatientId,
  }: {
    domain: string;
    externalPatientId?: string;
  }): string => {
    return `https://${domain}/#/providers/patients/view/${externalPatientId}`;
  };

  getLogo = () => logo;

  getHomeUrl = (domain: string): string => {
    return `https://${domain}`;
  };

  fetchAppointments = async ({
    domain,
    request,
    startDate,
    endDate,
  }: {
    domain: string;
    request: typeof fetch;
    startDate: number;
    endDate: number;
  }) => {
    let allRecordsRetrieved = false;
    let allResults: Appointment[] = [];

    // Tracking num of request made as a safety net against an infinite loop in the event
    // that MX returns a bad count or something else goes wrong.
    //
    // We cap it at 5 requests, fetching up to 50 records per request.
    // We should be able to fetch all appointments in 5 requests, this would mean
    // 250 appointments in 2 weeks... which is a lot.
    const maxRequests = 5;

    for (let i = 0; i < maxRequests && !allRecordsRetrieved; i++) {
      const query = [
        `start_time=${new Date(startDate).toISOString()}`,
        `end_time=${new Date(endDate).toISOString()}`,
        `limit=50`,
        `skip=${allResults.length}`,
      ].join('&');
      const rootDomain = domain.replace(/^[^.]+\./, '');
      const baseUrl = `https://platform.${rootDomain}/api/session/?${query}`;

      const appointmentResponse = await request(baseUrl);

      if (!appointmentResponse.ok) {
        if (appointmentResponse.status === 401) {
          return { ok: false, error: ERRORS.NOT_LOGGED_IN };
        }
        return { ok: false, error: ERRORS.FAILED_TO_IMPORT };
      }

      const body = await appointmentResponse.json();

      allResults = allResults.concat(body.data);

      if (allResults.length >= body.count) {
        allRecordsRetrieved = true;
      }
    }

    return {
      ok: true,
      data: {
        response: allResults,
        timezone: '+00:00',
      },
    };
  };

  fetchPatientsByIds = async ({
    ids,
    domain,
    request,
  }: {
    ids: number[];
    domain: string;
    request: typeof fetch;
  }) => {
    const patients: any[] = [];
    const batchSize = 3;

    const idChunks = chunk(ids, batchSize);

    const rootDomain = domain.replace(/^[^.]+\./, '');

    for (const chunk of idChunks) {
      try {
        const responses = await Promise.all(
          chunk.map(async (id) => {
            const response = await request(
              `https://platform.${rootDomain}/api/patient?id=${id}`,
            );

            if ([401, 403].includes(response.status)) {
              return {
                ok: false,
                error: ERRORS.NOT_LOGGED_IN,
              };
            }

            if (!response.ok) {
              const body = await response.json();
              return {
                ok: false,
                error: `Could not fetch patient ${id} by ID: ${JSON.stringify({ body, status: response.status })}`,
              };
            }

            const { data } = await response.json();
            return data[0];
          }),
        );

        patients.push(...responses);
      } catch (error: unknown) {
        return { ok: false, error: (error as Error)?.message };
      }
    }

    return { ok: true, data: patients };
  };

  getSyncConfig(): VendorSyncConfig {
    return {
      isSyncEnabled: true,
      canSignNoteInEhr: false,
      canSyncNoteToEhr: ({
        externalAppointmentId,
        externalPatientId,
        summary,
        eluveExtExists,
      }: CanSyncToEhrArgs) => {
        const isExternalAppointment = Boolean(externalAppointmentId);
        const isExternalPatient = Boolean(externalPatientId);
        if (!eluveExtExists) {
          return {
            canSync: false,
            reason: 'Extension is not installed',
          };
        }
        if (!isExternalPatient) {
          return {
            canSync: false,
            reason: 'Selected patient was not imported from EHR',
          };
        }

        if (!isExternalAppointment) {
          return {
            canSync: false,
            reason: 'This appointment was not imported from EHR',
          };
        }

        if (summary && isDynamicSummary(summary)) {
          // check if there's fields that are not supported
          const dynamicSummary = summary as DynamicSummary;

          const unsupportedBlocks = dynamicSummary.blocks.filter(
            (block) => block.type !== 'text',
          );

          const supportedFields = [
            'subjective',
            'objective',
            'assessment',
            'plan',
            'client recap',
          ];

          const unsupportedFields = dynamicSummary.blocks.filter(
            (block) => !supportedFields.includes(block.label.toLowerCase()),
          );

          if (unsupportedBlocks.length > 0 || unsupportedFields.length > 0) {
            return {
              canSync: false,
              reason: 'This summary is not compatible with Movement X',
            };
          }
        }

        return { canSync: true };
      },
      canSyncWithManualChartUrl: false,
      syncStartDate: subDays(new Date(), 7),
    };
  }
}
