import { getFormattedDateInMMMMdyyyyWithTime } from '@eluve/date-utils';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './DropdownMenu';
import { HStack } from './HStack';
import { Icon } from './Icon';
import { NewButton } from './NewButton';
import { VStack } from './VStack';
import { Box } from './box';
import { P } from './typography';

export const AmendmentFlag: React.FC = () => (
  <Box className="flex size-5 items-center justify-center rounded-md bg-orange text-orangeContrast">
    <Icon name="FlagTriangleRight" size="2xs" />
  </Box>
);

export const AmendmentHeader: React.FC<{
  date: string;
  userName: string;
  isReadonly: boolean;
  onDelete?: () => void;
  onDuplicate?: () => void;
  duplicateSectionLabel?: string;
}> = ({
  duplicateSectionLabel = '',
  date,
  userName,
  isReadonly = true,
  onDuplicate,
  onDelete,
}) => {
  const signature = `${getFormattedDateInMMMMdyyyyWithTime(date ? new Date(date) : new Date())} by ${userName}`;
  return (
    <HStack justify="between">
      <VStack className="sm:flex-row" gap={1}>
        <HStack wFull={false}>
          <AmendmentFlag />
          <P className="text-contentPrimary">Amendment</P>
        </HStack>
        <P className="text-nowrap">
          <span className="hidden text-contentTertiary sm:inline">{' • '}</span>
          <span className="text-contentTertiary">{signature}</span>
        </P>
      </VStack>
      {!isReadonly && (
        <HStack justify="end">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <NewButton icon={{ name: 'Ellipsis' }} type="ghost" size="s" />
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {onDelete && (
                <DropdownMenuItem onSelect={onDelete}>
                  Delete Amendment
                </DropdownMenuItem>
              )}
              {onDuplicate && (
                <DropdownMenuItem onSelect={onDuplicate}>
                  Duplicate {duplicateSectionLabel} Section
                </DropdownMenuItem>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </HStack>
      )}
    </HStack>
  );
};
