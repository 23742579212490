import { useSubscription } from '@apollo/client';
import React from 'react';

import { Box, Content, Divider, VStack } from '@eluve/components';
import { PatientOverview } from '@eluve/feature-patients';
import {
  listenAppointmentStatusSubscription,
  useAppointmentPatient,
} from '@eluve/frontend-appointment-hooks';
import { AppointmentName, useIsFeatureFlagEnabled } from '@eluve/smart-blocks';

import { EluveAdminControls } from './EluveAdminControls';
import { NewAppointmentForm } from './NewAppointmentForm';
import { AppointmentDetails } from './components/AppointmentDetails';

export interface NotStartedAppointmentLayoutProps {
  tenantId: string;
  appointmentId: string;
}

export const NotStartedAppointmentLayout: React.FC<
  NotStartedAppointmentLayoutProps
> = ({ appointmentId, tenantId }) => {
  useSubscription(listenAppointmentStatusSubscription, {
    variables: {
      tenantId,
      appointmentId,
    },
  });

  const patient = useAppointmentPatient();
  const patientOverviews = patient?.patient_overviews ?? [];
  const isPatientOverviewEnabled = useIsFeatureFlagEnabled('PATIENT_OVERVIEW');
  const isPatientOverviewAvailable =
    isPatientOverviewEnabled && patientOverviews.length > 0;

  return (
    <>
      <AppointmentDetails appointmentId={appointmentId} tenantId={tenantId} />
      <Content padded size="sm">
        <VStack wFull gap={2}>
          <AppointmentName />
          <EluveAdminControls />
        </VStack>

        {isPatientOverviewAvailable && (
          <Box>
            <Box className="overflow-y-auto rounded-lg py-3">
              <PatientOverview includeIcon includeHistory={false} />
            </Box>
            <Divider />
          </Box>
        )}

        <NewAppointmentForm
          tenantId={tenantId}
          appointmentId={appointmentId}
          isCompactMicrophone={isPatientOverviewAvailable}
          isSettingsAvailable
        />
      </Content>
    </>
  );
};
