import copy from 'copy-to-clipboard';

import { convertMarkdownToPlainText } from '@eluve/utils';

export const useCopyContentElement = ({
  element,
  markdownContent,
  onCopied,
}: {
  element: HTMLElement | null;
  markdownContent?: string;
  onCopied?: () => void;
}) => {
  return async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!element) return;

    const clonedElement = element.cloneNode(true) as HTMLElement;
    clonedElement.querySelectorAll('button').forEach((a) => {
      a.remove();
    });

    const outerHTML = clonedElement.innerHTML;
    const plainText = markdownContent
      ? convertMarkdownToPlainText(markdownContent)
      : clonedElement.textContent ?? '';

    if (typeof ClipboardItem !== 'undefined') {
      const html = new Blob([outerHTML], { type: 'text/html' });
      const text = new Blob([plainText], { type: 'text/plain' });
      const data = new ClipboardItem({ 'text/html': html, 'text/plain': text });
      await navigator.clipboard.write([data]);
    } else {
      copy(outerHTML, { format: 'text/html' });
      copy(plainText);
    }

    onCopied?.();
    e.currentTarget?.blur();
  };
};
