import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  appointmentFileTypesSchema,
  supportedApiBasedVendorsSchema,
  supportedVendorsSchema,
} from '@eluve/graphql-types';

const c = initContract();

const importAppointmentsRequestSchema = z.object({
  data: z.record(z.unknown()),
  vendor: supportedVendorsSchema,
  domain: z.string(),
  timezone: z.string().nullish(),
});

export type ImportAppointmentsRequest = z.infer<
  typeof importAppointmentsRequestSchema
>;

const prepareAppointmentFileUploadRequestSchema = z.object({
  uploadId: z.string(),
  extension: z.string(),
  fileType: appointmentFileTypesSchema,
  fileName: z.string(),
  fileSize: z.number(),
  fileHash: z.string(),
});
export type PrepareAppointmentFileUploadRequest = z.infer<
  typeof prepareAppointmentFileUploadRequestSchema
>;

const markFileUploadCompleteRequestSchema = z.object({
  uploadId: z.string().uuid(),
  shouldBeTranscribed: z.boolean(),
  recordingStartedAt: z.string(),
  recordingDuration: z.number().nullish(),
  wasSegmentDegraded: z.boolean(),
});

export type MarkFileUploadCompleteRequest = z.infer<
  typeof markFileUploadCompleteRequestSchema
>;

export const appointmentsContract = c.router(
  {
    getAppointments: {
      method: 'GET',
      path: '/',
      responses: {
        200: z.array(
          z.object({
            appointmentId: z.string(),
            externalAppointmentId: z.string().nullish(),
            name: z.string(),
            description: z.string().nullish(),
            startDate: z.date(),
            endDate: z.date().nullish(),
          }),
        ),
      },
      query: z.object({
        startDate: z.string(),
        endDate: z.string(),
        status: z
          .array(z.enum(['NOT_STARTED', 'COMPLETED', 'ACTIVE', 'CANCELLED']))
          .default(['NOT_STARTED']),
        vendor: supportedVendorsSchema.optional(),
        domain: z.string().optional(),
      }),
      summary: 'Get appointments',
    },
    syncToEhr: {
      method: 'POST',
      path: ':appointmentId/sync-to-ehr',
      responses: {
        201: z.object({
          chartId: z.string(),
          chartUrl: z.string(),
        }),
      },
      body: z.object({
        vendor: supportedApiBasedVendorsSchema,
        domain: z.string(),
      }),
      summary: 'Sync summary to an external EHR',
    },
    create: {
      method: 'POST',
      path: '',
      responses: {
        201: z.object({
          id: z.string(),
          magicLink: z.string(),
        }),
      },
      headers: z.object({
        authorization: z.string(),
      }),
      body: z.object({
        vendor: supportedVendorsSchema,
        user: z.unknown(),
        appointment: z.unknown(),
      }),
      summary: 'Create new or get existing appointment',
    },
    importAppointmentsFromApi: {
      method: 'POST',
      path: 'api-import',
      responses: {
        201: z.array(
          z.object({
            id: z.string(),
          }),
        ),
      },
      body: z.object({
        vendor: supportedApiBasedVendorsSchema,
        domain: z.string(),
        data: z.object({
          startDate: z.number(),
          endDate: z.number(),
        }),
      }),
      summary: 'Import appointments from vendor API',
    },
    importAppointments: {
      method: 'POST',
      path: 'import',
      responses: {
        201: z.array(
          z.object({
            id: z.string(),
          }),
        ),
      },
      body: importAppointmentsRequestSchema,
      summary: 'Import appointments',
    },
    getOrCreatePdfUrl: {
      method: 'POST',
      path: ':appointmentId/pdf',
      responses: {
        201: z.object({
          pdfUrl: z.string(),
        }),
      },
      body: z.object({}),
      summary: 'Get or create a PDF URL for an appointment',
    },
    /**
     * Prepare a file upload for an appointment by creating necessary DB records
     * to track the attempt and then returning a presigned upload URL
     */
    prepareAppointmentFileUpload: {
      method: 'POST',
      path: ':appointmentId/file-upload',
      body: prepareAppointmentFileUploadRequestSchema,
      responses: {
        201: z.object({
          uploadId: z.string(),
          gcsFilePath: z.string(),
          uploadURL: z.string(),
          status: z.enum(['FILE_UPLOAD_REQUIRED']),
        }),
        200: z.object({
          status: z.enum(['FILE_ALREADY_EXISTS']),
          uploadId: z.string(),
        }),
      },
    },
    /**
     * Mark a file upload that we previously prepped as complete once the
     * file has been succesfully uploaded to GCS
     */
    markFileUploadComplete: {
      method: 'POST',
      path: ':appointmentId/file-upload/complete',
      body: markFileUploadCompleteRequestSchema,
      responses: {
        200: z.object({}),
      },
    },
    /**
     * Sets up an appointment for manual charting mode
     */
    startAppointmentInManualChartingMode: {
      method: 'POST',
      path: ':appointmentId/manual-mode',
      body: z.object({
        llmOutputTemplateId: z.string().optional(),
        duplicatedAppointmentId: z.string().optional(),
      }),
      responses: {
        200: z.object({}),
      },
    },
    /**
     * Generate billing code recommendations for an appointment in manual charting mode
     * using the latest human output instead of transcripts
     */
    generateBillingCodes: {
      method: 'POST',
      path: ':appointmentId/billing-codes/generate',
      body: z.object({}),
      responses: {
        200: z.object({}),
        422: z.object({
          message: z.string(),
        }),
      },
    },
  },
  {
    pathPrefix: '/:tenantId/appointments/',
  },
);
