import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import format from 'date-fns/format';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useCompleteFragment } from '@eluve/apollo-client';
import {
  FormField,
  HStack,
  Input,
  Label,
  NewButton,
  VStack,
  toast,
} from '@eluve/components';
import { useAssignedTenantIdFromParams } from '@eluve/session-helpers';

import {
  tenantDefaultSettings,
  updatePreferredDateFormatMutation,
} from './operations';

const userFormSchema = z.object({
  preferredDateFormat: z
    .string()
    .min(1, 'Format is required')
    .refine((formatString) => {
      try {
        format(new Date(), formatString);
        return true;
      } catch {
        return false;
      }
    }, 'Invalid date format'),
});

type UserFormValues = z.infer<typeof userFormSchema>;

export const PreferredDateFormatSetting: React.FC = () => {
  const tenantId = useAssignedTenantIdFromParams();
  const [isEditing, setIsEditing] = useState(false);

  const userPreferences = useCompleteFragment({
    fragment: tenantDefaultSettings,
    key: { id: tenantId },
  });

  const { control, formState, handleSubmit, reset } = useForm<UserFormValues>({
    resolver: zodResolver(userFormSchema),
    defaultValues: {
      preferredDateFormat:
        userPreferences?.default_tenant_user_settings?.preferredDateFormat ??
        'P',
    },
  });

  const [updatePreferences] = useMutation(updatePreferredDateFormatMutation, {
    onError: () => {
      toast.error('Failed to update date format');
      setIsEditing(true);
    },
    optimisticResponse: (input) => ({
      updateDefaultTenantUserSettingsByPk: {
        __typename: 'DefaultTenantUserSettings' as const,
        tenantId,
        inputLanguage:
          userPreferences.default_tenant_user_settings?.inputLanguage ?? '',
        outputLanguage:
          userPreferences.default_tenant_user_settings?.outputLanguage ?? '',
        preferredDateFormat: input.preferredDateFormat,
      },
    }),
  });

  const onSubmit = handleSubmit((data) => {
    setIsEditing(false);

    return updatePreferences({
      variables: {
        tenantId,
        preferredDateFormat: data.preferredDateFormat,
      },
    });
  });

  const handleCancel = () => {
    reset({
      preferredDateFormat:
        userPreferences?.default_tenant_user_settings?.preferredDateFormat ??
        'P',
    });
    setIsEditing(false);
  };

  if (!isEditing) {
    return (
      <VStack gap={4} className="w-full">
        {userPreferences?.default_tenant_user_settings?.preferredDateFormat ??
          'P'}
        <NewButton
          type="outline"
          onClick={() => setIsEditing(true)}
          text="Edit"
        />
      </VStack>
    );
  }

  return (
    <VStack gap={4} className="w-full">
      <Label>
        <VStack>
          <FormField
            control={control}
            name="preferredDateFormat"
            render={({ field }) => (
              <Input placeholder="Enter date format" {...field} />
            )}
          />
        </VStack>
      </Label>

      <HStack gap={2}>
        <NewButton type="subtle" onClick={handleCancel} text="Cancel" />
        <NewButton
          type="primary"
          onClick={onSubmit}
          text="Save"
          disabled={
            formState.isSubmitting || !formState.isValid || !formState.isDirty
          }
        />
      </HStack>
    </VStack>
  );
};
