import { useMutation, useSuspenseQuery } from '@apollo/client';
import { produce } from 'immer';
import React, { useState } from 'react';

import { cacheUtils } from '@eluve/apollo-client';
import {
  ColDefBuilder,
  DataTable,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Input,
  NewButton,
  VStack,
  toast,
} from '@eluve/components';
import { useAssignedTenantIdFromParams } from '@eluve/session-helpers';
import { useDialog } from '@eluve/utility-hooks';

import {
  createUserGroupMutation,
  getTenantUserGroupsQuery,
  tenantUserGroupsFragment,
} from './operations';

export interface UserGroupsListPageProps {}

type Row = {
  id: string;
  name: string;
};

const cols = new ColDefBuilder<Row>()
  .detailsLink((r) => r.id)
  .defaultSortable('name')
  .build();

export const UserGroupsListPage: React.FC<UserGroupsListPageProps> = () => {
  const tenantId = useAssignedTenantIdFromParams();

  const { isDialogOpen, toggleDialog } = useDialog();
  const [newGroupName, setNewGroupName] = useState('');

  const { data } = useSuspenseQuery(getTenantUserGroupsQuery, {
    variables: {
      tenantId,
    },
  });

  const [createUserGroup] = useMutation(createUserGroupMutation, {
    onError: () => toast.error('Failed to create user group'),
    optimisticResponse: () => ({
      insertUserGroupsOne: {
        __typename: 'UserGroups' as const,
        id: 'optimistic',
        name: newGroupName,
      },
    }),
    update: (_cache, { data }) => {
      cacheUtils.updateFragment(
        {
          fragment: tenantUserGroupsFragment,
          key: {
            id: tenantId,
          },
        },
        (existing) => {
          const newGroup = data?.insertUserGroupsOne;
          if (!existing || !newGroup) {
            return existing;
          }

          return produce(existing, (draft) => {
            draft.user_groups.push(newGroup);
          });
        },
      );
    },
  });

  const handleCreateUserGroup = async () => {
    const result = await createUserGroup({
      variables: {
        name: newGroupName,
      },
    });

    setNewGroupName('');
    toggleDialog();
  };

  return (
    <VStack>
      <Dialog open={isDialogOpen} onOpenChange={toggleDialog} modal>
        <DialogTrigger asChild>
          <NewButton
            text="Create User Group"
            type="primary"
            icon={{
              name: 'Plus',
            }}
          />
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>New User Group</DialogTitle>
          </DialogHeader>
          <Input
            placeholder="Enter group name"
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.target.value)}
          />
          <DialogFooter>
            <NewButton type="outline" onClick={toggleDialog} text="Cancel" />
            <NewButton
              text="Add"
              onClick={handleCreateUserGroup}
              disabled={!newGroupName}
            />
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <DataTable
        enableGlobalSearch
        columns={cols}
        data={data.tenantsByPk?.user_groups ?? []}
      />
    </VStack>
  );
};
