import { graphql } from '@eluve/graphql.tada';

export const modelArgsFragment = graphql(`
  fragment modelArgs on query_root @_unmask {
    llmModelArgs(where: { isActive: { _eq: true } }) {
      __typename
      id
      name
      modelType
      args
    }
  }
`);

export const modelPromptTemplatesFragment = graphql(`
  fragment modelPromptTemplates on query_root @_unmask {
    __typename
    llmModelPromptTemplates(
      where: { isActive: { _eq: true }, template: { isActive: { _eq: true } } }
    ) {
      __typename
      id
      template {
        __typename
        id
        name
        outputType
      }
      model_args {
        __typename
        id
        name
        modelType
        args
      }
      modelArgsId
    }
  }
`);

export const outputTemplatesFragment = graphql(`
  fragment outputTemplates on query_root @_unmask {
    __typename
    llmOutputTemplates(
      where: { isActive: { _eq: true }, llmOutputType: { _eq: SOAP_NOTE } }
    ) {
      __typename
      id
      name
      description
      llmOutputType
    }
  }
`);

export const promptTemplatesFragment = graphql(`
  fragment promptTemplates on query_root @_unmask {
    __typename
    promptTemplates(
      where: {
        isActive: { _eq: true }
        outputType: { _in: [SOAP_NOTE, DYNAMIC_OUTPUT] }
      }
    ) {
      __typename
      id
      name
      outputType

      prompt_models(
        where: {
          isActive: { _eq: true }
          model_args: { isActive: { _eq: true } }
        }
      ) {
        __typename
        id
        model_args {
          __typename
          id
          name
          args
          modelType
        }
      }
    }
  }
`);

export const artifactsFragment = graphql(`
  fragment artifacts on query_root @_unmask {
    evalDatasets {
      __typename
      id
      name
      description
      datasetSourceArtifacts {
        __typename
        id
        sourceArtifact {
          __typename
          id
          name
          factsAggregate {
            __typename
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`);

export const getWorkflowOptionsQuery = graphql(
  `
    query getWorkflowOptions {
      ...modelPromptTemplates
      ...artifacts
      ...outputTemplates
      ...modelArgs
      ...promptTemplates
    }
  `,
  [
    modelPromptTemplatesFragment,
    artifactsFragment,
    outputTemplatesFragment,
    modelArgsFragment,
    promptTemplatesFragment,
  ],
);
