import { createTw } from 'react-pdf-tailwind';

export const tw = createTw({
  theme: {
    fontFamily: {
      sans: ['Inter'],
    },
    extend: {
      colors: {
        brandGray50: '#FAFAFA',
        brandGray100: '#F5F5F5',
        brandGray200: '#F0F0F0',
        brandGray300: '#DEDEDE',
        brandGray400: '#C2C2C2',
        brandGray500: '#979797',
        brandGray600: '#818181',
        brandGray700: '#606060',
        brandGray800: '#3C3C3C',
        brandGray900: '#252527',
        brandGray950: '#171717',
      },
    },
  },
});
