import { useMutation, useSuspenseQuery } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import React, { ReactNode, useState } from 'react';
import { Helmet } from 'react-helmet';
import { P, match } from 'ts-pattern';

import { useCompleteFragment } from '@eluve/apollo-client';
import { usePrivacyModeToggle } from '@eluve/blocks';
import {
  Box,
  Content,
  FCC,
  H3,
  HStack,
  Header,
  Kbd,
  NewButton,
  PageTitle,
  Separator,
  SidebarTrigger,
  Switch,
  Textarea,
  VStack,
  textStyles,
  toast,
} from '@eluve/components';
import {
  EnableSoundEffects,
  LanguageSettings,
  PromptTemplateSettings,
  ProviderDetailsSetting,
  UserProfileSettings,
  userFragment,
} from '@eluve/frontend-feature-user-settings';
import { graphql } from '@eluve/graphql.tada';
import {
  useAssignedTenantIdFromParams,
  useUserIdFromSession,
} from '@eluve/session-helpers';
import {
  MicrophonePermissionProvider,
  TenantOrUserFeatureFlaggedComponent,
  UserFeatureFlaggedComponent,
} from '@eluve/smart-blocks';
import { useTenantLocalStorage } from '@eluve/utility-hooks';
import { useBeforeUnload } from '@eluve/utility-hooks';

import { useShowAppointmentsOwner } from '../../../settings/hooks/useShowAppointmentsOwner';

import { MicSettings } from './MicSettings';

const isString = (x: unknown): x is string => typeof x === 'string';

const userSettingsFragment = graphql(`
  fragment UserSettings on TenantUserSettings {
    __typename
    userId
    tenantId
    customNoteInstructions
    providerDetails
  }
`);

const updateCustomInstructionsMutation = graphql(
  `
    mutation UpdateCustomInstructions(
      $tenantId: uuid!
      $userId: uuid!
      $instructions: String!
    ) {
      updateTenantUserSettingsByPk(
        pkColumns: { tenantId: $tenantId, userId: $userId }
        _set: { customNoteInstructions: $instructions }
      ) {
        ...UserSettings
      }
    }
  `,
  [userSettingsFragment],
);

const getTenantUserSettingsQuery = graphql(
  `
    query getTenantUserSettings($tenantId: uuid!, $userId: uuid!) {
      tenantUserSettingsByPk(tenantId: $tenantId, userId: $userId) {
        ...UserSettings
      }
      usersByPk(id: $userId) {
        __typename
        id
        ...User
      }
    }
  `,
  [userSettingsFragment, userFragment],
);

const SettingsSection: FCC<{ title: string; description?: ReactNode }> = ({
  title,
  description,
  children,
}) => {
  return (
    <Box className="flex w-full flex-col justify-between gap-2 md:flex-row">
      <Box className="w-80 md:w-96 lg:w-128">
        <h3 className={textStyles.body({ size: 'l', weight: 'semibold' })}>
          {title}
        </h3>
      </Box>
      <VStack>
        {description &&
          match(description)
            .with(P.when(isString), (d) => (
              <h4
                className={textStyles.body({ size: 's', weight: 'semibold' })}
              >
                {d}
              </h4>
            ))
            .otherwise(() => description)}
        {children}
      </VStack>
    </Box>
  );
};

const advancedMonitoringThresholdHours = 48;

export const TenantUserSettingsPage: React.FC = () => {
  const tenantId = useAssignedTenantIdFromParams();
  const userId = useUserIdFromSession();
  const { setPrivacyMode, privacyMode } = usePrivacyModeToggle();
  const [showAppointmentsOwner, setShowAppointmentsOwner] =
    useShowAppointmentsOwner();

  const [enabledAdvancedMonitoring, setEnabledAdvancedMonitoring] =
    useTenantLocalStorage({
      initialValue: false,
      key: 'ADVANCED_MONITORING',
      tenantId,
      ttlInSeconds: 60 * 60 * advancedMonitoringThresholdHours,
    });

  useSuspenseQuery(getTenantUserSettingsQuery, {
    variables: { tenantId, userId },
  });

  const [setCustomInstructions] = useMutation(
    updateCustomInstructionsMutation,
    {
      onError: () => toast.error('Failed to save instructions'),
    },
  );

  const { customNoteInstructions } =
    useCompleteFragment({
      fragment: userSettingsFragment,
      key: { tenantId, userId },
      strict: false,
    }) ?? {};

  const [instructions, setInstructions] = useState<string>(
    customNoteInstructions ?? '',
  );

  const unsavedChanges =
    (Boolean(instructions) || Boolean(customNoteInstructions)) &&
    (instructions ?? null) !== (customNoteInstructions ?? null);

  useBeforeUnload(unsavedChanges);

  const saveInstructions = async () => {
    await setCustomInstructions({
      variables: { tenantId, userId, instructions },
    });
  };

  return (
    <>
      <Helmet>
        <title>Settings | Eluve</title>
      </Helmet>
      <>
        <Header>
          <SidebarTrigger />
          <PageTitle>Settings</PageTitle>
        </Header>
        <Content padded>
          <VStack gap={6} className="py-4">
            <H3>Audio Settings</H3>
            <SettingsSection
              title="Microphone"
              description="Test and pick your preferred audio input device"
            >
              <MicrophonePermissionProvider>
                <MicSettings />
              </MicrophonePermissionProvider>
            </SettingsSection>

            <Separator />

            <H3>User Settings</H3>
            <SettingsSection
              title="Your Profile"
              description="Update your name as it appears in the system"
            >
              <UserProfileSettings />
            </SettingsSection>

            <SettingsSection
              title="Medical Provider Details"
              description="Enter your name, specialty, license number, NPI number, and any other relevant details about your medical practice"
            >
              <ProviderDetailsSetting />
            </SettingsSection>

            <SettingsSection
              title="Preferred Chart Style"
              description="Select your preferred default output style"
            >
              <PromptTemplateSettings className="w-full max-w-96" />
            </SettingsSection>

            <TenantOrUserFeatureFlaggedComponent flag="MULTILINGUAL_SUPPORT">
              <SettingsSection title="Language">
                <LanguageSettings />
              </SettingsSection>
            </TenantOrUserFeatureFlaggedComponent>

            <SettingsSection
              title="Privacy Mode"
              description={
                <h4
                  className={textStyles.body({ size: 's', weight: 'semibold' })}
                >
                  Enable privacy mode to hide patient information in the UI. It
                  can be toggled from anywhere in the app by pressing the
                  keyboard shortcut <Kbd>Shift</Kbd> + <Kbd>P</Kbd>.
                </h4>
              }
            >
              <HStack gap={4} asChild>
                <label>
                  <Switch
                    checked={privacyMode}
                    onCheckedChange={(checked) => setPrivacyMode(!checked)}
                  />
                  <i className="text-sm text-gray-11">
                    Ex : Patient name:
                    <strong className="privacy-text"> John Doe</strong>
                  </i>
                </label>
              </HStack>
            </SettingsSection>
            <SettingsSection
              title="Sound Effects"
              description="Play sound effects when starting/stopping recordings."
            >
              <EnableSoundEffects />
            </SettingsSection>
            <SettingsSection
              title="Appointment Owners"
              description="Displays the name of the owner of appointments in the agenda view"
            >
              <HStack gap={4}>
                <label>
                  <Switch
                    checked={showAppointmentsOwner}
                    onCheckedChange={(checked) =>
                      setShowAppointmentsOwner(checked)
                    }
                  />
                </label>
              </HStack>
            </SettingsSection>
            <SettingsSection
              title="Share screen with Eluve Support"
              description={
                <h4
                  className={textStyles.body({ size: 's', weight: 'semibold' })}
                >
                  Temporarily monitor interactions for debugging. This will
                  record your interactions on Eluve products for up to{' '}
                  {advancedMonitoringThresholdHours} hours and then
                  automatically turn off. These recordings will be saved for up
                  to 30 days in a HIPAA compliant environment and then be
                  automatically deleted.
                </h4>
              }
            >
              <HStack gap={4} asChild>
                <label>
                  <Switch
                    checked={Boolean(enabledAdvancedMonitoring)}
                    onCheckedChange={(checked) => {
                      setEnabledAdvancedMonitoring(checked);
                      datadogRum.setTrackingConsent(
                        checked ? 'granted' : 'not-granted',
                      );
                    }}
                  />
                </label>
              </HStack>
            </SettingsSection>

            <UserFeatureFlaggedComponent flag="USER_PREFERENCES_CUSTOM_INSTRUCTIONS">
              <Separator />
              <SettingsSection
                title="Custom Instructions"
                description="Customize your notes by providing specific guidelines. Try to be as clear and concise as possible for best results"
              >
                <Textarea
                  placeholder="Type your instructions here"
                  rows={6}
                  value={instructions}
                  onChange={(e) => {
                    setInstructions(e.target.value);
                  }}
                />
                <NewButton
                  text="Save"
                  disabled={!unsavedChanges}
                  onClick={saveInstructions}
                  type="primary"
                />
              </SettingsSection>
            </UserFeatureFlaggedComponent>
          </VStack>
        </Content>
      </>
    </>
  );
};
