import { Box, Content } from '@eluve/components';
import { useAppointmentTranscript } from '@eluve/frontend-appointment-hooks';
import { AppointmentName, EluveAdminOnly } from '@eluve/smart-blocks';

import { AppointmentFilesDrawer } from './AppointmentFilesDrawer';
import { appointmentLayoutStyles } from './appointmentLayoutStyles';
import { AppointmentSummaryFooter } from './components/AppointmentSummaryFooter';
import {
  AppointmentTabs,
  SummaryTabContent,
  TranscriptTabContent,
} from './components/AppointmentTabs';
import { ExternalChartLink } from './components/ExternalChartLink';
import { ReadonlyAppointmentDetails } from './components/ReadonlyAppointmentDetails';
import { SignedSummaryResult } from './components/SignedSummaryResult';
import { useAppointmentTabNav } from './useAppointmentTabNav';

interface SignedAppointmentLayoutProps {
  appointmentId: string;
}

export const SignedAppointmentLayout: React.FC<
  SignedAppointmentLayoutProps
> = ({ appointmentId }) => {
  const { transcript } = useAppointmentTranscript();
  const { tab } = useAppointmentTabNav();
  return (
    <>
      <ReadonlyAppointmentDetails appointmentId={appointmentId} />
      <Content padded>
        <Box vStack className={appointmentLayoutStyles()}>
          <Box spaceBetween fullWidth className="p-2">
            <AppointmentName readonly />
            <EluveAdminOnly>
              <AppointmentFilesDrawer />
            </EluveAdminOnly>
          </Box>
          <ExternalChartLink appointmentId={appointmentId} />
          <AppointmentTabs />
          <TranscriptTabContent>
            <Box className="h-full overflow-y-auto pb-8">
              <p className="whitespace-pre-line px-2 align-baseline font-normal !leading-10 text-gray-11">
                {transcript}
              </p>
            </Box>
          </TranscriptTabContent>
          <SummaryTabContent>
            <SignedSummaryResult appointmentId={appointmentId} />
          </SummaryTabContent>
        </Box>
      </Content>
      {tab === 'summary' && <AppointmentSummaryFooter />}
    </>
  );
};
