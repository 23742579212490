import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { tv } from 'tailwind-variants';

import { PdfTable } from './Table';
import { tw } from './pdf-tw';

export type SuperBillLineItem = {
  code: string;
  description: string;
  modifier: string;
  diagnosisPointer: string;
  fee: string;
  quantity: string;
  total: string;
};

export type SuperbillProps = {
  logoUrl?: string;
  patientInfo: {
    name: string;
    dob: string;
    address?: string;
  };
  location?: {
    address?: string | null;
    phoneNumber?: string | null;
  };
  date: string;
  providerName: string;
  lineItems: SuperBillLineItem[];
  diagnosisCodes?: {
    id: string;
    number: string;
    code: string;
    description: string;
  }[];
  totalCharges: string;
};

const styles = tv({
  slots: {
    row: 'flex w-full flex-row gap-4 px-1 text-sm',
  },
});

const { row } = styles();

const Header = ({ text }: { text: string }) => {
  return (
    <View style={tw('w-full bg-brandGray950 p-2 text-sm')}>
      <Text style={tw('text-brandGray50')}>{text}</Text>
    </View>
  );
};

export const SuperbillPdf = (props: SuperbillProps) => {
  const {
    logoUrl,
    patientInfo,
    lineItems,
    totalCharges,
    providerName,
    diagnosisCodes = [],
    location = {},
    date,
  } = props;

  return (
    <Document>
      <Page size="A4" style={tw('px-4 py-8 text-brandGray900 font-[Inter]')}>
        <View style={tw('flex flex-row w-full justify-between')}>
          {logoUrl ? (
            <Image style={tw('max-w-64')} src={logoUrl}></Image>
          ) : (
            <View />
          )}

          <View style={tw('flex flex-col gap-0.5 text-sm items-end')}>
            {Boolean(location?.address) && <Text>{location.address}</Text>}
            {Boolean(location?.phoneNumber) && (
              <Text
                style={{
                  textAlign: 'center',
                }}
              >
                Tel: {location.phoneNumber}
              </Text>
            )}
          </View>
        </View>

        <Text
          style={tw(
            'text-3xl mt-6 mb-2 leading-6 font-light text-brandGray700',
          )}
        >
          Superbill
        </Text>

        <View style={tw('flex flex-col w-full gap-2')}>
          <Header text="Session Information" />
          <PdfTable<'date' | 'provider' | 'placeOfService'>
            columns={{
              date: {
                label: 'Date of Visit',
                width: 20,
              },
              provider: {
                width: 30,
              },
              placeOfService: {
                width: 50,
              },
            }}
            rows={[
              {
                date: date,
                provider: providerName,
                placeOfService: '--',
              },
            ]}
          />
          <Header text="Patient Information" />
          <View
            style={tw(
              row({
                className: 'w-full bg-brandGray400',
              }),
            )}
          ></View>
          <PdfTable<'name' | 'dateOfBirth' | 'address'>
            columns={{
              name: {
                width: 20,
              },
              dateOfBirth: {
                width: 30,
              },
              address: {
                width: 50,
              },
            }}
            rows={[
              {
                name: patientInfo.name,
                dateOfBirth: patientInfo.dob,
                address: patientInfo.address ?? '',
              },
            ]}
          />
          <Header text="Diagnosis" />
          <PdfTable<'number' | 'code' | 'description'>
            columns={{
              number: {
                label: '#',
                width: 20,
              },
              code: {
                width: 30,
              },
              description: {
                width: 50,
              },
            }}
            rows={diagnosisCodes.map((dc, i) => ({
              number: `${i + 1}`,
              code: dc.code,
              description: dc.description,
            }))}
          />
          <Header text="Session" />
          <PdfTable<
            | 'code'
            | 'description'
            | 'modifier'
            | 'diagnosisPointer'
            | 'fee'
            | 'quantity'
            | 'total'
          >
            columns={{
              code: {
                label: 'Billing Code',
                width: 15,
              },
              description: {
                width: 30,
              },
              modifier: {
                alignText: 'center',
                width: 14,
              },
              diagnosisPointer: {
                alignText: 'center',
                width: 17,
              },
              fee: {
                alignText: 'right',
                width: 8,
              },
              quantity: {
                alignText: 'right',
                width: 8,
              },
              total: {
                alignText: 'right',
                width: 8,
              },
            }}
            rows={lineItems}
          />

          <Header text="Summary" />
          <View style={tw('w-full flex flex-row justify-end')}>
            <View style={tw('grow')}></View>
            <View style={tw('flex flex-row gap-6 items-center text-sm')}>
              <Text style={tw('font-semibold')}>Total Charges</Text>
              <Text>{totalCharges}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
