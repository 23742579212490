import { CaretDownIcon } from '@radix-ui/react-icons';
import { Save } from 'lucide-react';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@eluve/components';
import {
  useAppointmentActivity,
  useAppointmentId,
  useSummary,
} from '@eluve/frontend-appointment-hooks';
import { AppointmentPdf } from '@eluve/frontend-feature-appointment';
import { useTenantIdFromParams } from '@eluve/session-helpers';
import { FeatureFlaggedComponent } from '@eluve/smart-blocks';

import { useHandleSavePdf } from '../hooks/useHandleSavePdf';

export const AppointmentPDFOutputControls = () => {
  const { summary, isSummaryAvailable } = useSummary();
  const tenantId = useTenantIdFromParams();
  const { isSummarizationInProgress } = useAppointmentActivity();

  const appointmentId = useAppointmentId();
  const handleSavePdf = useHandleSavePdf();

  if (isSummarizationInProgress || !isSummaryAvailable) {
    return null;
  }

  return (
    <FeatureFlaggedComponent flag="PDF_OUTPUT">
      <Dialog>
        <Box hStack className="w-full gap-0">
          <DialogTrigger asChild>
            <Button variant="outline" className="w-full rounded-r-none">
              Preview PDF
            </Button>
          </DialogTrigger>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" size="icon" className="rounded-l-none">
                <CaretDownIcon />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent side="top">
              <DropdownMenuItem
                disabled={!tenantId}
                className="flex cursor-pointer items-center gap-2"
                onClick={handleSavePdf}
              >
                <Save className="size-5" />
                <span>Save PDF</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </Box>
        <DialogContent className="max-w-7xl">
          <DialogHeader>
            <DialogTitle>PDF Note</DialogTitle>
          </DialogHeader>
          <AppointmentPdf
            tenantId={tenantId ?? ''}
            appointmentId={appointmentId}
            summary={summary!}
          />
        </DialogContent>
      </Dialog>
    </FeatureFlaggedComponent>
  );
};
