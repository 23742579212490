export const formatHumanName = (
  firstName = '',
  lastName?: string | null,
): string => {
  return [firstName, lastName].filter(Boolean).join(' ');
};

export const getUserName = ({
  firstName,
  lastName,
  email,
}: {
  firstName?: string;
  lastName?: string | null;
  email?: string;
}): string => {
  if (firstName || lastName) {
    return formatHumanName(firstName, lastName);
  }
  return email ?? '';
};
