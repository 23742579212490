import { ApolloClient } from '@apollo/client';
import { fromPromise } from 'xstate';

import { graphql } from '@eluve/graphql.tada';

export const updateSegmentEndedAtMutation = graphql(`
  mutation updateSegmentEndedAt(
    $tenantId: uuid!
    $appointmentId: uuid!
    $segmentId: uuid!
    $recordingEndedAt: timestamptz!
  ) {
    updateAppointmentSegmentsByPk(
      pkColumns: {
        tenantId: $tenantId
        appointmentId: $appointmentId
        id: $segmentId
      }
      _set: { recordingEndedAt: $recordingEndedAt }
    ) {
      id
      recordingEndedAt
    }
  }
`);

export interface SetSegmentRecordingEndedAtInput {
  apolloClient: ApolloClient<unknown>;
  tenantId: string;
  appointmentId: string;
  segmentId: string;
  recordingEndedAt: string;
}

export const setSegmentRecordingEndedAt = fromPromise<
  unknown,
  SetSegmentRecordingEndedAtInput
>(async ({ input }) => {
  const { apolloClient, tenantId, appointmentId, segmentId, recordingEndedAt } =
    input;

  await apolloClient.mutate({
    mutation: updateSegmentEndedAtMutation,
    variables: {
      tenantId,
      appointmentId,
      segmentId,
      recordingEndedAt: recordingEndedAt,
    },
  });
});
