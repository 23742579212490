import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

export const avatarContract = c.router(
  {
    getSessionKeys: {
      method: 'GET',
      path: '/get-session-keys',
      pathParams: z.object({
        appointmentId: z.string(),
        tenantId: z.string(),
      }),
      responses: {
        200: z.object({ apiToken: z.string(), sessionId: z.string() }),
      },
      summary: 'Fetches key for a realtime avatar session',
    },
    addSessionMessage: {
      method: 'POST',
      path: '/add-session-message',
      pathParams: z.object({
        appointmentId: z.string(),
        tenantId: z.string(),
      }),
      responses: {
        200: z.void(),
      },
      body: z.object({
        conversationId: z.string(),
        role: z.enum(['user', 'assistant']),
        messageId: z.string(),
        messageTimestamp: z.number(),
        content: z.string(),
      }),
      summary: 'Inserts a single message for a given appointment',
    },
    getSessionAppointmentDetails: {
      method: 'GET',
      path: '/get-session-details',
      pathParams: z.object({
        appointmentId: z.string(),
        tenantId: z.string(),
      }),
      responses: {
        200: z.object({
          start_date: z.date(),
          end_date: z.date(),
          status: z.string(),
          patient_reason_for_visit: z.string(),
          first_name: z.string(),
          last_name: z.string(),
          location_name: z.string().nullable(),
          location_address: z.string().nullable(),
          provider_first_name: z.string().nullable(),
          provider_last_name: z.string().nullable(),
        }),
        404: z.object({ message: z.string() }),
      },
      summary: 'Gets the details of a realtime session',
    },
    endConversation: {
      method: 'POST',
      path: '/:appointmentConversationId/end-conversation',
      body: z.object({
        patientId: z.string(),
      }),
      responses: {
        200: z.object({}),
      },
      summary: 'Finalizes avatar conversation with patient',
    },
  },
  {
    pathPrefix: '/:tenantId/:appointmentId/avatar',
  },
);
