import { Text, View } from '@react-pdf/renderer';
// eslint-disable-next-line no-restricted-imports
import { tv } from 'tailwind-variants';

import { Block } from '@eluve/llm-outputs';

import { tw } from '../pdf-tw';
import { commonStyles } from '../styles';
import { PdfAmendment } from '../types';

import { TextAmendments } from './TextAmendments';

const styles = tv({
  slots: {
    checkboxSymbol: 'text-sm font-normal',
    noContent: 'text-sm font-normal text-brandGray500',
    checkboxRow: 'flex-row',
  },
})();

export const PdfCheckboxField: React.FC<{
  block: Block;
  amendments: PdfAmendment[];
}> = ({ block, amendments }) => {
  if (block.type !== 'checkbox') return null;
  return (
    <View key={block.key}>
      {block.label && (
        <Text style={tw(commonStyles.headingText())}>{block.label}</Text>
      )}
      <View style={tw(commonStyles.content())}>
        {block.options && block.options.length > 0 ? (
          block.options.map((option, index) => (
            <View key={index} style={tw(styles.checkboxRow())}>
              <Text style={tw(styles.checkboxSymbol())}>
                {option.isChecked ? '☑' : '☐'}
              </Text>
              <Text style={tw(commonStyles.text())}>{option.label}</Text>
            </View>
          ))
        ) : (
          <Text style={tw(styles.noContent())}>No options</Text>
        )}
        <TextAmendments blockPath={block.key} amendments={amendments} />
      </View>
    </View>
  );
};
