import { Image, Text, View } from '@react-pdf/renderer';
// eslint-disable-next-line no-restricted-imports
import { tv } from 'tailwind-variants';

import { tw } from '../pdf-tw';
import { commonStyles } from '../styles';

const styles = tv({
  slots: {
    header: 'flex-row justify-between',
    logoContainer: 'w-1/3',
    clinicInfoContainer: 'w-1/2 text-right',
    logo: 'max-h-[48px] max-w-[120px]',
    clinicName: 'text-right ' + commonStyles.boldText(),
  },
})();

export const LocationHeader: React.FC<{
  tenant: {
    name: string;
  };
  location?: {
    logoUrl?: string;
    name?: string;
    address?: string;
    phoneNumber?: string;
    email?: string;
  };
}> = ({ tenant, location }) => {
  const clinicName = [tenant.name, location?.name].filter(Boolean).join(' - ');
  const logoUrl = location?.logoUrl;
  const locationInfoLines = [
    location?.address || '',
    location?.phoneNumber || '',
  ].filter(Boolean);
  return (
    <View fixed style={tw(styles.header())}>
      <View style={tw(styles.logoContainer())}>
        {logoUrl && <Image style={tw(styles.logo())} src={logoUrl} />}
      </View>

      <View style={tw(styles.clinicInfoContainer())}>
        <View>
          <Text style={tw(styles.clinicName())}>{clinicName}</Text>
        </View>
        {Boolean(locationInfoLines.length) && (
          <View>
            {locationInfoLines.map((line, i) => {
              return (
                <Text key={i} style={tw(commonStyles.text())}>
                  {line}
                </Text>
              );
            })}
          </View>
        )}
      </View>
    </View>
  );
};
