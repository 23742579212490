import { useSuspenseQuery } from '@apollo/client';
import { PDFViewer } from '@react-pdf/renderer';
import React, { Suspense } from 'react';

import { pastBillingCodesFragment } from '@eluve/frontend-appointment-hooks';
import { userFragment } from '@eluve/frontend-feature-user-settings';
import { graphql } from '@eluve/graphql.tada';
import { Block } from '@eluve/llm-outputs';
import { DynamicOutputPDF } from '@eluve/pdf-rendering';
import { tenantNameFragment } from '@eluve/smart-blocks';

import { appointmentBillingCodesFragment } from './AppointmentBillingCodes.operations';

export const patientFragment = graphql(`
  fragment Patient on Patients @_unmask {
    __typename
    id
    firstName
    lastName
    dateOfBirth
    sex
    external_patients_info {
      __typename
      id
      externalEhrId
      email
      cellPhoneNumber
      workPhoneNumber
      homePhoneNumber
      email
    }
  }
`);

export const locationFragment = graphql(`
  fragment Location on Locations @_unmask {
    __typename
    id
    name
    externalEhrId
    address
    logoUrl
    phoneNumber
    externalId
    inherited_logo_url {
      __typename
      locationId
      logoUrl
    }
  }
`);

const appointmentPdfDataQuery = graphql(
  `
    query AppointmentPdfData($tenantId: uuid!, $appointmentId: uuid!) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        id
        __typename
        user {
          id
          __typename
          ...User
        }
        tenant {
          id
          __typename
          ...TenantName
        }
        location {
          id
          __typename
          ...Location
        }
        doctor_interaction {
          __typename
          appointmentId
          noteSignedAt
        }
        patient {
          id
          __typename
          ...Patient
        }
        past_billing_codes(
          where: { billingCodeAmendmentId: { _isNull: true } }
        ) {
          id
          __typename
          ...PastBillingCodes
        }
        ...BillingCodes
        amendments(
          where: { submittedAt: { _isNull: false } }
          orderBy: { submittedAt: DESC }
        ) {
          id
          __typename
          submittedAt
          user {
            id
            __typename
            ...User
          }
          chart_amendments {
            id
            __typename
            keyPath
            value
          }
          billing_code_amendment {
            id
            __typename
            amendmentId
            billing_codes {
              id
              __typename
              medical_code {
                id
                __typename
                description
                code
              }
            }
          }
        }
      }
    }
  `,
  [
    userFragment,
    tenantNameFragment,
    locationFragment,
    patientFragment,
    pastBillingCodesFragment,
    appointmentBillingCodesFragment,
  ],
);

export const AppointmentDynamicOutputPdf: React.FC<{
  tenantId: string;
  appointmentId: string;
  blocks: Block[];
}> = (props) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <AppointmentDynamicOutputPdfContent {...props} />
    </Suspense>
  );
};

export const AppointmentDynamicOutputPdfContent: React.FC<{
  tenantId: string;
  appointmentId: string;
  blocks: Block[];
}> = ({ tenantId, appointmentId, blocks }) => {
  const {
    data: { appointmentsByPk: appointment },
  } = useSuspenseQuery(appointmentPdfDataQuery, {
    variables: { tenantId, appointmentId },
  });

  if (!appointment) {
    return null;
  }

  const { location, patient, user, tenant, billingCodes } = appointment;

  const patientInfo = patient?.external_patients_info ?? [];
  const externalPatientInfo =
    patientInfo.find(
      (info) => info.externalEhrId === location?.externalEhrId,
    ) ?? patientInfo[0];
  const phoneNumber =
    externalPatientInfo?.cellPhoneNumber ??
    externalPatientInfo?.homePhoneNumber ??
    externalPatientInfo?.workPhoneNumber ??
    undefined;
  const email = externalPatientInfo?.email ?? undefined;

  const amendments = (appointment.amendments ?? []).map((a) => ({
    user: {
      firstName: a.user?.firstName,
      lastName: a.user?.lastName,
      email: a.user?.email,
    },
    submittedAt: a.submittedAt!,
    chartAmendments: a.chart_amendments.map((ca) => ({
      keyPath: ca.keyPath,
      value: ca.value,
    })),
    billingCodeAmendments: (a.billing_code_amendment?.billing_codes ?? [])
      .map((billingCode) => billingCode.medical_code)
      .filter(Boolean)
      .map((medicalCode) => ({
        code: medicalCode!.code,
        description: medicalCode!.description,
      })),
  }));

  const originalBillingCodes = appointment.past_billing_codes
    ?.map((pastBillingCode) => pastBillingCode.medical_code)
    .filter(Boolean)
    .map((medicalCode) => ({
      code: medicalCode!.code,
      description: medicalCode!.description,
    }));

  const unsignedBillingCodes = billingCodes
    .filter((billingCode) => billingCode.medical_code)
    .map((billingCode) => ({
      code: billingCode.medical_code!.code!,
      description: billingCode.medical_code!.description ?? null,
    }));

  return (
    <div>
      <PDFViewer className="h-[1024px] w-full">
        <DynamicOutputPDF
          blocks={blocks}
          amendments={amendments}
          location={{
            ...location,
            logoUrl: location?.inherited_logo_url?.logoUrl ?? undefined,
            name: location?.name,
            address: location?.address ?? undefined,
            phoneNumber: location?.phoneNumber ?? undefined,
          }}
          patient={{
            firstName: patient?.firstName,
            lastName: patient?.lastName ?? undefined,
            dateOfBirth: patient?.dateOfBirth ?? undefined,
            email,
            phoneNumber,
          }}
          user={{
            firstName: user!.firstName,
            lastName: user!.lastName,
            email: user!.email,
          }}
          tenant={{
            name: tenant.name,
          }}
          noteSignedAt={
            appointment.doctor_interaction?.noteSignedAt ?? undefined
          }
          billingCodes={
            appointment.doctor_interaction?.noteSignedAt
              ? originalBillingCodes
              : unsignedBillingCodes
          }
        />
      </PDFViewer>
    </div>
  );
};
