import { useQuery } from '@apollo/client';
import { usePostHog } from 'posthog-js/react';
import { useState } from 'react';

import { useApiClient } from '@eluve/api-client-provider';
import { AppointmentStatusLabel } from '@eluve/blocks';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  HStack,
  NewButton,
  P,
  VStack,
  textStyles,
} from '@eluve/components';
import {
  useAppointmentId,
  useAppointmentPatient,
} from '@eluve/frontend-appointment-hooks';
import { AppointmentLanguageSelector } from '@eluve/frontend-feature-appointment';
import { ResultOf, graphql } from '@eluve/graphql.tada';
import { useAssignedTenantIdFromParams } from '@eluve/session-helpers';
import { useAppointmentTemplates } from '@eluve/smart-blocks';
import {
  NewAppointmentTemplateSelector,
  TenantOrUserFeatureFlaggedComponent,
} from '@eluve/smart-blocks';
import { useDialog } from '@eluve/utility-hooks';

import { DuplicateChartCard } from './DuplicateChartCard';

const duplicateAppointmentFragment = graphql(`
  fragment duplicatedAppointment on Appointments @_unmask {
    __typename
    id
    name
    startDate
    status
    initialLlmOutputTemplateId
    llm_output_template {
      __typename
      id
      name
    }
    doctor_interaction {
      __typename
      appointmentId
      noteSignedAt
    }
    amendments {
      __typename
      id
      submittedAt
    }
  }
`);

const getPreviousPatientChartsQuery = graphql(
  `
    query getPreviousPatientCharts($tenantId: uuid!, $patientId: uuid!) {
      appointments(
        where: {
          tenantId: { _eq: $tenantId }
          patientId: { _eq: $patientId }
          humanOutputs: { output: { content: { _isNull: false } } }
        }
        orderBy: { startDate: DESC }
        limit: 10
      ) {
        ...duplicatedAppointment
      }
    }
  `,
  [duplicateAppointmentFragment],
);

export interface NewAppointmentFormManualModeProps {
  isSettingsAvailable: boolean;
}

export const NewAppointmentFormManualMode: React.FC<
  NewAppointmentFormManualModeProps
> = ({ isSettingsAvailable }) => {
  const appointmentId = useAppointmentId();
  const tenantId = useAssignedTenantIdFromParams();
  const { isDialogOpen: isOpen, toggleDialog: toggle } = useDialog();

  const [duplicateAppointment, setDuplicatedAppointment] = useState<ResultOf<
    typeof duplicateAppointmentFragment
  > | null>(null);

  const [settingsOpen, setSettingsOpen] = useState(isSettingsAvailable);
  const apiClient = useApiClient();

  const postHog = usePostHog();

  const patient = useAppointmentPatient();

  const { initialLlmOutputTemplateId } = useAppointmentTemplates();

  const { data: previousChartData } = useQuery(getPreviousPatientChartsQuery, {
    variables: {
      tenantId,
      patientId: patient?.id ?? '',
    },
    skip: !patient?.id,
  });

  const previousCharts = previousChartData?.appointments ?? [];

  const startSession = async () => {
    postHog.capture('started_manual_charting_session', {
      appointmentId,
    });

    await apiClient.appointments.startAppointmentInManualChartingMode({
      params: {
        appointmentId,
        tenantId: tenantId!,
      },
      body: {
        llmOutputTemplateId:
          duplicateAppointment?.initialLlmOutputTemplateId ?? '',
        duplicatedAppointmentId: duplicateAppointment?.id ?? '',
      },
    });
  };

  return (
    <VStack wFull gap={5}>
      <VStack wFull align="stretch" gap={0}>
        <Collapsible open={settingsOpen} onOpenChange={setSettingsOpen}>
          {isSettingsAvailable && (
            <HStack className="mb-5" align="stretch" justify="between" wFull>
              <P
                className={textStyles.body({
                  color: 'primary',
                  weight: 'semibold',
                  size: 'm',
                })}
              >
                Note Settings
              </P>
              <CollapsibleTrigger asChild>
                <NewButton
                  size="s"
                  text={`View ${settingsOpen ? 'Less' : 'More'} Options`}
                  type="outline"
                />
              </CollapsibleTrigger>
            </HStack>
          )}

          <NewAppointmentTemplateSelector
            tenantId={tenantId!}
            appointmentId={appointmentId}
          />

          <CollapsibleContent
            className='h-0 data-[state="open"]:h-auto data-[state="closed"]:overflow-hidden data-[state="open"]:pt-5'
            data-state={settingsOpen ? 'open' : 'closed'}
            forceMount
          >
            <TenantOrUserFeatureFlaggedComponent flag="MULTILINGUAL_SUPPORT">
              <AppointmentLanguageSelector outputLanguage />
            </TenantOrUserFeatureFlaggedComponent>
          </CollapsibleContent>
        </Collapsible>
      </VStack>

      {Boolean(previousCharts.length) && (
        <VStack gap={3}>
          <HStack justify="between">
            <p
              className={textStyles.body({
                size: 'm',
                weight: 'semibold',
              })}
            >
              Duplicate Previous Chart
            </p>
            <Dialog open={isOpen} onOpenChange={toggle}>
              <DialogTrigger asChild>
                <p
                  className={textStyles.link({
                    size: 'm',
                    weight: 'semibold',
                    color: 'tertiary',
                    className: 'cursor-pointer underline',
                  })}
                >
                  Select Chart
                </p>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Duplicate Chart</DialogTitle>
                  <p
                    className={textStyles.body({
                      size: 'm',
                      weight: 'medium',
                    })}
                  >
                    Select the chart you want to duplicate
                  </p>
                </DialogHeader>
                <VStack gap={4}>
                  {previousCharts.map((c) => (
                    <HStack
                      key={c.id}
                      justify="between"
                      className="cursor-pointer"
                      onClick={() => {
                        setDuplicatedAppointment(c);
                        toggle();
                      }}
                    >
                      <DuplicateChartCard
                        name={c.name}
                        startDate={c.startDate}
                      />
                      <AppointmentStatusLabel
                        isSummarized
                        status={c.status}
                        isSigned={Boolean(c.doctor_interaction?.noteSignedAt)}
                        isAmending={
                          Boolean(c.amendments.length) &&
                          c.amendments.some((a) => !a.submittedAt)
                        }
                        isAmended={
                          Boolean(c.amendments.length) &&
                          c.amendments.every((a) => a.submittedAt)
                        }
                      />
                    </HStack>
                  ))}
                </VStack>
              </DialogContent>
            </Dialog>
          </HStack>
          {Boolean(duplicateAppointment) && (
            <HStack className="rounded-lg border border-borderPrimary p-3">
              <DuplicateChartCard
                name={duplicateAppointment!.name}
                startDate={duplicateAppointment!.startDate}
              />
              <NewButton
                onClick={() => setDuplicatedAppointment(null)}
                iconOnly
                type="ghost"
                icon={{
                  name: 'X',
                }}
              />
            </HStack>
          )}
        </VStack>
      )}

      <NewButton
        onClick={startSession}
        size="l"
        text="Start Session"
        wFull
        disabled={!initialLlmOutputTemplateId}
      />
    </VStack>
  );
};
