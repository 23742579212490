import * as he from 'he';

const removeSpecialCharacters = (str = '') => {
  try {
    return (str ?? '')
      .trim()
      .replaceAll('\n\n-', '')
      .replaceAll('\n-', '')
      .replaceAll('\n\n*', '')
      .replaceAll('\n*', '')
      .replaceAll('\n', '');
  } catch {
    return '';
  }
};

export const areStringsEqualWithoutSpecialCharacters = (
  a: string | null = '',
  b: string | null = '',
) => {
  const cleanA = he.decode(removeSpecialCharacters(a ?? ''));
  const cleanB = he.decode(removeSpecialCharacters(b ?? ''));
  return cleanA === cleanB;
};
