import { z } from 'zod';

export const appointmentConversationMessageSchema = z.object({
  role: z.enum(['user', 'assistant']).optional(),
  content: z.string().optional(),
  messageId: z.string().optional(),
  messageTimestamp: z.number().optional(),
});

export const appointmentConversationSchema = z.array(
  appointmentConversationMessageSchema,
);
export type AppointmentConversation = z.infer<
  typeof appointmentConversationSchema
>;
export type AppointmentConversationMessage = z.infer<
  typeof appointmentConversationMessageSchema
>;
