import camelCase from 'lodash/camelCase';
import chunk from 'lodash/chunk';
import isFinite from 'lodash/isFinite';
import { match } from 'ts-pattern';
import { z } from 'zod';

import { Block, DynamicArtifactTemplate } from '@eluve/llm-outputs';
import { AppointmentSummaryKeys } from '@eluve/utils';

import { ERRORS } from '../../errors';
import { AppointmentModel } from '../../models/appointment';
import { ChartTemplateType } from '../../models/chart-template';
import { PatientType } from '../../models/patient';
import {
  CanSyncToEhrArgs,
  VendorDataMapper,
  VendorSyncConfig,
} from '../vendor-data-mapper';

import { convertJaneDataToAppointmentModel } from './jane.appointment';
import { JaneVendorContract } from './jane.contract';
import { logo } from './logo';
import {
  ChartTemplate,
  ChartTemplateMetadata,
  IntakeFormsResponse,
  Patient,
  PatientArtifact,
  appointmentSchema,
  chartEntrySchema,
  chartTemplateSchema,
  chartTemplateWithStaffMemberIdSchema,
  patientArtifactSchema,
  patientSchema,
} from './types';

const appointmentData = z.object({
  staff_member_ids: z.array(z.number().nullish()),
  appointments: z.array(z.unknown()),
  shifts: z.array(z.unknown()),
  openings: z.array(z.unknown()),
});

export class JaneVendorDataMapper implements VendorDataMapper {
  convertChartTemplateToDynamicArtifactTemplate = (
    data: ChartTemplateType,
  ): DynamicArtifactTemplate | null => {
    const result = chartTemplateSchema.safeParse(data.rawData);

    if (!result.success) {
      return null;
    }
    const blocks: Block[] = [];
    for (const part of result.data.parts) {
      if (!part.label) {
        continue;
      }
      const options = match(part.type)
        .with('Chart::Part::ChiefComplaint', () => ({
          type: 'text' as const,
          importedDescription: part.text ?? '',
        }))
        .with('Chart::Part::Note', () => ({
          type: 'text' as const,
          importedDescription: part.text ?? '',
        }))
        .with('Chart::Part::Slider', () => {
          const numberOfSliderLabels = part.slider_labels?.length ?? 0;

          if (!numberOfSliderLabels) {
            return null;
          }

          const min = Number(part.slider_labels?.[0]);
          const max = Number(part.slider_labels?.[numberOfSliderLabels - 1]);

          if (!isFinite(min) || !isFinite(max)) {
            return null;
          }

          return {
            type: 'range' as const,
            min,
            max,
            importedDescription: part.text ?? '',
            value: (part.value as number) ?? 0,
            step: 1,
          };
        })
        .with('Chart::Part::CheckBoxes', () => ({
          type: 'checkbox' as const,
          options: (part.visible_options ?? []).map((option) => ({
            label: option ?? '',
            isChecked: (part.selected_options ?? []).includes(option),
          })),
        }))
        .with('Chart::Part::Upload', () => null)
        .otherwise(() => null);

      const key = camelCase(part.label);
      if (options) {
        blocks.push({
          key,
          ...options,
          label: part.label ?? '',
          // Assume that all blocks are AI enabled to start.
          // This can be overwritten through the template builder
          isAiEnabled: true,
        });
      }
    }

    return {
      name: result.data.name ?? '',
      blocks,
    };
  };

  getChartTemplate = (data: unknown) => {
    const result = chartTemplateWithStaffMemberIdSchema.safeParse(data);
    if (result.success) {
      const { chartTemplate, staffMemberId } = result.data;
      const { id } = chartTemplate;
      return {
        rawData: chartTemplate as Record<string, unknown>,
        externalChartTemplateId: id.toString(),
        externalChartTemplateOwnerId: staffMemberId.toString(),
      };
    }

    // Fallback to old schema. We can remove this once all users are using extension >= 0.5.7
    const resultChartTemplate = chartTemplateSchema.safeParse(data);
    if (resultChartTemplate.success) {
      const { id } = resultChartTemplate.data;
      return {
        rawData: data as Record<string, unknown>,
        externalChartTemplateId: id.toString(),
      };
    }

    return null;
  };

  getArtifact = (data: unknown) => {
    const artifact = patientArtifactSchema.safeParse(data);
    if (!artifact.success) {
      return null;
    }
    const { artifactType, metadata, rawData } = artifact.data;

    return {
      externalPatientId: metadata.patient_id.toString(),
      externalArtifactId: metadata.id.toString(),
      artifactType,
      createdAt: metadata.submitted_at ?? null,
      rawData: { metadata, html: rawData },
      name: metadata.name,
    };
  };

  getChart = (data: unknown, additionalFields?: Record<string, unknown>) => {
    const { domain } = additionalFields ?? {};
    const chart = chartEntrySchema.safeParse(data);
    if (!chart.success) {
      throw new Error(`Invalid Jane chart data: ${chart.error}`);
    }

    return {
      externalChartId: `${chart.data.id}`,
      externalPatientId: `${chart.data.patient_id}`,
      externalAppointmentId: chart.data.appointment_id
        ? `${chart.data.appointment_id}`
        : null,
      rawData: data as Record<string, unknown>,
      signedAt: chart.data.signed_at,
      chartUrl: domain
        ? `https://${domain}/admin#patients/${chart.data.patient_id}/charts/${chart.data.id}`
        : undefined,
    };
  };

  getAppointments = (data: unknown): AppointmentModel[] => {
    const rawAppointments = appointmentData.parse(data);
    const appointments: AppointmentModel[] = [];
    for (const appointment of rawAppointments.appointments) {
      const appointmentParseResult = appointmentSchema.safeParse(appointment);
      if (!appointmentParseResult.success) {
        throw new Error(
          `Invalid appointment data from 3rd party EHR: ${appointmentParseResult.error}`,
        );
      }
      const appointmentModel = convertJaneDataToAppointmentModel(
        appointmentParseResult.data,
      );
      appointments.push(appointmentModel);
    }

    return appointments;
  };

  getPatient = (data: unknown): PatientType => {
    const janeData = patientSchema.parse(data);

    return {
      firstName: janeData.first_name ?? '',
      lastName: janeData.last_name ?? '',
      externalPatientId: `${janeData.id}`,
      dateOfBirth: janeData.dob ?? null,
      email: janeData.email ?? null,
      homePhone: janeData.home_phone ?? null,
      cellPhone: janeData.mobile_phone ?? null,
      workPhone: janeData.work_phone ?? null,
      rawData: data as Record<string, unknown>,
    };
  };

  getPatientEhrUrl = ({
    domain,
    externalPatientId,
  }: {
    domain: string;
    externalPatientId?: string;
  }) => {
    return `https://${domain}/admin#patients/${externalPatientId}/charts`;
  };

  getLogo = () => logo;

  getHomeUrl = (domain: string) => {
    return `https://${domain}/admin`;
  };

  getChartIdFromChartUrl = (chartUrl: string) => {
    const regex = /#patients\/\d+\/charts\/(\d+)/;
    const match = chartUrl.match(regex);
    if (match && match.length > 1 && match[1]) {
      return match[1];
    }
    return null;
  };

  getStaffMemberId = async ({
    domain,
    request,
  }: {
    domain: string;
    request: typeof fetch;
  }) => {
    try {
      const response = await request(`https://${domain}/admin/prefs`);
      if (!response.ok) {
        return { ok: false, error: ERRORS.NOT_LOGGED_IN };
      }
      const prefs = (await response.json()) as { staff_badges: { id: number } };
      const id = prefs?.staff_badges?.id;
      return {
        ok: true,
        data: id,
      };
    } catch (_error) {
      return { ok: false, error: ERRORS.NOT_LOGGED_IN };
    }
  };

  fetchAppointments = async (args: {
    startDate: string;
    endDate: string;
    includeUnscheduled?: boolean;
    domain: string;
    request: typeof fetch;
    staffMemberIds?: number[];
  }) => {
    const {
      startDate,
      endDate,
      includeUnscheduled = true,
      domain,
      request,
      staffMemberIds = [],
    } = args;
    const baseUrl = `https://${domain}/admin/api/v2/calendar`;
    const url = new URL(baseUrl);

    if (!staffMemberIds.length) {
      const staffMemberIdResponse = await this.getStaffMemberId({
        request: fetch,
        domain,
      });

      if (!staffMemberIdResponse.ok || !staffMemberIdResponse.data) {
        return { ok: false, error: staffMemberIdResponse.error };
      }
      staffMemberIds.push(staffMemberIdResponse.data);
    }

    url.searchParams.append('start_date', startDate);
    url.searchParams.append('end_date', endDate);
    url.searchParams.append('include_unscheduled', `${includeUnscheduled}`);
    url.searchParams.append('staff_member_ids[]', staffMemberIds.join(','));
    const response = await request(url.toString());
    if (response.status === 401) {
      return { ok: false, error: ERRORS.NOT_LOGGED_IN };
    }
    if (!response.ok) {
      const body = await response.json();
      return {
        ok: false,
        error: `Could not fetch calendar events: ${JSON.stringify({
          body,
          status: response.status,
        })}`,
      };
    }
    const appointmentData: JaneVendorContract['JANE']['types']['AppointmentsResponse'] =
      await response.json();
    const timezone = await this.getTimezone({
      appointmentIds: appointmentData?.appointments?.map(
        (appointment) => appointment.id,
      ),
      request,
      domain,
    });

    const data = {
      response: appointmentData,
      timezone,
    };

    return { ok: true, data };
  };

  fetchPatientsExternalArtifacts = async ({
    ids,
    request,
    domain,
  }: {
    ids: number[];
    domain: string;
    request: typeof fetch;
  }) => {
    const allIntakeForms: PatientArtifact[] = [];
    let error = '';
    for (const chunkOfIds of chunk(ids, 3)) {
      try {
        const responses = await Promise.all(
          chunkOfIds.map(async (id) => {
            const response = await request(
              `https://${domain}/admin/api/v3/intake_forms?patient_id=${id}&page=1&per_page=100&sort_by=for_patient`,
            );

            if (!response.ok) {
              return null;
            }

            const body = (await response.json()) as IntakeFormsResponse;
            const forms = body?._batch_response?.intake_forms ?? [];

            const intakeForms: PatientArtifact[] = [];
            for (const form of forms) {
              const response = await request(
                `https://${domain}/admin/intake_forms/${form.id}`,
              );

              if (!response.ok) {
                error = `Could not fetch intake form with id ${form.id}. Status: ${response.status}`;
                return null;
              }
              const formBody = await response.text();

              intakeForms.push({
                rawData: this.removeSensitiveFieldsFromHtml(formBody),
                metadata: form,
                artifactType: 'INTAKE_FORM',
              });
            }
            return intakeForms;
          }),
        );

        allIntakeForms.push(
          ...(responses.filter(Boolean).flat() as PatientArtifact[]),
        );
      } catch (error) {
        return { ok: false, error: (error as Error)?.message };
      }
    }
    return { ok: true, error, data: allIntakeForms };
  };

  fetchChartTemplates = async ({
    request,
    domain,
    staffMemberId,
  }: {
    request: typeof fetch;
    domain: string;
    staffMemberId: number;
  }) => {
    const templatesUrl = [
      `https://${domain}/admin/api/v2/chart_templates?staff_member_id=${staffMemberId}`,
      encodeURI(`categories[]=system`),
      encodeURI(`categories[]=chart`),
      encodeURI(`categories[]=survey`),
      encodeURI(`categories[]=individual_survey`),
    ].join('&');
    const response = await request(templatesUrl);
    if (!response.ok) {
      return {
        ok: false,
        error: `Could not fetch chart templates. Status: ${response.status}`,
      };
    }
    const allTemplates: ChartTemplateMetadata[] = await response.json();
    const templatesMetadata = allTemplates.filter(
      (template) =>
        template.selected &&
        template.system === false &&
        template.display_as_template,
    );
    const templates: ChartTemplate[] = [];
    for (const templateMetadata of templatesMetadata) {
      const templateId = templateMetadata.id;
      const chartTemplateUrl = `https://${domain}/admin/api/v2/chart_templates/${templateId}?staff_member_id=${staffMemberId}`;
      const chartTemplateResponse = await request(chartTemplateUrl);
      if (!chartTemplateResponse.ok) {
        return {
          ok: false,
          error: `Could not fetch chart template. Status: ${chartTemplateResponse.status}`,
        };
      }
      templates.push(await chartTemplateResponse.json());
    }
    const templatesWithNote = templates.filter((template) =>
      template.parts.some((part) => part.type === 'Chart::Part::Note'),
    );
    return { ok: true, data: templatesWithNote };
  };

  fetchPatientsByIds = async ({
    ids,
    request,
    domain,
  }: {
    ids: number[];
    domain: string;
    request: typeof fetch;
  }) => {
    const patients: Patient[] = [];
    const batchSize = 4;

    const idChunks = chunk(ids, batchSize);

    let error = '';
    for (const chunk of idChunks) {
      try {
        const responses = await Promise.all(
          chunk.map(async (id) => {
            const response = await request(
              `https://${domain}/admin/api/v2/patients/${id}`,
            );

            if (!response.ok) {
              error = `Could not fetch patient with id ${id}. Status: ${response.status}`;
              return null;
            }

            return response.json();
          }),
        );

        patients.push(...responses.filter(Boolean));
      } catch (error) {
        return { ok: false, error: (error as Error)?.message };
      }
    }

    return { ok: true, data: patients, error };
  };

  private getTimezone = async ({
    appointmentIds,
    request,
    domain,
  }: {
    appointmentIds: number[];
    request: typeof fetch;
    domain: string;
  }): Promise<string | null> => {
    if (appointmentIds?.length) {
      for (const appointmentId of appointmentIds) {
        const response = await request(
          `https://${domain}/admin/api/v3/appointments/${appointmentId}`,
        );
        const appointment: JaneVendorContract['JANE']['types']['Appointment'] =
          await response.json();
        const { start_at } = appointment;
        if (start_at) {
          const timezoneMatch = start_at.match(/([+-][0-9]{2}:[0-9]{2})$/);
          if (timezoneMatch) {
            return timezoneMatch[0];
          }
        }
      }
    }
    return null;
  };

  convertChartToParts = (data: unknown) => {
    const chart = chartEntrySchema.safeParse(data);
    if (!chart.success) {
      return [];
    }
    return chart.data?.chart_parts ?? [];
  };

  convertChartToSummary = (data: unknown) => {
    const summary: { [key in AppointmentSummaryKeys]?: string } = {};
    if (!data) {
      return summary;
    }

    const chart = chartEntrySchema.safeParse(data);
    if (!chart.success) {
      return summary;
    }
    const chartData = chart.data;
    const chartParts = chartData?.chart_parts ?? [];

    for (const chartPart of chartParts) {
      const { label, text } = chartPart;
      if (!text) continue;
      if (label === 'Subjective') {
        summary.SUBJECTIVE = text;
      } else if (label === 'Objective') {
        summary.OBJECTIVE = text;
      } else if (label === 'Assessment') {
        summary.ASSESSMENT = text;
      } else if (label === 'Plan') {
        summary.PLAN = text;
      }
    }
    return summary;
  };

  removeSensitiveFieldsFromHtml = (inputHtml: string) => {
    const REDACTED = 'REDACTED';

    const csrfTokenRegex =
      /(<meta\s+name="csrf-token"\s+content=")[^"]*(".*?>)/g;

    const signatureUrlRegex =
      /(<div\s+class=['"]signature['"][^>]*>\s*<img\s+src=['"])[^'"]*(['"][^>]*>)/g;

    let cleanedHtml = inputHtml.replace(csrfTokenRegex, `$1${REDACTED}$2`);
    cleanedHtml = cleanedHtml.replace(signatureUrlRegex, `$1${REDACTED}$2`);

    return cleanedHtml;
  };

  getChartTemplateUrl = (data: {
    domain: string;
    externalChartTemplateOwnerId: string;
    externalChartTemplateId: string;
  }) => {
    // Example: https://myodetoxla.janeapp.com/admin#staff/151/chart_templates/108
    return `https://${data.domain}/admin#staff/${data.externalChartTemplateOwnerId}/chart_templates/${data.externalChartTemplateId}`;
  };

  getSyncConfig(): VendorSyncConfig {
    return {
      isSyncEnabled: true,
      canSignNoteInEhr: true,
      canSyncNoteToEhr: (data: CanSyncToEhrArgs) => {
        const externalPatientId = Boolean(data.externalPatientId);
        const eluveExtExists = Boolean(data.eluveExtExists);
        if (!externalPatientId) {
          return {
            canSync: false,
            reason: 'Selected patient was not imported from EHR',
          };
        }
        if (!eluveExtExists) {
          return {
            canSync: false,
            reason: 'Extension is not installed',
          };
        }
        return {
          canSync: true,
        };
      },
      canSyncWithManualChartUrl: true,
    };
  }
}
