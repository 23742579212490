import { useSuspenseQuery } from '@apollo/client';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { BackBreadcrumbItem } from '@eluve/blocks';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
  Content,
  Header,
  SidebarTrigger,
} from '@eluve/components';
import { getPatientDetailsQuery } from '@eluve/frontend-appointment-hooks';
import { formatHumanName } from '@eluve/utils';

import { PatientDetailsPage } from './PatientDetailsPage';

export const PatientDetailsPageLayout: React.FC = () => {
  const { tenantId, patientId } = useParams() as {
    tenantId: string;
    patientId: string;
  };
  const { data } = useSuspenseQuery(getPatientDetailsQuery, {
    variables: {
      tenantId,
      patientId,
    },
  });
  const basicDetails = data?.patientsByPk;

  return (
    <>
      <Header>
        <SidebarTrigger />
        <Breadcrumb className="flex-1">
          <BreadcrumbList>
            <BackBreadcrumbItem />
            <BreadcrumbItem>
              <Link to={`/tenants/${tenantId}/patients`}>Patients</Link>
            </BreadcrumbItem>

            <BreadcrumbSeparator>/</BreadcrumbSeparator>
            <BreadcrumbItem className="text-gray-12 privacy-text">
              {basicDetails?.firstName
                ? formatHumanName(basicDetails.firstName, basicDetails.lastName)
                : 'Loading...'}
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </Header>
      <Content padded>
        <PatientDetailsPage />
      </Content>
    </>
  );
};
