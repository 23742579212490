import React, { useState } from 'react';
import Markdown from 'react-markdown';

import { Box, HStack, Icon, P, VStack, textStyles } from '@eluve/components';
import { getFormattedLocalDateTime } from '@eluve/date-utils';
import {
  useAppointmentId,
  useAppointmentPatient,
  useListenForAppointmentPatient,
} from '@eluve/frontend-appointment-hooks';
import { ViewConversationButton } from '@eluve/frontend-feature-avatar';
import { patientOverviewSchema } from '@eluve/llm-outputs';
import { nestedObjToMarkdown } from '@eluve/utils';

const COLLAPSED_DETAILS_LENGTH = 300;

interface PatientOverViewDetailsProps {
  details: string;
  date: string;
  includeTimestamp?: boolean;
}

const PatientOveriewDetails: React.FC<PatientOverViewDetailsProps> = ({
  details,
  date,
  includeTimestamp = true,
}) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const canOpenDetails = details.length > COLLAPSED_DETAILS_LENGTH;

  let formattedDetails = details;
  if (canOpenDetails) {
    formattedDetails = isDetailsOpen
      ? `${details} **View Less**`
      : `${details?.substring(0, COLLAPSED_DETAILS_LENGTH).trim()}... **View More**`;
  }

  return (
    <VStack>
      {includeTimestamp && (
        <P className="text-contentPrimary">{getFormattedLocalDateTime(date)}</P>
      )}
      <div
        className={canOpenDetails ? 'cursor-pointer' : ''}
        onClick={
          canOpenDetails ? () => setIsDetailsOpen(!isDetailsOpen) : undefined
        }
      >
        <Markdown
          className={textStyles.body({
            className:
              'prose mx-1 [&>ul>li:not(:last-child)]:mb-3 [&>ul]:list-disc',
            color: 'secondary',
            weight: 'medium',
            size: 'm',
          })}
          components={{
            strong({ children }) {
              return (
                <strong className="inline-block font-semibold">
                  {children}
                </strong>
              );
            },
          }}
        >
          {formattedDetails}
        </Markdown>
      </div>
    </VStack>
  );
};

export const PatientOverview: React.FC<{
  includeIcon?: boolean;
  includeHistory?: boolean;
}> = ({ includeIcon = false, includeHistory = false }) => {
  const patient = useAppointmentPatient();
  const appointmentId = useAppointmentId();

  useListenForAppointmentPatient();

  const patientOverviews = patient?.patient_overviews ?? [];
  const currentPatientOverview = patientOverviews.find(
    (overview) => overview.isCurrent,
  );
  const parsedCurrentPatientOverview = patientOverviewSchema.safeParse(
    currentPatientOverview?.content ?? '',
  );

  if (!parsedCurrentPatientOverview.success) {
    return null;
  }
  const { reasonForVisit: rawReasonForVisit } =
    parsedCurrentPatientOverview.data;

  const reasonForVisit = nestedObjToMarkdown({
    reasonForVisit: rawReasonForVisit,
  }).reasonForVisit;

  const allPatientOverviewsDetails = patientOverviews
    .map((overview) => {
      const parsed = patientOverviewSchema.safeParse(overview?.content ?? '');
      if (!parsed.success) {
        return null;
      }
      const { details } = parsed.data;
      const parsedDetails = nestedObjToMarkdown({ details }).details;
      if (!parsedDetails) {
        return null;
      }
      return {
        details: parsedDetails,
        date: overview.createdAt,
        id: overview.id,
      };
    })
    .filter(Boolean);

  if (!allPatientOverviewsDetails.length) {
    return null;
  }

  return (
    <VStack wFull align="stretch" gap={6} key={currentPatientOverview?.id}>
      {reasonForVisit && (
        <HStack
          align="start"
          className="rounded-lg bg-brandGray100 p-3 pr-6"
          gap={3}
        >
          {includeIcon && (
            <div className="rounded-md bg-purple p-2.5 text-purpleContrast">
              <Icon name="Lightbulb" size="sm" />
            </div>
          )}
          <VStack className="text-secondary" gap={0}>
            <P
              className={textStyles.body({
                color: 'secondary',
                weight: 'bold',
                size: 'l',
              })}
            >
              Reason for visit
            </P>
            <Markdown
              className={textStyles.body({
                color: 'tertiary',
                weight: 'medium',
                size: 's',
              })}
            >
              {reasonForVisit}
            </Markdown>
          </VStack>
          <Box className="ml-auto">
            <ViewConversationButton appointmentId={appointmentId} />
          </Box>
        </HStack>
      )}

      {(includeHistory
        ? allPatientOverviewsDetails
        : [allPatientOverviewsDetails[0]]
      ).map((overview) => (
        <PatientOveriewDetails
          includeTimestamp={includeHistory}
          details={overview!.details}
          date={overview!.date}
          key={overview!.id}
        />
      ))}
    </VStack>
  );
};

PatientOverview.displayName = 'PatientOverview';
