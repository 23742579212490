import { useSuspenseQuery } from '@apollo/client';
import React, { Suspense, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  AppointmentStatusLabel,
  ShowAllAppointmentsToggle,
  appointmentStatusAccessorFn,
  useShowAllAppointmentsToggle,
} from '@eluve/blocks';
import { ColDefBuilder, DataTable, TableSkeleton } from '@eluve/components';
import { ResultOf, graphql } from '@eluve/graphql.tada';
import { useUserIdFromSession } from '@eluve/session-helpers';

const getPatientAppointmentsQuery = graphql(`
  query getPatientAppointments($patientId: uuid!) {
    appointments(
      where: { patientId: { _eq: $patientId } }
      orderBy: { startDate: DESC }
    ) {
      __typename
      id
      tenantId
      name
      startDate
      status
      userId
      amendments {
        id
        __typename
        submittedAt
      }
      humanOutputs(
        where: { output: { outputType: { _eq: SOAP_NOTE } } }
        limit: 1
      ) {
        __typename
        humanOutputId
      }
      doctor_interaction {
        __typename
        appointmentId
        noteSignedAt
      }
    }
  }
`);

type Row = Omit<
  ResultOf<typeof getPatientAppointmentsQuery>['appointments'][number],
  'humanOutputs' | 'doctor_interaction' | 'amendments'
> & {
  isSummarized: boolean;
  isSigned: boolean;
  isAmending: boolean;
  isAmended: boolean;
};

const columns = new ColDefBuilder<Row>()
  .detailsLink((r) => `/tenants/${r.tenantId}/appointments/${r.id}`, 'Details')
  .linkSortable('name', (r) => `/tenants/${r.tenantId}/appointments/${r.id}`)
  .dateSortable('startDate', 'Start Date')
  .defaultSortable('status', {
    colOptions: {
      accessorFn: appointmentStatusAccessorFn,
    },
    cellRenderer: (row) => {
      return <AppointmentStatusLabel {...row} />;
    },
  })
  .build();

export interface PatientAppointmentsListProps {}

export const PatientAppointmentsList: React.FC<
  PatientAppointmentsListProps
> = () => {
  const [showAllAppointment] = useShowAllAppointmentsToggle();
  const userId = useUserIdFromSession();
  const { patientId } = useParams() as {
    patientId: string;
  };

  const {
    data: { appointments },
  } = useSuspenseQuery(getPatientAppointmentsQuery, {
    variables: {
      patientId,
    },
  });

  const data = useMemo(() => {
    return appointments
      .map<Row>((a) => {
        const { amendments, doctor_interaction, humanOutputs, ...rest } = a;

        const isSigned = Boolean(doctor_interaction?.noteSignedAt);
        const isAmending =
          Boolean(amendments.length) &&
          Boolean(amendments.some((a) => a.submittedAt === null));
        const isAmended =
          Boolean(amendments.length) &&
          Boolean(amendments.every((a) => a.submittedAt));

        return {
          ...rest,
          isSummarized: humanOutputs.length > 0,
          isSigned,
          isAmending,
          isAmended,
        };
      })
      .filter((a) => showAllAppointment || a.userId === userId);
  }, [appointments, showAllAppointment, userId]);

  return (
    <>
      <ShowAllAppointmentsToggle />
      <Suspense fallback={<TableSkeleton />}>
        <DataTable enableGlobalSearch columns={columns} data={data} />
      </Suspense>
    </>
  );
};
