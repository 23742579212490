import { ApolloProvider } from '@apollo/client';
import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import 'supertokens-auth-react';

import {
  BrowserSessionProvider,
  apolloClientFactory,
  defaultCache,
  sessionAccessTokenProvider,
} from '@eluve/apollo-client';
import {
  GlobalAssetDeletionHistoryPage,
  SubmitAssetDeletionRequestPage,
  TenantAssetDeletionHistoryPage,
} from '@eluve/frontend-asset-deletion-dash';
import {
  ArtifactDetailsPage,
  ArtifactListPage,
  CreateArtifactPage,
  CreateDatasetPage,
  CreateWorkflowPage,
  DatasetDetailsPage,
  DatasetsListPage,
  DuplicateWorkflowContextProvider,
  FactVerificationIndexPage,
  FactVerificationModelPromptTemplatePage,
  FactVerificationWorkflowDetailsPage,
} from '@eluve/frontend-feature-eval';
import {
  CreatePromptTemplatePage,
  CreatePromptTemplateVariantPage,
  ModelPromptTemplateDetailsPage,
  ModelsListPage,
  PromptTemplateDetailsLayout,
  PromptTemplateDetailsPromptTextPage,
  PromptTemplateModelArgsListPage,
  PromptTemplateUsersPage,
} from '@eluve/frontend-feature-prompt-templates';
import {
  ReviewAppointmentLayout,
  reviewAppointmentDetailsRoutes,
} from '@eluve/frontend-review-dash';

import { appConfig } from './config';
import { SingularEntityRedirect } from './routes/app/SingularEntityRedirect';
import { AdminAuditTrail } from './routes/app/admin/AdminAuditTrail';
import { AdminLayout } from './routes/app/admin/AdminLayout';
import { ApiTokensPage } from './routes/app/admin/api-tokens/ApiTokensPage';
import { ReviewersListPage } from './routes/app/admin/components/ReviewersListPage';
import { GlobalFeatureFlagsPage } from './routes/app/admin/global-features-flags/GlobalFeatureFlagsPage';
import { AdminTenant } from './routes/app/admin/tenant/$tenantId/AdminTenant';
import { AdminTenantCodeTypesPage } from './routes/app/admin/tenant/$tenantId/AdminTenantCodeTypesPage';
import { AdminTenantFeatureFlagsPage } from './routes/app/admin/tenant/$tenantId/AdminTenantFeatureFlagsPage';
import { AdminTenantImportedOutputTemplatesPage } from './routes/app/admin/tenant/$tenantId/AdminTenantImportedOutputTemplatesPage';
import { AdminTenantLayout } from './routes/app/admin/tenant/$tenantId/AdminTenantLayout';
import { AdminTenantLlmOutputTemplatesPage } from './routes/app/admin/tenant/$tenantId/AdminTenantLlmOutputTemplatesPage';
import { AdminTenantLlmOutputsPage } from './routes/app/admin/tenant/$tenantId/AdminTenantLlmOutputsPage';
import { AdminTenantPromptTemplatesPage } from './routes/app/admin/tenant/$tenantId/AdminTenantPromptTemplatesPage';
import { AdminTenantReviewersPage } from './routes/app/admin/tenant/$tenantId/AdminTenantReviewersPage';
import { AdminTenantUserDefaultSettingsPage } from './routes/app/admin/tenant/$tenantId/AdminTenantUserDefaultSettingsPage';
import { AssetDeletionDash } from './routes/app/admin/tenant/$tenantId/asset-deletion/AssetDeletionDash';
import { TenantDash } from './routes/app/admin/tenant/$tenantId/dash/TenantDash';
import { AdminAppointment } from './routes/app/admin/tenant/$tenantId/dash/appointments/$appointmentId/AdminAppointment';
import { AdminAppointmentOutlet } from './routes/app/admin/tenant/$tenantId/dash/appointments/$appointmentId/AdminAppointmentOutlet';
import { AdminAppointmentFiles } from './routes/app/admin/tenant/$tenantId/dash/appointments/$appointmentId/components/AdminAppointmentFiles';
import { AdminAppointmentSourceArtifacts } from './routes/app/admin/tenant/$tenantId/dash/appointments/$appointmentId/components/AdminAppointmentSourceArtifacts';
import { AdminTranscriptsPage } from './routes/app/admin/tenant/$tenantId/dash/appointments/$appointmentId/components/AdminTranscriptsPage';
import { AdminAppointmentsDash } from './routes/app/admin/tenant/$tenantId/dash/appointments/AdminAppointmentsDash';
import { AdminTenantUsers } from './routes/app/admin/tenant/$tenantId/users/AdminTenantUsers';
import { AdminTenants } from './routes/app/admin/tenant/AdminTenants';
import { UserPage } from './routes/app/admin/users/UserPage';
import { UsersTable } from './routes/app/admin/users/UsersTable';
import { CloneOutputTemplatePage } from './routes/app/output-templates/CloneOutputTemplatePage';
import { CreateOutputTemplatePage } from './routes/app/output-templates/CreateOutputTemplatePage';
import { CreateOutputTemplateVariantPage } from './routes/app/output-templates/CreateOutputTemplateVariantPage';
import { OutputTemplateDetailsPage } from './routes/app/output-templates/OutputTemplateDetailsPage';
import { OutputTemplatesListPage } from './routes/app/output-templates/OutputTemplatesListPage';
import { PromptTemplatesListPage } from './routes/app/prompts/PromptTemplatesListPage';
import { TenantGuard } from './routes/app/tenant/$tenantId/TenantGuard';

const adminApolloClient = apolloClientFactory({
  uri: appConfig.VITE_APOLLO_CLIENT_URI,
  desiredRole: 'eluve-admin',
  cacheInstance: defaultCache,
  accessTokenProvider: sessionAccessTokenProvider(new BrowserSessionProvider()),
});

export const createAdminRoutes = (path: string) => {
  return [
    {
      path,
      element: <AdminLayout />,
      children: [
        {
          index: true,
          element: <Navigate to="tenants" replace={true} />,
        },
        {
          path: 'tenants',
          element: (
            <ApolloProvider client={adminApolloClient}>
              <AdminTenants />
            </ApolloProvider>
          ),
        },
        {
          path: 'audit-trail',
          element: (
            <ApolloProvider client={adminApolloClient}>
              <AdminAuditTrail />
            </ApolloProvider>
          ),
        },
        {
          path: 'fact-verification',
          element: (
            <ApolloProvider client={adminApolloClient}>
              <Outlet />
            </ApolloProvider>
          ),
          children: [
            {
              index: true,
              element: <FactVerificationIndexPage />,
            },
            {
              path: 'workflow/create',
              element: <CreateWorkflowPage />,
            },
            {
              path: 'workflow/copy/:workflowId',
              element: (
                <DuplicateWorkflowContextProvider>
                  <CreateWorkflowPage />
                </DuplicateWorkflowContextProvider>
              ),
            },
            {
              path: ':workflowId',
              element: <FactVerificationWorkflowDetailsPage />,
            },
            {
              path: ':workflowId/:modelPromptTemplateId',
              element: <FactVerificationModelPromptTemplatePage />,
            },
            {
              path: 'artifacts',
              element: <ArtifactListPage />,
            },
            {
              path: 'artifacts/:artifactId',
              element: <ArtifactDetailsPage />,
            },
            {
              path: 'artifacts/create',
              element: <CreateArtifactPage />,
            },
            {
              path: 'datasets',
              element: <DatasetsListPage />,
            },
            {
              path: 'datasets/:datasetId',
              element: <DatasetDetailsPage />,
            },
            {
              path: 'datasets/create',
              element: <CreateDatasetPage />,
            },
          ],
        },
        {
          path: 'models',
          element: (
            <ApolloProvider client={adminApolloClient}>
              <ModelsListPage />
            </ApolloProvider>
          ),
        },
        {
          path: 'output-templates',
          element: (
            <ApolloProvider client={adminApolloClient}>
              <Suspense fallback={'Loading...'}>
                <Outlet />
              </Suspense>
            </ApolloProvider>
          ),
          children: [
            {
              index: true,
              element: <OutputTemplatesListPage />,
            },
            {
              path: 'create',
              element: <CreateOutputTemplatePage />,
            },
            {
              path: 'clone/:outputTemplateId',
              element: <CloneOutputTemplatePage />,
            },
            {
              path: ':outputTemplateId',
              element: <OutputTemplateDetailsPage />,
            },
            {
              path: ':outputTemplateId/variant/:variantNumber',
              element: <OutputTemplateDetailsPage />,
            },
            {
              path: ':outputTemplateId/variant-add/:baseVariantNumber',
              element: <CreateOutputTemplateVariantPage />,
            },
          ],
        },
        {
          path: 'prompts',
          element: (
            <ApolloProvider client={adminApolloClient}>
              <Suspense fallback={'Loading...'}>
                <Outlet />
              </Suspense>
            </ApolloProvider>
          ),
          children: [
            {
              index: true,
              element: <PromptTemplatesListPage />,
            },
            {
              path: 'create',
              element: (
                <CreatePromptTemplatePage onDoneNavigateTo="/admin/prompts" />
              ),
            },
            {
              path: 'clone/:promptTemplateId',
              element: (
                <CreatePromptTemplatePage onDoneNavigateTo="/admin/prompts" />
              ),
            },
            {
              path: 'clone/:promptTemplateId/variant/:variantNumber',
              element: <CreatePromptTemplateVariantPage />,
            },
            {
              path: ':promptTemplateId',
              element: <PromptTemplateDetailsLayout />,
              children: [
                {
                  index: true,
                  element: <Navigate to="prompt" replace />,
                },
                {
                  path: 'prompt',
                  element: <PromptTemplateDetailsPromptTextPage />,
                },
                {
                  path: 'prompt/variant/:variantNumber',
                  element: <PromptTemplateDetailsPromptTextPage />,
                },
                {
                  path: 'model-args',
                  element: <PromptTemplateModelArgsListPage />,
                },
                {
                  path: 'users',
                  element: <PromptTemplateUsersPage />,
                },
              ],
            },
            {
              path: ':promptTemplateId/:modelPromptTemplateId',
              element: <ModelPromptTemplateDetailsPage />,
            },
          ],
        },
        {
          path: 'tenant/*',
          element: <SingularEntityRedirect entityName="tenant" />,
        },
        {
          path: 'tenants/:tenantId',
          element: (
            <TenantGuard>
              <AdminTenantLayout />
            </TenantGuard>
          ),
          children: [
            {
              index: true,
              element: <Navigate to="manage" replace />,
            },
            {
              path: 'manage',
              element: (
                <ApolloProvider client={adminApolloClient}>
                  <AdminTenant />
                </ApolloProvider>
              ),
              children: [
                {
                  index: true,
                  element: <Navigate to="feature-flags" replace />,
                },
                {
                  path: 'feature-flags',
                  element: <AdminTenantFeatureFlagsPage />,
                },
                {
                  path: 'llm-outputs',
                  element: <AdminTenantLlmOutputsPage />,
                },
                {
                  path: 'prompt-templates',
                  element: <AdminTenantPromptTemplatesPage />,
                },
                {
                  path: 'llm-output-templates',
                  element: <AdminTenantLlmOutputTemplatesPage />,
                },
                {
                  path: 'imported-llm-output-templates',
                  element: <AdminTenantImportedOutputTemplatesPage />,
                },
                {
                  path: 'default-user-settings',
                  element: <AdminTenantUserDefaultSettingsPage />,
                },
                {
                  path: 'code-types',
                  element: <AdminTenantCodeTypesPage />,
                },
                {
                  path: 'reviewers',
                  element: <AdminTenantReviewersPage />,
                },
              ],
            },
            {
              path: 'dash',
              element: <TenantDash />,
            },
            {
              path: 'asset-deletion',
              element: (
                <ApolloProvider client={adminApolloClient}>
                  <AssetDeletionDash />
                </ApolloProvider>
              ),
              children: [
                {
                  index: true,
                  element: <Navigate to="new" replace />,
                },
                {
                  path: 'new',
                  element: <SubmitAssetDeletionRequestPage />,
                },
                {
                  path: 'history',
                  element: <TenantAssetDeletionHistoryPage />,
                },
              ],
            },
            {
              path: 'appointment/*',
              element: <SingularEntityRedirect entityName="appointment" />,
            },
            {
              path: 'appointments',
              element: <AdminAppointmentsDash />,
            },
            {
              path: 'appointments/:appointmentId',
              element: <AdminAppointmentOutlet />,
              children: [
                {
                  index: true,
                  element: <Navigate to="details" replace />,
                },
                {
                  path: 'details',
                  element: <AdminAppointment />,
                },
                {
                  path: 'artifacts',
                  element: <ReviewAppointmentLayout />,
                  children: reviewAppointmentDetailsRoutes,
                },
                {
                  path: 'files',
                  element: <AdminAppointmentFiles />,
                },
                {
                  path: 'transcript-comparison',
                  element: <AdminTranscriptsPage />,
                },
                {
                  path: 'source-artifacts',
                  element: (
                    <ApolloProvider client={adminApolloClient}>
                      <AdminAppointmentSourceArtifacts />
                    </ApolloProvider>
                  ),
                },
              ],
            },
            {
              path: 'users',
              element: <AdminTenantUsers />,
            },
          ],
        },

        {
          path: 'global-feature-flags',
          element: (
            <ApolloProvider client={adminApolloClient}>
              <GlobalFeatureFlagsPage />
            </ApolloProvider>
          ),
        },
        {
          path: 'users',
          element: (
            <ApolloProvider client={adminApolloClient}>
              <Outlet />
            </ApolloProvider>
          ),
          children: [
            {
              index: true,
              element: <UsersTable />,
            },
            {
              path: ':userId',
              element: <UserPage />,
            },
          ],
        },
        {
          path: 'api-tokens',
          element: (
            <ApolloProvider client={adminApolloClient}>
              <ApiTokensPage />
            </ApolloProvider>
          ),
        },
        {
          path: 'reviewers-list',
          element: (
            <ApolloProvider client={adminApolloClient}>
              <ReviewersListPage />
            </ApolloProvider>
          ),
        },
        {
          path: 'asset-deletion-global-history',
          element: (
            <ApolloProvider client={adminApolloClient}>
              <GlobalAssetDeletionHistoryPage />
            </ApolloProvider>
          ),
        },
      ],
    },
  ];
};
