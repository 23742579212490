import { useMutation } from '@apollo/client';
import { produce } from 'immer';
import React, { useState } from 'react';
import { v4 } from 'uuid';

import {
  QUERY_ROOT_ID,
  cacheUtils,
  useCompleteFragment,
} from '@eluve/apollo-client';
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  HStack,
  Icon,
  NewButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tag,
  toast,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

import { formatSnakeCaseLabel } from './formatSnakeCaseLabel';
import {
  appointmentTagOptionsFragment,
  appointmentTagsFragment,
} from './operations';

const addAppointmentTagMutation = graphql(`
  mutation addAppointmentTag(
    $appointmentId: uuid!
    $tagId: uuid!
    $tenantId: uuid!
  ) {
    insertAppointmentTagsOne(
      object: {
        appointmentId: $appointmentId
        tagId: $tagId
        tenantId: $tenantId
      }
    ) {
      __typename
      id
    }
  }
`);

const removeAppointmentTagMutation = graphql(`
  mutation removeAppointmentTag($tenantId: uuid!, $appointmentTagId: uuid!) {
    deleteAppointmentTagsByPk(tenantId: $tenantId, id: $appointmentTagId) {
      __typename
      id
    }
  }
`);

export interface AppointmentTagsProps {
  tenantId: string;
  appointmentId: string;
}

export const AppointmentTags: React.FC<AppointmentTagsProps> = ({
  tenantId,
  appointmentId,
}) => {
  const [tagsOpen, setTagsOpen] = useState(false);

  const tagOptions = useCompleteFragment({
    fragment: appointmentTagOptionsFragment,
    key: {
      id: QUERY_ROOT_ID,
    },
  });

  const appointmentTags = useCompleteFragment({
    fragment: appointmentTagsFragment,
    key: {
      id: appointmentId,
    },
  });

  const [addAppointmentTag] = useMutation(addAppointmentTagMutation, {
    onError: () => toast.error('Failed to add tag.'),
    optimisticResponse: {
      insertAppointmentTagsOne: {
        __typename: 'AppointmentTags' as const,
        id: v4(),
      },
    },
  });

  const [removeAppointmentTag] = useMutation(removeAppointmentTagMutation, {
    onError: () => toast.error('Failed to remove tag.'),
    optimisticResponse: ({ appointmentTagId }) => ({
      deleteAppointmentTagsByPk: {
        __typename: 'AppointmentTags' as const,
        id: appointmentTagId,
      },
    }),
  });

  const handleAddAppointmentTag = (tagId: string, tagName: string) => {
    addAppointmentTag({
      variables: {
        appointmentId,
        tagId,
        tenantId,
      },
      update: (_cache, { data }) => {
        cacheUtils.updateFragment(
          {
            fragment: appointmentTagsFragment,
            key: {
              id: appointmentId,
            },
          },
          (existing) => {
            if (!existing) {
              return existing;
            }
            return produce(existing, (draft) => {
              draft.tags.push({
                __typename: 'AppointmentTags' as const,
                id: data?.insertAppointmentTagsOne?.id ?? v4(),
                tag: {
                  __typename: 'AppointmentTagOptions',
                  id: tagId,
                  tag: tagName,
                },
              });
            });
          },
        );
      },
    });
  };

  const handleRemoveAppointmentTag = (appointmentTagId: string) => {
    removeAppointmentTag({
      variables: {
        tenantId,
        appointmentTagId,
      },
      update: (_cache) => {
        cacheUtils.updateFragment(
          {
            fragment: appointmentTagsFragment,
            key: {
              id: appointmentId,
            },
          },
          (existing) => {
            if (!existing) {
              return existing;
            }

            return {
              ...existing,
              tags: existing.tags.filter((t) => t.id !== appointmentTagId),
            };
          },
        );
      },
    });
  };

  const tags = appointmentTags?.tags ?? [];

  const availableTagOptions = (tagOptions.appointmentTagOptions ?? []).filter(
    (t) => !tags.some((tag) => tag.tag.id === t.id),
  );

  return (
    <HStack wrap>
      {tags.map((t) => (
        <Tag
          key={t.id}
          color="teal"
          size="s"
          label={formatSnakeCaseLabel(t.tag.tag)}
          pill
        >
          <button
            onClick={() => handleRemoveAppointmentTag(t.id)}
            className="-m-2 -mr-2.5 size-8 cursor-pointer rounded-full border-8 border-transparent bg-clip-padding p-px hover:bg-tealContrast hover:text-teal"
          >
            <Icon name="X" size="2xs" />
          </button>
        </Tag>
      ))}
      <Popover open={tagsOpen} onOpenChange={setTagsOpen}>
        <PopoverTrigger asChild>
          <NewButton
            icon={{ name: 'Plus' }}
            size="s"
            text="Add"
            type="outline"
          />
        </PopoverTrigger>
        <PopoverContent side="right" align="start">
          <Command>
            <CommandInput placeholder="Select tag" />
            <CommandList>
              <CommandGroup>
                {availableTagOptions.map((t) => (
                  <CommandItem
                    key={t.tag}
                    onSelect={() => handleAddAppointmentTag(t.id, t.tag)}
                  >
                    {formatSnakeCaseLabel(t.tag)}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </HStack>
  );
};
