import { Text, View } from '@react-pdf/renderer';

import { Block } from '@eluve/llm-outputs';

import { tw } from '../pdf-tw';
import { commonStyles } from '../styles';
import { PdfAmendment } from '../types';

import { TextAmendments } from './TextAmendments';

export const PdfRangeField: React.FC<{
  block: Block;
  amendments: PdfAmendment[];
}> = ({ block, amendments }) => {
  if (block.type !== 'range') return null;

  return (
    <View key={block.key}>
      {block.label && (
        <Text style={tw(commonStyles.headingText())}>{block.label}</Text>
      )}
      <View style={tw(commonStyles.content())}>
        <Text style={tw(commonStyles.text())}>
          Value: {block.value !== undefined ? block.value : 'Not set'}
          {block.min !== undefined &&
            block.max !== undefined &&
            ` (Range: ${block.min} - ${block.max})`}
        </Text>
        <TextAmendments blockPath={block.key} amendments={amendments} />
      </View>
    </View>
  );
};
