import { match } from 'ts-pattern';

import { Block, isEmptyBlock } from '@eluve/llm-outputs';

import { PdfAmendment } from '../types';

import { PdfCheckboxField } from './PdfCheckboxField';
import { PdfGroupField } from './PdfGroupField';
import { PdfRangeField } from './PdfRangeField';
import { PdfTextField } from './PdfTextField';

export const PdfBlock: React.FC<{
  block: Block;
  amendments: PdfAmendment[];
}> = ({ block, amendments }) => {
  if (isEmptyBlock(block)) {
    return null;
  }
  return match(block.type)
    .with('group', () => (
      <PdfGroupField block={block} amendments={amendments} />
    ))
    .with('range', () => (
      <PdfRangeField block={block} amendments={amendments} />
    ))
    .with('checkbox', () => (
      <PdfCheckboxField block={block} amendments={amendments} />
    ))
    .otherwise(() => <PdfTextField block={block} amendments={amendments} />);
};
