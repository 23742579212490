import { useCompleteFragment } from '@eluve/apollo-client';
import { graphql } from '@eluve/graphql.tada';

import { useAppointmentId } from './appointment.context';

const appointmentModeFragment = graphql(`
  fragment AppointmentMode on Appointments {
    __typename
    id
    mode
  }
`);

export const useAppointmentMode = () => {
  const appointmentId = useAppointmentId();
  const apptMode = useCompleteFragment({
    fragment: appointmentModeFragment,
    key: { id: appointmentId },
  });

  return apptMode.mode;
};
