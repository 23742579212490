import { useMutation } from '@apollo/client';
import { WandSparkles } from 'lucide-react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useMount } from 'react-use';

import { CopyRichTextButton } from '@eluve/blocks';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  DetailsSidebarProvider,
  Divider,
  H4,
  NewButton,
  P,
  VStack,
  textStyles,
  toast,
  useDetailsSidebar,
} from '@eluve/components';
import { HStack } from '@eluve/components';
import {
  useAppointmentActivity,
  useAppointmentId,
  useAppointmentLocationId,
  useAppointmentMode,
  useAppointmentPatient,
  useIsAppointmentReadonly,
  useSummary,
} from '@eluve/frontend-appointment-hooks';
import {
  AppointmentBillingCodes,
  AppointmentLanguageSelector,
  MedicineRecommendations,
} from '@eluve/frontend-feature-appointment';
import { DbEnums } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';
import { DynamicSummary } from '@eluve/llm-outputs';
import { useNamedLogger } from '@eluve/logger';
import { FeatureFlagKeysLookup } from '@eluve/posthog';
import { useCaptureEvent, useFeatureFlagPayload } from '@eluve/posthog-react';
import { useAssignedTenantIdFromParams } from '@eluve/session-helpers';
import {
  CustomInstructions,
  DynamicArtifactRenderer,
  DynamicArtifactSaveContextProps,
  FeatureFlaggedComponent,
  RegenerateSummaryButton,
  RollbackPreviousSummaryButton,
  SummaryFeedbackModal,
  TenantOrUserFeatureFlaggedComponent,
  UserFeatureFlaggedComponent,
} from '@eluve/smart-blocks';
import { convertArrayToPgLiteral } from '@eluve/utils';

import { SummarizationErrorCard } from '../SummarizationErrorCard';
import { useSummarizationInProgress } from '../hooks/useSummarizationInProgress';

import { pendingUnsavedChangesStore } from './AppointmentLastSaved';
import { HiddenForManualCharting } from './HiddenForManualCharting';
import { DifferentialDiagnosesComponent } from './Insights/DifferentialDiagnosesComponent';

const updateHumanOutputContentMutation = graphql(`
  mutation updateHumanOutputContent($id: uuid!, $path: _text!, $value: jsonb!) {
    updateHumanOutputContent(args: { _id: $id, path: $path, value: $value }) {
      __typename
      id
      content
      updatedAt
    }
  }
`);

const SummaryInProgress = () => (
  <Box
    vStack
    fullWidth
    className="max-w-[500px] items-center gap-8 self-center pt-9 text-center"
  >
    <h4 className="text-2xl text-gray-12">Generating Notes...</h4>
    <p className="text-lg text-gray-11">
      You can leave this page and start a new session! We&apos;ll let you know
      when your note is ready.
    </p>
  </Box>
);

type SummaryProps = {
  llmOutputId: string | null;
  humanOutputId: string | null;
};

const SummaryInProgressTakingLonger = () => (
  <Box
    vStack
    fullWidth
    className="max-w-[500px] items-center gap-8 self-center pt-9 text-center"
  >
    <h4 className="text-2xl text-gray-12">
      This is taking longer than expected...
    </h4>
    <p className="text-lg text-gray-11">
      We're still working on your notes. You can leave this page and start a new
      session! We&apos;ll let you know when your note is ready.
    </p>
  </Box>
);

const SummaryFailed: React.FC = () => {
  const logger = useNamedLogger('AppointmentSummaryFailed');

  useMount(() => {
    logger.error('No summary available to display to user');
  });

  return (
    <Box
      vStack
      fullWidth
      className="max-w-[470px] items-center gap-8 self-center py-9 text-center"
    >
      <h4 className="text-2xl text-gray-12">Failed to generate notes</h4>
      <p className="text-lg text-gray-11">
        We were unable to generate notes for this appointment. We alerted the
        team and will investigate the issue.
      </p>
      <p className="text-lg text-gray-11">
        In the meantime you might try regenerating the summary using a different
        template.
      </p>
      <RegenerateSummaryButton />
    </Box>
  );
};

const DynamicSummaryResult: React.FC<
  SummaryProps & { summary: DynamicSummary }
> = ({ summary, llmOutputId, humanOutputId }) => {
  const isReadonly = useIsAppointmentReadonly();
  const patient = useAppointmentPatient();

  const [updateHumanOutputContent] = useMutation(
    updateHumanOutputContentMutation,
  );

  const saveBlock: DynamicArtifactSaveContextProps['saveBlock'] | undefined =
    useMemo(() => {
      if (isReadonly || !humanOutputId) {
        return undefined;
      }

      return async (blockPath, value) => {
        if (!blockPath || value === undefined) {
          return;
        }

        await updateHumanOutputContent({
          variables: {
            id: humanOutputId,
            path: convertArrayToPgLiteral(blockPath),
            value: value as Record<string, unknown>,
          },
          onCompleted: () => pendingUnsavedChangesStore.setState(false),
          onError: () => toast.error('Failed to save block'),
        });
      };
    }, [isReadonly, updateHumanOutputContent, humanOutputId]);

  const onPendingChanges: DynamicArtifactSaveContextProps['onPendingChanges'] =
    useCallback(() => pendingUnsavedChangesStore.setState(true), []);

  return (
    <DynamicArtifactRenderer
      key={llmOutputId}
      blocks={summary.blocks}
      isReadonly={isReadonly}
      saveBlock={saveBlock}
      onPendingChanges={onPendingChanges}
      email={patient?.external_patients_info?.[0]?.email}
    />
  );
};

const SummaryResult = () => {
  const currentSummary = useSummary();
  const appointmentId = useAppointmentId();
  const contentRef = useRef<HTMLDivElement>(null);

  const isReadonly = useIsAppointmentReadonly();
  const flag = FeatureFlagKeysLookup.NOTE_REGENERATION_WITH_CUSTOM_INSTRUCTIONS;
  const exampleChips = useFeatureFlagPayload(flag);
  const [instructions, setInstructions] = useState<string>('');
  const tenantId = useAssignedTenantIdFromParams();
  const locationId = useAppointmentLocationId();

  const captureEvent = useCaptureEvent({
    tenantId,
    appointmentId,
  });

  const { setOpen } = useDetailsSidebar();

  if (!currentSummary.isSummaryAvailable) {
    return null;
  }

  return (
    <div className="flex w-full flex-col">
      {!isReadonly && (
        <VStack
          justify="between"
          wFull
          className="pb-5 lg:flex-row lg:items-center"
        >
          <HStack gap={4} className="flex-wrap">
            <HiddenForManualCharting>
              <RegenerateSummaryButton />
              <UserFeatureFlaggedComponent flag="NOTE_REGENERATION_WITH_CUSTOM_INSTRUCTIONS">
                <CustomInstructions
                  instructions={instructions}
                  setInstructions={setInstructions}
                  disabled={currentSummary.isEmpty}
                />
              </UserFeatureFlaggedComponent>
              <RollbackPreviousSummaryButton />
            </HiddenForManualCharting>

            <CopyRichTextButton
              getContentElement={() => contentRef.current}
              hideContentInTooltip
              size="s"
              text="Copy Summary"
              type="outline"
            />
          </HStack>

          <div>
            <TenantOrUserFeatureFlaggedComponent flag="MULTILINGUAL_SUPPORT">
              <AppointmentLanguageSelector
                inline
                outputLanguage
                regenerateSummary
              />
            </TenantOrUserFeatureFlaggedComponent>
          </div>
        </VStack>
      )}

      <SummarizationErrorCard />

      <div ref={contentRef} className="w-full">
        {currentSummary.isSummaryAvailable && (
          <DynamicSummaryResult
            llmOutputId={currentSummary.llmOutputId}
            humanOutputId={currentSummary.humanOutputId}
            summary={currentSummary.summary!}
          />
        )}
      </div>

      <Divider className="my-7 border-brandGray300" />

      <VStack gap={8} wFull className="mb-10">
        <FeatureFlaggedComponent flag="INSIGHTS">
          <VStack>
            <P
              className={textStyles.title({
                weight: 'semibold',
                size: 'l',
                className: 'text-nowrap',
              })}
            >
              Differential Diagnoses{' '}
            </P>
            <div
              className="w-full rounded-lg border border-brandGray200 bg-white
 p-6"
            >
              <DifferentialDiagnosesComponent />
            </div>
          </VStack>
        </FeatureFlaggedComponent>

        <FeatureFlaggedComponent flag="BILLING_CODES">
          <AppointmentBillingCodes
            isReadonly={isReadonly}
            appointmentId={appointmentId}
            locationId={locationId}
            tenantId={tenantId}
          />
        </FeatureFlaggedComponent>

        <FeatureFlaggedComponent flag="MEDICATION">
          <MedicineRecommendations
            appointmentId={appointmentId}
            tenantId={tenantId}
          />
        </FeatureFlaggedComponent>

        {currentSummary.isSummaryAvailable && (
          <HiddenForManualCharting>
            <Box className="flex w-full flex-row items-center justify-between gap-2 rounded-lg bg-brandGray100 p-4">
              <SummaryFeedbackModal
                appointmentId={appointmentId}
                llmOutputId={currentSummary?.llmOutputId}
              />
            </Box>
          </HiddenForManualCharting>
        )}

        {currentSummary.isSummaryAvailable &&
          !isReadonly &&
          !currentSummary.isEmpty && (
            <HiddenForManualCharting>
              <UserFeatureFlaggedComponent flag="NOTE_REGENERATION_WITH_CUSTOM_INSTRUCTIONS">
                <Card className="w-full rounded-xl border-gray-4">
                  <CardHeader className="flex h-10 flex-row rounded-t-xl bg-white p-5">
                    <div className="flex flex-row gap-3">
                      <WandSparkles />
                      <H4>Improve your notes</H4>
                    </div>
                  </CardHeader>
                  <CardContent className="flex flex-col items-start rounded-b-xl bg-white px-0 py-3 pt-4">
                    {exampleChips?.map((chip, index) => {
                      return (
                        <div key={chip} className="w-full px-2">
                          <NewButton
                            onClick={() => {
                              captureEvent(
                                'user_clicked_top_level_improve_notes_chip',
                                {
                                  instructions,
                                },
                              );
                              setInstructions(chip);
                              setOpen(true);
                            }}
                            text={chip}
                            type="ghost"
                          />
                          {index !== exampleChips.length - 1 && (
                            <hr className="mx-3 my-1 bg-gray-4" />
                          )}
                        </div>
                      );
                    })}
                  </CardContent>
                </Card>
              </UserFeatureFlaggedComponent>
            </HiddenForManualCharting>
          )}
      </VStack>
    </div>
  );
};

export const AppointmentSummaryResult = () => {
  const { latestSummaryStatus, latestSummaryNumAttempts } =
    useAppointmentActivity();
  const isSummarizationInProgress = useSummarizationInProgress();
  const appointmentMode = useAppointmentMode();

  if (appointmentMode === DbEnums.AppointmentMode.MANUAL_CHARTING) {
    return (
      <DetailsSidebarProvider overlayOnly>
        <SummaryResult />
      </DetailsSidebarProvider>
    );
  }

  if (isSummarizationInProgress) {
    if (latestSummaryNumAttempts && latestSummaryNumAttempts < 2) {
      return <SummaryInProgress />;
    } else {
      return <SummaryInProgressTakingLonger />;
    }
  }

  if (latestSummaryStatus === 'FAILED') {
    return <SummaryFailed />;
  }

  return (
    <DetailsSidebarProvider overlayOnly>
      <SummaryResult />
    </DetailsSidebarProvider>
  );
};
