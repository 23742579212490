import { Suspense } from 'react';
import { Helmet } from 'react-helmet';

import { Content, Layout } from '@eluve/components';

import { CreatePromptTemplatePage } from './CreatePromptTemplatePage';

export const PromptsPage = () => {
  return (
    <Suspense fallback={'Loading...'}>
      <Layout type="simple">
        <Helmet>
          <title>Prompts | Eluve</title>
        </Helmet>
        <Content padded>
          <div className="p-3 pb-6 pt-0">
            <CreatePromptTemplatePage onDoneNavigateTo="/" />
          </div>
        </Content>
      </Layout>
    </Suspense>
  );
};
