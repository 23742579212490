import { useLocalStorage } from '@uidotdev/usehooks';
import React from 'react';
import { useMedia } from 'react-use';

import { Box, Label, Switch } from '@eluve/components';
import { useNamedLogger } from '@eluve/logger';
import { useIsTenantAdminFromSession } from '@eluve/session-helpers';
import {
  useTenantIdFromParams,
  useUserIdFromSession,
} from '@eluve/session-helpers';

export const useShowAllAppointmentsToggle = () => {
  const logger = useNamedLogger('useShowAllAppointmentsToggle');
  const userId = useUserIdFromSession();
  const tenantId = useTenantIdFromParams();

  if (!userId || !tenantId) {
    logger.error(
      'userId or tenantId is not available in route params, use useShowAllAppointmentsToggle only in routes where userId and tenantId are available',
    );

    throw new Error(
      'useShowAllAppointmentsToggle: userId or tenantId is not available',
    );
  }

  return useLocalStorage<boolean>(
    `eluve-config-show-all-appointments-for-${tenantId}-${userId}`,
    false,
  );
};

export const ShowAllAppointmentsToggle: React.FC = () => {
  const isTenantAdmin = useIsTenantAdminFromSession();
  const [showAllAppointments, setShowAllAppointments] =
    useShowAllAppointmentsToggle();
  const isPhoneSize = !useMedia('(min-width: 32rem)');

  if (!isTenantAdmin) {
    return null;
  }

  return (
    <Box hStack className="w-full sm:w-max sm:border-r sm:pr-6">
      <Switch
        isCompact={isPhoneSize}
        checked={showAllAppointments}
        onCheckedChange={(checked) => setShowAllAppointments(checked)}
      />
      <Label className="text-nowrap text-sm md:text-base">
        Show all appointments
      </Label>
    </Box>
  );
};
