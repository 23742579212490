import { useMutation, useSuspenseQuery } from '@apollo/client';
import { produce } from 'immer';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { cacheUtils } from '@eluve/apollo-client';
import { AvatarWithInitials } from '@eluve/blocks';
import {
  ColDefBuilder,
  Combobox,
  ComboboxDropdown,
  ComboboxEmpty,
  ComboboxOption,
  ComboboxSelectButton,
  DataTable,
  HStack,
  Icon,
  NewButton,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  toast,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { useAssignedTenantIdFromParams } from '@eluve/session-helpers';

import {
  addUserToUserGroupMutation,
  removeUserFromGroupMutation,
  tenantUsersFragment,
  userGroupUsersFragment,
} from './operations';

// Types

type UserRow = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  tenantId: string;
  groupId: string;
  userId: string;
};

// Private Components

type RemoveUserButtonProps = {
  groupId: string;
  id: string;
  tenantId: string;
};

const RemoveUserButton: React.FC<RemoveUserButtonProps> = ({
  groupId,
  id,
  tenantId,
}) => {
  const [removeUserFromGroup] = useMutation(removeUserFromGroupMutation, {
    variables: {
      id,
      tenantId,
    },
    onError: () => toast.error('We could not remove the user from the group.'),
    optimisticResponse: () => {
      return {
        deleteUserGroupUsersByPk: {
          __typename: 'UserGroupUsers' as const,
          id,
        },
      };
    },
    update: (_cache) => {
      cacheUtils.updateFragment(
        {
          fragment: userGroupUsersFragment,
          key: {
            id: groupId,
          },
        },
        (existing) => {
          if (!existing) {
            return existing;
          }

          return produce(existing, (draft) => {
            const index = draft.users.findIndex(
              (userGroupUser) => userGroupUser.id === id,
            );
            if (index !== -1) {
              draft.users.splice(index, 1);
            }
          });
        },
      );
    },
  });

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <NewButton
          icon={{ name: 'Trash' }}
          onClick={() => removeUserFromGroup()}
          type="outline"
        />
      </TooltipTrigger>
      <TooltipContent>Remove user from this group</TooltipContent>
    </Tooltip>
  );
};

// Constants

const columns = new ColDefBuilder<UserRow>()
  .colDef({
    id: 'avatar',
    cell: ({ row }) => (
      <AvatarWithInitials
        id={row.original.userId}
        name={[row.original.firstName, row.original.lastName].join(' ')}
      />
    ),
  })
  .defaultSortable('firstName', 'First Name')
  .defaultSortable('lastName', 'Last Name')
  .defaultSortable('email', 'Email')
  .colDef({
    id: 'actions',
    cell: ({ row }) => (
      <HStack justify="end" wFull>
        <RemoveUserButton
          groupId={row.original.groupId}
          id={row.original.id}
          tenantId={row.original.tenantId}
        />
      </HStack>
    ),
  })
  .build();

const getUserGroupUsersDataQuery = graphql(
  `
    query getUserGroupUsersData($tenantId: uuid!, $userGroupId: uuid!) {
      userGroupsByPk(id: $userGroupId, tenantId: $tenantId) {
        __typename
        id
        ...UserGroupUsers
      }
      tenantsByPk(id: $tenantId) {
        __typename
        id
        ...TenantUsers
      }
    }
  `,
  [userGroupUsersFragment, tenantUsersFragment],
);

// Public Components

export const UserGroupUsers: React.FC = () => {
  const tenantId = useAssignedTenantIdFromParams();
  const { userGroupId } = useParams() as { userGroupId: string };

  const { data } = useSuspenseQuery(getUserGroupUsersDataQuery, {
    variables: {
      tenantId,
      userGroupId,
    },
  });

  const tenantUsers = data.tenantsByPk?.tenantUsers ?? [];
  const userGroupUsers = useMemo(
    () => data.userGroupsByPk?.users ?? [],
    [data?.userGroupsByPk],
  );

  const usersToAdd = tenantUsers.filter(
    (tenantUser) =>
      !userGroupUsers.some(
        (userGroupUser) =>
          userGroupUser.tenant_user.user?.id === tenantUser.user.id,
      ),
  );

  const rows = useMemo(
    () =>
      userGroupUsers.map<UserRow>((userGroupUser) => {
        const user = userGroupUser.tenant_user.user;
        return {
          id: userGroupUser.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          tenantId,
          groupId: userGroupId,
          userId: user.id,
        };
      }),
    [tenantId, userGroupId, userGroupUsers],
  );

  const [addUserToGroup] = useMutation(addUserToUserGroupMutation, {
    onError: () => toast.error('We could not add the user to the group.'),
    optimisticResponse: ({ groupId, userId }) => ({
      insertUserGroupUsersOne: {
        __typename: 'UserGroupUsers' as const,
        id: 'optimistic',
        tenantId,
        groupId,
        userId,
      },
    }),
    update: (_cache, { data }) => {
      cacheUtils.updateFragment(
        {
          fragment: userGroupUsersFragment,
          key: {
            id: userGroupId,
          },
        },
        (existing) => {
          const insertedUser = data?.insertUserGroupUsersOne;
          const tenantUser = tenantUsers.find(
            (tenantUser) => tenantUser.user.id === insertedUser?.userId,
          );

          if (!existing || !insertedUser || !tenantUser) {
            return existing;
          }

          return produce(existing, (draft) => {
            draft.users.push({
              ...insertedUser,
              tenant_user: tenantUser,
            });
          });
        },
      );
    },
  });

  const handleAddUserToGroup = async (userId: string) => {
    await addUserToGroup({
      variables: {
        groupId: userGroupId,
        userId,
      },
    });
  };

  return (
    <div className="grid gap-5">
      <div className="flex w-full items-center gap-5">
        <div className="flex-1 rounded-lg border">
          <Combobox>
            <ComboboxSelectButton
              className="w-full border-transparent hover:bg-gray-3"
              size="sm"
            >
              <HStack className="p-2">
                <Icon name="UserRoundPlus" size="xs" />
                <span className="text-brandGray700">Select a user to add</span>
              </HStack>
            </ComboboxSelectButton>
            <ComboboxDropdown searchPlaceholder="Search users">
              <ComboboxEmpty>
                <HStack
                  className="p-2 text-sm text-brandGray600"
                  justify="center"
                >
                  No users available to add.
                </HStack>
              </ComboboxEmpty>
              {usersToAdd.map(({ user, userId }) => (
                <ComboboxOption
                  key={userId}
                  onSelect={() => handleAddUserToGroup(userId)}
                >
                  <HStack>
                    <AvatarWithInitials
                      id={userId}
                      name={[user.firstName, user.lastName].join(' ')}
                    />
                    <span>
                      {`${user.firstName} ${user.lastName} (${user.email})`}
                    </span>
                  </HStack>
                </ComboboxOption>
              ))}
            </ComboboxDropdown>
          </Combobox>
        </div>
      </div>
      <DataTable
        data={rows}
        columns={columns}
        placeholder="No users in this group yet."
      />
    </div>
  );
};
