import { z } from 'zod';

export const medicineRecommendationSchema = z.array(
  z
    .object({
      name: z.string().describe('Name of the medication.'),
      amount: z
        .string()
        .optional()
        .describe('Dosage amount in some unit type (e.g mg, g, etc).'),
      dosageInstruction: z
        .string()
        .optional()
        .describe('Instructions on how frequently to take medication.'),
    })
    .passthrough(),
);

export type MedicineOutputType = z.infer<typeof medicineRecommendationSchema>;
