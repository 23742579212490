import { useSubscription } from '@apollo/client';
import React from 'react';

import { useCompleteFragment } from '@eluve/apollo-client';
import { MedicalCode } from '@eluve/blocks';
import { HStack, VStack } from '@eluve/components';
import {
  appointmentAmendments,
  appointmentBillingCodeAmendments,
  originalAppointmentBillingCodes,
  useAmendmentActivity,
  useIsUserAppointmentOwner,
} from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';

import { AppointmentBillingCodesAmendment } from './AppointmentBillingCodesAmendment';

export interface AppointmentBillingCodesAmendmentHistoryProps {
  tenantId: string;
  appointmentId: string;
}

const listenForBillingCodeAmendmentsSubscription = graphql(
  `
    subscription BillingCodeAmendments(
      $tenantId: uuid!
      $appointmentId: uuid!
    ) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        ...AppointmentAmendments
      }
    }
  `,
  [appointmentAmendments],
);

export const AppointmentBillingCodesAmendmentHistory: React.FC<
  AppointmentBillingCodesAmendmentHistoryProps
> = ({ appointmentId, tenantId }) => {
  const { hasAmendments } = useAmendmentActivity();

  useSubscription(listenForBillingCodeAmendmentsSubscription, {
    variables: { tenantId, appointmentId },
    skip: !hasAmendments,
  });

  const originalCodesFrag = useCompleteFragment({
    fragment: originalAppointmentBillingCodes,
    key: { id: appointmentId },
  });

  const amendmentsFrag = useCompleteFragment({
    fragment: appointmentBillingCodeAmendments,
    key: { id: appointmentId },
  });

  const amendments =
    amendmentsFrag?.amendments
      .filter(
        (a) => Boolean(a.billing_code_amendment) && Boolean(a.submittedAt),
      )
      .sort((a, b) => {
        const dateA = new Date(a.createdAt).getTime();
        const dateB = new Date(b.createdAt).getTime();
        return dateA - dateB;
      }) ?? [];

  const { isBillingCodeAmendmentInProgress, hasBillingCodeAmendments } =
    useAmendmentActivity();
  const isUserApptOwner = useIsUserAppointmentOwner();

  const latestAmendmentId =
    amendments.length > 0 ? amendments[amendments.length - 1]!.id : null;

  if (!hasBillingCodeAmendments) {
    return null;
  }

  return (
    <VStack gap={8}>
      {originalCodesFrag.past_billing_codes?.length ? (
        <HStack gap={3} wrap>
          {originalCodesFrag.past_billing_codes.map((code) => (
            <MedicalCode
              code={
                (code.tenant_medical_code?.code ?? code.medical_code?.code)!
              }
              codeType={
                (code.tenant_medical_code?.codeType ??
                  code.medical_code?.codeType)!
              }
              description={
                (code.tenant_medical_code?.description ??
                  code.medical_code?.description)!
              }
              disabled
              key={code.id}
            />
          ))}
        </HStack>
      ) : (
        <div className="text-brandGray400">No billing codes.</div>
      )}

      {amendments.map((a) => (
        <AppointmentBillingCodesAmendment
          key={a.billing_code_amendment!.id}
          amendmentId={a.id}
          billingCodeAmendmentId={a.billing_code_amendment!.id}
          disabled={
            isBillingCodeAmendmentInProgress && isUserApptOwner
              ? true
              : a.id !== latestAmendmentId
          }
        />
      ))}
    </VStack>
  );
};
