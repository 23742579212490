import { z } from 'zod';

import { AppointmentModel } from '../../models/appointment';
import { ChartType } from '../../models/chart';
import { PatientType } from '../../models/patient';
import {
  PatientEhrUrlArgs,
  VendorDataMapper,
  VendorSyncConfig,
} from '../vendor-data-mapper';

import { logo } from './logo';

export class BlvdVendorDataMapper implements VendorDataMapper {
  getAppointments(data: unknown): AppointmentModel[] {
    throw new Error('Not implemented');
  }

  getPatient(data: unknown): PatientType | null {
    throw new Error('Not implemented');
  }

  getPatientEhrUrl({ domain, externalPatientId }: PatientEhrUrlArgs): string {
    return `https://${domain}/clients/${externalPatientId}`;
  }

  getLogo = () => logo;

  getHomeUrl(domain: string): string {
    return `https://${domain}`;
  }

  getChart(
    data: unknown,
    additionalFields?: Record<string, unknown>,
  ): ChartType {
    throw new Error('Not implemented');
  }

  getSyncConfig(): VendorSyncConfig {
    return {
      isSyncEnabled: false,
      canSignNoteInEhr: false,
      canSyncWithManualChartUrl: false,
      canSyncNoteToEhr: () => ({ canSync: false }),
    };
  }

  getVendorSchema = () =>
    z.object({
      'API Key': z.string(),
      'Key Secret': z.string(),
      'Business ID': z.string(),
    });
}
