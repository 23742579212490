import { useEffect, useState } from 'react';
import { useMedia } from 'react-use';

import { InputLabel, MicrophoneLevel, VStack } from '@eluve/components';

import { MicrophoneDeviceSelector } from './MicrophoneDeviceSelector';

type AppointmentUserMicSettingsProps = {
  isCompact?: boolean;
  selectedMicId?: string | null;
  setSelectedMicId: (id: string) => void;
  setHasPreviouslySelectedMicId?: (hasPreviouslySelectedMicId: boolean) => void;
};

export const AppointmentUserMicSettings: React.FC<
  AppointmentUserMicSettingsProps
> = ({
  isCompact = false,
  selectedMicId,
  setSelectedMicId,
  setHasPreviouslySelectedMicId,
}: AppointmentUserMicSettingsProps) => {
  const isSmallScreen = !useMedia('(min-width: 32rem)');
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

  useEffect(() => {
    window.eluveMediaStreams.stop('microphoneTester');

    if (!selectedMicId) {
      setMediaStream(null);
      return;
    }

    navigator.mediaDevices
      .getUserMedia({
        audio: {
          deviceId: selectedMicId,
        },
      })
      .then((stream) => {
        setMediaStream(stream);
        window.eluveMediaStreams.set('microphoneTester', stream);
      });

    return () => {
      window.eluveMediaStreams.stop('microphoneTester');
    };
  }, [selectedMicId, setSelectedMicId]);

  return (
    <VStack className={isCompact ? 'flex-row-reverse items-end gap-5' : ''}>
      <MicrophoneLevel
        stream={mediaStream}
        key={selectedMicId}
        className={isCompact ? 'w-fit' : 'mb-3 w-full'}
        variant={isCompact || isSmallScreen ? 'compact' : 'default'}
      />
      <InputLabel label="Audio Source">
        <MicrophoneDeviceSelector
          onDeviceValueChange={setSelectedMicId}
          selectedMicId={selectedMicId}
          setHasPreviouslySelectedMicId={setHasPreviouslySelectedMicId}
        />
      </InputLabel>
    </VStack>
  );
};
