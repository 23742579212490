import orderBy from 'lodash/orderBy';
import { ThumbsDown, ThumbsUp } from 'lucide-react';
import React, { useMemo, useState } from 'react';

import { useCompleteFragment } from '@eluve/apollo-client';
import { TooltipLabel } from '@eluve/blocks';
import {
  ColDefBuilder,
  DataTable,
  HStack,
  Label,
  P,
  SortableColumnHeader,
  Switch,
} from '@eluve/components';

import { SetDefaultPromptTemplateModelAction } from './SetDefaultPromptTemplateModelAction';
import { TogglePromptTemplateModelAction } from './TogglePromptTemplateModelAction';
import { promptTemplateModelsFragment } from './prompt.operations';

type PromptModelRow = {
  promptTemplateId: string;
  modelArgsId: string;
  id: string;
  name: string;
  modelName: string;
  modelArgs: Record<any, any>;
  isDefault: boolean;
  isActive: boolean;
  totalFeedbackCount: number;
  positiveFeedbackCount: number;
  negativeFeedbackCount: number;
  averageRating: number | null;
};

const createColumns = (disableView?: boolean) => {
  const builder = new ColDefBuilder<PromptModelRow>();

  if (!disableView) {
    builder.detailsLink((row) => row.id);
  }

  return builder
    .defaultSortable('modelName', 'Type')
    .defaultSortable('name', 'Model Name')
    .colDef({
      accessorKey: 'modelArgs',
      header: 'Model Args',
      cell: ({ row }) => <pre>{JSON.stringify(row.original.modelArgs)}</pre>,
    })
    .defaultBoolean('isDefault', 'Default')
    .defaultSortable('totalFeedbackCount', 'Feedback')
    .colDef({
      accessorKey: 'positiveFeedbackCount',
      header: ({ column }) => (
        <SortableColumnHeader
          column={column}
          label={
            <TooltipLabel label="Positive Feedback">
              <ThumbsUp className="size-5" />
            </TooltipLabel>
          }
        />
      ),
      cell: ({ row }) => <P>{row.original.positiveFeedbackCount}</P>,
    })
    .colDef({
      accessorKey: 'negativeFeedbackCount',
      header: ({ column }) => (
        <SortableColumnHeader
          column={column}
          label={
            <TooltipLabel label="Negative Feedback">
              <ThumbsDown className="size-5" />
            </TooltipLabel>
          }
        />
      ),
      cell: ({ row }) => <P>{row.original.negativeFeedbackCount}</P>,
    })
    .colDef({
      accessorKey: 'averageRating',
      header: ({ column }) => (
        <SortableColumnHeader
          column={column}
          label={
            <TooltipLabel label="Average Rating">
              <span>Rating</span>
            </TooltipLabel>
          }
        />
      ),
      cell: ({ row }) => <P>{row.original.averageRating?.toFixed(2)}</P>,
    })
    .colDef({
      header: 'Actions',
      cell: ({
        row: {
          original: { modelArgsId, isDefault, isActive, promptTemplateId, id },
        },
      }) => (
        <div className="flex gap-2">
          {isActive ? (
            <SetDefaultPromptTemplateModelAction
              promptTemplateId={promptTemplateId}
              modelArgsId={modelArgsId}
              isDefault={isDefault}
            />
          ) : null}
          <TogglePromptTemplateModelAction
            promptTemplateId={promptTemplateId}
            modelArgsId={modelArgsId}
            isDefault={isDefault}
            isActive={isActive}
            id={id}
          />
        </div>
      ),
    })
    .build();
};

export const PromptTemplateModelArgsList: React.FC<{
  promptTemplateId: string;
  disableView?: boolean;
}> = ({ promptTemplateId, disableView }) => {
  const [showInactive, setShowInactive] = useState(false);

  const data = useCompleteFragment({
    fragment: promptTemplateModelsFragment,
    key: {
      id: promptTemplateId,
    },
  });

  const rows = useMemo(() => {
    return orderBy(
      (data.prompt_models ?? [])
        .filter((pm) => pm.isActive !== showInactive)
        .map((pm) => {
          const { feedback_aggregates: agg } = pm ?? {};

          return {
            promptTemplateId,
            id: pm.id,
            name: pm.model_args.name ?? '',
            modelName: pm.model_args.modelType,
            modelArgs: pm.model_args.args ?? {},
            modelArgsId: pm.modelArgsId,
            isDefault: pm.isDefault,
            isActive: pm.isActive,
            totalFeedbackCount: agg?.totalFeedbackCount ?? 0,
            negativeFeedbackCount: agg?.negativeFeedbackCount ?? 0,
            positiveFeedbackCount: agg?.positiveFeedbackCount ?? 0,
            averageRating: agg?.averageRating ?? null,
          };
        }),
      (pm) => pm.isDefault,
      ['desc'],
    );
  }, [data, promptTemplateId, showInactive]);

  const columns = useMemo(() => createColumns(disableView), [disableView]);

  return (
    <>
      <HStack>
        <Switch checked={showInactive} onCheckedChange={setShowInactive} />
        <Label>Show Inactive</Label>
      </HStack>
      <div className="overflow-x-auto rounded-md">
        <DataTable columns={columns} data={rows} />
      </div>
    </>
  );
};
