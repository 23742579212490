import { useCompleteFragment } from '@eluve/apollo-client';
import { useUserIdFromSession } from '@eluve/session-helpers';

import { useAppointmentId } from './appointment.context';
import { appointmentReadonlyDataFragment } from './operations';

export const useIsUserAppointmentOwner = () => {
  const userId = useUserIdFromSession();
  const appointmentId = useAppointmentId();

  const { userId: appointmentUserId } = useCompleteFragment({
    fragment: appointmentReadonlyDataFragment,
    key: {
      id: appointmentId,
    },
  });

  return userId === appointmentUserId;
};
