import { useApolloClient } from '@apollo/client';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { PrivacyToggleProvider } from '@eluve/blocks';
import {
  Layout,
  Main,
  Sidebar,
  SidebarContent,
  SidebarContentSpacer,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarLogoHeader,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuLinkButton,
  SidebarMenuNavButton,
} from '@eluve/components';
import { useNamedLogger } from '@eluve/logger';
import {
  useAssignedTenantIdFromParams,
  useUserIdFromSession,
} from '@eluve/session-helpers';
import {
  EluveAdminOnly,
  FeatureFlaggedComponent,
  useTenantName,
} from '@eluve/smart-blocks';

import { TenantUserSettings } from '../../TenantUserSettings';
import { UserDropdownMenu } from '../components/UserDropdownMenu';

import { TranscriptionWidget } from './appointments/machines/transcription/components/TranscriptionWidget';

export const TenantLayout = () => {
  const client = useApolloClient();
  const logger = useNamedLogger('TenantLayout');
  const navigate = useNavigate();
  const postHog = usePostHog();
  const tenantId = useAssignedTenantIdFromParams();
  const tenantName = useTenantName(tenantId);
  const userId = useUserIdFromSession();

  const localStorageKey = `last-tenant-id-for-user-${userId}`;

  const lastTenantId = useMemo(
    () => localStorage.getItem(localStorageKey),
    [localStorageKey],
  );

  useEffect(
    function trackLastAccessedTenantId() {
      if (tenantId !== lastTenantId) {
        localStorage.setItem(localStorageKey, tenantId);
      }
    },
    [tenantId, lastTenantId, client, logger, localStorageKey],
  );

  useEffect(() => {
    postHog.group('tenant', tenantId, {
      name: tenantName,
    });
  }, [postHog, tenantId, tenantName]);

  const handleLogoClick = () => {
    navigate(`/tenants/${tenantId}/home`);
  };

  return (
    <Layout>
      <Sidebar>
        <SidebarHeader>
          <SidebarLogoHeader onClick={handleLogoClick} />
        </SidebarHeader>
        <SidebarContent>
          <SidebarGroup>
            <SidebarGroupContent>
              <SidebarMenu>
                <SidebarMenuItem>
                  <SidebarMenuNavButton
                    icon={{ name: 'Calendar' }}
                    text="Overview"
                    to={`/tenants/${tenantId}/home`}
                  />
                </SidebarMenuItem>
                <FeatureFlaggedComponent flag="APPOINTMENTS_VIEW">
                  <SidebarMenuItem>
                    <SidebarMenuNavButton
                      icon={{ name: 'FileEdit' }}
                      text="Appointments"
                      to={`/tenants/${tenantId}/appointments`}
                    />
                  </SidebarMenuItem>
                </FeatureFlaggedComponent>
                <FeatureFlaggedComponent flag="PATIENTS_VIEW">
                  <SidebarMenuItem>
                    <SidebarMenuNavButton
                      icon={{ name: 'User' }}
                      text="Patients"
                      to={`/tenants/${tenantId}/patients`}
                    />
                  </SidebarMenuItem>
                </FeatureFlaggedComponent>
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
          <SidebarContentSpacer />
          <SidebarGroup>
            <SidebarGroupContent>
              <SidebarMenu compact>
                <EluveAdminOnly>
                  <SidebarMenuItem>
                    <SidebarMenuNavButton
                      icon={{ name: 'LayoutTemplate' }}
                      text="Templates"
                      to={`/tenants/${tenantId}/output-templates`}
                      variant="compact"
                    />
                  </SidebarMenuItem>
                </EluveAdminOnly>
                <SidebarMenuItem>
                  <SidebarMenuNavButton
                    icon={{ name: 'Settings' }}
                    text="Settings"
                    to={`/tenants/${tenantId}/settings`}
                    variant="compact"
                  />
                </SidebarMenuItem>
                <SidebarMenuItem>
                  <SidebarMenuLinkButton
                    icon={{ name: 'CircleHelp' }}
                    text="Support"
                    href="https://eluveinc.notion.site/Eluve-Support-e7de10ae63384ede8e48a062467bee81"
                    variant="compact"
                  />
                </SidebarMenuItem>
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        </SidebarContent>
        <SidebarFooter>
          <UserDropdownMenu />
        </SidebarFooter>
      </Sidebar>
      <Main>
        <PrivacyToggleProvider>
          <TenantUserSettings>
            <Outlet />
          </TenantUserSettings>
        </PrivacyToggleProvider>
      </Main>
      <TranscriptionWidget />
    </Layout>
  );
};
