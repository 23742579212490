import { useMutation } from '@apollo/client';

import { useApiClient } from '@eluve/api-client-provider';
import { cacheUtils } from '@eluve/apollo-client';
import { MedicalCode } from '@eluve/blocks';
import {
  H4,
  HStack,
  Icon,
  NewButton,
  VStack,
  textStyles,
  toast,
} from '@eluve/components';
import {
  amendmentFragment,
  useAmendmentActivity,
  useAppointmentActivity,
  useAppointmentMode,
  useIsAppointmentReadonly,
  useIsUserAppointmentOwner,
  useSummary,
} from '@eluve/frontend-appointment-hooks';
import { DbEnums, WorkerStatusTypesLookup } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';
import { FeatureFlaggedComponent } from '@eluve/smart-blocks';

import { AppointmentBillingCodePrices } from './AppointmentBillingCodePrices';
import { AppointmentBillingCodesAmendmentHistory } from './AppointmentBillingCodesAmendmentHistory';
import { AppointmentBillingCodesEditorContainer } from './AppointmentBillingCodesEditorContainer';
import {
  BillingCodeOption,
  SearchableBillingCodes,
} from './SearchableBillingCodes';
import { useBillingCodeRecommendations } from './useBillingCodeRecommendations';
import { useEditBillingCodes } from './useEditBillingCodes';
import { useSearchCodesForBilling } from './useSearchBillingCodes';

export interface AppointmentBillingCodesProps {
  tenantId: string;
  appointmentId: string;
  isReadonly?: boolean;
  locationId: string;
}

const insertBillingCodeAmendmentMutation = graphql(`
  mutation addBillingCodeAmendment($amendmentId: uuid!, $appointmentId: uuid!) {
    insertBillingCodeAmendmentsOne(
      object: { amendmentId: $amendmentId, appointmentId: $appointmentId }
    ) {
      __typename
      id
      tenantId
      appointmentId
      amendmentId
      appointmentId
      createdAt
    }
  }
`);

export const AppointmentBillingCodes: React.FC<
  AppointmentBillingCodesProps
> = ({ tenantId, appointmentId }) => {
  const { appointmentLlmJobs } = useAppointmentActivity();
  const appointmentMode = useAppointmentMode();
  const { isEmpty: isSummaryEmpty } = useSummary();
  const apiClient = useApiClient();

  const isReadonly = useIsAppointmentReadonly();
  const isUserApptOwner = useIsUserAppointmentOwner();

  const {
    billingCodes,
    recommendations,
    onRemoveBillingCode,
    onAddBillingCode,
    refetchBillingCodes,
  } = useEditBillingCodes({
    tenantId,
    appointmentId,
  });

  const searchCodesForBilling = useSearchCodesForBilling({ tenantId });

  const { onAcceptRecommendation } = useBillingCodeRecommendations({
    appointmentId,
  });

  const billingCodesJobStatus = appointmentLlmJobs.find(
    (job) => job.llmOutputType === 'BILLING_CODE_RECOMMENDATION',
  )?.jobStatus;

  const {
    isAmendmentInProgress,
    activeAmendmentId,
    isBillingCodeAmendmentInProgress,
    hasBillingCodeAmendments,
  } = useAmendmentActivity();

  const billingCodesDisabled = isReadonly && !isBillingCodeAmendmentInProgress;

  const [insertBillingCodeAmendment] = useMutation(
    insertBillingCodeAmendmentMutation,
  );

  const isManualChartingMode =
    appointmentMode === DbEnums.AppointmentMode.MANUAL_CHARTING;

  const generateBillingCodes = async () => {
    try {
      const result = await apiClient.appointments.generateBillingCodes({
        params: { tenantId, appointmentId },
        body: {},
      });

      if (result.status !== 200) {
        toast.error(
          'Error generating billing code recommendations. Please try again later.',
        );
        return;
      }
    } catch (e) {
      toast.error(
        'Error generating billing code recommendations. Please try again later.',
      );
    }
  };

  const onAddBillingCodeAmendment = async () => {
    if (!activeAmendmentId) {
      return;
    }

    await insertBillingCodeAmendment({
      variables: {
        amendmentId: activeAmendmentId!,
        appointmentId,
      },
      optimisticResponse: {
        insertBillingCodeAmendmentsOne: {
          __typename: 'BillingCodeAmendments',
          id: activeAmendmentId,
          amendmentId: activeAmendmentId,
          appointmentId,
          createdAt: new Date().toISOString(),
          tenantId,
        },
      },
      update: (_, { data }) => {
        cacheUtils.updateFragment(
          {
            fragment: amendmentFragment,
            key: { id: activeAmendmentId },
          },
          (existing) => {
            if (!existing || !data?.insertBillingCodeAmendmentsOne) {
              return null;
            }

            return {
              ...existing,
              billing_code_amendment: {
                __typename: 'BillingCodeAmendments' as const,
                id: data.insertBillingCodeAmendmentsOne.id,
                tenantId,
                amendmentId: activeAmendmentId,
                createdAt: new Date().toISOString(),
                billing_codes: [],
              },
            };
          },
        );
      },
      onError: () => {
        toast.error('Failed to add billing code amendment');
      },
    });
  };

  const codeRecommendationsToShow =
    recommendations.filter(
      (c) =>
        !c.isFinal &&
        (Boolean(c.medical_code) || Boolean(c.tenant_medical_code)),
    ) ?? [];

  const shouldShowGenerateButton = Boolean(
    isManualChartingMode &&
      !isReadonly &&
      codeRecommendationsToShow.length === 0,
  );

  const isInEditingAmendmentMode =
    isBillingCodeAmendmentInProgress && isUserApptOwner;

  const isInEditingCodesMode = !isReadonly || isInEditingAmendmentMode;

  const shouldShowBillingCodeRecommendations =
    (Boolean(codeRecommendationsToShow.length) || isManualChartingMode) &&
    !isReadonly;

  const canAddAmendment =
    isAmendmentInProgress &&
    !isBillingCodeAmendmentInProgress &&
    isUserApptOwner;

  return (
    <VStack gap={6} className={isInEditingAmendmentMode ? 'mb-80' : ''}>
      <HStack gap={3} justify="between">
        <H4>Billing Codes</H4>
        {canAddAmendment && (
          <NewButton
            text="Add Amendments"
            type="outlineSubtle"
            size="s"
            onClick={onAddBillingCodeAmendment}
          />
        )}
      </HStack>

      <AppointmentBillingCodesAmendmentHistory
        tenantId={tenantId}
        appointmentId={appointmentId}
      />

      {!isInEditingCodesMode && !hasBillingCodeAmendments && (
        <HStack gap={3} wrap>
          {billingCodes.map((code) => (
            <MedicalCode
              code={
                (code.tenant_medical_code?.code ?? code.medical_code?.code)!
              }
              codeType={
                (code.tenant_medical_code?.codeType ??
                  code.medical_code?.codeType)!
              }
              description={
                (code.tenant_medical_code?.description ??
                  code.medical_code?.description)!
              }
              key={code.id}
            />
          ))}

          {billingCodes.length === 0 && (
            <div className="text-brandGray400">No billing codes.</div>
          )}
        </HStack>
      )}

      {isInEditingCodesMode && (
        <AppointmentBillingCodesEditorContainer
          refetchBillingCodes={refetchBillingCodes}
        >
          <SearchableBillingCodes
            searchCodes={searchCodesForBilling}
            disabled={billingCodesDisabled}
            onCodeAdded={(code) => {
              if (!billingCodesDisabled) {
                onAddBillingCode({
                  medicalCode:
                    code.source === 'medical_codes' ? code : undefined,
                  tenantMedicalCode:
                    code.source === 'tenant_medical_codes' ? code : undefined,
                });
              }
            }}
            onCodeRemoved={(code) => {
              if (!billingCodesDisabled) {
                onRemoveBillingCode({
                  medicalCode:
                    code.source === 'medical_codes' ? code : undefined,
                  tenantMedicalCode:
                    code.source === 'tenant_medical_codes' ? code : undefined,
                });
              }
            }}
            selectedCodes={
              billingCodes
                .map((c) => {
                  const code = c.tenant_medical_code ?? c.medical_code;
                  return {
                    ...code,
                    source: c.tenant_medical_code
                      ? 'tenant_medical_codes'
                      : 'medical_codes',
                  };
                })
                .filter(Boolean) as BillingCodeOption[]
            }
          />
        </AppointmentBillingCodesEditorContainer>
      )}

      {shouldShowBillingCodeRecommendations && (
        <VStack gap={3}>
          <h6
            className={textStyles.body({
              weight: 'semibold',
              size: 's',
              className: 'uppercase text-brandGray600',
            })}
          >
            Recommended Codes
          </h6>

          {shouldShowGenerateButton && (
            <NewButton
              size="s"
              spacing="compact"
              text="Generate Recommended Codes"
              type="outline"
              onClick={generateBillingCodes}
              disabled={
                isSummaryEmpty ||
                billingCodesJobStatus === WorkerStatusTypesLookup.IN_PROGRESS
              }
            />
          )}

          {billingCodesJobStatus === WorkerStatusTypesLookup.IN_PROGRESS && (
            <div className="flex items-center text-brandGray600">
              <Icon
                name="Loader2Icon"
                className="mr-2 animate-spin"
                size="xs"
              />
              <span className="text-sm">Generating recommendations...</span>
            </div>
          )}

          {codeRecommendationsToShow.length > 0 && (
            <HStack gap={3} wrap>
              {codeRecommendationsToShow.map(
                ({ medical_code, tenant_medical_code }) => {
                  const {
                    id: codeId,
                    code,
                    codeType,
                    description,
                  } = (tenant_medical_code ?? medical_code)!;
                  return (
                    <MedicalCode
                      onClick={() => {
                        if (!billingCodesDisabled) {
                          onAcceptRecommendation({
                            tenantMedicalCode: tenant_medical_code ?? undefined,
                            medicalCode: medical_code ?? undefined,
                          });
                        }
                      }}
                      startAdornment={
                        <div className="pl-1">
                          <Icon
                            size="xs"
                            name="Plus"
                            className="text-brandGray600"
                          />
                        </div>
                      }
                      key={codeId}
                      code={code}
                      codeType={codeType}
                      description={description ?? ''}
                      type="outlineDashed"
                    />
                  );
                },
              )}
            </HStack>
          )}
        </VStack>
      )}

      <FeatureFlaggedComponent flag="BILLING_CODE_PRICES">
        {!isAmendmentInProgress && (
          <AppointmentBillingCodePrices
            appointmentId={appointmentId}
            tenantId={tenantId}
          />
        )}
      </FeatureFlaggedComponent>
    </VStack>
  );
};
