import React from 'react';
import { Outlet } from 'react-router-dom';

import { Content, H1, HStack, Header, SidebarTrigger } from '@eluve/components';
import { TabNav } from '@eluve/smart-blocks';

export const TenantSettingsMainLayout: React.FC = () => {
  return (
    <>
      <Header>
        <SidebarTrigger />
        <HStack>
          <H1 p="p-2">Tenant Settings</H1>
        </HStack>
        {/* <AdminLocationsBreadcrumbs /> */}
      </Header>
      <Content padded size="xl">
        <TabNav
          tabs={[
            {
              link: 'session-types',
              name: 'Session Types',
              featureFlag: 'SESSION_TYPES',
            },
            {
              link: 'layers',
              name: 'Layers',
            },
            {
              link: 'locations',
              name: 'Locations',
            },
            {
              link: 'user-groups',
              name: 'User Groups',
              featureFlag: 'USER_GROUPS',
            },
          ]}
        />
        <Outlet />
      </Content>
    </>
  );
};
