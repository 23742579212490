import { z } from 'zod';

import {
  humanReadableDateAndTimeWithDashes,
  splitFullName,
} from '@eluve/utils';

import { AppointmentModel } from '../../models/appointment';
import { ChartType } from '../../models/chart';
import { PatientType } from '../../models/patient';
import { UserType } from '../../models/user';
import {
  PatientEhrUrlArgs,
  VendorDataMapper,
  VendorSyncConfig,
} from '../vendor-data-mapper';

import { logo } from './logo';
import { chartSchema } from './types';

export const appointmentSchema = z.object({
  id: z.string(),
  name: z.string(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  patient: z.object({
    id: z.string(),
    fullName: z.string(),
    birthDate: z.string().optional(),
    gender: z.string().optional(),
  }),
});
const appointmentsSchema = z.array(appointmentSchema);

const userSchema = z.object({
  id: z.string(),
  fullName: z.string(),
});

export class SiloamVendorDataMapper implements VendorDataMapper {
  getAppointments(data: unknown): AppointmentModel[] {
    const appointments = appointmentsSchema.parse(data);
    return appointments.map((appointment) => {
      const fullName = splitFullName(appointment.patient.fullName);
      return {
        externalId: appointment.id,
        name:
          appointment.name ??
          `New Session ${humanReadableDateAndTimeWithDashes()}`,
        description: '',
        externalPatientId: appointment.patient.id,
        firstName: fullName.firstName,
        lastName: fullName.lastName,
        startTime: appointment.startDate ?? new Date().toISOString(),
        endTime: appointment.endDate ?? '',
        rawEhrData: {},
        isArchived: false,
        isImportable: true,
        status: undefined,
        sessionType: undefined,
      };
    });
  }

  getUser(data: unknown): UserType {
    const user = userSchema.parse(data);
    const email = `${user.id}-eluve@siloamhospitals.com`;
    const fullName = splitFullName(user.fullName);
    return {
      id: user.id,
      email,
      ...fullName,
    };
  }

  getPatient(data: unknown): PatientType | null {
    const { patient } = appointmentSchema.parse(data);
    const fullName = splitFullName(patient.fullName);
    return {
      externalPatientId: patient.id,
      firstName: fullName.firstName,
      lastName: fullName.lastName,
      dateOfBirth: patient.birthDate ?? null,
      email: null,
      homePhone: null,
      cellPhone: null,
      workPhone: null,
      gender: patient.gender ?? null,
      rawData: patient as Record<string, unknown>,
    };
  }

  getPatientEhrUrl(data: PatientEhrUrlArgs): string {
    return '';
  }

  getLogo(): string {
    return logo;
  }

  getHomeUrl(domain: string): string {
    return 'https://www.siloamhospitals.com';
  }

  getChart(
    data: unknown,
    additionalFields?: Record<string, unknown>,
  ): ChartType {
    const parsed = chartSchema.parse(data);
    return {
      externalPatientId: parsed.PatientId,
      externalChartId: parsed.EncounterId,
      externalAppointmentId: parsed.EncounterId,
      signedAt: null,
      rawData: data as Record<string, unknown>,
    };
  }

  getSyncConfig(): VendorSyncConfig {
    return {
      isSyncEnabled: true,
      canSignNoteInEhr: false,
      canSyncWithManualChartUrl: false,
      canSyncNoteToEhr: ({ externalAppointmentId }) => {
        if (!externalAppointmentId) {
          return {
            canSync: false,
            reason: 'Selected appointment was not imported from EHR',
          };
        }
        return { canSync: true };
      },
    };
  }

  getVendorSchema = () =>
    z.object({
      'Auth Token': z.string(),
      'SOAP URL': z
        .string()
        .url()
        .describe('Additional full URL where SOAP note will be synced to'),
    });
}
