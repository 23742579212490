import React from 'react';

import { Content, Header, PageTitle, SidebarTrigger } from '@eluve/components';

import { OutputTemplateDetailsPage } from '../../../output-templates/OutputTemplateDetailsPage';

export const TenantOutputTemplateDetailsPage: React.FC = () => {
  return (
    <>
      <Header>
        <SidebarTrigger />
        <PageTitle>Template Details</PageTitle>
      </Header>
      <Content padded size="xl">
        <OutputTemplateDetailsPage showJsonSchema={false} />
      </Content>
    </>
  );
};
