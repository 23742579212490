import { useEffect, useState } from 'react';

import { Icon } from './Icon';
import { tv } from './cn';

const microphoneLevelVariant = tv(
  {
    slots: {
      base: 'flex items-center rounded-lg bg-brandGray100',
      iconWrapper: 'flex items-center justify-center text-brandGray900',
      levelBarsWrapper: 'flex gap-2',
      levelBar: 'h-[18px] w-2 rounded-full',
    },
    variants: {
      barStatus: {
        active: {
          levelBar: 'bg-brand-9',
        },
        inactive: {
          levelBar: 'bg-brandGray300',
        },
      },
      variant: {
        default: {
          base: 'w-max flex-col gap-12 p-8 pt-10',
          iconWrapper: 'rounded-full bg-brandGray900 p-4 text-brandGray100',
        },
        compact: {
          base: 'w-full justify-between gap-3 p-3',
          levelBar: 'h-[16px] w-1.5 rounded-full',
        },
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
  {
    responsiveVariants: true,
  },
);

interface MicrophoneLevelProps {
  stream?: MediaStream | null;
  variant?: 'default' | 'compact';
  className?: string;
}

const LEVEL_BAR_COUNT = {
  compact: 10,
  default: 20,
};

export const MicrophoneLevel: React.FC<MicrophoneLevelProps> = ({
  stream,
  variant = 'default',
  className,
}) => {
  const { base, levelBarsWrapper, levelBar, iconWrapper } =
    microphoneLevelVariant({ variant });

  const [barCount, setBarCount] = useState(0);

  useEffect(() => {
    if (!stream) return;

    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    analyser.smoothingTimeConstant = 0.3;
    analyser.fftSize = 1024;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const source = audioContext.createMediaStreamSource(stream);
    source.connect(analyser);

    const draw = () => {
      analyser.getByteFrequencyData(dataArray);
      const volumeLevel = Math.round(
        ((dataArray.reduce((acc, val) => acc + val, 0) / bufferLength) *
          LEVEL_BAR_COUNT[variant]) /
          64,
      );
      setBarCount(volumeLevel);
      requestAnimationFrame(draw);
    };

    draw();

    return () => {
      audioContext.close();
      source.mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
    };
  }, [stream]);

  return (
    <div className={base({ className })}>
      <div className={iconWrapper()}>
        <Icon name="Mic" size={variant === 'compact' ? 'xs' : 'md'} />
      </div>
      <div className={levelBarsWrapper()}>
        {Array(LEVEL_BAR_COUNT[variant])
          .fill(0)
          .map((_, i) => (
            <div
              key={i}
              className={levelBar({
                barStatus: i + 1 < barCount ? 'active' : 'inactive',
              })}
            />
          ))}
      </div>
    </div>
  );
};
