import { useMutation } from '@apollo/client';

import { useCompleteFragment } from '@eluve/apollo-client';
import { toast } from '@eluve/components';
import { useAppointmentContext } from '@eluve/frontend-appointment-hooks';
import { appointmentLlmOutputTemplateFragment } from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';

const AppointmentTemplateFragment = graphql(`
  fragment AppointmentTemplate on Appointments {
    __typename
    id
    initialPromptTemplateId
    initialLlmOutputTemplateId
  }
`);

const updateAppointmentTemplateMutation = graphql(
  `
    mutation UpdateAppointmentTemplateMutation(
      $tenantId: uuid!
      $id: uuid!
      $initialPromptTemplateId: uuid!
      $initialLlmOutputTemplateId: uuid
    ) {
      updateAppointmentsByPk(
        pkColumns: { tenantId: $tenantId, id: $id }
        _set: {
          initialPromptTemplateId: $initialPromptTemplateId
          initialLlmOutputTemplateId: $initialLlmOutputTemplateId
        }
      ) {
        __typename
        id
        initialPromptTemplateId
        initialLlmOutputTemplateId
        ...llmOutputTemplate
      }
    }
  `,
  [appointmentLlmOutputTemplateFragment],
);

const updateAppointmentOutputTemplateMutation = graphql(
  `
    mutation UpdateAppointmentTemplateMutation(
      $tenantId: uuid!
      $id: uuid!
      $initialLlmOutputTemplateId: uuid!
    ) {
      updateAppointmentsByPk(
        pkColumns: { tenantId: $tenantId, id: $id }
        _set: { initialLlmOutputTemplateId: $initialLlmOutputTemplateId }
      ) {
        __typename
        id
        initialLlmOutputTemplateId
        ...llmOutputTemplate
      }
    }
  `,
  [appointmentLlmOutputTemplateFragment],
);

export const useAppointmentTemplates = () => {
  const { appointmentId } = useAppointmentContext();

  const data = useCompleteFragment({
    fragment: AppointmentTemplateFragment,
    key: {
      id: appointmentId,
    },
  });

  const [updateAppointmentTemplates] = useMutation(
    updateAppointmentTemplateMutation,
    {
      optimisticResponse: ({
        initialLlmOutputTemplateId,
        initialPromptTemplateId,
        id,
      }) => ({
        __typename: 'mutation_root',
        updateAppointmentsByPk: {
          __typename: 'Appointments' as const,
          id,
          initialPromptTemplateId: initialPromptTemplateId,
          initialLlmOutputTemplateId: initialLlmOutputTemplateId ?? null,
          llm_output_template: null,
        },
      }),
      onError: () => {
        toast.error('Unable to update appointment template');
      },
    },
  );

  const [updateAppointmentOutputTemplate] = useMutation(
    updateAppointmentOutputTemplateMutation,
    {
      optimisticResponse: ({ initialLlmOutputTemplateId, id }) => ({
        __typename: 'mutation_root',
        updateAppointmentsByPk: {
          __typename: 'Appointments' as const,
          id,
          initialLlmOutputTemplateId: initialLlmOutputTemplateId,
          llm_output_template: null,
        },
      }),
      onError: () => {
        toast.error('Unable to update appointment output template');
      },
    },
  );

  return {
    initialPromptTemplateId: data?.initialPromptTemplateId,
    initialLlmOutputTemplateId: data?.initialLlmOutputTemplateId,
    updateAppointmentTemplates,
    updateAppointmentOutputTemplate,
  };
};
