import { useSuspenseQuery } from '@apollo/client';
import sumBy from 'lodash/sumBy';
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import { graphql } from '@eluve/graphql.tada';

import { DataSetItem } from './ArtifactSearch';
import { TargetConfig } from './FactVerificationTargetConfigsForm';

// Define a minimal interface for the context
interface WorkflowContextType {
  workflowId: string | null;
  sourceArtifactIds: string[];
  targetConfigs: TargetConfig[];
  dataSets: DataSetItem[];
}

// Create the context with default values
const WorkflowContext = createContext<WorkflowContextType>({
  workflowId: null,
  sourceArtifactIds: [],
  targetConfigs: [],
  dataSets: [],
});

// Custom hook for using the context
export const useWorkflow = () => useContext(WorkflowContext);

interface WorkflowProviderProps {
  children: ReactNode;
}
const getFactVerificationWorkflow = graphql(`
  query getFactVerificationWorkflow($id: uuid!) {
    evalFactVerificationWorkflowsByPk(id: $id) {
      __typename
      id
      inputs {
        __typename
        id
        sourceArtifactId
        artifact {
          __typename
          id
          datasetSourceArtifacts {
            __typename
            datasetId
          }
        }
      }
      workflow_model_prompt_templates {
        __typename
        id
        modelPromptTemplateId
        model_prompt_template {
          __typename
          id
          model_args {
            __typename
            id
          }
          template {
            __typename
            id
          }
        }
        outputTemplateId
      }
    }
    evalDatasets {
      __typename
      id
      name
      description
      datasetSourceArtifacts {
        __typename
        id
        sourceArtifact {
          __typename
          id
          name
          factsAggregate {
            __typename
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`);

export type WorkflowState = {
  workflowId: string;
  sourceArtifactIds: string[];
  targetConfigs: TargetConfig[];
  dataSets: DataSetItem[];
};

export const DuplicateWorkflowContextProvider: React.FC<
  WorkflowProviderProps
> = ({ children }) => {
  const { workflowId } = useParams() as { workflowId: string };

  // Create a single state for WorkflowState
  const [workflowState, setWorkflowState] = useState<WorkflowState>({
    workflowId: workflowId ?? '',
    sourceArtifactIds: [],
    targetConfigs: [],
    dataSets: [],
  });

  // Fetch workflow data if workflowId exists
  const { data: workflowElements } = useSuspenseQuery(
    getFactVerificationWorkflow,
    {
      variables: {
        id: workflowId ?? '',
      },
      skip: !workflowId,
    },
  );

  // Extract the IDs we need when data changes
  useEffect(() => {
    if (!workflowId || !workflowElements?.evalFactVerificationWorkflowsByPk) {
      return;
    }

    const workflow = workflowElements.evalFactVerificationWorkflowsByPk;

    // Extract source artifact IDs from the inputs (if it exists)
    const artifactIds = workflow.inputs.map((input) => input.sourceArtifactId);

    // Extract target configs
    const extractedTargetConfigs = workflow.workflow_model_prompt_templates.map(
      (template) => ({
        modelPromptTemplateId: template.modelPromptTemplateId,
        outputTemplateId: template.outputTemplateId,
      }),
    );

    // Extract selected data sets
    const allDataSets = workflowElements.evalDatasets;
    const selectedDataSetIds = workflow.inputs
      .map((input) =>
        input.artifact.datasetSourceArtifacts.map(
          (dataset) => dataset.datasetId,
        ),
      )
      .flat();
    const selectedDataSets = allDataSets
      .filter((dataset) => selectedDataSetIds.includes(dataset.id))
      .map<DataSetItem>((d) => ({
        id: d.id,
        name: d.name,
        totalArtifacts: d.datasetSourceArtifacts.length,
        totalFacts: sumBy(
          d.datasetSourceArtifacts,
          (a) => a.sourceArtifact?.factsAggregate?.aggregate?.count ?? 0,
        ),
      }));

    setWorkflowState({
      workflowId,
      sourceArtifactIds: artifactIds ?? [],
      dataSets: selectedDataSets,
      targetConfigs: extractedTargetConfigs,
    });
  }, [workflowId, workflowElements]);

  // Update context value to use the single workflowState
  const value = React.useMemo<WorkflowContextType>(
    () => workflowState,
    [workflowState],
  );

  return (
    <WorkflowContext.Provider value={value}>
      {children}
    </WorkflowContext.Provider>
  );
};
