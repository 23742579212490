import { AppointmentStatusLabel, BackBreadcrumbItem } from '@eluve/blocks';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
  DetailsSidebarTrigger,
  HStack,
  Header,
  NewButton,
  SidebarTrigger,
} from '@eluve/components';
import {
  useAppointmentHeaderDetails,
  useAppointmentName,
} from '@eluve/frontend-appointment-hooks';

import { NewAppointmentButton } from '../../components/NewAppointmentButton';
import { OverviewBreadcrumbItem } from '../../components/OverviewBreadcrumbItem';

export const AppointmentHeader: React.FC = () => {
  const appointmentHeaderDetails = useAppointmentHeaderDetails();
  const appointmentName = useAppointmentName();

  return (
    <Header>
      <HStack>
        <SidebarTrigger />
        <Breadcrumb className="flex-1 overflow-hidden">
          <BreadcrumbList>
            <BackBreadcrumbItem />
            <OverviewBreadcrumbItem />
            <BreadcrumbSeparator className="hidden sm:block">
              /
            </BreadcrumbSeparator>
            <BreadcrumbItem className="hidden truncate text-gray-12 sm:block">
              {appointmentName}
            </BreadcrumbItem>
            <BreadcrumbItem className="hidden truncate pl-2 text-gray-12 sm:block">
              {appointmentHeaderDetails && (
                <AppointmentStatusLabel
                  isSigned={appointmentHeaderDetails.isSigned}
                  isSummarized={appointmentHeaderDetails.isSummarized}
                  status={appointmentHeaderDetails.status}
                  isAmending={appointmentHeaderDetails.isAmending}
                  isAmended={appointmentHeaderDetails.isAmended}
                  size="xs"
                />
              )}
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </HStack>
      <HStack justify="end">
        <NewAppointmentButton />
        <DetailsSidebarTrigger>
          <NewButton icon={{ name: 'User' }} type="outline" />
        </DetailsSidebarTrigger>
      </HStack>
    </Header>
  );
};
