import { z } from 'zod';

import { makeFeatureFlagPayloadValidator } from './utils';

/**
 * Transcription configuration settings shared between frontend and backend.
 *
 * This configuration allows the selection of transcription providers and optional chunking of audio files
 * into smaller parts. It is used for both batch and live transcription.
 *
 * Since this is a shared library with the frontend, we don't expose the actual provider names directly here.
 * Instead, the following abbreviations are used:
 *
 * - `d`: Deepgram
 * - `a`: Azure
 *
 * The optional `chunkingDurationMinutes` field is used specifically by Azure to define how long each chunk of
 * audio should be when processing batch transcriptions. For example, if an audio file is 1 hour long and
 * `chunkingDurationMinutes` is set to 5, the audio will be split into 12 chunks of 5 minutes each. Each chunk
 * is uploaded to Google Cloud Storage (GCS), transcribed by Azure Speech-to-Text (STT), and then combined into
 * a single transcript. If `chunkingDurationMinutes` is not set, no chunking is performed.
 */
export const transcriptionConfigurations = makeFeatureFlagPayloadValidator({
  defaultEnabled: false,
  schema: z.object({
    batchTranscriptionProvider: z.enum(['d', 'a', 'd3']),
    liveTranscriptionProvider: z.enum(['d', 'a', 'd3']),
    chunkingDurationMinutes: z.number().nullish(),
  }),
  defaultValue: {
    batchTranscriptionProvider: 'd',
    liveTranscriptionProvider: 'd',
    chunkingDurationMinutes: null,
  },
});
