import { useCompleteFragment } from '@eluve/apollo-client';
import { graphql } from '@eluve/graphql.tada';

import { useAppointmentId } from './appointment.context';
import { useAppointmentName } from './useAppointmentName';

export const appointmentHeaderDetailsFragment = graphql(`
  fragment AppointmentHeaderDetails on Appointments {
    id
    amendments {
      submittedAt
    }
    doctor_interaction {
      noteSignedAt
    }
    humanOutputs(
      where: { output: { outputType: { _eq: SOAP_NOTE } } }
      limit: 1
      orderBy: { output: { updatedAt: DESC } }
    ) {
      humanOutputId
      appointmentId
    }
    status
  }
`);

export const useAppointmentHeaderDetails = () => {
  const appointmentId = useAppointmentId();

  const data = useCompleteFragment({
    fragment: appointmentHeaderDetailsFragment,
    key: {
      id: appointmentId,
    },
    strict: false,
  });

  const name = useAppointmentName();

  if (!data) {
    return undefined;
  }

  const status = data.status ?? 'NOT_STARTED';
  const isSigned = Boolean(data.doctor_interaction?.noteSignedAt);
  const isSummarized = Boolean(data.humanOutputs?.length);
  const amendments = data.amendments ?? [];
  const isAmending =
    isSigned &&
    Boolean(amendments.length) &&
    amendments.some((a) => !a.submittedAt);
  const isAmended =
    isSigned &&
    Boolean(amendments.length) &&
    amendments.every((a) => a.submittedAt);

  return {
    name,
    status,
    isSigned,
    isSummarized,
    isAmending,
    isAmended,
  };
};
