import { View } from '@react-pdf/renderer';
// eslint-disable-next-line no-restricted-imports
import { tv } from 'tailwind-variants';

import { tw } from '../pdf-tw';

const styles = tv({
  slots: {
    divider: 'h-px w-full bg-brandGray300',
  },
})();

export const Divider: React.FC = () => <View style={tw(styles.divider())} />;
