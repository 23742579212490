import React from 'react';
import { match } from 'ts-pattern';

import { useCompleteFragment } from '@eluve/apollo-client';
import {
  appointmentExternalInfoFragment,
  useAppointmentActivity,
  useAppointmentId,
  useSummary,
} from '@eluve/frontend-appointment-hooks';
import { getVendorDataMapper } from '@eluve/vendor';

import { useUserLocations } from '../../hooks/useUserLocation';

import { SignNoteOnEluveButton } from './SignNoteOnEluveButton';
import { SyncToEhrButton } from './SyncToEhrButton';

export const SignAndSyncAppointmentButton: React.FC = () => {
  const { isSummaryAvailable } = useSummary();
  const { isSummarizationInProgress } = useAppointmentActivity();
  const appointmentId = useAppointmentId();

  const { isAnyLocationEhrEnabled: userHasEhrEnabled } = useUserLocations();

  const { external_appointments_infos: externalInfo } = useCompleteFragment({
    fragment: appointmentExternalInfoFragment,
    key: {
      id: appointmentId,
    },
  });

  let isSyncAllowed = userHasEhrEnabled;
  const externalEhr = externalInfo?.[0]?.ehr;
  if (externalEhr?.vendor) {
    const provider = getVendorDataMapper(externalEhr.vendor);
    const syncConfig = provider.getSyncConfig();
    isSyncAllowed = syncConfig.isSyncEnabled;
  }

  if (isSummarizationInProgress || !isSummaryAvailable) {
    return null;
  }

  return match(isSyncAllowed)
    .with(true, () => <SyncToEhrButton />)
    .otherwise(() => <SignNoteOnEluveButton />);
};
