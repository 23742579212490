import { blvdGraphql } from './blvdGraphql';

const getClientByExternalId = blvdGraphql(`
  query GetClientByExternalId($query: QueryString!) {
    clients(query: $query, first: 1) {
      edges {
        node {
          id
          firstName
          lastName
          email
          mobilePhone
          active
          pronoun
          createdAt
          updatedAt
          dob
          name
          externalId
        }
      }
    }
  }
`);

const getWebhooks = blvdGraphql(`
query getWebhooks {
  webhooks(first: 100) {
    edges {
      node {
        id
        name
        url
        subscriptions {
          id
          eventType
          enabled
        }
      }
    }
  }
}
`);

const updateClientMutation = blvdGraphql(`
  mutation UpdateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      client {
        id
        firstName
        lastName
        email
        mobilePhone
        active
        pronoun
        createdAt
        updatedAt
        dob
        name
        externalId
      }
    }
  }
`);

const getClientByExternalIdQuery = blvdGraphql(`
  query GetClientByExternalId($query: QueryString!) {
    clients(query: $query, first: 1) {
      edges {
        node {
          id
          firstName
          lastName
          email
          mobilePhone
          active
          pronoun
          createdAt
          updatedAt
          dob
          name
          externalId
        }
      }
    }
  }
`);

const createClientMutation = blvdGraphql(`
  mutation CreateClient($input: CreateClientInput!) {
    createClient(input: $input) {
      client {
        id
        firstName
        lastName
        email
        mobilePhone
        active
        pronoun
        createdAt
        updatedAt
        dob
        name
        externalId
      }
    }
  }
`);

const getAppointmentsQuery = blvdGraphql(`
  query ListAppointments(
    $locationId: ID!
    $first: Int
    $after: String
    $query: QueryString
  ) {
    appointments(
      locationId: $locationId
      first: $first
      after: $after
      query: $query
    ) {
      edges {
        node {
          id
          notes
          state
          startAt
          endAt
          duration
          createdAt
          cancelled
          rating {
            rating
            text
            id
          }
          orderId

          client {
            firstName
            lastName
            email
            mobilePhone
            active
            createdAt
            dob
            email
            id
            name
            updatedAt
          }

          appointmentServices {
            id
            startAt
            endAt
            price
            duration
            finishDuration
            service {
              name
              category {
                name
              }
            }

            staffRequested
            staff {
              firstName
              lastName
              email
              mobilePhone
              id

              role {
                name
              }
            }
          }
        }
      }

      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

const createStaffMutation = blvdGraphql(`
  mutation CreateStaff($input: CreateStaffInput!) {
    createStaff(input: $input) {
      staff {
        id
        displayName
        email
        mobilePhone
        role {
          name
        }
      }
      }
    }
`);

const getLocationsQuery = blvdGraphql(`
  query getLocations {
    locations(first: 100) {
      edges {
        node {
          id
          name
          businessName
        }
      }
    }
  }
`);

const createWebhookMuration = blvdGraphql(`
  mutation CreateWebhook($input: CreateWebhookInput!) {
    createWebhook(input: $input) {
      webhook {
        id
        subscriptions {
          id
          enabled
          eventType
        }
      }
    }
  }
`);

const deleteWebhookMutation = blvdGraphql(`
  mutation DeleteWebhook($id: ID!) {
    deleteWebhook(input: { id: $id }) {
      webhook {
        id
      }
    }
  }
`);

const updateAppointmentNoteMutation = blvdGraphql(`
  mutation updateAppointmentNoteMutation ($ID: ID!, $notes: String!) {
    updateAppointment(input: { id: $ID, notes: $notes }) {
      appointment {
        id
      }
    }
  }
`);

const addExternalIdToClientMutation = blvdGraphql(`
  mutation addExternalIdToClient($ID: ID!, $externalId: String!) {
    updateClient(input: { id: $ID, externalId: $externalId }) {
      client {
        id
        externalId
      }
    }
  }
`);

const getClientByIdQuery = blvdGraphql(`
  query getClientById($ID: ID!) {
    client(id: $ID) {
      id
      externalId
      id
      firstName
      lastName
      email
      mobilePhone
      active
      pronoun
      createdAt
      updatedAt
      dob
      name
      externalId
    }
  }
`);

export const blvdOperations = {
  getClientByIdQuery,
  createClientMutation,
  getClientByExternalIdQuery,
  updateClientMutation,
  getAppointmentsQuery,
  getWebhooks,
  createStaffMutation,
  getClientByExternalId,
  getLocationsQuery,
  createWebhookMuration,
  deleteWebhookMutation,
  updateAppointmentNoteMutation,
  addExternalIdToClientMutation,
};
