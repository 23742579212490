import { useLocalStorage } from '@uidotdev/usehooks';
import { XIcon } from 'lucide-react';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  H4,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@eluve/components';

interface OneTimeTooltipProps {
  title: string;
  content: string;
  children: ReactNode;
  scrollContainerQuerySelector?: string;
  position?: 'top' | 'bottom' | 'left' | 'right';
}

export const ONE_TIME_TOOLTIP_LOCAL_STORAGE_KEY = `oneTimeTooltipsDismissed`;

const getClosestScrollableParent = (
  element: HTMLElement | null,
): HTMLElement | null => {
  let parent = element?.parentElement;
  while (parent != null) {
    const overflowY = window.getComputedStyle(parent).overflowY;
    if (overflowY === 'auto' || overflowY === 'scroll') {
      return parent;
    }
    parent = parent.parentElement;
  }
  return null;
};

export const OneTimeTooltip: React.FC<OneTimeTooltipProps> = ({
  title,
  content,
  children,
  position = 'top',
}) => {
  const ref = useRef(null);

  const [dismissedTooltips, setDismissedTooltips] = useLocalStorage<{
    [id: string]: boolean;
  }>(ONE_TIME_TOOLTIP_LOCAL_STORAGE_KEY, {});
  const id = `${title}-${content}`;

  const [open, setOpen] = useState<boolean>(!dismissedTooltips[id]);

  const handleTooltipDismiss = useCallback(() => {
    setDismissedTooltips((prev) => ({ ...prev, [id]: true }));
    setOpen(false);
  }, [id, setDismissedTooltips]);

  useEffect(() => {
    setOpen(!dismissedTooltips[id]);
  }, [dismissedTooltips, id]);

  useEffect(() => {
    const scrollContainer = getClosestScrollableParent(ref.current);
    scrollContainer?.addEventListener('scroll', handleTooltipDismiss);
    return () => {
      scrollContainer?.removeEventListener('scroll', handleTooltipDismiss);
    };
  }, [handleTooltipDismiss]);

  return (
    <Tooltip open={open} delayDuration={0}>
      <TooltipTrigger asChild ref={ref}>
        {children}
      </TooltipTrigger>
      <TooltipContent
        className="w-72 border-none bg-transparent p-0"
        side={position}
        align="center"
        style={{
          boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.15)',
          borderRadius: '0.75rem',
        }}
      >
        <Card className="w-full rounded-xl border-none bg-white">
          <CardHeader className="flex h-5 flex-row items-center justify-end rounded-t-xl bg-white px-0 pb-0 pt-4">
            <Button
              onClick={handleTooltipDismiss}
              className="h-5 w-10 rounded-sm border-none p-0"
              variant="outline"
            >
              <XIcon size={20} />
            </Button>
          </CardHeader>
          <CardContent className="flex flex-col items-start rounded-b-xl bg-white px-3 pb-3 pt-0">
            <H4>{title}</H4>
            <span className="pt-2 font-normal text-contentSecondary sm:text-xs">
              {content}
            </span>

            <div
              className="pt-3 font-light text-brandGray500 underline hover:cursor-pointer sm:text-xs"
              onClick={handleTooltipDismiss}
            >
              Got it!
            </div>
          </CardContent>
          <TooltipArrow className="h-4 w-8 fill-white" offset={10} />{' '}
        </Card>
      </TooltipContent>
    </Tooltip>
  );
};
