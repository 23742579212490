import format from 'date-fns/format';
import React from 'react';

import { Divider, HStack, Tag } from '@eluve/components';

export interface TranscriptTimestampDividerProps {
  timestamp?: string;
  action?: string;
  className?: string;
  dateFormat?: string;
}

export const TranscriptTimestampDivider: React.FC<
  TranscriptTimestampDividerProps
> = ({
  className,
  timestamp = new Date().toISOString(),
  action = 'paused',
  dateFormat,
}) => {
  const date = new Date(timestamp);

  const formattedDate = dateFormat
    ? format(date, dateFormat)
    : format(date, 'P');
  const formattedTime = format(date, 'p');

  const label = `Session ${action} ${formattedDate} at ${formattedTime}`;

  return (
    <HStack gap={0} className={className}>
      <Divider className="h-[1px] text-borderPrimary" />
      <Tag
        size="s"
        color="default"
        pill
        label={label}
        className="flex-1 whitespace-nowrap"
      />
      <Divider className="h-[1px] text-borderPrimary" />
    </HStack>
  );
};
