import React, { useImperativeHandle, useState } from 'react';
import { useEffect, useRef } from 'react';
import { useLatest } from 'react-use';

import { useNamedLogger } from '@eluve/logger';

export type AudioPlayerHandle = {
  rewind: (seconds: number) => void;
  fastForward: (seconds: number) => void;
  seekTo: (seconds: number) => void;
  setPlaybackSpeed: (speed: number) => void;
  play: () => void;
  pause: () => void;
  isPaused: () => boolean;
};

export type AudioPlayerProps = {
  audioUrl?: string;
  onTimeUpdate?: (time: number) => void;
};

export const AudioPlayer = React.forwardRef<
  AudioPlayerHandle,
  AudioPlayerProps
>(({ audioUrl, onTimeUpdate }, ref) => {
  const logger = useNamedLogger('AudioPlayer');
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [audioSrc, setAudioSrc] = useState<string | undefined>(undefined);

  const latestOnTimeUpdate = useLatest(onTimeUpdate);

  useImperativeHandle(ref, () => ({
    rewind(seconds: number) {
      if (audioRef.current) {
        audioRef.current.currentTime = Math.max(
          0,
          audioRef.current.currentTime - seconds,
        );
      }
    },
    fastForward(seconds: number) {
      if (audioRef.current) {
        audioRef.current.currentTime = Math.min(
          audioRef.current.duration,
          audioRef.current.currentTime + seconds,
        );
      }
    },
    seekTo: (timestamp) => {
      if (audioRef.current) {
        audioRef.current.currentTime = timestamp;
      }
    },
    setPlaybackSpeed: (speed) => {
      if (audioRef.current) {
        audioRef.current.playbackRate = speed;
      }
    },
    play: () => {
      if (audioRef.current) {
        audioRef.current.play();
      }
    },
    pause: () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    },
    isPaused: () => {
      if (audioRef.current) {
        return audioRef.current.paused;
      }
      return true;
    },
  }));

  useEffect(() => {
    let blobAudioUrl: string | undefined = undefined;
    let blobAudioSizeInMb: number | undefined = undefined;
    const audioElement = audioRef.current;
    let isMounted = true;

    if (!audioElement || !audioUrl) return;

    const onTimeUpdateCurrent = latestOnTimeUpdate.current;

    const handleTimeUpdate = () => {
      onTimeUpdateCurrent?.(audioElement.currentTime);
    };

    if (onTimeUpdateCurrent) {
      audioElement.addEventListener('timeupdate', handleTimeUpdate);
    }

    const loadAudio = async () => {
      try {
        const response = await fetch(audioUrl);
        if (!response.ok) {
          throw new Error(`Failed to fetch audio: ${response.status}`);
        }

        const audioBlob = await response.blob();
        // under strict mode found an error that if the component
        // is unmounted it would still load the audio file
        // into memory with no way to clean it up
        if (!isMounted) {
          return;
        }

        blobAudioUrl = URL.createObjectURL(audioBlob);
        setAudioSrc(blobAudioUrl);

        blobAudioSizeInMb = audioBlob.size / 1024 / 1024;
        logger.info(`Audio loaded into memory. Size: ${blobAudioSizeInMb} MB`);
      } catch {
        if (isMounted) {
          logger.error(`Failed to load audio for URL ${audioUrl}`);
        }
      }
    };

    loadAudio();

    return () => {
      isMounted = false;

      if (audioElement && onTimeUpdateCurrent) {
        audioElement.removeEventListener('timeupdate', handleTimeUpdate);
      }

      if (blobAudioUrl) {
        URL.revokeObjectURL(blobAudioUrl);
        logger.info(`Audio unloaded from memory. ${blobAudioSizeInMb} MB`);
      }
    };
  }, [audioUrl, latestOnTimeUpdate, logger]);

  return <audio ref={audioRef} src={audioSrc} controls preload="metadata" />;
});
