import { useQuery, useSubscription } from '@apollo/client';
import add from 'date-fns/add';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useShowAllAppointmentsToggle } from '@eluve/blocks';
import { convertUrlStringDate, urlSafeDate } from '@eluve/date-utils';
import { AppointmentsBoolExp } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';
import { useUserIdFromSession } from '@eluve/session-helpers';

import { appointmentTimelineInfoFragment } from './components/AppointmentAgendaTimelineItem';

const getAppointmentRangeSummary = graphql(
  `
    query getAppointmentRangeSummary($where: AppointmentsBoolExp!) {
      appointments(where: $where) {
        ...AppointmentInfo
      }
    }
  `,
  [appointmentTimelineInfoFragment],
);

const appointmentsStreamingSubscription = graphql(
  `
    subscription appointmentStream(
      $initialValue: timestamptz
      $where: AppointmentsBoolExp!
    ) {
      appointmentsStream(
        where: $where
        batchSize: 1
        cursor: { initialValue: { updatedAt: $initialValue }, ordering: ASC }
      ) {
        ...AppointmentInfo
      }
    }
  `,
  [appointmentTimelineInfoFragment],
);

export const useCalendarAppointmentData = () => {
  const userId = useUserIdFromSession();
  const [searchParams, setSearchParams] = useSearchParams();
  const dateSearchParam = searchParams.get('date') ?? urlSafeDate();
  const date = convertUrlStringDate(dateSearchParam);
  const [showAllAppointments] = useShowAllAppointmentsToggle();
  const [streamingDateStart] = useState(new Date().toISOString());

  const setDate = (date: Date | undefined) => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set('date', urlSafeDate(date ?? new Date()));
    setSearchParams(urlSearchParams);
  };

  const range = [date.toISOString(), add(date, { days: 1 }).toISOString()];

  const min = range[0]!;
  const max = range[1]!;

  const baseFilters: AppointmentsBoolExp[] = [
    { startDate: { _gte: min } },
    { startDate: { _lt: max } },
  ];

  // If we're not showing all appointments, we only want to show the appointments for the current user
  if (!showAllAppointments) {
    baseFilters.push({ userId: { _eq: userId } });
  }

  const queryFilters: AppointmentsBoolExp[] = [
    ...baseFilters,
    { status: { _neq: 'CANCELLED' } },
  ];
  const { data, error, loading } = useQuery(getAppointmentRangeSummary, {
    skip: !dateSearchParam,
    variables: {
      where: {
        _and: queryFilters,
      },
    },
  });

  // Keep the cache up to date with the latest appointment data
  useSubscription(appointmentsStreamingSubscription, {
    skip: !dateSearchParam,
    variables: {
      initialValue: streamingDateStart,
      where: { _and: baseFilters },
    },
    onData: (sub) => {
      const appointments = sub.data?.data?.appointmentsStream;
      if (appointments && appointments.length > 0) {
        // We checked this above so we know its not null
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const appointment = appointments[0]!;

        sub.client.cache.updateQuery(
          {
            query: getAppointmentRangeSummary,
            variables: { where: { _and: queryFilters } },
          },
          (queryData) => {
            const appointments = queryData?.appointments ?? [];
            if (appointment.status === 'CANCELLED') {
              // If we received an event for a newly cancelled appointment, we remove it from the cache
              return {
                appointments: appointments.filter(
                  (a) => a.id !== appointment.id,
                ),
              };
            }

            if (appointments.some((a) => a.id === appointment.id)) {
              return queryData;
            }

            return {
              appointments: [...appointments, appointment],
            };
          },
        );
      }
    },
  });

  return {
    setDate,
    data,
    loading,
    error,
    dateSearchParam,
    date,
  };
};
