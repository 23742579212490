import React from 'react';

import { cn } from './cn';

export const Divider: React.FC<React.HTMLProps<HTMLHRElement>> = ({
  className,
  ...props
}) => (
  <hr
    {...props}
    className={cn('my-2 w-full border-borderPrimary', className)}
  />
);
