import { UniqueIdentifier } from '@dnd-kit/core';
import { Plus } from 'lucide-react';
import React from 'react';

import { Box, P } from '@eluve/components';
import { GroupBlock } from '@eluve/llm-outputs';

import { BlockPickerPopover } from '../BlockPicker';
import { AsBuilderBlock } from '../utilities';

export interface GroupBlockControlProps {
  parentId: UniqueIdentifier | null;
  group: AsBuilderBlock<GroupBlock>;
}

export const GroupBlockControl: React.FC<GroupBlockControlProps> = ({
  group,
}) => {
  return (
    <BlockPickerPopover parentId={group.id}>
      <Box className="mt-6 grid w-full cursor-pointer place-items-center rounded-lg border border-dashed border-gray-8 p-3 px-8 hover:border-gray-10">
        <Box hStack>
          <Plus />
          <P>Add a block to this group</P>
        </Box>
      </Box>
    </BlockPickerPopover>
  );
};
