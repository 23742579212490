import { Text, View } from '@react-pdf/renderer';
import startCase from 'lodash/startCase';
import camelCase from 'lodash/startCase';
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { tv } from 'tailwind-variants';

import { tw } from './pdf-tw';

const styles = tv({
  slots: {
    row: 'flex w-full flex-row gap-2 px-2 text-xs',
  },
});

const { row } = styles();

const titleCase = (str: string) => startCase(camelCase(str));

type ColDef = {
  label?: string;
  alignText?: 'left' | 'right' | 'center';
  width: number;
};

export function PdfTable<Keys extends string>({
  columns,
  rows,
}: {
  columns: { [K in Keys]: ColDef };
  rows: Record<Keys, string | string[]>[];
}) {
  return (
    <View style={tw('w-full flex flex-col gap-3 mb-2')}>
      <View style={tw(row())}>
        {Object.entries(columns).map((e) => {
          const [key, c] = e as [Keys, ColDef];
          const alignText = c.alignText ?? 'left';
          return (
            <View key={key} style={tw(`w-[${c.width}%] font-semibold`)}>
              <Text style={tw(`text-${alignText}`)}>
                {c.label ?? titleCase(key)}
              </Text>
            </View>
          );
        })}
      </View>
      {rows.map((r, i) => {
        return (
          <View style={tw(row())} key={i}>
            {Object.entries(columns).map((e) => {
              const [key, c] = e as [Keys, ColDef];
              const alignText = c.alignText ?? 'left';
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const colValue = r[key]!;
              return (
                <View key={i} style={tw(`w-[${c.width}%]`)}>
                  <Text style={tw(`text-${alignText}`)}>{colValue}</Text>
                </View>
              );
            })}
          </View>
        );
      })}
    </View>
  );
}
