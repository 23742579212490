import { useLazyQuery } from '@apollo/client';

import { useApiClient } from '@eluve/api-client-provider';
import { MedicalCodeTypesEnum } from '@eluve/graphql-types';

import { CodeSearchFn } from './SearchableBillingCodes';
import { searchBillingCodesQuery } from './SearchableBillingCodes.operations';

const defaultCodeTypes: MedicalCodeTypesEnum[] = ['CPT', 'ICD_10', 'SNOMED'];

/**
 * Hook that allows searching the medical codes table
 */
export const useSearchAllMedicalCodes = (args: {
  codeTypes?: MedicalCodeTypesEnum[];
  limit?: number;
}): CodeSearchFn => {
  const { codeTypes = defaultCodeTypes, limit = 25 } = args;

  const [searchMedicalCodes] = useLazyQuery(searchBillingCodesQuery);

  return async (query) => {
    const result = await searchMedicalCodes({
      variables: {
        types: codeTypes,
        query,
        limit,
      },
    });

    return (result?.data?.searchMedicalCodes ?? []).map((c) => ({
      ...c,
      source: 'medical_codes',
    }));
  };
};

/**
 * Hook that allows searching codes that the user might have access to for billing
 * purposes through tenant medical codes or through inherited user group configs
 */
export const useSearchCodesForBilling = (args: {
  tenantId: string;
}): CodeSearchFn => {
  const { tenantId } = args;
  const client = useApiClient();

  return async (query) => {
    const results = await client.medicalCodes.search({
      params: { tenantId: tenantId },
      query: { searchTerm: query },
    });

    if (results.status === 200) {
      return results.body.map(
        ({ code, codeType, id, description, price, type }) => {
          return {
            id,
            description: description ?? null,
            code,
            codeType: codeType as MedicalCodeTypesEnum,
            price: price ?? undefined,
            source: type,
          };
        },
      );
    } else {
      return [];
    }
  };
};
