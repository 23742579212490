import { useApiClient } from '@eluve/api-client-provider';
import { useAppointmentContext } from '@eluve/frontend-appointment-hooks';

export const useHandleSavePdf = () => {
  const { tenantId, appointmentId } = useAppointmentContext();
  const api = useApiClient();
  return () =>
    api.appointments.getOrCreatePdfUrl({
      params: {
        tenantId,
        appointmentId,
      },
    });
};
