import React from 'react';

import { textStyles, tv } from '@eluve/components';

const styles = tv({
  base: 'inline-flex flex-shrink-0 text-nowrap rounded-md px-2 py-1',
  variants: {
    type: {
      CPT: 'bg-teal text-tealContrast',
      ICD_10: 'bg-purple text-purpleContrast',
      ICD_9: 'bg-orange text-orangeContrast',
      SNOMED: 'border',
    },
  },
});

export type CodeType = 'ICD_10' | 'ICD_9' | 'CPT' | 'SNOMED';

export interface MedicalCodeTypeBadgeProps {
  codeType: CodeType;
  label?: string;
}

export const MedicalCodeTypeBadge = React.forwardRef<
  HTMLDivElement,
  MedicalCodeTypeBadgeProps
>(({ codeType, label }, ref) => {
  const className = styles({
    type: codeType,
    className: textStyles.label({ size: 'l', weight: 'semibold' }),
  });

  return (
    <div ref={ref} className={className}>
      {label ?? codeType.replace('_', '-')}
    </div>
  );
});
