import { PlayIcon, StopCircleIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';

import { Button, HStack, MicrophoneLevel, toast } from '@eluve/components';
import {
  MicrophoneDeviceSelector,
  useSelectedMicrophoneDevice,
} from '@eluve/smart-blocks';

export interface MicSettingsProps {}

export const MicSettings: React.FC<MicSettingsProps> = () => {
  const [selectedMicId, setSelectedMicId] = useSelectedMicrophoneDevice();
  const [enableMicTest, setEnableMicTest] = useState(false);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

  useEffect(
    function runMicTest() {
      if (!enableMicTest) {
        return;
      }

      navigator.mediaDevices
        .getUserMedia({
          audio: selectedMicId
            ? {
                deviceId: selectedMicId,
              }
            : true,
        })
        .then((stream) => {
          setMediaStream(stream);
          window.eluveMediaStreams.set('microphoneTester', stream);
        });

      return () => {
        window.eluveMediaStreams.stop('microphoneTester');
      };
    },
    [selectedMicId, enableMicTest],
  );

  return (
    <>
      <HStack>
        <MicrophoneDeviceSelector
          onDeviceValueChange={(deviceId) => {
            toast.success('Microphone preferences changed');
            setSelectedMicId(deviceId);
          }}
          selectedMicId={selectedMicId}
        />
        <Button
          variant="outline"
          onClick={() => setEnableMicTest(!enableMicTest)}
        >
          {enableMicTest ? (
            <>
              <StopCircleIcon className="mr-2 size-4" />
              Stop
            </>
          ) : (
            <>
              <PlayIcon className="mr-2 size-4" />
              Test
            </>
          )}
        </Button>
      </HStack>
      {enableMicTest && (
        <MicrophoneLevel
          stream={mediaStream}
          key={selectedMicId}
          className="w-max"
          variant="compact"
        />
      )}
    </>
  );
};
