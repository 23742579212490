import { useSelector } from '@xstate/react';
import React, { useCallback, useEffect, useRef } from 'react';

import { Box, Checkbox, HStack, Label, NewButton } from '@eluve/components';

import { useSegmentAudioContext } from './SegmentAudioContextStore';

type KaraokeEntry = { start: number; end: number; id: number };

type KaraokeState = {
  words: KaraokeEntry[];
  utterances: KaraokeEntry[];
  currentWordIndex: number;
  currentUtteranceIndex: number;
  segmentId: string;
};

const SegmentKaraokeLogic: React.FC = () => {
  const { store } = useSegmentAudioContext();
  const timestamp = useSelector(
    store,
    (state) => state.context.currentTimestamp,
  );

  const [followWord, setFollowWord] = React.useState(true);
  const [highlightEdits, setHighlightEdits] = React.useState(false);

  const karaokeState = useRef<KaraokeState>({
    words: [],
    utterances: [],
    currentWordIndex: -1,
    currentUtteranceIndex: -1,
    segmentId: '',
  });

  function setupKaraokeElements(segmentId: string) {
    if (segmentId === karaokeState.current.segmentId) return;

    karaokeState.current = {
      ...karaokeState.current,
      segmentId,
      currentWordIndex: -1,
      currentUtteranceIndex: -1,
    };

    function collectElements(attributePrefix: string): KaraokeEntry[] {
      const elements: KaraokeEntry[] = [];
      const dataElementPrefix = `data-karaoke${attributePrefix}`;
      document
        .querySelectorAll(
          `[data-karaoke-segment-id="${segmentId}"] [${dataElementPrefix}-start]`,
        )
        .forEach((el, index) => {
          el.setAttribute(`${dataElementPrefix}-id`, index.toString());
          elements.push({
            start: parseFloat(
              el.getAttribute(`${dataElementPrefix}-start`) ?? '0',
            ),
            end: parseFloat(el.getAttribute(`${dataElementPrefix}-end`) ?? '0'),
            id: index,
          });
        });
      return elements.sort((a, b) => a.start - b.start);
    }

    karaokeState.current.words = collectElements('-word');
    karaokeState.current.utterances = collectElements('-utterance');
  }

  function findNextElementIndex(
    timestamp: number,
    entries: KaraokeEntry[],
  ): number {
    let low = 0;
    let high = entries.length - 1;

    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      const element = entries[mid];

      if (element && timestamp >= element.start && timestamp <= element.end) {
        return mid;
      } else if (element && timestamp < element.start) {
        high = mid - 1;
      } else {
        low = mid + 1;
      }
    }

    return -1;
  }

  function goToLatestEdit() {
    const allEdits = document.querySelectorAll('[data-edited-by]');
    if (allEdits.length === 0) return;
    const lastEdit = allEdits[allEdits.length - 1] as HTMLElement;
    lastEdit.scrollIntoView({ behavior: 'smooth', block: 'center' });
    lastEdit.classList.add('bg-orange', 'animate-pulse');
    setTimeout(() => {
      lastEdit.classList.remove('bg-orange', 'animate-pulse');
    }, 2200);
  }

  const updateKaraokeElement = useCallback(
    (type: 'word' | 'utterance', index: number, active: boolean) => {
      const selector = `[data-karaoke-${type}-id="${index}"]`;
      const element = document.querySelector(selector);

      if (active) {
        element?.setAttribute('data-karaoke-active', 'true');
        if (type === 'word' && followWord) {
          element?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        }
      } else {
        element?.removeAttribute('data-karaoke-active');
      }
    },
    [followWord],
  );

  const updateKaraokeHighlights = useCallback(() => {
    const state = karaokeState.current;
    if (!state.words.length) return;

    function updateElement(
      entries: KaraokeEntry[],
      currentIndex: number,
      type: 'word' | 'utterance',
    ): number {
      const current = currentIndex >= 0 ? entries[currentIndex] : entries[0];
      if (current && current.start >= timestamp && current.end <= timestamp)
        return currentIndex;

      let nextIndex = -1;
      if (currentIndex < entries.length - 1) {
        const next = entries[currentIndex + 1];
        if (next && timestamp > next.start && timestamp < next.end) {
          nextIndex = currentIndex + 1;
        } else {
          nextIndex = findNextElementIndex(timestamp, entries);
        }
      } else {
        nextIndex = findNextElementIndex(timestamp, entries);
      }

      if (current && (current.end < timestamp || current.start > timestamp))
        updateKaraokeElement(type, current.id, false);
      if (nextIndex !== -1) {
        const entry = entries[nextIndex];
        if (entry) {
          updateKaraokeElement(type, entry.id, true);
        }
      }

      return nextIndex;
    }

    const newWordIndex = updateElement(
      state.words,
      state.currentWordIndex,
      'word',
    );
    const newUtteranceIndex = updateElement(
      state.utterances,
      state.currentUtteranceIndex,
      'utterance',
    );

    if (newWordIndex !== -1) state.currentWordIndex = newWordIndex;
    if (newUtteranceIndex !== -1)
      state.currentUtteranceIndex = newUtteranceIndex;
  }, [timestamp, updateKaraokeElement]);

  useEffect(() => {
    setupKaraokeElements(store.getSnapshot().context.segmentId);
  }, [store]);

  useEffect(() => {
    updateKaraokeHighlights();
  }, [timestamp, followWord, updateKaraokeHighlights]);

  useEffect(() => {
    const allEdits = document.querySelectorAll('[data-edited-by]');
    if (allEdits.length === 0) return;
    allEdits.forEach((edit) => {
      if (highlightEdits) {
        edit.classList.add('bg-teal');
      } else {
        edit.classList.remove('bg-teal');
      }
    });
  }, [highlightEdits]);

  return (
    <HStack wFull={false}>
      <NewButton
        onClick={goToLatestEdit}
        text="Go to furthest edit"
        size="m"
        icon={{ name: 'ArrowRightToLine' }}
      />
      <Label>
        <Box gap={2} hStack>
          <Checkbox
            className="m-0"
            aria-label="Follow Word"
            checked={followWord}
            onCheckedChange={(e) => {
              setFollowWord(Boolean(e));
            }}
          />
          <span>Follow Word</span>
        </Box>
      </Label>
      <Label>
        <Box gap={2} hStack>
          <Checkbox
            className="m-0"
            aria-label="Hightlight Edits"
            checked={highlightEdits}
            onCheckedChange={(e) => {
              setHighlightEdits(Boolean(e));
            }}
          />
          <span>Highlight Edits</span>
        </Box>
      </Label>
    </HStack>
  );
};

export default SegmentKaraokeLogic;
