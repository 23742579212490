import { useSuspenseQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';

import { latestHumanSOAPNoteFragment } from '@eluve/frontend-appointment-hooks';
import { DbEnums } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';
import {
  LegacySOAPOutputTemplate,
  dynamicArtifactTemplateSchema,
  hydrateDynamicArtifactTemplate,
} from '@eluve/llm-outputs';

import { AppointmentPdf } from './AppointmentPdf';

export const getCurrentSummary = graphql(
  `
    query getCurrentSummary($tenantId: uuid!, $appointmentId: uuid!) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        __typename
        id
        ...LatestHumanSOAPNote
        mode
      }
    }
  `,
  [latestHumanSOAPNoteFragment],
);

export const AppointmentPdfPage: React.FC = () => {
  const { tenantId, appointmentId } = useParams() as {
    tenantId: string;
    appointmentId: string;
  };

  const { data } = useSuspenseQuery(getCurrentSummary, {
    variables: { appointmentId, tenantId },
  });

  const appointmentMode = data.appointmentsByPk?.mode;
  const currentSoapNote = data?.appointmentsByPk?.humanOutputs?.[0];
  const summary = currentSoapNote?.output?.content ?? null;
  const llmOutputMetadata = currentSoapNote?.output?.llm_output?.metadata;
  const outputTemplate =
    appointmentMode === DbEnums.AppointmentMode.AUDIO_COPILOT
      ? llmOutputMetadata?.output_variant?.template
      : data?.appointmentsByPk?.llm_output_template?.variants?.[0]?.template;

  const outputTemplateParsed =
    llmOutputMetadata?.prompt_template?.outputType ===
    DbEnums.LlmOutputType.DYNAMIC_OUTPUT
      ? dynamicArtifactTemplateSchema.parse(outputTemplate)
      : LegacySOAPOutputTemplate;

  const hydrated = hydrateDynamicArtifactTemplate(
    outputTemplateParsed,
    summary ?? {},
  );

  return (
    <AppointmentPdf
      tenantId={tenantId}
      appointmentId={appointmentId}
      summary={{
        type: 'DYNAMIC',
        blocks: hydrated.blocks,
      }}
    />
  );
};
