import { useMutation } from '@apollo/client';
import { produce } from 'immer';
import { v4 } from 'uuid';

import { cacheUtils } from '@eluve/apollo-client';
import { toast } from '@eluve/components';
import { AppointmentBillingCodeRecommendationsBoolExp } from '@eluve/graphql-types';

import {
  acceptBillingCodeRecommendationMutation,
  appointmentBillingCodesFragment,
} from './AppointmentBillingCodes.operations';
import { MedicalCodeType } from './useEditBillingCodes';

export const useBillingCodeRecommendations = ({
  appointmentId,
}: {
  appointmentId: string;
}) => {
  const [acceptRecommendation] = useMutation(
    acceptBillingCodeRecommendationMutation,
    {
      onError: () => toast.error('Failed to accept recommendation'),
      optimisticResponse: ({ appointmentId, billingCodeId, tenantCodeId }) => ({
        insertAppointmentBillingCodesOne: {
          __typename: 'AppointmentBillingCodes' as const,
          id: v4(),
        },
        updateAppointmentBillingCodeRecommendations: {
          returning: [
            {
              __typename: 'AppointmentBillingCodeRecommendations' as const,
              id: v4(),
              appointmentId,
              codeId: billingCodeId ?? null,
              tenantCodeId: tenantCodeId ?? null,
              isFinal: true,
            },
          ],
        },
      }),
    },
  );

  const onAcceptRecommendation = async ({
    medicalCode,
    tenantMedicalCode,
  }: {
    medicalCode?: MedicalCodeType;
    tenantMedicalCode?: MedicalCodeType;
  }) => {
    const where: AppointmentBillingCodeRecommendationsBoolExp = {
      appointmentId: { _eq: appointmentId },
      _or: [],
    };

    if (medicalCode) {
      where._or!.push({ codeId: { _eq: medicalCode.id } });
    }
    if (tenantMedicalCode) {
      where._or!.push({ tenantCodeId: { _eq: tenantMedicalCode.id } });
    }

    await acceptRecommendation({
      variables: {
        appointmentId,
        billingCodeId: medicalCode?.id,
        tenantCodeId: tenantMedicalCode?.id,
        price: null,
        quantity: 1,
        where,
      },
      // TODO(jesse)[ELU-3920]: Implement this properly
      // onCompleted(data) {
      //   const id = data.insertAppointmentBillingCodesOne?.id;
      //   if (!id) {
      //     return;
      //   }
      //   if (medicalCode) {
      //     setInheritedBillingCodePrice(
      //       id,
      //       medicalCode.id,
      //       medicalCode.codeType,
      //     );
      //   }
      // },
      update: (_cache, { data }) => {
        cacheUtils.updateFragment(
          {
            fragment: appointmentBillingCodesFragment,
            key: {
              id: appointmentId,
            },
          },
          (existing) => {
            const updatedId = data?.insertAppointmentBillingCodesOne?.id;
            if (!existing || !updatedId) {
              return existing;
            }

            return produce(existing, (draft) => {
              draft.billingCodes.push({
                __typename: 'AppointmentBillingCodes',
                id: updatedId,
                price: null,
                quantity: 1,
                modifiers: null,
                linked_codes: [],
                medical_code: medicalCode
                  ? {
                      __typename: 'MedicalCodes',
                      ...medicalCode,
                    }
                  : null,
                tenant_medical_code: tenantMedicalCode
                  ? { __typename: 'TenantMedicalCodes', ...tenantMedicalCode }
                  : null,
              });
            });
          },
        );
      },
    });
  };

  return {
    onAcceptRecommendation,
  };
};
