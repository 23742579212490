import { useQuery } from '@apollo/client';
import React from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { useCompleteFragment } from '@eluve/apollo-client';
import { H4 } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { useAssignedTenantIdFromParams } from '@eluve/session-helpers';
import { TabNav } from '@eluve/smart-blocks';

export interface UserGroupDetailsLayoutProps {}

const userGroupNameFragment = graphql(`
  fragment GroupName on UserGroups @_unmask {
    __typename
    id
    name
  }
`);

const getUserGroupNameQuery = graphql(
  `
    query getUserGroupName($tenantId: uuid!, $id: uuid!) {
      userGroupsByPk(tenantId: $tenantId, id: $id) {
        ...GroupName
      }
    }
  `,
  [userGroupNameFragment],
);

const useUserGroupName = (tenantId: string, groupId: string) => {
  const group = useCompleteFragment({
    fragment: userGroupNameFragment,
    key: {
      id: groupId,
    },
    strict: false,
  });

  useQuery(getUserGroupNameQuery, {
    fetchPolicy: 'cache-first',
    variables: {
      tenantId,
      id: groupId,
    },
    skip: Boolean(group),
  });

  return group?.name;
};

export const UserGroupDetailsLayout: React.FC<
  UserGroupDetailsLayoutProps
> = () => {
  const tenantId = useAssignedTenantIdFromParams();
  const { userGroupId } = useParams() as { userGroupId: string };

  const groupName = useUserGroupName(tenantId, userGroupId);

  return (
    <div className="mt-4">
      <H4>{groupName}</H4>
      <TabNav
        tabs={[
          {
            name: 'Users',
            link: 'users',
          },
          {
            name: 'Billing Code Configs',
            link: 'configs',
          },
        ]}
      />
      <div className="mt-4">
        <Outlet />
      </div>
    </div>
  );
};
