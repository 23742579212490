import clsx from 'clsx';
import React from 'react';
import { VariantProps } from 'tailwind-variants';
import { match } from 'ts-pattern';

import { tv } from '@eluve/components';
import {
  Badge,
  HStack,
  P,
  Tag,
  TagProps,
  cn,
  textStyles,
} from '@eluve/components';

import { CodeType } from './MedicalCodeTypeBadge';

export const styles = tv({
  slots: {
    container: 'relative rounded-lg border border-transparent p-2',
    content: 'min-w-0 flex-1 pr-2',
    frame: 'pointer-events-none absolute -inset-px rounded-lg',
  },
  variants: {
    clickable: {
      true: {
        container: 'cursor-pointer hover:bg-brandGray50',
      },
    },
    type: {
      default: {
        container: 'bg-brandGray100',
        content: 'text-brandGray800',
      },
      ghost: {
        container: '',
        content: 'text-brandGray800',
      },
      outline: {
        container: 'border-brandGray200',
        content: 'text-brandGray800',
      },
      outlineDashed: {
        content: 'text-brandGray600',
        frame: 'bg-dashed-border-recommendation',
      },
    },
    disabled: {
      true: {
        container: 'pointer-events-none border-brandGray200 bg-white',
        content: 'pointer-events-none text-brandGray800',
      },
    },
  },
  compoundVariants: [
    {
      clickable: true,
      type: 'default',
      class: {
        container: 'hover:bg-brandGray200',
      },
    },
  ],
  defaultVariants: {
    type: 'default',
  },
});

const getTagColor = (codeType: CodeType) =>
  match<CodeType, TagProps['color']>(codeType)
    .with('CPT', () => 'teal')
    .with('ICD_10', () => 'purple')
    .with('ICD_9', () => 'orange')
    .with('SNOMED', () => 'default')
    .exhaustive();

export interface MedicalCodePropsOld {
  code: string;
  type: string;
}

export const MedicalCodeOld: React.FC<MedicalCodePropsOld> = ({
  code,
  type,
}) => {
  const typeLabel = type === 'ICD_10' ? 'ICD-10' : type;

  return (
    <>
      <Badge
        className={cn(
          'pointer-events-none',
          clsx({
            'bg-cyan-500': type === 'CPT',
            'bg-primary': typeLabel === 'ICD-10',
            'bg-gray-10': typeLabel !== 'CPT' && typeLabel !== 'ICD-10',
          }),
        )}
      >
        {type}
      </Badge>
      <P>{code}</P>
    </>
  );
};

export type MedicalCodeProps = {
  codeType: CodeType;
  code: string;
  description: string;
  includeDescription?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  onClick?: () => void;
  type?: VariantProps<typeof styles>['type'];
  disabled?: boolean;
};

export const MedicalCodeTag = React.forwardRef<
  HTMLDivElement,
  { code: string; codeType: CodeType; disabled?: boolean }
>(({ code, codeType, disabled = false }, ref) => (
  <Tag
    label={code}
    color={disabled ? 'gray' : getTagColor(codeType)}
    ref={ref}
  />
));

export const MedicalCode = React.forwardRef<HTMLDivElement, MedicalCodeProps>(
  (
    {
      codeType,
      code,
      description,
      includeDescription = true,
      onClick,
      startAdornment,
      endAdornment,
      type,
      disabled = false,
    },
    ref,
  ) => {
    const { container, content, frame } = styles({
      clickable: Boolean(onClick) && !disabled,
      type,
      disabled,
    });

    return (
      <HStack
        align="center"
        className={container()}
        wFull={false}
        inline
        ref={ref}
        onClick={onClick}
        gap={3}
      >
        {startAdornment && startAdornment}

        <HStack gap={2}>
          <Tag
            label={code}
            size="xs"
            color={disabled ? 'gray' : getTagColor(codeType)}
          />

          <div className={textStyles.body({ size: 's', className: content() })}>
            <p>{`${includeDescription ? `${description}` : ''}`}</p>
          </div>
        </HStack>

        {endAdornment && endAdornment}

        <div className={frame()} />
      </HStack>
    );
  },
);

MedicalCode.displayName = 'MedicalCode';
