import { ChevronsUpDown } from 'lucide-react';
import React, { useState } from 'react';

import { HStack } from './HStack';
import { Icon } from './Icon';
import { Button } from './button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from './command';
import { Popover, PopoverContent, PopoverTrigger } from './popover';

export interface SelectCommandPopoverProps<T> {
  /** Array of items to list in the popover */
  items: T[];
  /** The currently selected item (if any) */
  selectedItem: T | null;
  /** Callback when a user selects an item */
  onItemSelect: (item: T) => void;

  /** Extract a unique key for each item */
  itemKey: (item: T) => string;
  /** String used for searching/filtering */
  itemValue: (item: T) => string;
  /** How to render the item visually in the menu */
  itemContent: (item: T) => React.ReactNode;

  /** Text to show on the trigger button if no item is selected */
  triggerText: string;
  /** Placeholder for the Command input */
  placeholder?: string;
  /** Text shown if no matching items are found */
  noResultsMessage?: string;
}

export function SelectCommandPopover<T>(props: SelectCommandPopoverProps<T>) {
  const {
    items,
    selectedItem,
    onItemSelect,
    itemKey,
    itemValue,
    itemContent,
    triggerText,
    placeholder = 'Search...',
    noResultsMessage = 'No items found.',
  } = props;

  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between"
        >
          {selectedItem ? itemValue(selectedItem) : triggerText}
          <ChevronsUpDown className="ml-2 size-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>

      <PopoverContent
        className="w-auto p-0"
        align="start"
        side="bottom"
        sideOffset={4}
        /** Ensure the popover matches the width of the trigger */
        style={{ width: 'var(--radix-popover-trigger-width)' }}
      >
        <Command className="w-full">
          <CommandInput placeholder={placeholder} />
          <CommandList>
            <CommandEmpty>{noResultsMessage}</CommandEmpty>
            <CommandGroup>
              {items.map((item) => {
                const isSelected =
                  selectedItem && itemKey(item) === itemKey(selectedItem);

                return (
                  <CommandItem
                    key={itemKey(item)}
                    value={itemValue(item)}
                    onSelect={() => {
                      onItemSelect(item);
                      setOpen(false);
                    }}
                  >
                    <HStack gap={3}>
                      {isSelected && <Icon name="Check" size="xs" />}
                      {itemContent(item)}
                    </HStack>
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
