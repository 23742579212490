import { MatchingRecord } from '@eluve/utils';

import { UserUploadStatusEnum } from './types';

export const UserUploadStatusLookup = {
  AVAILABLE: 'AVAILABLE',
  DELETED: 'DELETED',
  PENDING_DELETION: 'PENDING_DELETION',
  PENDING_UPLOAD: 'PENDING_UPLOAD',
  UPLOAD_ERROR: 'UPLOAD_ERROR',
} as const satisfies MatchingRecord<UserUploadStatusEnum>;
