import { graphql } from 'gql.tada';

import { tenantPendingApprovalFragment } from './TenantApprovalToggle';

export const getAdminTenantsSummaryQuery = graphql(
  `
    query getAdminTenantSummary {
      tenants(where: { deletedAt: { _isNull: true } }) {
        __typename
        id
        name
        createdAt
        ...TenantPendingApproval
        appointmentsAggregate {
          aggregate {
            count
          }
        }
        tenantUsersAggregate {
          aggregate {
            count
          }
        }
      }
    }
  `,
  [tenantPendingApprovalFragment],
);

export const getAdminTenantsSummaryFilterQuery = graphql(
  `
    query getAdminTenantSummaryFilter(
      $filter: TenantsBoolExp
      $offset: Int
      $orderBy: [TenantsOrderBy!]
      $limit: Int
    ) {
      tenants(
        where: $filter
        offset: $offset
        limit: $limit
        orderBy: $orderBy
      ) {
        __typename
        id
        name
        createdAt
        ...TenantPendingApproval
        isCurrentUserProvisioned
        appointmentsAggregate {
          aggregate {
            count
          }
        }
        tenantUsersAggregate {
          aggregate {
            count
          }
        }
      }
      tenantsAggregate(where: $filter) {
        __typename
        aggregate {
          __typename
          count
        }
      }
    }
  `,
  [tenantPendingApprovalFragment],
);
