import { create } from 'zustand';

type SummaryContentRefStore = {
  contentRef: HTMLDivElement | null;
  setContentRef: (ref: HTMLDivElement | null) => void;
};

/**
 * Hook to get and set the summary content ref
 * @returns The content ref and a function to set it
 */
export const useSummaryContentRef = create<SummaryContentRefStore>((set) => ({
  contentRef: null,
  setContentRef: (ref) => set({ contentRef: ref }),
}));
