import { LogOut, ShieldCheck } from 'lucide-react';
import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Outlet, useLocation } from 'react-router-dom';

import { Box, Button, TooltipProvider } from '@eluve/components';
import { useSession } from '@eluve/session-helpers';
import { TabNav } from '@eluve/smart-blocks';

import { NotFoundPage } from '../../NotFoundPage';

import { AdminBreadcrumbs } from './components/AdminBreadcrumbs';

const adminRoutes = [
  { name: 'Tenants', link: 'tenants' },
  { name: 'Prompts', link: 'prompts' },
  { name: 'Output Templates', link: 'output-templates' },
  { name: 'Models', link: 'models' },
  { name: 'Fact Verification', link: 'fact-verification' },
  { name: 'Audit Trail', link: 'audit-trail' },
  { name: 'Global Feature Flags', link: 'global-feature-flags' },
  { name: 'Users', link: 'users' },
  { name: 'API Tokens', link: 'api-tokens' },
  { name: 'Reviewers', link: 'reviewers-list' },
];

export const AdminLayout: React.FC = () => {
  const location = useLocation();
  const session = useSession();

  const isCloning = location.pathname.includes('clone');
  const isCreating = location.pathname.includes('create');
  const isPrompts = location.pathname.includes('prompts');
  const shouldHideNavigation = isPrompts && (isCloning || isCreating);

  if (!session.isEluveAdmin) {
    return <NotFoundPage />;
  }

  return (
    <TooltipProvider delayDuration={0}>
      <Helmet>
        <title>Admin | Eluve</title>
      </Helmet>
      <div className="min-h-full overflow-x-hidden">
        <div className="bg-brandGray800 pb-32">
          {!shouldHideNavigation && (
            <header className="py-4 lg:py-8">
              <div className="mx-auto w-full px-2 lg:px-8">
                <div className="flex flex-wrap items-center justify-between gap-y-2">
                  <div className="flex items-center gap-2">
                    <ShieldCheck className="size-10 text-yellow" />
                    <h1 className="text-3xl font-bold tracking-tight text-white">
                      Eluve Admin Dashboard
                    </h1>
                  </div>
                  <div className="flex flex-wrap gap-1 md:gap-2">
                    <Button
                      size={'xs'}
                      variant="secondary"
                      onClick={() => {
                        const { firstName, lastName } = session;
                        throw new Error(
                          `Test Error: triggered by ${[
                            firstName,
                            lastName,
                          ].join(' ')}`,
                        );
                      }}
                    >
                      <span className="sr-only">test-error</span>
                      Trigger Sentry Test Error
                    </Button>
                    <Link to="/">
                      <Button size="xs">
                        Return to App <LogOut className="ml-2" />
                      </Button>
                    </Link>
                  </div>
                </div>
                <Box className="mt-4">
                  <TabNav tabs={adminRoutes} colorMode="dark" />
                </Box>
              </div>
            </header>
          )}
        </div>

        <main className={shouldHideNavigation ? '-mt-24' : '-mt-32'}>
          <div className="mx-auto w-full px-2 pb-2 lg:px-8 lg:pb-8">
            <div
              className={`min-h-96 rounded-lg bg-white px-5 py-6 shadow sm:px-6 ${shouldHideNavigation ? 'pt-1' : ''}`}
            >
              <div className="w-full">
                {!shouldHideNavigation && <AdminBreadcrumbs />}
                <Suspense fallback={'Loading...'}>
                  <Outlet />
                </Suspense>
              </div>
            </div>
          </div>
        </main>
      </div>
    </TooltipProvider>
  );
};
