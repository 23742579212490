import { fromCallback } from 'xstate';

import { Logger } from '@eluve/logger';

type Input = { logger: Logger };
type GenericEvent = { type: string };

export const keepScreenAwake = fromCallback<GenericEvent, Input>(
  ({ input }) => {
    const { logger } = input;
    if (navigator && 'wakeLock' in navigator) {
      let wakeLock: WakeLockSentinel | null = null;

      const requestWakeLock = async () => {
        try {
          wakeLock = await navigator.wakeLock.request('screen');
        } catch (error) {
          logger.warn('Failed to request screen wake lock', { error });
        }
      };

      requestWakeLock();

      const visibilityChangeHandler = () => {
        if (wakeLock !== null && document.visibilityState === 'visible') {
          // Re-request the wake lock if it was released while tab was unfocused
          requestWakeLock();
        }
      };

      document.addEventListener('visibilitychange', visibilityChangeHandler);

      const releaseWakeLock = async () => {
        if (wakeLock) {
          await wakeLock.release();
          wakeLock = null;
        }
      };

      return () => {
        releaseWakeLock();
        document.removeEventListener(
          'visibilitychange',
          visibilityChangeHandler,
        );
      };
    }
  },
);
