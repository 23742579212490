import { useCompleteFragment } from '@eluve/apollo-client';
import { FeatureFlagsEnum } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';
import {
  useAssignedTenantIdFromParams,
  useTenantIdFromParams,
} from '@eluve/session-helpers';
import { locationFeatureFlagFragment } from '@eluve/shared-gql-operations';

const tenantFeatureFlagFragment = graphql(`
  fragment TenantFeatureFlag on TenantFeatureFlags @_unmask {
    __typename
    tenantId
    flag
    isEnabled
  }
`);

const tenantFeatureFlagsFragment = graphql(
  `
    fragment TenantFeatureFlags on Tenants @_unmask {
      __typename
      id
      featureFlags {
        ...TenantFeatureFlag
      }
    }
  `,
  [tenantFeatureFlagFragment],
);

/**
 * Indicates whether the feature flag in question is active for a tenant.
 * The tenant is derived from the current scoped route in the URL
 */
export const useIsFeatureFlagEnabled = (flag: FeatureFlagsEnum) => {
  const tenantId = useTenantIdFromParams();

  const data = useCompleteFragment({
    fragment: tenantFeatureFlagFragment,
    key: {
      tenantId,
      flag,
    },
    strict: false,
  });

  return data?.isEnabled ?? false;
};

/**
 * Checks to see if a given feature flag is enabled for a specific location. If
 * the feature flag isn't set for the location, this will fall back to whatever the
 * feature flag setting is at the tenant level
 */
export const useIsFeatureFlagEnabledForLocation = (
  flag: FeatureFlagsEnum,
  locationId: string,
) => {
  const isTenantFlagEnabled = useIsFeatureFlagEnabled(flag);

  const data = useCompleteFragment({
    fragment: locationFeatureFlagFragment,
    key: {
      flag,
      locationId,
    },
    strict: false,
  });

  if (data) {
    return data.isEnabled ?? false;
  }

  return isTenantFlagEnabled;
};

export const useEnabledFeatureFlags = () => {
  const tenantId = useAssignedTenantIdFromParams();

  const data = useCompleteFragment({
    fragment: tenantFeatureFlagsFragment,
    key: {
      id: tenantId,
    },
    strict: false,
  });

  return (
    (data?.featureFlags ?? [])
      .filter((flag) => flag.isEnabled)
      .map((flag) => flag.flag) ?? []
  );
};
