import { useEffect, useState } from 'react';
import { FaMicrophone } from 'react-icons/fa';

import { formatSecondsToTime } from '@eluve/date-utils';

import { Box } from './box';

interface WaveformProps {
  stream: MediaStream;
  totalDuration?: number;
  barCount?: number;
  onTimeDurationUpdate?: (time: number) => void;
}

export const Waveform: React.FC<WaveformProps> = ({
  stream,
  totalDuration = 0,
  barCount = 24,
  onTimeDurationUpdate,
}) => {
  const [bars, setBars] = useState<number[]>(Array(barCount).fill(0));
  const [elapsedTime, setElapsedTime] = useState<number>(totalDuration);

  useEffect(() => {
    setElapsedTime(totalDuration);
  }, [totalDuration]);

  useEffect(() => {
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 256;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const source = audioContext.createMediaStreamSource(stream);
    source.connect(analyser);

    const draw = () => {
      if (audioContext.state === 'closed') {
        return;
      }
      analyser.getByteFrequencyData(dataArray);
      setBars(Array.from(dataArray).splice(8, barCount)); // Select a portion of the frequency data
      requestAnimationFrame(draw);
    };

    draw();

    return () => {
      audioContext.close();
    };
  }, [stream, barCount]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setElapsedTime((prevTime) => {
        const newTime = prevTime + 1;
        onTimeDurationUpdate?.(newTime);
        return newTime;
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [onTimeDurationUpdate]);

  return (
    <Box hStack className="rounded-lg">
      <Box className="flex items-center gap-1 rounded-lg bg-brand-5/40 p-2 text-brand-8 sm:gap-1.5 md:gap-2">
        <FaMicrophone className="size-4 md:size-5" />
      </Box>
      <span className="font-mono text-sm">
        {formatSecondsToTime(elapsedTime)}
      </span>
      <div className="flex h-[28px] items-center gap-0.5 sm:gap-1">
        {bars.map((bar, i) => (
          <div
            key={i}
            className="max-h-[28px] min-h-1 w-px rounded-full bg-gray-11 sm:w-[2px]"
            style={{ height: `${bar / 6}px` }}
          />
        ))}
      </div>
    </Box>
  );
};

interface WaveformLoadingProps {
  totalDuration?: number;
  barCount?: number;
}

export const WaveformLoading: React.FC<WaveformLoadingProps> = ({
  totalDuration = 0,
  barCount = 24,
}) => (
  <Box hStack className="rounded-lg">
    <Box className="flex items-center gap-1 rounded-lg bg-gray-5/40 p-2 text-gray-9 sm:gap-1.5 md:gap-2">
      <FaMicrophone className="size-4 md:size-5" />
    </Box>
    <span className="font-mono text-sm">
      {formatSecondsToTime(totalDuration)}
    </span>
    <div className="flex h-[28px] items-center gap-0.5 sm:gap-1">
      {Array(barCount)
        .fill(0)
        .map((_, i) => (
          <div
            key={i}
            className="max-h-[28px] min-h-1 w-px rounded-full bg-gray-11 sm:w-[2px]"
          />
        ))}
    </div>
  </Box>
);
