import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import { FeatureFlaggedPage } from '@eluve/smart-blocks';

import { LayersPage } from './LayersPage';
import { LocationPage } from './LocationPage';
import { LocationsPage } from './LocationsPage';
import { SessionTypesPage } from './SessionTypesPage';
import { UserGroupConfigsPage } from './UserGroupConfigsPage';
import { UserGroupDetailsLayout } from './UserGroupDetailsLayout';
import { UserGroupUsers } from './UserGroupUsers';
import { UserGroupsListPage } from './UserGroupsListPage';

export const tenantAdminRoutes = [
  {
    path: 'user-groups',
    element: <UserGroupsListPage />,
  },
  {
    path: 'user-groups/:userGroupId',
    element: (
      <FeatureFlaggedPage flag="USER_GROUPS">
        <UserGroupDetailsLayout />
      </FeatureFlaggedPage>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="users" replace />,
      },
      {
        path: 'users',
        element: <UserGroupUsers />,
      },
      {
        path: 'configs',
        element: <UserGroupConfigsPage />,
      },
    ],
  },
  {
    path: 'layers',
    element: (
      <FeatureFlaggedPage flag="LOCATIONS">
        <LayersPage />
      </FeatureFlaggedPage>
    ),
  },
  {
    path: 'locations',
    element: (
      <FeatureFlaggedPage flag="LOCATIONS">
        <Outlet />
      </FeatureFlaggedPage>
    ),
    children: [
      {
        index: true,
        element: <LocationsPage />,
      },
      {
        path: ':locationId',
        element: <LocationPage />,
      },
    ],
  },
  {
    path: 'session-types',
    element: (
      <FeatureFlaggedPage flag="SESSION_TYPES">
        <SessionTypesPage />
      </FeatureFlaggedPage>
    ),
  },
] satisfies RouteObject[];
