import { useMutation } from '@apollo/client';
import { Tag } from 'lucide-react';
import React from 'react';
import { match } from 'ts-pattern';

import { useCompleteFragment } from '@eluve/apollo-client';
import {
  Box,
  Combobox,
  ComboboxDropdown,
  ComboboxOption,
  ComboboxSelectButton,
  ComboboxSelectCheck,
  InputVariants,
  toast,
} from '@eluve/components';
import {
  appointmentSessionTypeFragment,
  useAppointmentId,
  useAppointmentSessionType,
} from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';
import { useTenantIdFromParams } from '@eluve/session-helpers';
import { tenantSessionTypesFragment } from '@eluve/shared-gql-operations';

const setAppointmentSessionTypeMutation = graphql(
  `
    mutation setAppointmentSessionType(
      $tenantId: uuid!
      $appointmentId: uuid!
      $sessionTypeId: uuid
    ) {
      updateAppointmentsByPk(
        pkColumns: { tenantId: $tenantId, id: $appointmentId }
        _set: { sessionTypeId: $sessionTypeId }
      ) {
        ...SessionType
      }
    }
  `,
  [appointmentSessionTypeFragment],
);

export interface SessionTypeSelectorProps extends InputVariants {
  disabled?: boolean;
}

export const SessionTypeSelector: React.FC<SessionTypeSelectorProps> = ({
  color,
  size,
  variant,
  disabled = false,
}) => {
  const tenantId = useTenantIdFromParams() ?? '';
  const appointmentId = useAppointmentId();

  const appointmentSessionType = useAppointmentSessionType();

  const { session_types: types } = useCompleteFragment({
    fragment: tenantSessionTypesFragment,
    key: {
      id: tenantId,
    },
  });

  const selectedsessionType = types.find(
    (type) => type.id === appointmentSessionType?.id,
  );

  const [setAppointmentSessionType] = useMutation(
    setAppointmentSessionTypeMutation,
    {
      optimisticResponse: (input) => ({
        updateAppointmentsByPk: {
          __typename: 'Appointments' as const,
          id: appointmentId,
          session_type: input.sessionTypeId
            ? {
                __typename: 'SessionTypes' as const,
                id: input.sessionTypeId ?? '',
                name:
                  types.find((type) => type.id === input.sessionTypeId)?.name ??
                  '',
              }
            : null,
        },
      }),
      onError: () => toast.error('Failed to update session type'),
    },
  );

  const handleSessionTypeChange = (typeId: string | undefined) => {
    setAppointmentSessionType({
      variables: {
        tenantId,
        appointmentId,
        sessionTypeId: typeId,
      },
    });
  };

  return (
    <Combobox disabled={disabled || types.length === 0}>
      <ComboboxSelectButton
        className="mb-2 w-full overflow-hidden border-transparent hover:bg-gray-3"
        color={color}
        size={size}
        variant={variant}
        title={selectedsessionType?.name}
      >
        <Box hStack className="w-full overflow-hidden">
          <Tag size={16} />
          <p className="flex-1 truncate text-left text-sm">
            {match(types.length)
              .with(0, () => 'None available')
              .otherwise(() => selectedsessionType?.name ?? 'No Type')}
          </p>
        </Box>
      </ComboboxSelectButton>
      <ComboboxDropdown searchPlaceholder="Search types...">
        {types.map((type) => (
          <ComboboxOption
            key={type.id}
            onSelect={() => handleSessionTypeChange(type.id)}
          >
            <ComboboxSelectCheck
              className="mr-2"
              selected={selectedsessionType?.id === type.id}
            />
            {type.name}
          </ComboboxOption>
        ))}
        <ComboboxOption onSelect={() => handleSessionTypeChange(undefined)}>
          <ComboboxSelectCheck
            selected={selectedsessionType === undefined}
            className="mr-2"
          />
          No Type
        </ComboboxOption>
      </ComboboxDropdown>
    </Combobox>
  );
};
