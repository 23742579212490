import { useCallback, useEffect } from 'react';
import { z } from 'zod';

import { useSearchParamValue } from '@eluve/components';
import {
  useAppointmentMode,
  useAppointmentStatus,
} from '@eluve/frontend-appointment-hooks';
import { DbEnums } from '@eluve/graphql-types';

export const TRANSCRIPT_TAB = 'transcript' as const;
export const SUMMARY_TAB = 'summary' as const;
export const BILLING_CODES_TAB = 'billing codes' as const;

const AppointmentTabSchema = z
  .enum([TRANSCRIPT_TAB, SUMMARY_TAB, BILLING_CODES_TAB])
  .nullable()
  .transform((val) => val ?? TRANSCRIPT_TAB);

type AppointmentTab = z.infer<typeof AppointmentTabSchema>;

/**
 * Read or set the current tab in a type safe way
 */
export const useAppointmentTabNav = () => {
  const [currentTab, setCurrentTab] = useSearchParamValue('tab');
  const appointmentStatus = useAppointmentStatus();
  const appointmentMode = useAppointmentMode();

  const tab =
    !currentTab &&
    (appointmentStatus === 'COMPLETED' ||
      appointmentMode === DbEnums.AppointmentMode.MANUAL_CHARTING)
      ? SUMMARY_TAB
      : AppointmentTabSchema.parse(currentTab);

  const setTab = useCallback(
    (newTab: AppointmentTab) => {
      setCurrentTab(newTab);
    },
    [setCurrentTab],
  );

  // If a tab wasn't explicitly set, update the URL to reflect the current desired
  // state
  useEffect(() => {
    if (!currentTab) {
      setTab(tab);
    }
  }, [tab, currentTab, setTab]);

  return {
    tab,
    setTab,
  };
};
