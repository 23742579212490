import add from 'date-fns/add';
import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';

import {
  AgendaTimeline,
  AgendaTimelineGroup,
  DateNavigator,
  ShowAllAppointmentsToggle,
  groupOverlapItems,
  useShowAllAppointmentsToggle,
} from '@eluve/blocks';
import {
  Box,
  Calendar,
  Content,
  DetailsSidebar,
  DetailsSidebarProvider,
  DetailsSidebarTrigger,
  Divider,
  H1,
  HStack,
  Header,
  NewButton,
  SidebarTrigger,
  VStack,
  toast,
} from '@eluve/components';
import { useAppointmentCounts } from '@eluve/frontend-appointment-hooks';
import { useEHRSyncStore } from '@eluve/frontend-feature-ehr-sync';
import { useTenantIdFromParams } from '@eluve/session-helpers';
import { useUserIdFromSession } from '@eluve/session-helpers';
import { useIsFeatureFlagEnabled } from '@eluve/smart-blocks';

import { AppointmentAgendaTimelineItem } from './components/AppointmentAgendaTimelineItem';
import { AppointmentsLoader } from './components/AppointmentsLoader';
import { EhrDataImporter } from './components/EhrDataImporter';
import { EhrOnboarding } from './components/EhrOnboarding';
import { EmptyHomePageState } from './components/EmptyHomePageState';
import { NewAppointmentButton } from './components/NewAppointmentButton';
import { NoAppointmentPlaceholder } from './components/NoAppointmentPlaceholder';
import { useCalendarAppointmentData } from './useCalendarAppointmentData';
import { useImportDataFromEhr } from './useImportDataFromEhr';

export const HomePage: React.FC = () => {
  const lastSuccessfulEhrSyncAt = useEHRSyncStore(
    (s) => s.lastSuccessfulEhrSyncAt,
  );
  const userId = useUserIdFromSession();
  const [showAllAppointments] = useShowAllAppointmentsToggle();
  const tenantId = useTenantIdFromParams();

  const locationsEnabled = useIsFeatureFlagEnabled('LOCATIONS');

  const { data, loading, error, dateSearchParam, setDate, date } =
    useCalendarAppointmentData();

  const {
    totalAppointmentsCount,
    upcomingAppointmentsCount,
    refetch: refetchAppointmentCountData,
    loading: loadingAppointmentCountData,
  } = useAppointmentCounts({ days: 7, fromDate: dateSearchParam });

  const { isSupportedBrowser } = useImportDataFromEhr(tenantId!);
  const displayEmptyHomePageState =
    locationsEnabled &&
    isSupportedBrowser &&
    !showAllAppointments &&
    (!totalAppointmentsCount || !upcomingAppointmentsCount);

  const appointments = useMemo(() => {
    const appointments = data?.appointments ?? [];
    if (showAllAppointments) {
      return appointments;
    }
    return appointments.filter((a) => a.userId === userId);
  }, [data?.appointments, showAllAppointments, userId]);

  const appointmentsGrouped = useMemo(
    () =>
      groupOverlapItems(appointments, (a) => [
        new Date(a.startDate),
        new Date(a.endDate ?? add(new Date(a.startDate), { hours: 1 })),
      ]),
    [appointments],
  );

  useEffect(
    function displayToastOnError() {
      if (error) {
        toast.error('Failed to retrieve appointments');
      }
    },
    [error],
  );

  useEffect(
    function refetchOnEhrSync() {
      if (lastSuccessfulEhrSyncAt) {
        refetchAppointmentCountData();
      }
    },
    [lastSuccessfulEhrSyncAt, refetchAppointmentCountData],
  );

  return (
    <>
      <Helmet>
        <title>Home | Eluve</title>
      </Helmet>
      <DetailsSidebarProvider>
        <Header>
          <HStack>
            <SidebarTrigger />
            <H1>Overview</H1>
          </HStack>
          <HStack justify="end">
            <NewAppointmentButton />
            <DetailsSidebarTrigger>
              <NewButton icon={{ name: 'Calendar' }} type="outline" />
            </DetailsSidebarTrigger>
          </HStack>
        </Header>
        <Content>
          <VStack className="h-full overflow-auto" gap={0}>
            <Box
              vStack
              className="w-full flex-col gap-4 border-b border-borderPrimary p-3 sm:flex-row sm:items-center"
            >
              <ShowAllAppointmentsToggle />
              <DateNavigator
                onDateChanged={(newDate) => setDate(newDate)}
                startingDate={date}
                key={date.toDateString()}
              />
            </Box>
            <Box
              zStack
              className={`[data-empty="true"]:overflow-hidden relative size-full overflow-y-auto`}
              data-empty={displayEmptyHomePageState}
            >
              <AgendaTimeline day={date} showGrid={!displayEmptyHomePageState}>
                {appointmentsGrouped.map((appointments, index) => {
                  if (appointments.length === 1) {
                    // We know this is safe because we're checking the length
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    const appointment = appointments[0]!;
                    return (
                      <AppointmentAgendaTimelineItem
                        key={appointment.id}
                        appointmentId={appointment.id}
                      />
                    );
                  }

                  return (
                    <AgendaTimelineGroup key={index}>
                      {appointments.map((a) => (
                        <AppointmentAgendaTimelineItem
                          key={a!.id}
                          appointmentId={a!.id}
                        />
                      ))}
                    </AgendaTimelineGroup>
                  );
                })}
              </AgendaTimeline>
              {loading && loadingAppointmentCountData && <AppointmentsLoader />}
              {!displayEmptyHomePageState &&
                data?.appointments?.length === 0 && (
                  <NoAppointmentPlaceholder />
                )}
              {displayEmptyHomePageState && <EmptyHomePageState />}
            </Box>
          </VStack>
        </Content>
        <DetailsSidebar title="Calendar">
          <VStack align="center" gap={0}>
            <VStack align="center" className="pb-2 pt-6" gap={2}>
              <Calendar mode="single" selected={date} onSelect={setDate} />
            </VStack>
            <Divider />
            <VStack align="center" className="p-4 pt-2" gap={2}>
              {isSupportedBrowser && <EhrDataImporter />}
              {isSupportedBrowser && <EhrOnboarding />}
            </VStack>
          </VStack>
        </DetailsSidebar>
      </DetailsSidebarProvider>
    </>
  );
};
