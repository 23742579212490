import { useSuspenseQuery } from '@apollo/client';
import { PDFViewer } from '@react-pdf/renderer';
import format from 'date-fns/format';
import React, { Suspense } from 'react';

import '@eluve/date-utils';
import { graphql } from '@eluve/graphql.tada';
import { SuperBillLineItem, SuperbillPdf } from '@eluve/pdf-rendering';
import { formatHumanName } from '@eluve/utils';

import { appointmentBillingCodesFragment } from './AppointmentBillingCodes.operations';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const appointmentDataFragment = graphql(`
  fragment Metadata on Appointments @_unmask {
    __typename
    id
    startDate
    user {
      __typename
      id
      firstName
      lastName
    }
    patient {
      __typename
      id
      firstName
      lastName
      dateOfBirth
      sex
      external_patients_info {
        __typename
        id
        externalPatientId
        patientId
      }
    }
    location {
      __typename
      id
      name
      address
      phoneNumber
      inherited_logo_url {
        __typename
        locationId
        logoUrl
      }
    }
  }
`);

const appointmentPdfDataQuery = graphql(
  `
    query AppointmentPdfData(
      $tenantId: uuid!
      $appointmentId: uuid!
      $userId: uuid!
    ) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        ...Metadata
        ...BillingCodes
      }
      tenantUserSettingsByPk(tenantId: $tenantId, userId: $userId) {
        __typename
        tenantId
        userId
        providerDetails
      }
    }
  `,
  [appointmentDataFragment, appointmentBillingCodesFragment],
);

export const AppointmentSuperbillPdf: React.FC<{
  tenantId: string;
  appointmentId: string;
  userId: string;
}> = (props) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <AppointmentSuperbillPdfContent {...props} />
    </Suspense>
  );
};

export const AppointmentSuperbillPdfContent: React.FC<{
  tenantId: string;
  appointmentId: string;
  userId: string;
}> = ({ tenantId, appointmentId, userId }) => {
  const {
    data: {
      appointmentsByPk: appointment,
      tenantUserSettingsByPk: userSettings,
    },
  } = useSuspenseQuery(appointmentPdfDataQuery, {
    variables: { tenantId, appointmentId, userId },
  });

  if (!appointment) {
    throw new Error('Appointment not found');
  }

  const { location, patient, startDate, billingCodes } = appointment;

  const logoUrl = location?.inherited_logo_url?.logoUrl ?? undefined;

  let totalCharges = 0;

  const medicalCodes = billingCodes ?? [];

  const diagnosisCodes = medicalCodes
    .filter((c) => c?.medical_code?.codeType === 'ICD_10')
    .map((c, i) => ({
      id: c.id,
      number: `${i + 1}`,
      code: c.medical_code?.code ?? '',
      description: c.medical_code?.description ?? '',
    }));

  const lineItems = medicalCodes
    .filter((c) => c?.medical_code?.codeType === 'CPT')
    .map<SuperBillLineItem>((c) => {
      const quantity = c.quantity ?? 1;
      const lineItemTotal = c.price ? (c.price / 100) * quantity : -1;

      if (lineItemTotal > 0) {
        totalCharges += lineItemTotal;
      }

      return {
        code: c?.medical_code?.code ?? '',
        description: c?.medical_code?.description ?? '',
        total:
          lineItemTotal >= 0 ? currencyFormatter.format(lineItemTotal) : '--',
        fee: c.price ? currencyFormatter.format(c.price / 100) : '--',
        quantity: `${quantity}`,
        modifier: c.modifiers?.join(', ') ?? '--',
        diagnosisPointer:
          c.linked_codes
            ?.map((lc) => {
              const diagnosisCode = diagnosisCodes.find(
                (dc) => dc.id === lc.targetAppointmentBillingCodeId,
              );
              return diagnosisCode?.number;
            })
            .filter(Boolean)
            .join(', ') ?? '--',
      };
    });
  const providerName =
    userSettings?.providerDetails ||
    formatHumanName(appointment.user?.firstName, appointment.user?.lastName);

  return (
    <div>
      <PDFViewer className="h-[1024px] w-full">
        {SuperbillPdf({
          logoUrl,
          providerName,
          patientInfo: {
            name: formatHumanName(patient?.firstName, patient?.lastName),
            dob: patient?.dateOfBirth ?? '--',
          },
          location: {
            address: location?.address,
            phoneNumber: location?.phoneNumber,
          },
          date: format(new Date(startDate), 'P'),
          diagnosisCodes,
          lineItems,
          totalCharges: currencyFormatter.format(totalCharges),
        })}
      </PDFViewer>
    </div>
  );
};
