import { graphql } from '@eluve/graphql.tada';

export const defaultSettingsFragment = graphql(`
  fragment DefaultSettings on DefaultTenantUserSettings @_unmask {
    __typename
    tenantId
    inputLanguage
    outputLanguage
    preferredDateFormat
  }
`);

export const tenantDefaultSettings = graphql(
  `
    fragment TenantDefaultSettings on Tenants @_unmask {
      __typename
      id
      default_tenant_user_settings {
        ...DefaultSettings
      }
    }
  `,
  [defaultSettingsFragment],
);

export const getDefaultTenantUserSettingsQuery = graphql(
  `
    query getDefaultTenantUserSettings($tenantId: uuid!) {
      tenantsByPk(id: $tenantId) {
        ...TenantDefaultSettings
      }
    }
  `,
  [tenantDefaultSettings],
);

export const setDefaultTenantUserLanguageMutation = graphql(
  `
    mutation setDefaultTenantUserLanguage(
      $tenantId: uuid!
      $inputLanguage: String!
      $outputLanguage: String!
    ) {
      updateDefaultTenantUserSettingsByPk(
        pkColumns: { tenantId: $tenantId }
        _set: { inputLanguage: $inputLanguage, outputLanguage: $outputLanguage }
      ) {
        __typename
        tenantId
        ...DefaultSettings
      }
    }
  `,
  [defaultSettingsFragment],
);

export const updatePreferredDateFormatMutation = graphql(
  `
    mutation setDefaultTenantUserLanguage(
      $tenantId: uuid!
      $preferredDateFormat: String!
    ) {
      updateDefaultTenantUserSettingsByPk(
        pkColumns: { tenantId: $tenantId }
        _set: { preferredDateFormat: $preferredDateFormat }
      ) {
        __typename
        tenantId
        ...DefaultSettings
      }
    }
  `,
  [defaultSettingsFragment],
);
