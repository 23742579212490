import { useSuspenseQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import {
  getAppointmentDetailsQuery,
  useAppointmentStatus,
  useIsAppointmentSigned,
} from '@eluve/frontend-appointment-hooks';
import { useEHRSyncStore } from '@eluve/frontend-feature-ehr-sync';
import { useIsFeatureFlagEnabled } from '@eluve/smart-blocks';

import { ActiveAppointmentLayout } from './ActiveAppointmentLayout';
import { CancelledAppointmentLayout } from './CancelledAppointmentLayout';
import { NotStartedAppointmentLayout } from './NotStartedAppointmentLayout';
import { SignedAppointmentLayout } from './SignedAppointmentLayout';

export const AppointmentLayout: React.FC = () => {
  const { appointmentId, tenantId } = useParams() as {
    tenantId: string;
    appointmentId: string;
  };
  const skipNotStartedAppointmentLayout = useIsFeatureFlagEnabled(
    'SKIP_NOT_STARTED_APPOINTMENT_LAYOUT',
  );

  const lastSuccessfulEhrSyncAt = useEHRSyncStore(
    (s) => s.lastSuccessfulEhrSyncAt,
  );

  const {
    data: { appointmentsByPk: appointment },
    refetch,
  } = useSuspenseQuery(getAppointmentDetailsQuery, {
    variables: {
      tenantId,
      appointmentId,
    },
  });

  const status = useAppointmentStatus();

  const isAppointmentSigned = useIsAppointmentSigned();

  useEffect(
    function refetchOnEhrSync() {
      if (lastSuccessfulEhrSyncAt) {
        refetch();
      }
    },
    [lastSuccessfulEhrSyncAt, refetch],
  );

  if (appointment === null) {
    return <Navigate to="/404" />;
  }

  if (status === 'CANCELLED') {
    return <CancelledAppointmentLayout appointmentId={appointmentId} />;
  }

  if (status === 'NOT_STARTED' && !skipNotStartedAppointmentLayout) {
    return (
      <NotStartedAppointmentLayout
        key={appointment?.id}
        appointmentId={appointmentId}
        tenantId={tenantId}
      />
    );
  }

  if (!isAppointmentSigned) {
    return (
      <ActiveAppointmentLayout
        key={appointment?.id}
        appointmentId={appointmentId}
        tenantId={tenantId}
      />
    );
  }

  return <SignedAppointmentLayout appointmentId={appointmentId} />;
};
