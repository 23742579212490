import { PropsWithChildren, useState } from 'react';

import { MicrophonePermissionState } from '@eluve/mic-utils';

import { MicrophoneRequestAlert } from './MicrophoneRequestAlert';

interface MicrophonePermissionProvider extends PropsWithChildren {
  fallback?: React.ReactNode;
}

export const MicrophonePermissionProvider: React.FC<
  MicrophonePermissionProvider
> = ({ children, fallback = null }) => {
  const [permissionState, setPermissionState] =
    useState<MicrophonePermissionState>('undetermined');

  return (
    <>
      <MicrophoneRequestAlert onPermissionStateChange={setPermissionState} />
      {permissionState === 'granted' ? children : fallback}
    </>
  );
};
