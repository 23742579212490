import { Text, View } from '@react-pdf/renderer';
// eslint-disable-next-line no-restricted-imports
import { tv } from 'tailwind-variants';

import { formatHumanName } from '@eluve/utils';

import { tw } from '../pdf-tw';
import { commonStyles } from '../styles';

const styles = tv({
  slots: {
    patientName: commonStyles.boldText(),
  },
})();

export const PatientDetails: React.FC<{
  patient?: {
    firstName?: string;
    lastName?: string;
    dateOfBirth?: string;
    phoneNumber?: string;
    email?: string;
  };
}> = ({ patient }) => {
  const patientName = formatHumanName(patient?.firstName, patient?.lastName);
  const dob = patient?.dateOfBirth ? new Date(patient.dateOfBirth) : null;
  const formattedDob = dob ? dob.toLocaleDateString() : '';
  const patientInfoLines = [
    formattedDob ? `Date of birth: ${formattedDob}` : '',
    patient?.phoneNumber ? `Phone: ${patient.phoneNumber}` : '',
    patient?.email ? `Email: ${patient.email}` : '',
  ].filter(Boolean);

  return (
    <View>
      <Text style={tw(styles.patientName())}>{patientName}</Text>
      {patientInfoLines.map((line, i) => (
        <Text key={i} style={tw(commonStyles.text())}>
          {line}
        </Text>
      ))}
    </View>
  );
};
