import { useMutation } from '@apollo/client';
import { produce } from 'immer';
import noop from 'lodash/noop';
import { useEffect, useMemo, useRef } from 'react';
import { v4 } from 'uuid';

import { cacheUtils, useCompleteFragment } from '@eluve/apollo-client';
import { Box, HStack, VStack, toast } from '@eluve/components';
import {
  amendmentFragment,
  appointmentDoctorInteractionFragment,
  appointmentPatientFragment,
  baseAppointmentInfoFragment,
  useAmendments,
  useAppointmentLocationId,
  useAppointmentPatient,
  useIsAppointmentReadonly,
  useIsUserAppointmentOwner,
  useSummary,
  useSummaryContentRef,
} from '@eluve/frontend-appointment-hooks';
import {
  AppointmentBillingCodes,
  MedicineRecommendations,
} from '@eluve/frontend-feature-appointment';
import { graphql } from '@eluve/graphql.tada';
import { useAssignedTenantIdFromParams } from '@eluve/session-helpers';
import {
  DynamicArtifactRenderer,
  DynamicArtifactSaveContextProps,
  FeatureFlaggedComponent,
  SummaryFeedbackModal,
  SummarySectionMarkdown,
} from '@eluve/smart-blocks';

export const signedAppointmentLayoutFragment = graphql(
  `
    fragment SignedAppointmentLayout on Appointments {
      ...baseAppointmentInfo
      ...appointmentPatient
      ...appointmentDoctorInteraction
    }
  `,
  [
    baseAppointmentInfoFragment,
    appointmentPatientFragment,
    appointmentDoctorInteractionFragment,
  ],
);

interface SignedSummaryResultProps {
  appointmentId: string;
}

const createChartAmendmentMutation = graphql(`
  mutation createChartAmendment($amendmentId: uuid!, $keyPath: String!) {
    insertChartAmendmentsOne(
      object: { amendmentId: $amendmentId, keyPath: $keyPath }
    ) {
      __typename
      id
      amendmentId
      keyPath
    }
  }
`);

export const SignedSummaryResult: React.FC<SignedSummaryResultProps> = ({
  appointmentId,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { setContentRef } = useSummaryContentRef();
  const tenantId = useAssignedTenantIdFromParams();
  const locationId = useAppointmentLocationId();
  const isReadonly = useIsAppointmentReadonly();
  const isUserApptOwner = useIsUserAppointmentOwner();

  useEffect(() => {
    if (contentRef.current) {
      setContentRef(contentRef.current);
    }

    return () => {
      setContentRef(null);
    };
  }, [contentRef, setContentRef]);

  const [createChartAmendment] = useMutation(createChartAmendmentMutation, {
    onError: () => toast.error('Failed to create amendment'),
    optimisticResponse: (data) => ({
      insertChartAmendmentsOne: {
        __typename: 'ChartAmendments' as const,
        id: v4(),
        amendmentId: data.amendmentId,
        keyPath: data.keyPath,
      },
    }),
    update: (_, { data }) => {
      if (!data?.insertChartAmendmentsOne) {
        return null;
      }
      cacheUtils.updateFragment(
        {
          fragment: amendmentFragment,
          key: { id: data?.insertChartAmendmentsOne?.amendmentId },
        },
        (existing) => {
          if (!existing || !data?.insertChartAmendmentsOne) {
            return null;
          }
          return produce(existing, (draft) => {
            draft.chart_amendments.push({
              __typename: 'ChartAmendments' as const,
              id: data.insertChartAmendmentsOne!.id,
              value: null,
              keyPath: data.insertChartAmendmentsOne!.keyPath,
              amendmentId: data.insertChartAmendmentsOne!.amendmentId,
              tenantId,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
            });
          });
        },
      );
    },
  });

  const appointment = useCompleteFragment({
    fragment: signedAppointmentLayoutFragment,
    key: {
      id: appointmentId,
    },
  });

  const patient = useAppointmentPatient();

  const interaction = appointment.doctor_interaction!;

  const { summary, llmOutputId, isSummaryAvailable } = useSummary();

  const { amendments: draftAmendments } = useAmendments(appointmentId, {
    draftOnly: true,
  });
  const amendmentId = draftAmendments.length
    ? draftAmendments[0]!.id
    : undefined;
  const insertChartAmendment:
    | DynamicArtifactSaveContextProps['insertChartAmendment']
    | undefined = useMemo(() => {
    if (!amendmentId) {
      return undefined;
    }
    return async (keyPath: string) => {
      await createChartAmendment({
        variables: {
          amendmentId,
          keyPath,
        },
      });
    };
  }, [createChartAmendment, amendmentId]);

  return (
    <VStack justify="between" wFull align="stretch">
      <HStack>
        <SummaryFeedbackModal
          appointmentId={appointmentId}
          llmOutputId={llmOutputId}
        />
      </HStack>
      <Box vStack className="items-stretch gap-4 md:gap-6" ref={contentRef}>
        {isSummaryAvailable && (
          <DynamicArtifactRenderer
            blocks={summary!.blocks}
            isReadonly={true}
            insertChartAmendment={insertChartAmendment}
            email={patient?.external_patients_info?.[0]?.email}
            canViewAndEditAmendments={isUserApptOwner}
          />
        )}

        {interaction.additionalNotes && (
          <SummarySectionMarkdown
            appointmentId={appointmentId}
            key="AdditionalNotes"
            // TODO(jesse)[ELU-949]: This should be presentational only and doesn't need to reuse
            // the same component. The fact that we're passing in noop is a red flag
            summaryKey={`AdditionalNotes` as any}
            sectionTitle="Additional Notes"
            content={interaction.additionalNotes}
            disabled={true}
            handleContentChange={noop}
          />
        )}

        <FeatureFlaggedComponent flag="BILLING_CODES">
          <AppointmentBillingCodes
            isReadonly={isReadonly}
            appointmentId={appointmentId}
            locationId={locationId}
            tenantId={tenantId}
          />
        </FeatureFlaggedComponent>

        <FeatureFlaggedComponent flag="MEDICATION">
          <MedicineRecommendations
            isReadonly={isReadonly}
            appointmentId={appointmentId}
            tenantId={tenantId}
          />
        </FeatureFlaggedComponent>
      </Box>
    </VStack>
  );
};
