import { Check } from 'lucide-react';
import React from 'react';

import { Box, H2, NewButton, P, VStack } from '@eluve/components';

import { useChat } from './ConversationContextProvider';

interface ConversationCompletedProps {
  providerName?: string;
}

export const ConversationCompleted: React.FC<ConversationCompletedProps> = (
  props,
) => {
  const { isCompleted } = useChat();
  if (!isCompleted) return null;
  return (
    <VStack className="size-full bg-white p-8 pt-32" align="center" gap={2}>
      <Box className="mb-4 flex size-14 items-center justify-center rounded-full bg-[#3fa378] text-white">
        <Check />
      </Box>
      <H2>Thank you!</H2>
      <P>{props.providerName && <>{props.providerName} will see you soon</>}</P>
      <Box className="flex grow items-center justify-center">
        <NewButton
          onClick={() => window.close()}
          text="Close Window"
          icon={{ name: 'X' }}
        />
      </Box>
    </VStack>
  );
};
