import { Extension } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';
import { Plugin, Transaction } from '@tiptap/pm/state';
import { PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';

// Footnote regex patterns
const FOOTNOTE_REFERENCE_REGEX = /\[\^(\d+)\]/g;
const FOOTNOTE_DEFINITION_REGEX =
  /(\[\^(\d+)\]:\s+)("[^"]+"|[^"]\S+(?:\s+\S+)*)/g;

export const Footnote = Extension.create({
  name: 'footnotes',

  addProseMirrorPlugins() {
    const pluginKey = new PluginKey('footnotes');
    function isClosingBracketTransaction(tr: Transaction) {
      if (tr.docChanged) {
        for (const step of tr.steps) {
          if ('from' in step && 'to' in step && 'slice' in step) {
            const replaceStep = step as {
              slice: {
                content: {
                  size: number;
                  textBetween: (from: number, to: number) => string;
                };
              };
            };

            if (replaceStep.slice.content.size === 1) {
              const insertedText = replaceStep.slice.content.textBetween(
                0,
                replaceStep.slice.content.size,
              );
              return insertedText === ']';
            }
          }
        }
      }
      return false;
    }
    return [
      new Plugin({
        key: pluginKey,
        state: {
          init(_, { doc }) {
            return createDecorations(doc);
          },
          apply(tr, set) {
            if (tr.docChanged && isClosingBracketTransaction(tr)) {
              return createDecorations(tr.doc);
            }
            return set.map(tr.mapping, tr.doc);
          },
        },
        props: {
          decorations(state) {
            return this.getState(state);
          },
        },
      }),
    ];
  },
});

function createDecorations(doc: Node) {
  const decorations: Decoration[] = [];
  doc.descendants((node: Node, pos: number) => {
    if (node.isText && node.text) {
      if (node.text.startsWith('[^')) {
        for (const match of node.text.matchAll(FOOTNOTE_DEFINITION_REGEX)) {
          const fullMatch = match[0];
          const markerText = match[1];
          if (!markerText) continue;

          const start = pos + match.index;
          const end = start + fullMatch.length;
          const markerEnd = start + markerText.length;

          // Create decoration for the marker
          decorations.push(
            Decoration.inline(start, markerEnd, {
              class: 'footnote-definition-marker',
              nodeName: 'span',
              style:
                'vertical-align: super; font-size: 0.75em; cursor: help; color: #0066cc;',
            }),
            Decoration.inline(start, end, {
              class: 'footnote-definition-wrapper',
            }),
          );
        }
      } else {
        const regex = new RegExp(FOOTNOTE_REFERENCE_REGEX);
        for (const match of node.text.matchAll(regex)) {
          const start = pos + match.index;
          const end = start + match[0].length;
          const footnoteId = match[1];

          decorations.push(
            Decoration.inline(start, end, {
              class: 'footnote-reference',
              'data-footnote-id': footnoteId,
              style:
                'vertical-align: super; font-size: 0.75em; color: #0066cc;',
            }),
          );
        }
      }
    }
  });
  return DecorationSet.create(doc, decorations);
}
