import React from 'react';

import { useApiClient } from '@eluve/api-client-provider';
import { useCompleteFragment } from '@eluve/apollo-client';
import { NewButton, VStack, textStyles } from '@eluve/components';
import {
  useAppointmentContext,
  useAppointmentMode,
  useUpdateAppointmentMode,
} from '@eluve/frontend-appointment-hooks';
import {
  AppointmentLanguageSelector,
  PendingTranscriptionLoader,
} from '@eluve/frontend-feature-appointment';
import { TenantOrUserFeatureFlaggedComponent } from '@eluve/smart-blocks';

import {
  useTranscriptionMachineActor,
  useTranscriptionMachineSelector,
} from '../machines/transcription/TranscriptionMachineProvider';

import { AppointmentDetailsFragment } from './AppointmentDetails';
import { AppointmentInSessionTranscript } from './AppointmentInSessionTranscript';
import { UploadingFileLoader } from './UploadingFileLoader';

export const AppointmentTranscription: React.FC<{
  compact?: boolean;
}> = ({ compact = false }) => {
  const { appointmentId, tenantId } = useAppointmentContext();
  const isCurrentAppointmentInSession = useTranscriptionMachineSelector(
    (s) =>
      s.matches({ InSession: 'Recording' }) &&
      s.context.sessionAppointmentId === appointmentId,
  );

  const { send } = useTranscriptionMachineActor();
  const apiClient = useApiClient();

  const appointmentDetails = useCompleteFragment({
    fragment: AppointmentDetailsFragment,
    key: {
      id: appointmentId,
    },
  });

  const isInManualChartingMode = useAppointmentMode() === 'MANUAL_CHARTING';
  const updateAppointmentMode = useUpdateAppointmentMode();

  const onStartRecording = () => {
    updateAppointmentMode({
      variables: {
        tenantId,
        appointmentId,
        mode: 'AUDIO_COPILOT',
      },
    });
  };

  if (isInManualChartingMode) {
    return (
      <VStack wFull align="center" className="mt-9 gap-8 py-10">
        <VStack gap={3} align="center" className="max-w-[500px]">
          <h3
            className={textStyles.title({
              size: 'l',
              weight: 'semibold',
            })}
          >
            Want to automate your notes instead?
          </h3>
          <p
            className={textStyles.title({
              size: 's',
              weight: 'medium',
              color: 'tertiary',
              className: 'mb-2 text-center',
            })}
          >
            You can start recording but it will overwrite your existing manual
            notes.
          </p>
          <NewButton text="Start Recording" onClick={onStartRecording} />
        </VStack>
      </VStack>
    );
  }

  return (
    <>
      <VStack className="rounded-lg border border-gray-5 p-5">
        <VStack
          wFull
          gap={3}
          justify="between"
          className="items-start md:flex-row md:items-center"
        >
          <div>
            <TenantOrUserFeatureFlaggedComponent flag="MULTILINGUAL_SUPPORT">
              <AppointmentLanguageSelector
                inline
                inputLanguage
                onInputLanguageChange={(newLanguage) => {
                  if (appointmentDetails.inputLanguage === newLanguage) {
                    return;
                  }
                  if (isCurrentAppointmentInSession) {
                    send({ type: 'RECONNECT' });
                  }
                  apiClient.transcription.batchTranscribe({
                    params: {
                      tenantId,
                    },
                    body: {
                      appointmentId,
                    },
                  });
                }}
              />
            </TenantOrUserFeatureFlaggedComponent>
          </div>
        </VStack>

        <AppointmentInSessionTranscript
          compact={compact}
          inSession={isCurrentAppointmentInSession}
        />
      </VStack>

      {!isCurrentAppointmentInSession && (
        <>
          <PendingTranscriptionLoader
            tenantId={tenantId}
            appointmentId={appointmentId}
          />
          <UploadingFileLoader appointmentId={appointmentId} />
        </>
      )}
    </>
  );
};
