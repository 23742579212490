import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AvatarIntakePage } from '@eluve/frontend-feature-avatar';

export const createPatientRoutes = (
  path: string,
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return [
    {
      path,
      element: (
        <Suspense fallback={'Loading...'}>
          <ApolloProvider client={client}>
            <Outlet />
          </ApolloProvider>
        </Suspense>
      ),
      children: [
        {
          path: ':tenantId/:appointmentId',
          element: <AvatarIntakePage />,
        },
      ],
    },
  ];
};
