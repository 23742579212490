import { useSubscription } from '@apollo/client';

import { graphql } from '@eluve/graphql.tada';

import { useAppointmentContext } from './appointment.context';
import {
  appointmentConversationsFragment,
  appointmentPatientFragment,
} from './operations';

export const listenForAppointmentPatientOverviewsSubscription = graphql(
  `
    subscription listenForPatientOverviewsSubscription(
      $tenantId: uuid!
      $appointmentId: uuid!
    ) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        __typename
        id
        ...appointmentPatient
        ...appointmentConversations
      }
    }
  `,
  [appointmentPatientFragment, appointmentConversationsFragment],
);

export const useListenForAppointmentPatient = (shouldSubscribe = true) => {
  const { tenantId, appointmentId } = useAppointmentContext();

  return useSubscription(listenForAppointmentPatientOverviewsSubscription, {
    variables: {
      tenantId,
      appointmentId,
    },
    skip: !shouldSubscribe || !tenantId || !appointmentId,
  });
};
