import React from 'react';

interface EluveLogoProps {
  size?: number;
  showBackground?: boolean;
}

export const EluveLogo: React.FC<EluveLogoProps> = ({
  showBackground,
  size = 32,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {showBackground && <rect width="16" height="16" rx="2" fill="white" />}
      <path
        d="M8.47778 13.5C4.87778 13.5 2.5 11.3 2.5 8C2.5 4.65849 4.96667 2.5 8.61111 2.5C11.3444 2.5 13.3222 3.68302 13.3222 5.38491C13.3222 7.46038 10.5222 8.29057 7.58889 8.4566C8.03333 9.61887 8.87778 10.6151 10.3 10.6151C11.1 10.6151 11.6111 10.283 12.0556 9.99245C12.4333 9.7434 12.6778 9.61887 12.9444 9.61887C13.2556 9.61887 13.5 9.7849 13.5 10.3245C13.5 12.1094 11.3444 13.5 8.47778 13.5ZM7.12222 5.8C7.12222 6.2566 7.16667 6.83774 7.3 7.41887C8.76667 7.23207 9.27778 6.42264 9.27778 5.42641C9.27778 4.49245 8.85556 3.64151 8.16667 3.64151C7.34444 3.64151 7.12222 4.78302 7.12222 5.8Z"
        fill="#3C3C3C"
      />
    </svg>
  );
};
