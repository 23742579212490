import { Footer, HStack } from '@eluve/components';
import { useIsAppointmentSigned } from '@eluve/frontend-appointment-hooks';

import { AppointmentLastSaved } from './AppointmentLastSaved';
import { AppointmentPDFOutputControls } from './AppointmentPDFOutputControls';
import { SignAndSyncAppointmentButton } from './SignAndSyncAppointmentButton';
import { SignedSummaryFooter } from './SignedSummaryFooter';

export const AppointmentSummaryFooter: React.FC = () => {
  const isAppointmentSigned = useIsAppointmentSigned();
  return (
    <Footer>
      <HStack
        justify="between"
        gap={1}
        className="mx-auto max-w-screen-lg px-2"
      >
        <AppointmentLastSaved />

        <HStack wFull={false}>
          {isAppointmentSigned ? (
            <SignedSummaryFooter />
          ) : (
            <>
              <AppointmentPDFOutputControls />
              <SignAndSyncAppointmentButton />
            </>
          )}
        </HStack>
      </HStack>
    </Footer>
  );
};
