import { ForwardedRef, KeyboardEvent, forwardRef } from 'react';
import { z } from 'zod';

import { useForwardedRef } from '@eluve/utility-hooks';

import { Input } from './input';

export type CurrencyInputProps = {
  onBlur?: () => void;
  onChange: (newValue: string) => void;
  ref: ForwardedRef<HTMLInputElement>;
  value?: string;
};

export const currencyStringToCentsSchema = z
  .string()
  .refine((p) => !isNaN(parseFloat(p)))
  .transform((p) => Math.floor(Number(p) * 100));

export const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  ({ onBlur, onChange, value }, ref) => {
    const inputRef = useForwardedRef(ref);

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Escape') {
        inputRef.current?.blur();
      }
    };

    return (
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-sm text-muted-foreground">$</span>
        </div>
        <Input
          className="pl-7 [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          min={0.01}
          onBlur={() => {
            if (onBlur) {
              onBlur();
            }
          }}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          onKeyDown={handleKeyDown}
          placeholder="0.00"
          ref={inputRef}
          required
          step={0.01}
          type="number"
          value={value ?? ''}
        />
      </div>
    );
  },
);
