import { useMutation, useSubscription, useSuspenseQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { Plus, SaveIcon, Trash } from 'lucide-react';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import {
  Box,
  Button,
  Form,
  H3,
  HStack,
  Input,
  Label,
  NewButton,
  RadioGroup,
  RadioGroupItem,
  toast,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { useNamedLogger } from '@eluve/logger';

import CSVForFacts from './CSVForFacts';

const artifactFactsFormSchema = z.object({
  facts: z.array(
    z.object({
      id: z.string().uuid(),
      statement: z.string(),
      weightMultiplier: z.coerce.number().max(1),
      expectedOutput: z.coerce.boolean(),
      isVerified: z.coerce.boolean(),
    }),
  ),
});

type EditArtifactFactsFormData = z.infer<typeof artifactFactsFormSchema>;

const formDefaults: Partial<EditArtifactFactsFormData> = {
  facts: [],
};

export const sourceArtifactFactFragment = graphql(`
  fragment Fact on EvalSourceArtifactFacts @_unmask {
    __typename
    id
    sourceArtifactId
    statement
    expectedOutput
    weightMultiplier
    isVerified
  }
`);

const sourceArtifactFactsQuery = graphql(
  `
    query getSourceArtifactFacts($sourceArtifactId: uuid!) {
      evalSourceArtifactByPk(id: $sourceArtifactId) {
        __typename
        id
        facts {
          ...Fact
        }
      }
    }
  `,
  [sourceArtifactFactFragment],
);

const listenForFactsSubscription = graphql(
  `
    subscription listenForFacts($sourceArtifactId: uuid!) {
      evalSourceArtifactByPk(id: $sourceArtifactId) {
        __typename
        id
        facts {
          ...Fact
        }
      }
    }
  `,
  [sourceArtifactFactFragment],
);

const upsertFactsMutation = graphql(
  `
    mutation upsertFacts($facts: [EvalSourceArtifactFactsInsertInput!]!) {
      insertEvalSourceArtifactFacts(
        objects: $facts
        onConflict: {
          constraint: source_artifact_facts_pkey
          updateColumns: [
            statement
            weightMultiplier
            expectedOutput
            isVerified
          ]
        }
      ) {
        returning {
          ...Fact
        }
      }
    }
  `,
  [sourceArtifactFactFragment],
);

const deleteFactMutation = graphql(`
  mutation deleteFact($factId: uuid!) {
    deleteEvalSourceArtifactFactsByPk(id: $factId) {
      id
    }
  }
`);

export interface EditArtifactFactsFormProps {
  sourceArtifactId: string;
}

export const EditArtifactFactsForm: React.FC<EditArtifactFactsFormProps> = ({
  sourceArtifactId,
}) => {
  const logger = useNamedLogger('edit-artifact-facts-form');

  const { data } = useSuspenseQuery(sourceArtifactFactsQuery, {
    variables: {
      sourceArtifactId,
    },
  });

  const facts = data?.evalSourceArtifactByPk?.facts.map((f) => ({
    id: f.id,
    statement: f.statement || '',
    weightMultiplier: f.weightMultiplier,
    expectedOutput: f.expectedOutput,
    isVerified: f.isVerified,
  }));

  const defaultValues = facts?.length ? { facts } : formDefaults;

  const form = useForm<EditArtifactFactsFormData>({
    resolver: zodResolver(artifactFactsFormSchema),
    defaultValues,
    mode: 'all',
  });

  useSubscription(listenForFactsSubscription, {
    variables: {
      sourceArtifactId,
    },
    onData: ({ data }) => {
      form.reset({
        facts:
          data?.data?.evalSourceArtifactByPk?.facts.map((f) => ({
            id: f.id,
            statement: f.statement || '',
            weightMultiplier: f.weightMultiplier,
            expectedOutput: f.expectedOutput,
            isVerified: f.isVerified,
          })) || [],
      });
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'facts',
  });

  const [upsertFacts] = useMutation(upsertFactsMutation, {
    onCompleted: () => {
      toast.success(`Successfully updated facts for ${sourceArtifactId}`);
    },
    onError: () => toast.error('Failed to update facts'),
  });

  const [deleteFact] = useMutation(deleteFactMutation);

  const onSubmit = async ({ facts }: EditArtifactFactsFormData) => {
    await upsertFacts({
      variables: {
        facts: facts.map((f) => ({
          ...f,
          sourceArtifactId,
        })),
      },
    });
  };

  const handleFactDelete = async (index: number, factId: string) => {
    await deleteFact({
      variables: {
        factId,
      },
      onCompleted: () => {
        toast.success('Successfully deleted fact');
        remove(index);
      },
      onError: () => toast.error('Failed to update facts'),
    });
  };

  const factSchema = z.object({
    expectedOutput: z.preprocess(
      (val) => val?.toString().trim().toUpperCase() === 'TRUE',
      z.boolean(),
    ),
    weightMultiplier: z.preprocess(
      (val) => Number(val),
      z.number().min(0).max(1),
    ),
    isVerified: z.preprocess(
      (val) => val?.toString().trim().toUpperCase() === 'TRUE',
      z.boolean(),
    ),
    statement: z.string().min(5, 'Statement is required'),
  });

  const handleFactImport = (csvFacts: string) => {
    if (!csvFacts.trim()) {
      toast.error('CSV input is empty');
      return;
    }

    const lines = csvFacts.split('\n').filter((line) => line.trim() !== '');
    const parsedFacts = [];
    for (let index = 0; index < lines.length; index++) {
      const line = lines[index];
      if (line === undefined || line.trim() === '') {
        continue;
      }
      const parts = line.split('\t');
      if (parts.length !== 4) {
        toast.error(
          `Invalid format at line ${index + 1}. Expected 4 parts separated by tabs`,
        );
        return;
      }

      const result = factSchema.safeParse({
        expectedOutput: parts[0],
        weightMultiplier: parts[1],
        isVerified: parts[2],
        statement: parts[3]?.trim() ?? '', // if statement is empty will fail validation
      });

      if (!result.success) {
        const errorMsg = `Error at line ${index + 1}: ${result.error.message}`;
        toast.error(errorMsg);
        return;
      }

      parsedFacts.push({ id: uuidv4(), ...result.data });
    }
    if (parsedFacts.length > 0) {
      parsedFacts.forEach((fact) => {
        append(fact);
      });
      toast.success('Facts imported successfully');
    } else {
      toast.error('No valid facts were imported.');
    }
  };

  return (
    <Box vStack className="w-full">
      <HStack>
        <H3>Facts</H3>
        <NewButton
          onClick={() => {
            navigator.clipboard.writeText(
              (facts ?? [])
                .map(
                  (f) =>
                    `${f.expectedOutput ? 'TRUE' : 'FALSE'}\t${f.weightMultiplier}\t${f.isVerified}\t${f.statement}`,
                )
                .join('\n'),
            );
            toast.success('Facts copied to clipboard');
          }}
          type="outline"
          iconOnly
          icon={{ name: 'Copy' }}
        />
        <CSVForFacts setFacts={handleFactImport} />
      </HStack>

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit, () => {
            toast.error('Form failed validation');
            logger.debug('Invalid form submission attempted');
          })}
          className="w-full"
        >
          <fieldset>
            <div className="mb-10 grid gap-6">
              {fields.length > 0 && (
                <div className="mb-4 grid grid-cols-[8fr_2fr_1fr_2fr_1fr] items-center gap-6">
                  <span className="font-semibold">Statement</span>
                  <span className="font-semibold">Expected Output</span>
                  <span className="font-semibold">Multiplier</span>
                  <span className="font-semibold">Is Verified</span>
                  <span className="font-semibold">Actions</span>
                </div>
              )}
              {fields.map((field, index) => (
                <div
                  key={field.id}
                  className="grid  grid-cols-[8fr_2fr_1fr_2fr_1fr] items-center gap-6"
                >
                  <Input
                    key={`${field.id}.fact`}
                    {...form.register(`facts.${index}.statement`)}
                  />

                  <RadioGroup
                    {...form.register(`facts.${index}.expectedOutput`)}
                    className="flex gap-2"
                    defaultValue={String(
                      form.getValues(`facts.${index}.expectedOutput`),
                    )}
                  >
                    <RadioGroupItem
                      id={`expected-true-${index}`}
                      value="true"
                    />
                    <Label htmlFor={`expected-true-${index}`} className="pr-3">
                      True
                    </Label>
                    <RadioGroupItem
                      id={`expected-false-${index}`}
                      value="false"
                    />
                    <Label htmlFor={`expected-false-${index}`}>False</Label>
                  </RadioGroup>

                  <Input
                    type="number"
                    step="0.1"
                    key={`${field.id}.weightMultiplier`}
                    className="max-w-[80px]"
                    {...form.register(`facts.${index}.weightMultiplier`)}
                  />

                  <RadioGroup
                    {...form.register(`facts.${index}.isVerified`)}
                    className="flex gap-2"
                    defaultValue={String(
                      form.getValues(`facts.${index}.isVerified`),
                    )}
                  >
                    <RadioGroupItem
                      id={`verified-true-${index}`}
                      value="true"
                    />
                    <Label htmlFor={`verified-true-${index}`} className="pr-3">
                      True
                    </Label>
                    <RadioGroupItem
                      id={`verified-false-${index}`}
                      value="false"
                    />
                    <Label htmlFor={`verified-false-${index}`}>False</Label>
                  </RadioGroup>

                  <Button
                    variant="destructive"
                    type="button"
                    size="icon"
                    disabled={fields.length === 1}
                    onClick={() =>
                      handleFactDelete(
                        index,
                        form.getValues(`facts.${index}.id`),
                      )
                    }
                  >
                    <Trash />
                  </Button>
                </div>
              ))}
              <Box hStack className="justify-between">
                <Button
                  size="sm"
                  type="button"
                  variant="secondary"
                  onClick={() =>
                    append({
                      id: uuidv4(),
                      statement: '',
                      weightMultiplier: 0.5,
                      expectedOutput: true,
                      isVerified: true,
                    })
                  }
                >
                  <Plus className="mr-2" />
                  Add a fact
                </Button>
                <Box hStack>
                  <Button
                    size="sm"
                    type="submit"
                    disabled={!form.formState.isDirty}
                  >
                    <SaveIcon className="pr-1" /> Save Changes
                  </Button>
                  <Button
                    size="sm"
                    type="button"
                    variant="destructive"
                    disabled={!form.formState.isDirty}
                    onClick={() => form.reset()}
                  >
                    <Trash className="pr-1" /> Discard Changes
                  </Button>
                </Box>
              </Box>
            </div>
          </fieldset>
        </form>
      </Form>
    </Box>
  );
};
