import {
  FCC,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@eluve/components';

/**
 * A wrapper component for simple tooltip solution that display a text label when hovered over child
 * @param label The label to display in the tooltip.
 * @param children The children component to wrap with a tooltip.
 *
 * behind the scenes,
 * ```tsx
 * <Tooltip>
 *   <TooltipTrigger asChild>{children}</TooltipTrigger>
 *   <TooltipContent sideOffset={8}>{label}</TooltipContent>
 * </Tooltip>
 * ```
 */

export const TooltipLabel: FCC<{
  label: React.ReactNode;
  asChild?: boolean;
  isPrivate?: boolean;
  showArrow?: boolean;
  tooltipContentProps?: React.ComponentPropsWithoutRef<typeof TooltipContent>;
  tooltipArrowProps?: React.ComponentPropsWithoutRef<typeof TooltipArrow>;
}> = ({
  label,
  children,
  asChild = true,
  isPrivate = false,
  showArrow = false,
  tooltipArrowProps,
  tooltipContentProps,
}) => {
  if (!label) {
    return children;
  }
  return (
    <Tooltip delayDuration={100}>
      <TooltipTrigger asChild={asChild}>{children}</TooltipTrigger>
      <TooltipContent {...tooltipContentProps}>
        <span className={isPrivate ? 'privacy-text' : ''}>{label}</span>
        {showArrow && <TooltipArrow {...tooltipArrowProps} />}
      </TooltipContent>
    </Tooltip>
  );
};
