import fromPairs from 'lodash/fromPairs';
import { z } from 'zod';

import { DynamicArtifactTemplate } from './dynamic-artifact.schema';

const NoteSectionSchema = z.union([z.string(), z.record(z.string(), z.any())]);

const OptionalNoteSectionSchema = NoteSectionSchema.optional();

export const SOAP_KEYS = ['SUBJECTIVE', 'OBJECTIVE', 'ASSESSMENT', 'PLAN'];

export const SOAPSchema = z
  .object({
    SUBJECTIVE: OptionalNoteSectionSchema,
    OBJECTIVE: OptionalNoteSectionSchema,
    ASSESSMENT: OptionalNoteSectionSchema,
    PLAN: OptionalNoteSectionSchema,
    CLIENT_RECAP: OptionalNoteSectionSchema,
  })
  .refine(
    (val) => {
      const keys = Object.keys(val);
      return SOAP_KEYS.some((key) => keys.includes(key));
    },
    {
      message: `At least one key from "${SOAP_KEYS.join(',')}" must be present`,
    },
  )
  .transform((val) => {
    const entries = Object.entries(val);

    const missing = SOAP_KEYS.filter(
      (key) => !entries.some(([k]) => k === key),
    ).map((key) => [key, null]);

    return fromPairs([...entries, ...missing]);
  });

// An Output Template for our Legacy SOAP Note schema that conforms to
// to the `dynamicArtifactTemplateSchema` in @eluve/llm-outputs
//
// This is used to parse SOAP_NOTE output types in the frontend.
export const LegacySOAPOutputTemplate: DynamicArtifactTemplate = {
  name: 'Classic SOAP',
  blocks: [
    {
      key: 'SUBJECTIVE',
      type: 'text',
      label: 'Subjective',
      isRequired: false,
      isAiEnabled: true,
      isEmailEnabled: false,
    },
    {
      key: 'OBJECTIVE',
      type: 'text',
      label: 'Objective',
      isRequired: false,
      isAiEnabled: true,
      isEmailEnabled: false,
    },
    {
      key: 'ASSESSMENT',
      type: 'text',
      label: 'Assessment',
      isRequired: false,
      isAiEnabled: true,
      isEmailEnabled: false,
    },
    {
      key: 'PLAN',
      type: 'text',
      label: 'Plan',
      isRequired: false,
      isAiEnabled: true,
      isEmailEnabled: false,
    },
    {
      key: 'CLIENT_RECAP',
      type: 'text',
      label: 'Client Recap',
      isRequired: false,
      isAiEnabled: true,
      isEmailEnabled: true,
    },
  ],
  description: 'Classic SOAP',
};
