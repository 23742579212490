import { graphql } from '@eluve/graphql.tada';

export const userGroupConfigsFragment = graphql(`
  fragment Configs on UserGroups @_unmask {
    __typename
    id
    configs {
      __typename
      id
      label
      price
      modifiers
      medical_code {
        __typename
        id
        code
        codeType
      }
    }
  }
`);

export const getUserGroupConfigsQuery = graphql(
  `
    query getUserGroupConfigs($tenantId: uuid!, $userGroupId: uuid!) {
      userGroupsByPk(id: $userGroupId, tenantId: $tenantId) {
        __typename
        id
        ...Configs
      }
    }
  `,
  [userGroupConfigsFragment],
);

export const createUserGroupCodeConfigMutation = graphql(`
  mutation createUserGroupCodeConfig(
    $userGroupId: uuid!
    $label: String
    $price: Int!
    $modifiers: jsonb
    $codeId: uuid!
  ) {
    insertUserGroupCodeConfigsOne(
      object: {
        groupId: $userGroupId
        label: $label
        price: $price
        modifiers: $modifiers
        codeId: $codeId
      }
    ) {
      __typename
      id
      label
      price
      modifiers
    }
  }
`);
