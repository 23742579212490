import { useMutation } from '@apollo/client';
import { Editor } from '@tiptap/core';
import { graphql } from 'gql.tada';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLatest } from 'react-use';

import { cacheUtils } from '@eluve/apollo-client';
import { useTypingThrottle } from '@eluve/blocks';
import { AmendmentHeader, Box, toast } from '@eluve/components';
import { EluveEditor } from '@eluve/eluve-editor';
import { useAmendments } from '@eluve/frontend-appointment-hooks';
import { TextFieldBlock } from '@eluve/llm-outputs';
import { areStringsEqualWithoutSpecialCharacters } from '@eluve/utils';

import { useUserName } from './useUserName';

const updateChartAmendmentMutation = graphql(`
  mutation updateChartAmendmentMutation(
    $tenantId: uuid!
    $chartAmendmentId: uuid!
    $value: String!
  ) {
    updateChartAmendmentsByPk(
      pkColumns: { tenantId: $tenantId, id: $chartAmendmentId }
      _set: { value: $value }
    ) {
      __typename
      id
      value
    }
  }
`);

const deleteChartAmendmentMutation = graphql(`
  mutation deleteChartAmendmentMutation(
    $tenantId: uuid!
    $chartAmendmentId: uuid!
  ) {
    deleteChartAmendmentsByPk(id: $chartAmendmentId, tenantId: $tenantId) {
      __typename
      id
    }
  }
`);

export const Amendment: React.FC<{
  appointmentId: string;
  chartAmendmentId: string;
  block: TextFieldBlock;
}> = ({ appointmentId, chartAmendmentId, block }) => {
  const { label, text } = block;

  const { amendments } = useAmendments(appointmentId);
  const chartAmendments = amendments
    .map((amendment) => amendment.chart_amendments)
    .flat();
  const chartAmendment = chartAmendments.find(
    (amendment) => amendment.id === chartAmendmentId,
  );
  const amendment = amendments.find(
    (amendment) => amendment.id === chartAmendment?.amendmentId,
  );

  const [updateChartAmendment] = useMutation(updateChartAmendmentMutation, {
    onError: () => toast.error('Failed to update amendment'),
    optimisticResponse: (data) => ({
      updateChartAmendmentsByPk: {
        __typename: 'ChartAmendments' as const,
        id: chartAmendmentId,
        value: data.value,
      },
    }),
  });

  const [deleteChartAmendment] = useMutation(deleteChartAmendmentMutation, {
    onError: () => toast.error('Failed to delete amendment'),
    optimisticResponse: () => ({
      deleteChartAmendmentsByPk: {
        __typename: 'ChartAmendments' as const,
        id: chartAmendmentId,
      },
    }),
    update: () => {
      cacheUtils.evict({
        key: { id: chartAmendmentId },
        typeName: 'ChartAmendments',
      });
    },
  });
  const isReadonly = Boolean(amendment?.submittedAt);

  const date = amendment?.submittedAt ?? chartAmendment?.createdAt ?? '';

  const [forceUpdate, setForceUpdate] = useState(0);
  const amendmentValue = useRef<string | null>(null);

  useEffect(() => {
    amendmentValue.current = chartAmendment?.value ?? '';
  }, [chartAmendment]);

  const getDisplayValue = useCallback(() => {
    return amendmentValue.current ?? chartAmendment?.value ?? '';
  }, [chartAmendment]);

  const updateChartAmendmentValue = useRef(async (notes: string) => {
    if (
      chartAmendment &&
      !areStringsEqualWithoutSpecialCharacters(notes, chartAmendment.value)
    ) {
      await updateChartAmendment({
        variables: {
          tenantId: chartAmendment.tenantId,
          value: notes,
          chartAmendmentId: chartAmendment.id,
        },
      });
    }
  });

  const latestValue = useLatest(chartAmendment?.value);

  const updateChartAmendmentValueThrottle = useTypingThrottle(
    updateChartAmendmentValue.current,
  );

  const onUpdate = useMemo(() => {
    return async (editor: Editor | undefined) => {
      if (latestValue.current === editor?.storage.markdown.getMarkdown()) {
        return;
      }
      if (editor) {
        const content = editor.storage.markdown.getMarkdown();
        amendmentValue.current = content;
        updateChartAmendmentValueThrottle(content);
      }
    };
  }, [latestValue, updateChartAmendmentValueThrottle]);

  const onDuplicate = async () => {
    if (chartAmendment && text) {
      amendmentValue.current = text;
      try {
        await updateChartAmendment({
          variables: {
            tenantId: chartAmendment.tenantId,
            value: text,
            chartAmendmentId: chartAmendment.id,
          },
        });
      } catch {
        toast.error('Failed to duplicate content to amendment');
        amendmentValue.current = chartAmendment.value ?? '';
      } finally {
        setForceUpdate((prev) => prev + 1);
      }
    }
  };
  const onDelete = async () => {
    await deleteChartAmendment({
      variables: {
        chartAmendmentId,
        tenantId: chartAmendment!.tenantId,
      },
    });
  };
  const userName = useUserName(amendment?.userId ?? '');

  if (!chartAmendment) {
    return null;
  }

  return (
    <Box className="w-full border-t ">
      <div className=" px-6 pt-6">
        <AmendmentHeader
          date={date}
          isReadonly={isReadonly}
          userName={userName}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
          duplicateSectionLabel={label}
        />
      </div>
      <EluveEditor
        key={`editor-${forceUpdate}-${amendment?.submittedAt}`}
        content={getDisplayValue()}
        disabled={isReadonly}
        onUpdate={onUpdate}
      />
    </Box>
  );
};
