import { useMutation } from '@apollo/client';
import { produce } from 'immer';
import debounce from 'lodash/debounce';
import { useCallback, useMemo, useState } from 'react';
import { v4 } from 'uuid';

import { cacheUtils, useCompleteFragment } from '@eluve/apollo-client';
import { CodeType } from '@eluve/blocks';
import { toast } from '@eluve/components';

import {
  addAppointmentBillingCodeLinkMutation,
  removeAppointmentBillingCodeLinkMutation,
  updateAppointmentBillingCodeModifiersMutation,
  updateAppointmentBillingCodePriceMutation,
  updateAppointmentBillingCodeQuantityMutation,
} from './AppointmentBillingCodePrices.operations';
import { appointmentBillingCodesFragment } from './AppointmentBillingCodes.operations';

// Types

export type AppointmentBillingCode = {
  id: string;
  medical_code: AppointmentMedicalCode | null;
  price: number | null;
  quantity: number | null;
};

export type AppointmentMedicalCode = {
  id: string;
  code: string;
  codeType: CodeType;
  description: string | null;
};

// Hooks

export const useAppointmentBillingCodes = ({
  tenantId,
  appointmentId,
}: {
  tenantId: string;
  appointmentId: string;
}) => {
  const data = useCompleteFragment({
    fragment: appointmentBillingCodesFragment,
    key: { id: appointmentId },
    strict: false,
  });
  const billingCodes = data?.billingCodes ?? [];

  // Modifiers

  const [updateAppointmentBillingCodeModifiers] = useMutation(
    updateAppointmentBillingCodeModifiersMutation,
  );

  const updateModifiers = (
    billingCodeId: string,
    modifiers: string[] | null,
  ) => {
    updateAppointmentBillingCodeModifiers({
      variables: {
        billingCodeId,
        modifiers,
      },
      onError: () => toast.error('We could not update the modifiers.'),
      optimisticResponse: ({ billingCodeId, modifiers }) => {
        return {
          updateAppointmentBillingCodesByPk: {
            __typename: 'AppointmentBillingCodes' as const,
            id: billingCodeId,
            modifiers: modifiers ?? null,
          },
        };
      },
    });
  };

  // Price

  const [updateAppointmentBillingCodePrice] = useMutation(
    updateAppointmentBillingCodePriceMutation,
  );

  const updatePrice = (billingCodeId: string, price: number) => {
    updateAppointmentBillingCodePrice({
      variables: {
        billingCodeId,
        price,
      },
      onError: () => toast.error('We could not update the price.'),
      optimisticResponse: ({ billingCodeId, price }) => {
        return {
          updateAppointmentBillingCodesByPk: {
            __typename: 'AppointmentBillingCodes' as const,
            id: billingCodeId,
            price,
          },
        };
      },
    });
  };

  // Quantity

  const [codeQuantities, setCodeQuantities] = useState<
    { cptId: string; quantity: number }[]
  >([]);

  const getQuantity = (billingCode: AppointmentBillingCode) => {
    return (
      codeQuantities.find((q) => q.cptId === billingCode.id)?.quantity ??
      billingCode.quantity ??
      1
    );
  };

  const [updateAppointmentBillingCodeQuantity] = useMutation(
    updateAppointmentBillingCodeQuantityMutation,
  );

  const _updateQuantity = useCallback(
    (billingCodeId: string, quantity: number) => {
      updateAppointmentBillingCodeQuantity({
        variables: {
          billingCodeId,
          quantity,
        },
        onError: () => {
          toast.error('We could not update the quantity.');

          setCodeQuantities((prev) =>
            prev.filter((quantity) => quantity.cptId !== billingCodeId),
          );
        },
        optimisticResponse: ({ billingCodeId, quantity }) => {
          return {
            updateAppointmentBillingCodesByPk: {
              __typename: 'AppointmentBillingCodes' as const,
              id: billingCodeId,
              quantity,
            },
          };
        },
      });
    },
    [updateAppointmentBillingCodeQuantity],
  );

  const updateQuantityDebounced = useMemo(
    () => debounce(_updateQuantity, 250),
    [_updateQuantity],
  );

  const updateQuantity = (cptId: string, quantity: number) => {
    updateQuantityDebounced(cptId, quantity);
    setCodeQuantities((prev) => {
      const index = prev.findIndex((q) => q.cptId === cptId);
      if (index === -1) {
        return [...prev, { cptId, quantity }];
      }
      return [
        ...prev.slice(0, index),
        { cptId, quantity },
        ...prev.slice(index + 1),
      ];
    });
  };

  // Linking

  const [addAppointmentBillingCodeLink] = useMutation(
    addAppointmentBillingCodeLinkMutation,
    {
      onError: () => {
        toast.error('We could not select the ICD code.');
      },
    },
  );

  const [removeAppointmentBillingCodeLink] = useMutation(
    removeAppointmentBillingCodeLinkMutation,
    {
      onError: () => {
        toast.error('We could not deselect the ICD code.');
      },
    },
  );

  const addBillingCodeLink = (cptId: string, icdId: string) => {
    addAppointmentBillingCodeLink({
      variables: {
        appointmentId,
        sourceBillingCodeId: cptId,
        targetBillingCodeId: icdId,
      },
      optimisticResponse: () => ({
        insertAppointmentBillingCodeLinksOne: {
          __typename: 'AppointmentBillingCodeLinks' as const,
          id: v4(),
          sourceAppointmentBillingCodeId: cptId,
          targetAppointmentBillingCodeId: icdId,
        },
      }),
      update: (_cache, { data }) => {
        cacheUtils.updateFragment(
          {
            fragment: appointmentBillingCodesFragment,
            key: {
              id: appointmentId,
            },
          },
          (existing) => {
            const linkId = data?.insertAppointmentBillingCodeLinksOne?.id;
            if (!existing || !linkId) {
              return existing;
            }

            return produce(existing, (draft) => {
              const billingCode = draft.billingCodes.find(
                (code) => code.id === cptId,
              );
              billingCode?.linked_codes.push({
                __typename: 'AppointmentBillingCodeLinks' as const,
                id: linkId,
                sourceAppointmentBillingCodeId: cptId,
                targetAppointmentBillingCodeId: icdId,
              });
            });
          },
        );
      },
    });
  };

  const removeBillingCodeLink = (cptId: string, icdId: string) => {
    removeAppointmentBillingCodeLink({
      variables: {
        tenantId,
        appointmentId,
        sourceBillingCodeId: cptId,
        targetBillingCodeId: icdId,
      },
      optimisticResponse: () => ({
        deleteAppointmentBillingCodeLinks: {
          returning: [
            {
              __typename: 'AppointmentBillingCodeLinks' as const,
              id: v4(),
              sourceAppointmentBillingCodeId: cptId,
              targetAppointmentBillingCodeId: icdId,
            },
          ],
        },
      }),
      update: (_cache) => {
        cacheUtils.updateFragment(
          {
            fragment: appointmentBillingCodesFragment,
            key: {
              id: appointmentId,
            },
          },
          (existing) => {
            if (!existing) {
              return existing;
            }

            return produce(existing, (draft) => {
              const billingCode = draft.billingCodes.find(
                (code) => code.id === cptId,
              );
              const index =
                billingCode?.linked_codes.findIndex(
                  (code) => code.targetAppointmentBillingCodeId === icdId,
                ) ?? -1;
              if (index !== -1) {
                billingCode?.linked_codes.splice(index, 1);
              }
            });
          },
        );
      },
    });
  };

  const updateLink = (isSelected: boolean, cptId: string, icdId: string) => {
    if (isSelected) {
      removeBillingCodeLink(cptId, icdId);
    } else {
      addBillingCodeLink(cptId, icdId);
    }
  };

  return {
    billingCodes,
    getQuantity,
    updateLink,
    updateModifiers,
    updatePrice,
    updateQuantity,
  };
};
