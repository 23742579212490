import { useMutation } from '@apollo/client';
import upperFirst from 'lodash/upperFirst';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  SubmittablePrompt,
  SubmittablePromptSchema,
} from '@eluve/api-contract';
import { useCompleteFragment } from '@eluve/apollo-client';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  H6,
  HStack,
  NewButton,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  VStack,
  textStyles,
  toast,
} from '@eluve/components';
import { formatHumanName } from '@eluve/utils';

import { TemplateVariant, TemplateVariantCard } from './TemplateVariantCard';
import {
  promptTemplateVariantsFragment,
  setDefaultPromptTemplateVariantMutation,
} from './prompt.operations';

type PromptTemplateVariant = TemplateVariant & {
  template?: SubmittablePrompt;
};

type PromptTemplateDetailsPromptTextProps = {
  promptTemplateId: string;
  variantNumber?: string;
};

export const PromptTemplateDetailsPromptText: React.FC<
  PromptTemplateDetailsPromptTextProps
> = ({ promptTemplateId, variantNumber: selectedVariantNumber }) => {
  const navigate = useNavigate();

  const [setDefaultPromptTemplateVariant] = useMutation(
    setDefaultPromptTemplateVariantMutation,
  );

  const data = useCompleteFragment({
    fragment: promptTemplateVariantsFragment,
    key: { id: promptTemplateId },
  });

  const variants: PromptTemplateVariant[] = useMemo(
    () =>
      (data.prompt_template_variants ?? []).map((variant) => ({
        variantNumber: variant.variantNumber,
        template: variant.template as [],
        isCurrentDefault: variant.isCurrentDefault,
        variantNotes: variant.variantNotes,
        createdAt: variant.createdAt,
        createdBy: !variant.creator
          ? undefined
          : formatHumanName(
              variant.creator?.firstName,
              variant.creator?.lastName,
            ),
      })),
    [data.prompt_template_variants],
  );

  const currentDefaultVariant = variants.find(
    (variant) => variant.isCurrentDefault,
  );
  const selectedVariant = selectedVariantNumber
    ? variants.find(
        (variant) => variant.variantNumber === Number(selectedVariantNumber),
      )
    : currentDefaultVariant;
  const selectedTemplate = selectedVariant?.template;

  if (!selectedTemplate) {
    return (
      <Alert>
        <AlertTitle>Prompt Template Not Available</AlertTitle>
        <AlertDescription>
          This is a legacy template that should be disabled.
        </AlertDescription>
      </Alert>
    );
  }

  const parts = SubmittablePromptSchema.parse(selectedTemplate);

  const handleCardClick = (variantNumber: number) => {
    navigate(
      `/admin/prompts/${promptTemplateId}/prompt/variant/${variantNumber}`,
    );
  };

  const handleDuplicateClick = (variantNumber: number) => {
    navigate(
      `/admin/prompts/clone/${promptTemplateId}/variant/${variantNumber}`,
    );
  };

  const handleSetAsDefaultClick = async (variantNumber: number) => {
    if (!currentDefaultVariant) {
      toast.error('We could not set this variant as the default.');
      return;
    }

    if (selectedVariantNumber == null) {
      handleCardClick(currentDefaultVariant.variantNumber);
    }

    await setDefaultPromptTemplateVariant({
      variables: { promptTemplateId, variantNumber },
      onError: () => {
        toast.error('We could not set this variant as the default.');
      },
      optimisticResponse: () => ({
        updatePromptTemplateVariants: {
          returning: [
            {
              __typename: 'PromptTemplateVariants' as const,
              promptTemplateId,
              variantNumber: currentDefaultVariant.variantNumber,
              isCurrentDefault: false,
            },
          ],
        },
        updatePromptTemplateVariantsByPk: {
          __typename: 'PromptTemplateVariants' as const,
          promptTemplateId,
          variantNumber,
          isCurrentDefault: true,
        },
      }),
    });
  };

  return (
    <VStack align="stretch" className="sm:flex-row" gap={5}>
      <VStack gap={6}>
        {parts.map((part) => (
          <VStack align="stretch" key={part.role}>
            <HStack>
              <H6>{`${upperFirst(part.role)} Message`}</H6>
              <Tooltip>
                <TooltipTrigger asChild>
                  <NewButton
                    icon={{ name: 'Copy' }}
                    onClick={async () => {
                      await navigator.clipboard.writeText(part.content);
                      toast.success(
                        `${upperFirst(part.role)} Message copied to clipboard`,
                      );
                    }}
                    type="ghost"
                  />
                </TooltipTrigger>
                <TooltipContent sideOffset={4}>
                  Copy to clipboard
                </TooltipContent>
              </Tooltip>
            </HStack>
            <div className="max-h-96 overflow-y-auto rounded-md border border-brandGray200 bg-brandGray50 p-5">
              <pre
                className={textStyles.body({
                  size: 'm',
                  className: 'text-wrap',
                })}
              >
                {part.content}
              </pre>
            </div>
          </VStack>
        ))}
      </VStack>
      <div className="h-px w-auto shrink-0 bg-brandGray200 sm:h-auto sm:w-px" />
      <VStack className="flex-1 pt-2 sm:min-w-60 md:min-w-72">
        <H6>Variants</H6>
        <VStack align="stretch" className="mt-2" gap={6}>
          {variants.map((variant) => (
            <TemplateVariantCard
              isSelected={
                variant.variantNumber === selectedVariant.variantNumber
              }
              key={variant.variantNumber}
              onClick={() => handleCardClick(variant.variantNumber)}
              onDuplicateClick={() =>
                handleDuplicateClick(variant.variantNumber)
              }
              onSetAsDefaultClick={() =>
                handleSetAsDefaultClick(variant.variantNumber)
              }
              variant={variant}
            />
          ))}
        </VStack>
      </VStack>
    </VStack>
  );
};

export const PromptTemplateDetailsPromptTextPage: React.FC = () => {
  const { promptTemplateId, variantNumber } = useParams() as {
    promptTemplateId: string;
    variantNumber?: string;
  };
  return (
    <PromptTemplateDetailsPromptText
      promptTemplateId={promptTemplateId}
      variantNumber={variantNumber}
    />
  );
};
