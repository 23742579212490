import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { toast } from 'sonner';

import { useCompleteFragment } from '@eluve/apollo-client';
import { NewButton, Textarea } from '@eluve/components';
import {
  useAssignedTenantIdFromParams,
  useUserIdFromSession,
} from '@eluve/session-helpers';

import {
  tenantUserSettingsFragment,
  updateProviderDetailsMutation,
} from './operations';

export const ProviderDetailsSetting: React.FC = () => {
  const userId = useUserIdFromSession();
  const tenantId = useAssignedTenantIdFromParams();

  const { settings } = useCompleteFragment({
    fragment: tenantUserSettingsFragment,
    key: { userId, tenantId },
  });

  const [updateProvideDetails] = useMutation(updateProviderDetailsMutation, {
    onError: () => {
      toast.error('Failed to update profile');
    },
    optimisticResponse: (data) => ({
      updateTenantUserSettingsByPk: {
        __typename: 'TenantUserSettings' as const,
        tenantId,
        userId,
        providerDetails: data.providerDetails,
      },
    }),
  });

  const save = async () => {
    if (providerDetails !== null) {
      await updateProvideDetails({
        variables: {
          tenantId,
          userId,
          providerDetails,
        },
      });
    }
  };

  const [providerDetails, setProviderDetails] = useState<string>(
    settings?.providerDetails ?? '',
  );

  return (
    <>
      <Textarea
        placeholder="Type medical provider details here"
        rows={4}
        value={providerDetails}
        onChange={(e) => {
          setProviderDetails(e.target.value);
        }}
      />
      <NewButton
        text="Save"
        onClick={save}
        type="primary"
        disabled={
          providerDetails == null ||
          providerDetails === settings?.providerDetails
        }
      />
    </>
  );
};
