import { useSubscription, useSuspenseQuery } from '@apollo/client';
import orderBy from 'lodash/orderBy';
import sumBy from 'lodash/sumBy';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ColDefBuilder, DataTable, P, PageTitle } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

import { WorkflowMatrixDetails } from './WorkflowMatrixDetails';

const workflowModelPromptTemplatesFragment = graphql(`
  fragment workflowModelPromptTemplates on EvalFactVerificationWorkflows
  @_unmask {
    __typename
    id
    status
    name
    description
    workflow_artifacts {
      __typename
      id
      artifact {
        __typename
        id
        factsAggregate {
          aggregate {
            count
          }
        }
      }
    }
    workflow_model_prompt_templates {
      __typename
      id
      failedFactVerificationCount
      passedFactVerificationCount
      model_prompt_template {
        __typename
        id
        model_args {
          __typename
          id
          modelType
          args
          name
        }
        template {
          __typename
          id
          name
        }
      }
      output_template {
        __typename
        id
        name
      }
    }
  }
`);

const getWorkflowInputsQuery = graphql(
  `
    query getWorkflowInputs($workflowId: uuid!) {
      evalFactVerificationWorkflowsByPk(id: $workflowId) {
        ...workflowModelPromptTemplates
      }
    }
  `,
  [workflowModelPromptTemplatesFragment],
);

const listenWorkflowInputsSubscription = graphql(
  `
    subscription getWorkflowInputs($workflowId: uuid!) {
      evalFactVerificationWorkflowsByPk(id: $workflowId) {
        ...workflowModelPromptTemplates
      }
    }
  `,
  [workflowModelPromptTemplatesFragment],
);

type InputRow = {
  id: string;
  passedFactCount: number;
  failedFactCount: number;
  prompt: {
    id: string;
    name: string;
  };
  outputTemplate: {
    id: string;
    name: string;
  } | null;
  model: {
    id: string;
    type: string;
    name?: string | null;
    args?: Record<string, unknown> | null;
  };
};

const columns = new ColDefBuilder<InputRow>()
  .detailsLink((row) => row.id)
  .colDef({
    header: 'Prompt',
    cell: ({ row: { original } }) => {
      return <P>{original.prompt.name}</P>;
    },
  })
  .colDef({
    header: 'Output Template',
    cell: ({ row: { original } }) => {
      return original.outputTemplate ? (
        <P>{original.outputTemplate?.name}</P>
      ) : null;
    },
  })
  .colDef({
    header: 'Model',
    cell: ({ row: { original } }) => {
      return (
        <div className="grid">
          <div>{original.model.type}</div>
          {original.model.args && (
            <pre>{JSON.stringify(original.model.args)}</pre>
          )}
        </div>
      );
    },
  })
  .defaultSortable('passedFactCount', '# Pass')
  .defaultSortable('failedFactCount', '# Fail')
  .build();

export interface FactVerificationWorkflowDetailsPageProps {}

export const FactVerificationWorkflowDetailsPage: React.FC<
  FactVerificationWorkflowDetailsPageProps
> = () => {
  const { workflowId } = useParams() as { workflowId: string };
  const {
    data: { evalFactVerificationWorkflowsByPk: workflow },
  } = useSuspenseQuery(getWorkflowInputsQuery, {
    variables: {
      workflowId,
    },
  });

  const totalArtifacts = workflow?.workflow_artifacts?.length ?? 0;
  const totalFacts = sumBy(
    workflow?.workflow_artifacts ?? [],
    (a) => a.artifact.factsAggregate.aggregate?.count ?? 0,
  );

  useSubscription(listenWorkflowInputsSubscription, {
    variables: {
      workflowId,
    },
    skip: !workflow?.status || workflow.status === 'COMPLETED',
  });

  const rows = useMemo(() => {
    return orderBy(
      (workflow?.workflow_model_prompt_templates ?? []).map<InputRow>((i) => {
        const {
          model_prompt_template,
          output_template,
          id,
          failedFactVerificationCount,
          passedFactVerificationCount,
        } = i;
        const { model_args, template } = model_prompt_template;

        return {
          id,
          passedFactCount: passedFactVerificationCount,
          failedFactCount: failedFactVerificationCount,
          prompt: template,
          outputTemplate: output_template,
          model: {
            id: model_args.id,
            args: model_args.args,
            type: model_args.modelType,
            name: model_args.name,
          },
        };
      }),
      (r) => r.passedFactCount,
      ['desc'],
    );
  }, [workflow]);

  return (
    <div className="space-y-2">
      <PageTitle subtitle={workflow?.description}>{workflow?.name}</PageTitle>
      <WorkflowMatrixDetails
        modelPromptTemplatesCount={rows.length}
        totalArtifacts={totalArtifacts}
        totalFacts={totalFacts}
      />
      <div className="rounded-md bg-white p-2">
        <DataTable columns={columns} data={rows} />
      </div>
    </div>
  );
};
