import { Text, View } from '@react-pdf/renderer';

import { getFormattedDateInMMMMdyyyyWithTime } from '@eluve/date-utils';

import { tw } from '../pdf-tw';
import { commonStyles } from '../styles';
import { PdfAmendment, PdfBillingCode } from '../types';

const BillingCodesBody: React.FC<{ billingCodes: PdfBillingCode[] }> = ({
  billingCodes,
}) => (
  <View>
    {billingCodes.map((billingCode) => (
      <View key={billingCode.code} style={tw('flex flex-row gap-0')}>
        <Text style={tw(commonStyles.boldText())}>{billingCode.code}</Text>
        {billingCode.description && (
          <Text style={tw(commonStyles.text())}>
            : {billingCode.description}
          </Text>
        )}
      </View>
    ))}
  </View>
);

export const BillingCodes: React.FC<{
  billingCodes: PdfBillingCode[];
  amendments: PdfAmendment[];
}> = ({ billingCodes, amendments }) => {
  if (!billingCodes.length) {
    return null;
  }

  const billingCodeAmendments = amendments.map((amendment) => {
    const billingCodes = amendment.billingCodeAmendments.map(
      (billingCodeAmendment) => ({
        code: billingCodeAmendment.code,
        description: billingCodeAmendment.description,
      }),
    );
    return {
      user: amendment.user,
      submittedAt: amendment.submittedAt,
      billingCodes,
    };
  });

  return (
    <View style={tw(commonStyles.content())}>
      <Text style={tw(commonStyles.sectionTitle())}>Billing Codes</Text>
      <BillingCodesBody billingCodes={billingCodes} />
      {billingCodeAmendments.map((amendment, index) => {
        const userName =
          `${amendment.user.firstName} ${amendment.user.lastName}`.trim();
        const formattedDate = getFormattedDateInMMMMdyyyyWithTime(
          amendment.submittedAt,
        );
        if (!amendment.billingCodes.length) return null;

        return (
          <View key={index} style={tw(commonStyles.amendment())}>
            <Text style={tw(commonStyles.amendmentHeader())}>
              Amendment • {formattedDate} by {userName}
            </Text>
            <BillingCodesBody billingCodes={amendment.billingCodes} />
          </View>
        );
      })}
    </View>
  );
};
