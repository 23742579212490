import { Helmet } from 'react-helmet';

import { Content, Header, Layout, PageTitle } from '@eluve/components';

import { TenantsList } from './components/TenantsList';

export const TenantsPage = () => {
  return (
    <Layout type="simple">
      <Helmet>
        <title>Select a Tenant | Eluve</title>
      </Helmet>
      <Header>
        <PageTitle>Select a Tenant</PageTitle>
      </Header>
      <Content padded>
        <TenantsList />
      </Content>
    </Layout>
  );
};
