import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export const RemoveSegmentRedirect: React.FC<{ segment: string }> = ({
  segment,
}) => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <Navigate to={(pathname ?? '').replace(`/${segment}`, '')} replace={true} />
  );
};
