import { useSuspenseQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import sumBy from 'lodash/sumBy';
import { Info } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { match } from 'ts-pattern';
import { z } from 'zod';

import { useApiClient } from '@eluve/api-client-provider';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  Form,
  FormField,
  FormItem,
  FormMessage,
  H3,
  Input,
  PageTitle,
  Textarea,
  VStack,
  toast,
} from '@eluve/components';
import { useNamedLogger } from '@eluve/logger';

import { ArtifactSearch, DataSetItem } from './ArtifactSearch';
import { useWorkflow } from './DuplicateWorkflowContext';
import {
  FactVerificationTargetConfigsForm,
  TargetConfig,
} from './FactVerificationTargetConfigsForm';
import { ModelPromptTemplateSearch } from './ModelPromptTemplateSearch';
import { WorkflowMatrixDetails } from './WorkflowMatrixDetails';
import { getWorkflowOptionsQuery } from './eval.operations';

const createWokflowFormSchema = z.object({
  name: z.string().min(3),
  description: z.string().optional(),
});

type FormData = z.infer<typeof createWokflowFormSchema>;

export interface CreateWorkflowPageProps {}

export const CreateWorkflowPage: React.FC<CreateWorkflowPageProps> = () => {
  const logger = useNamedLogger('create-workflow-page');
  useSuspenseQuery(getWorkflowOptionsQuery);

  const form = useForm<FormData>({
    resolver: zodResolver(createWokflowFormSchema),
  });

  const navigate = useNavigate();

  const [
    factVerifierModelPromptTemplateId,
    setFactVerifierModelPromptTemplateId,
  ] = useState<string | undefined>(undefined);

  const [selectedTargetConfigs, setSelectedTargetConfigs] = useState<
    TargetConfig[]
  >([]);

  const [selectedDataSetItems, setSelectedDataSetItems] = useState<
    DataSetItem[]
  >([]);

  const totalArtifacts = sumBy(selectedDataSetItems, (a) => a.totalArtifacts);
  const totalFacts = sumBy(selectedDataSetItems, (a) => a.totalFacts);

  const artifactsAndModelSelectionValid =
    selectedDataSetItems.length > 0 && selectedTargetConfigs.length > 0;

  const client = useApiClient();

  const onSubmit = async ({ name, description }: FormData) => {
    if (
      !artifactsAndModelSelectionValid ||
      !factVerifierModelPromptTemplateId
    ) {
      return;
    }

    try {
      const response = await client.eval.startCustomFactVerification({
        body: {
          name,
          description,
          dataSetIds: selectedDataSetItems.map((d) => d.id),
          targetGeneratorConfigs: selectedTargetConfigs.map((c) => ({
            modelPromptTemplateId: c.modelPromptTemplateId,
            outputTemplateId: c.outputTemplateId,
          })),
          factVerifierModelPromptTemplateId,
        },
      });

      if (response.status !== 201) {
        toast.error('Failed to create workflow');
      } else {
        const { workflowId } = response.body;
        toast.success('Workflow created successfully');
        navigate(`/admin/fact-verification/${workflowId}`);
      }
    } catch (e) {
      toast.error('Failed to create workflow');
    }
  };

  // Consume DuplicateWorkflowContext values as defaults if available
  const workflowContext = useWorkflow();
  useEffect(() => {
    if (workflowContext.targetConfigs.length > 0) {
      setSelectedTargetConfigs(workflowContext.targetConfigs);
    }
    if (workflowContext.dataSets.length > 0) {
      setSelectedDataSetItems(workflowContext.dataSets);
    }
  }, [workflowContext]);

  return (
    <div className="space-y-3">
      <PageTitle subtitle="Select the model prompt templates and artifacts for this workflow">
        Fact Verification
      </PageTitle>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit, () => {
            logger.debug('Invalid form submission attempted');
          })}
        >
          <FormField
            control={form.control}
            name="name"
            render={({ field, fieldState: { error } }) => (
              <FormItem className="max-w-lg">
                <label htmlFor="name" className="sr-only">
                  Name
                </label>
                <Input className="bg-white/5" placeholder="Name" {...field} />
                {error && (
                  <FormMessage className="mt-4">{error.message}</FormMessage>
                )}
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field, fieldState: { error } }) => (
              <FormItem className="max-w-lg">
                <label htmlFor="description" className="sr-only">
                  Description
                </label>
                <Textarea
                  className="bg-white/5"
                  placeholder="Description"
                  {...field}
                />
                {error && (
                  <FormMessage className="mt-4">{error.message}</FormMessage>
                )}
              </FormItem>
            )}
          />
          <Button
            className="mt-4"
            disabled={
              !artifactsAndModelSelectionValid ||
              !factVerifierModelPromptTemplateId
            }
          >
            Run Workflow
          </Button>
        </form>
      </Form>

      <VStack gap={8}>
        <VStack gap={3}>
          <H3>Fact Verifier</H3>
          {!factVerifierModelPromptTemplateId && (
            <Alert variant="warning">
              <Info />
              <AlertTitle>Invalid Selection</AlertTitle>
              <AlertDescription>
                Choose the Fact Verification Model Prompt template that will be
                used for this workflow
              </AlertDescription>
            </Alert>
          )}
          <ModelPromptTemplateSearch
            isSingleSelection
            onSelectionChanged={([id]) =>
              setFactVerifierModelPromptTemplateId(id)
            }
            selectedIds={
              factVerifierModelPromptTemplateId
                ? [factVerifierModelPromptTemplateId]
                : []
            }
            filter={(pt) => pt.template.outputType === 'FACT_VERIFICATION'}
          />
        </VStack>

        <div className="h-[0.8px] w-full bg-positive600" />
        <VStack gap={3}>
          <H3>Configs Being Evaluated</H3>
          {match(artifactsAndModelSelectionValid)
            .with(true, () => (
              <Alert>
                <Info />
                <AlertTitle>Selection Details</AlertTitle>
                <AlertDescription className="space-y-1">
                  <WorkflowMatrixDetails
                    modelPromptTemplatesCount={selectedTargetConfigs.length}
                    totalArtifacts={totalArtifacts}
                    totalFacts={totalFacts}
                  />
                </AlertDescription>
              </Alert>
            ))
            .otherwise(() => (
              <Alert variant="warning">
                <Info />
                <AlertTitle>Invalid Selection</AlertTitle>
                <AlertDescription>
                  Select at least one Target Config and at least one Artifact
                  Dataset
                </AlertDescription>
              </Alert>
            ))}
        </VStack>
        <VStack gap={3}>
          <H3>Select Dataset</H3>
          <ArtifactSearch
            selectedDataSetItems={selectedDataSetItems}
            onSelectionChanged={(items) => setSelectedDataSetItems(items)}
          />
        </VStack>
        <VStack gap={3}>
          <H3>Select Target Configs</H3>
          <FactVerificationTargetConfigsForm
            selectedConfigs={selectedTargetConfigs}
            onSelectionChanged={(configs) => setSelectedTargetConfigs(configs)}
          />
        </VStack>
      </VStack>
    </div>
  );
};
