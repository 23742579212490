import { useSuspenseQuery } from '@apollo/client';
import React, { useMemo, useState } from 'react';

import {
  ColDefBuilder,
  DataTable,
  HStack,
  Label,
  PageTitle,
  Switch,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

import { AddModelArgsDialog } from './AddModelArgsDialog';
import { SetGlobalModelArgsAction } from './SetGlobalModelArgsAction';
import { ToggleModelActiveAction } from './ToggleModelActiveAction';

const llmModelArgsFragment = graphql(`
  fragment ModelArgs on LlmModelArgs @_unmask {
    __typename
    id
    modelType
    args
    name
    description
    isActive
    isGlobalDefault
  }
`);

export const allLlmModelArgsFragment = graphql(
  `
    fragment AllLlmModelArgs on query_root @_unmask {
      __typename
      llmModelArgs {
        ...ModelArgs
      }
    }
  `,
  [llmModelArgsFragment],
);

const getModelArgsQuery = graphql(
  `
    query getModels {
      ...AllLlmModelArgs
    }
  `,
  [allLlmModelArgsFragment],
);

type ModelArgsRow = {
  id: string;
  modelType: string;
  name: string | null;
  isGlobalDefault: boolean;
  isActive: boolean;
  description: string | null;
  args: Record<any, any> | null;
};

const columns = new ColDefBuilder<ModelArgsRow>()
  .defaultSortable('modelType', 'Model')
  .defaultSortable('name')
  .defaultBoolean('isGlobalDefault', 'Default')
  .defaultSortable('args', {
    colOptions: {
      accessorFn: (row) => JSON.stringify(row.args),
    },
    cellRenderer: (row) => <pre>{JSON.stringify(row.args)}</pre>,
  })
  .defaultSortable('description')
  .colDef({
    header: 'Actions',
    cell: ({
      row: {
        original: { id, isGlobalDefault, isActive },
      },
    }) => (
      <div className="flex gap-2">
        <SetGlobalModelArgsAction
          modelArgsId={id}
          isGlobalDefault={isGlobalDefault}
          isActive={isActive}
        />
        <ToggleModelActiveAction modelArgsId={id} />
      </div>
    ),
  })
  .build();

export const ModelsListPage: React.FC = () => {
  const {
    data: { llmModelArgs: modelArgs },
  } = useSuspenseQuery(getModelArgsQuery);

  const [showInactive, setShowInactive] = useState(false);

  const rows = useMemo(() => {
    return showInactive
      ? modelArgs
      : modelArgs.filter((modelArg) => modelArg.isActive);
  }, [modelArgs, showInactive]);

  return (
    <>
      <div className="flex w-full items-center justify-between">
        <PageTitle>Model Args</PageTitle>
        <AddModelArgsDialog />
      </div>
      <HStack>
        <Switch checked={showInactive} onCheckedChange={setShowInactive} />
        <Label>Show Inactive</Label>
      </HStack>
      <div className="rounded-md bg-white pb-2 pt-3">
        <DataTable
          columns={columns}
          data={rows}
          enableGlobalSearch
          initialSortingState={[
            {
              id: 'isGlobalDefault',
              desc: true,
            },
          ]}
        />
      </div>
    </>
  );
};
