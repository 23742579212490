import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import { Check } from 'lucide-react';
import React from 'react';
import { match } from 'ts-pattern';

import { QUERY_ROOT_ID, useCompleteFragment } from '@eluve/apollo-client';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  P,
} from '@eluve/components';
import { FragmentOf } from '@eluve/graphql.tada';

import {
  modelArgsFragment,
  modelPromptTemplatesFragment,
} from './eval.operations';

type ModelPromptTemplate = FragmentOf<
  typeof modelPromptTemplatesFragment
>['llmModelPromptTemplates'][number];

export interface ModelPromptTemplateSearchProps {
  selectedIds: string[];
  onSelectionChanged: (selectedIds: string[]) => void;
  filter?: (m: ModelPromptTemplate) => boolean;
  isSingleSelection?: boolean;
}

export const ModelPromptTemplateSearch: React.FC<
  ModelPromptTemplateSearchProps
> = ({
  onSelectionChanged,
  selectedIds,
  isSingleSelection = false,
  filter = () => true,
}) => {
  const { llmModelPromptTemplates } = useCompleteFragment({
    fragment: modelPromptTemplatesFragment,
    key: QUERY_ROOT_ID,
  });

  const { llmModelArgs } = useCompleteFragment({
    fragment: modelArgsFragment,
    key: QUERY_ROOT_ID,
  });

  const modelPromptTemplates = (llmModelPromptTemplates ?? []).filter(filter);
  const groupedByModel = groupBy(
    modelPromptTemplates,
    (template) => template.modelArgsId,
  );

  const modelArgsLookup = keyBy(llmModelArgs, (m) => m.id);

  const getNewSelection = (id: string) => {
    return match(isSingleSelection)
      .with(true, () => {
        return match(selectedIds)
          .with([id], () => [])
          .otherwise(() => [id]);
      })
      .otherwise(() => {
        return selectedIds.includes(id)
          ? selectedIds.filter((selectedId) => selectedId !== id)
          : [...selectedIds, id];
      });
  };

  return (
    <Command className="border">
      <CommandInput placeholder="Search for a model prompt template" />
      <CommandEmpty>No Model Prompt Templates found</CommandEmpty>
      <CommandList>
        {Object.entries(groupedByModel).map(([modelId, templates]) => {
          // Safe because we're mapping over the entries of the object and accessing
          // based on the object key
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const modelArgs = modelArgsLookup[modelId]!;
          return (
            <CommandGroup
              heading={
                <div>
                  <P>{modelArgs.name}</P>
                  <pre>{JSON.stringify(modelArgs.args)}</pre>
                </div>
              }
              key={modelId}
            >
              {templates.map((modelTemplate) => (
                <CommandItem
                  key={modelTemplate.id}
                  value={`${modelTemplate.template.name}-${modelArgs.name}-${modelArgs.modelType}-${modelTemplate.id}`}
                  onSelect={() => {
                    onSelectionChanged(getNewSelection(modelTemplate.id));
                  }}
                >
                  {/* {modelTemplate.template.name} */}
                  <div className="group flex w-full items-center justify-between gap-1">
                    <div className="flex items-center gap-2">
                      {selectedIds.includes(modelTemplate.id) ? (
                        <Check className="size-4 text-green" />
                      ) : (
                        <div className="size-4" />
                      )}
                      <P className="text-gray-12">
                        {modelTemplate.template.name}
                      </P>
                    </div>
                    <pre className="text-gray-10">
                      {JSON.stringify(modelArgs.args)}
                    </pre>
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          );
        })}
      </CommandList>
    </Command>
  );
};
