import React, { useRef, useState } from 'react';

import {
  Box,
  Button,
  H4,
  HStack,
  Icon,
  NewButton,
  P,
  VStack,
  tv,
} from '@eluve/components';

import { ConversationHistory } from './ConversationChatPanel';
import { useChat } from './ConversationContextProvider';

const panelStyles = tv({
  base: 'relative z-0 size-full select-none overflow-hidden text-clip',
});

const backgroundStyles = tv({
  base: 'absolute left-0 top-0 z-20 bg-white bg-center bg-no-repeat transition-all duration-300 ease-in-out',
  variants: {
    isOpen: {
      true: 'h-2/3 w-full bg-contain',
      false: 'size-full scale-[1.72] bg-contain',
    },
  },
});

const chatContainerStyles = tv({
  base: 'absolute bottom-0 left-0 z-30 h-3/5 w-full gap-0 rounded-t-3xl bg-white pt-3 transition-transform duration-300 ease-in-out',
  variants: {
    isOpen: {
      true: 'translate-y-0',
      false: 'translate-y-full',
    },
  },
});

const providerBadgeStyles = tv({
  base: 'flex h-10 rounded-full bg-brandGray800 pl-2 pr-3 text-sm font-semibold text-white opacity-75',
});

const chatToggleButtonStyles = tv({
  base: 'flex size-12 rounded-full bg-brandGray800 text-white hover:bg-brandGray800 hover:opacity-75',
});

const micButtonStyles = tv({
  base: 'flex size-24 items-center justify-center rounded-full bg-brandGray800 text-white hover:bg-brandGray800 hover:opacity-75',
});

const stopButtonStyles = tv({
  base: 'flex size-12 rounded-full bg-brandGray800 text-white hover:bg-brandGray800 hover:opacity-75',
});

type ConversationActivePanelProps = {
  providerName?: string;
  providerAvatarUrl?: string;
  providerBackgroundUrl?: string;
};

export const ConversationActivePanel: React.FC<
  ConversationActivePanelProps
> = ({ providerName, providerAvatarUrl, providerBackgroundUrl }) => {
  const {
    isConnected,
    handlePushToTalk,
    openChat,
    setOpenChat,
    toggleConnection,
    setIsCompleted,
    isCompleted,
    appointmentConversationId,
  } = useChat();
  const [pushToTalk, setPushToTalk] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const startTalking = () => {
    handlePushToTalk(true);
    setPushToTalk(true);
  };

  const stopTalking = () => {
    if (!pushToTalk) return;
    setPushToTalk(false);
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
    handlePushToTalk(false);
  };

  const doneWithConversation = () => {
    toggleConnection(false, appointmentConversationId);
    setIsCompleted(true);
    return;
  };

  if (!isConnected || isCompleted) return null;

  return (
    <Box className={panelStyles()}>
      <Box
        style={{
          backgroundImage: `url(${providerBackgroundUrl ?? providerAvatarUrl})`,
        }}
        className={backgroundStyles({ isOpen: openChat })}
        onClick={() => {
          setOpenChat(false);
        }}
      />
      <VStack justify="between" className="h-full">
        <HStack justify="between" className="z-30 p-6" gap={2}>
          <HStack wFull={false} className={providerBadgeStyles()}>
            <img
              src={providerAvatarUrl ?? ''}
              alt="Dr"
              className="size-6 rounded-full bg-white"
            />
            {providerName}
          </HStack>

          <Button
            className={chatToggleButtonStyles()}
            disabled={!isConnected}
            onClick={() => {
              setOpenChat(!openChat);
            }}
          >
            <Icon name="MessageSquare" size="lg" />
          </Button>
        </HStack>

        {!openChat && (
          <VStack className="z-30 pb-10">
            <HStack
              className="grid grid-cols-3"
              justify="center"
              align="center"
              gap={0}
            >
              <Box />
              <VStack
                justify="center"
                align="center"
                className={isConnected ? 'active:opacity-75' : ''}
                gap={2}
              >
                <Button
                  className={micButtonStyles()}
                  disabled={!isConnected}
                  onMouseDown={startTalking}
                  onMouseUp={stopTalking}
                  onMouseLeave={stopTalking}
                >
                  {pushToTalk ? (
                    <Icon
                      name="AudioWaveform"
                      size="lg"
                      className="animate-pulse"
                    />
                  ) : (
                    <Icon name="Mic" size="lg" />
                  )}
                </Button>
                <P className="h-6 text-contentPrimary">
                  {pushToTalk ? '' : 'Hold to talk'}
                </P>
              </VStack>
              <VStack
                className={isConnected ? 'active:opacity-75' : ''}
                align="start"
              >
                <Button
                  className={stopButtonStyles()}
                  onClick={doneWithConversation}
                >
                  <Icon name="Square" size="lg" className="fill-white" />
                </Button>

                <P className="h-6"></P>
              </VStack>
            </HStack>
            <HStack
              className="grid grid-cols-3"
              justify="center"
              align="center"
              gap={8}
            ></HStack>
          </VStack>
        )}
        <Box className={chatContainerStyles({ isOpen: openChat })}>
          <VStack gap={3} className="h-full p-2" justify="between">
            <HStack className="shrink-0" justify="between">
              <H4>Conversation</H4>
              <NewButton
                icon={{ name: 'X' }}
                onClick={() => setOpenChat(false)}
                size="l"
                type="ghost"
              />
            </HStack>
            <HStack className="overflow-auto p-2">
              <ConversationHistory
                providerAvatarUrl={providerAvatarUrl}
                providerName={providerName}
              />
            </HStack>
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
};
