import { useMutation, useSuspenseQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, PageTitle, toast } from '@eluve/components';
import {
  DynamicArtifactTemplate,
  dynamicArtifactTemplateSchema,
} from '@eluve/llm-outputs';
import {
  DynamicArtifactTemplateBuilder,
  templateBuilderStore,
} from '@eluve/smart-blocks';

import {
  createOutputTemplateMutation,
  getOutputTemplateQuery,
} from './operations';

export const CloneOutputTemplatePage: React.FC = () => {
  const { outputTemplateId } = useParams() as { outputTemplateId: string };
  const navigate = useNavigate();

  const [isValid, setIsValid] = useState(false);
  const [createOutputTemplate] = useMutation(createOutputTemplateMutation, {
    onCompleted: ({ insertLlmOutputTemplatesOne: template }) => {
      toast.success('Output template created');
      templateBuilderStore.send({ type: 'reset' });
      if (template) {
        navigate(`/admin/output-templates/${template.id}`);
      }
    },
    onError: () => toast.error('Failed to create output template'),
  });

  const handleSubmit = () => {
    const { context } = templateBuilderStore.getSnapshot();
    const dynamicArtifact: DynamicArtifactTemplate = {
      name: context.name,
      description: context.description,
      blocks: context.blocks,
    };

    createOutputTemplate({
      variables: {
        input: {
          name: context.name,
          description: context.description,
          llmOutputType: context.outputType,
          variants: {
            data: [
              {
                template: dynamicArtifact,
                variantNotes: context.variantNotes,
                isCurrentDefault: true,
              },
            ],
          },
        },
      },
    });
  };

  const {
    data: { llmOutputTemplatesByPk: outputTemplate },
  } = useSuspenseQuery(getOutputTemplateQuery, {
    variables: {
      id: outputTemplateId,
    },
  });

  const artifactTemplate = useMemo(() => {
    const parsed = dynamicArtifactTemplateSchema.safeParse(
      outputTemplate?.variants?.[0]?.template,
    );
    if (!parsed.success) {
      return undefined;
    }

    return parsed.data;
  }, [outputTemplate?.variants]);

  useEffect(() => {
    if (artifactTemplate) {
      templateBuilderStore.send({
        type: 'hydrateArtifact',
        artifactTemplate,
        outputType: outputTemplate?.llmOutputType ?? 'SOAP_NOTE',
      });
    }
  }, [artifactTemplate, outputTemplate?.llmOutputType]);

  return (
    <div className="container">
      <PageTitle subtitle={`Cloning from ${outputTemplate?.name}`}>
        Create Output Template
      </PageTitle>
      <Button disabled={!isValid} onClick={handleSubmit}>
        Submit
      </Button>
      <DynamicArtifactTemplateBuilder
        showJsonSchema
        onIsValidChange={setIsValid}
      />
    </div>
  );
};
