import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

/**
 * Formats a given date (string or Date object) into "MMMM d, yyyy h:mm a" format.
 *
 * @example output:
 * March 20, 2025 10:43 PM
 */
export const getFormattedDateInMMMMdyyyyWithTime = (
  dateInput: string | Date,
): string => {
  const date = typeof dateInput === 'string' ? parseISO(dateInput) : dateInput;

  return format(date, 'MMMM d, yyyy h:mm a');
};
