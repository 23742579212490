import * as Session from 'supertokens-auth-react/recipe/session';

import { ISessionProvider } from './types';

/**
 * Uses the SuperTokens web sdk to provide session tokens.
 */
export class BrowserSessionProvider implements ISessionProvider {
  getAccessToken = Session.getAccessToken;
  getAccessTokenPayloadSecurely = Session.getAccessTokenPayloadSecurely;
}
