import { Document, Page } from '@react-pdf/renderer';

import { Block } from '@eluve/llm-outputs';

import { BillingCodes } from './components/BillingCodes';
import { ChartSummary } from './components/ChartSummary';
import { ChartUserDetails } from './components/ChartUserDetails';
import { Divider } from './components/Divider';
import { LocationHeader } from './components/LocationHeader';
import { PatientDetails } from './components/PatientDetails';
import { SectionTitle } from './components/SectionTitle';
import { tw } from './pdf-tw';
import { PdfAmendment, PdfBillingCode } from './types';

export type DynamicOutputPDFProps = {
  blocks: Block[];
  amendments?: PdfAmendment[];
  location?: {
    logoUrl?: string;
    name?: string;
    address?: string;
    phoneNumber?: string;
    email?: string;
  };
  patient?: {
    firstName?: string;
    lastName?: string;
    dateOfBirth?: string;
    phoneNumber?: string;
    email?: string;
  };
  user: {
    firstName?: string;
    lastName?: string;
    email: string;
  };
  tenant: {
    name: string;
  };
  noteSignedAt?: string;
  billingCodes?: PdfBillingCode[];
};

export const DynamicOutputPDF = (props: DynamicOutputPDFProps) => {
  const {
    blocks,
    location,
    patient,
    tenant,
    user,
    amendments = [],
    noteSignedAt,
    billingCodes = [],
  } = props;

  return (
    <Document>
      <Page
        size="A4"
        style={tw('p-8 text-brandGray900 font-[Inter] font-medium gap-4')}
      >
        <LocationHeader tenant={tenant} location={location} />

        <SectionTitle title="Chart" />

        <PatientDetails patient={patient} />

        <Divider />

        <ChartUserDetails user={user} noteSignedAt={noteSignedAt} />

        <ChartSummary blocks={blocks} amendments={amendments} />

        <BillingCodes billingCodes={billingCodes} amendments={amendments} />
      </Page>
    </Document>
  );
};
