import { useAppointmentId } from './appointment.context';
import { useAmendments } from './useAmendments';

export const useAmendmentActivity = () => {
  const appointmentId = useAppointmentId();
  const { amendments: allAmendments } = useAmendments(appointmentId);

  const { amendments: activeAmendments } = useAmendments(appointmentId, {
    draftOnly: true,
  });
  const activeAmendment = activeAmendments.length ? activeAmendments[0] : null;

  const isBillingCodeAmendmentInProgress = Boolean(
    activeAmendment?.billing_code_amendment,
  );

  const hasBillingCodeAmendments = Boolean(
    allAmendments.find((a) => Boolean(a.billing_code_amendment)),
  );

  return {
    isAmendmentInProgress: Boolean(activeAmendment),
    activeAmendmentId: activeAmendment?.id,
    activeAmendment,
    hasAmendments: allAmendments.length > 0,
    hasBillingCodeAmendments,
    isBillingCodeAmendmentInProgress,
  };
};
