import { useMutation } from '@apollo/client';
import { produce } from 'immer';
import { useState } from 'react';

import { cacheUtils, useCompleteFragment } from '@eluve/apollo-client';
import { useCopyContentElement } from '@eluve/blocks';
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  HStack,
  Icon,
  NewButton,
  P,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  cn,
  toast,
} from '@eluve/components';
import {
  appointmentAmendments,
  billingCodeAmendmentFragment,
  externalChartsFragment,
  useAmendmentActivity,
  useAmendments,
  useAppointmentId,
  useIsUserAppointmentOwner,
  useSummary,
  useSummaryContentRef,
} from '@eluve/frontend-appointment-hooks';
import { appointmentBillingCodesFragment } from '@eluve/frontend-feature-appointment';
import { AppointmentPdf } from '@eluve/frontend-feature-appointment';
import { graphql } from '@eluve/graphql.tada';
import {
  useAssignedTenantIdFromParams,
  useUserIdFromSession,
} from '@eluve/session-helpers';
import { useIsFeatureFlagEnabled } from '@eluve/smart-blocks';
import { useDialog } from '@eluve/utility-hooks';

const SignedAppointmentDetailsFragment = graphql(
  `
    fragment SignedAppointmentDetails on Appointments {
      __typename
      id
      doctor_interaction {
        appointmentId
        __typename
        noteSignedAt
        user {
          __typename
          id
          firstName
          lastName
        }
      }
      ...externalCharts
    }
  `,
  [externalChartsFragment],
);

const createAmendmentMutation = graphql(`
  mutation createAmendment($appointmentId: uuid!) {
    insertAmendmentsOne(object: { appointmentId: $appointmentId }) {
      __typename
      id
      appointmentId
      user {
        __typename
        id
        firstName
        lastName
        email
      }
    }
  }
`);

const cancelAmendmentMutation = graphql(`
  mutation cancelAmendment($id: uuid!, $tenantId: uuid!) {
    deleteAmendmentsByPk(id: $id, tenantId: $tenantId) {
      __typename
      id
    }
  }
`);

const submitAmendmentMutation = graphql(`
  mutation updateAmendment($id: uuid!, $tenantId: uuid!) {
    updateAmendmentsByPk(
      pkColumns: { id: $id, tenantId: $tenantId }
      _set: { submittedAt: NOW }
    ) {
      __typename
      id
      submittedAt
    }
  }
`);

export const SignedSummaryFooter: React.FC = () => {
  const { contentRef } = useSummaryContentRef();
  const appointmentId = useAppointmentId();
  const tenantId = useAssignedTenantIdFromParams();
  const userId = useUserIdFromSession();
  const isUserApptOwner = useIsUserAppointmentOwner();
  const [tooltipContent, setTooltipContent] = useState('Copy to clipboard');
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { isDialogOpen: isPdfDialogOpen, toggleDialog: togglePdfDialog } =
    useDialog();

  const [createAmendment] = useMutation(createAmendmentMutation, {
    onError: () => {
      toast.error('Failed to create amendment');
    },
    optimisticResponse: () => ({
      insertAmendmentsOne: {
        __typename: 'Amendments' as const,
        id: 'not-created-yet',
        appointmentId,
        user: {
          __typename: 'Users' as const,
          id: userId,
          firstName: '',
          lastName: '',
          email: '',
        },
      },
    }),
    update: (_, { data }) => {
      cacheUtils.updateFragment(
        {
          fragment: appointmentAmendments,
          key: { id: appointmentId },
        },
        (existing) => {
          if (!existing || !data?.insertAmendmentsOne) {
            return null;
          }
          return produce(existing, (draft) => {
            draft.amendments.push({
              __typename: 'Amendments' as const,
              id: data.insertAmendmentsOne!.id,
              appointmentId,
              tenantId,
              userId,
              submittedAt: null,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              chart_amendments: [],
              billing_code_amendment: null,
              user: {
                __typename: 'Users' as const,
                id: userId,
                firstName: '',
                lastName: '',
                email: '',
              },
            });
          });
        },
      );
    },
  });

  const { activeAmendment } = useAmendmentActivity();

  const [cancelAmendment] = useMutation(cancelAmendmentMutation, {
    onError: () => toast.error('Failed to cancel amendment'),
    optimisticResponse: (data) => ({
      deleteAmendmentsByPk: {
        __typename: 'Amendments' as const,
        id: data.id,
      },
    }),
    update: () => {
      cacheUtils.evict({
        typeName: 'Amendments',
        key: { id: activeAmendment!.id },
      });
    },
  });

  const [submitAmendment] = useMutation(submitAmendmentMutation, {
    onError: () => toast.error('Failed to submit amendment'),
    optimisticResponse: (data) => ({
      updateAmendmentsByPk: {
        __typename: 'Amendments' as const,
        id: data.id,
        submittedAt: new Date().toISOString(),
      },
    }),
    update: () => {
      if (!activeAmendment?.billing_code_amendment) {
        return;
      }

      const existingBillingCodes = cacheUtils.readFragment({
        fragment: appointmentBillingCodesFragment,
        key: { id: appointmentId },
      });

      // write billing code amendment to cache if needed
      cacheUtils.writeFragment({
        fragment: billingCodeAmendmentFragment,
        key: { id: activeAmendment!.billing_code_amendment!.id },
        data: {
          __typename: 'BillingCodeAmendments' as const,
          id: activeAmendment!.billing_code_amendment!.id,
          tenantId,

          amendmentId: activeAmendment!.id,
          createdAt: new Date().toISOString(),

          billing_codes: existingBillingCodes.billingCodes.map((code) => ({
            __typename: 'AppointmentBillingCodesSnapshots' as const,
            id: code.id,
            appointmentId,
            tenantId,
            tenant_medical_code: code.tenant_medical_code,
            medical_code: code.medical_code,
          })),
        },
      });
    },
  });

  const { amendments: draftAmendments } = useAmendments(appointmentId, {
    draftOnly: true,
  });
  const isChartAmendmentEnabled = useIsFeatureFlagEnabled('CHART_AMENDMENTS');
  const isPdfEnabled = useIsFeatureFlagEnabled('PDF_OUTPUT');
  const currentSummary = useSummary();
  const currentDraftAmendment = draftAmendments[0];

  const scrollSummaryToTop = () => {
    if (contentRef) {
      let scrollableParent = contentRef.parentElement;
      while (scrollableParent) {
        const style = window.getComputedStyle(scrollableParent);
        const hasScrollableContent =
          style.overflowY === 'auto' ||
          style.overflowY === 'scroll' ||
          scrollableParent === document.documentElement;
        if (hasScrollableContent) {
          scrollableParent.scrollTo({ top: 0, behavior: 'smooth' });
          break;
        }
        scrollableParent = scrollableParent.parentElement;
      }
      if (!scrollableParent) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  };

  const amend = () => {
    scrollSummaryToTop();

    return createAmendment({
      variables: {
        appointmentId,
      },
    });
  };

  const cancelActiveAmendment = () => {
    return cancelAmendment({
      variables: {
        id: currentDraftAmendment!.id,
        tenantId,
      },
    });
  };

  const submitActiveAmendment = () => {
    scrollSummaryToTop();

    return submitAmendment({
      variables: {
        id: currentDraftAmendment!.id,
        tenantId,
      },
    });
  };

  const appointment = useCompleteFragment({
    fragment: SignedAppointmentDetailsFragment,
    key: {
      id: appointmentId,
    },
  });

  const copySummary = useCopyContentElement({
    element: contentRef,
    onCopied: () => {
      setTooltipContent('Copied!');
      setTooltipOpen(true);
      setTimeout(() => setTooltipContent('Copy to clipboard'), 3000);
    },
  });

  if (!appointment.doctor_interaction?.noteSignedAt) {
    return null;
  }

  const canAmendChart = isChartAmendmentEnabled && isUserApptOwner;
  const showDropdownMenu = canAmendChart || isPdfEnabled;

  return (
    <HStack wFull justify="end">
      <HStack wFull gap={0}>
        <Tooltip open={tooltipOpen} onOpenChange={setTooltipOpen}>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              className={cn('w-full', showDropdownMenu && 'rounded-r-none')}
              onClick={copySummary}
            >
              <P className="text-contentSecondary">Copy Summary</P>
            </Button>
          </TooltipTrigger>
          <TooltipContent sideOffset={8}>{tooltipContent}</TooltipContent>
        </Tooltip>
        {showDropdownMenu && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" size="icon" className="rounded-l-none">
                <Icon name="ChevronDown" size="sm" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent side="top">
              {canAmendChart && currentDraftAmendment && (
                <DropdownMenuItem
                  className="flex cursor-pointer items-center gap-2"
                  onClick={cancelActiveAmendment}
                >
                  <P className="text-contentSecondary">Discard Amendment</P>
                </DropdownMenuItem>
              )}
              {canAmendChart && !currentDraftAmendment && (
                <DropdownMenuItem
                  className="flex cursor-pointer items-center gap-2"
                  onClick={amend}
                >
                  <P className="text-contentSecondary">Amend Chart</P>
                </DropdownMenuItem>
              )}
              {isPdfEnabled && (
                <DropdownMenuItem
                  className="flex cursor-pointer items-center gap-2"
                  onClick={togglePdfDialog}
                >
                  <P className="text-contentSecondary">Preview PDF</P>
                </DropdownMenuItem>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </HStack>

      <Dialog open={isPdfDialogOpen} onOpenChange={togglePdfDialog}>
        <DialogContent className="max-w-7xl">
          <DialogHeader>
            <DialogTitle>PDF Note</DialogTitle>
          </DialogHeader>
          {isPdfDialogOpen && currentSummary.summary && (
            <AppointmentPdf
              tenantId={tenantId}
              appointmentId={appointmentId}
              summary={currentSummary.summary}
            />
          )}
        </DialogContent>
      </Dialog>
      {isChartAmendmentEnabled && currentDraftAmendment && isUserApptOwner && (
        <NewButton
          text="Sign Note"
          onClick={submitActiveAmendment}
          type="primary"
        />
      )}
    </HStack>
  );
};
