import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import { useCompleteFragment } from '@eluve/apollo-client';
import {
  FormField,
  HStack,
  Input,
  Label,
  NewButton,
  VStack,
} from '@eluve/components';
import { useUserIdFromSession } from '@eluve/session-helpers';

import { updateUserProfileMutation, userFragment } from './operations';

const userFormSchema = z.object({
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
});

type UserFormValues = z.infer<typeof userFormSchema>;

export const UserProfileSettings = () => {
  const userId = useUserIdFromSession();
  const [isEditing, setIsEditing] = useState(false);

  const userData = useCompleteFragment({
    fragment: userFragment,
    key: { id: userId },
  });

  const { control, formState, handleSubmit, reset } = useForm<UserFormValues>({
    resolver: zodResolver(userFormSchema),
    defaultValues: {
      firstName: userData?.firstName ?? '',
      lastName: userData?.lastName ?? '',
    },
  });

  const [updateProfile] = useMutation(updateUserProfileMutation, {
    onError: () => {
      toast.error('Failed to update profile');
      setIsEditing(true);
    },
    optimisticResponse: (data) => ({
      updateUsersByPk: {
        __typename: 'Users' as const,
        id: userId,
        firstName: data.firstName,
        lastName: data.lastName,
        email: userData?.email ?? '',
      },
    }),
  });

  const onSubmit = handleSubmit((data) => {
    setIsEditing(false);

    return updateProfile({
      variables: {
        userId,
        firstName: data.firstName,
        lastName: data.lastName,
      },
    });
  });

  const handleCancel = () => {
    reset({
      firstName: userData?.firstName ?? '',
      lastName: userData?.lastName ?? '',
    });
    setIsEditing(false);
  };

  if (!isEditing) {
    return (
      <VStack gap={4} className="w-full">
        <div className="text-gray-11">
          {userData?.firstName} {userData?.lastName}
        </div>
        <NewButton
          text="Edit"
          type="outline"
          onClick={() => setIsEditing(true)}
        />
      </VStack>
    );
  }

  return (
    <VStack gap={4} className="w-full">
      <Label>
        <VStack>
          First Name
          <FormField
            control={control}
            name="firstName"
            render={({ field }) => (
              <Input placeholder="First Name" {...field} />
            )}
          />
        </VStack>
      </Label>

      <Label>
        <VStack>
          Last Name
          <FormField
            control={control}
            name="lastName"
            render={({ field }) => <Input placeholder="Last Name" {...field} />}
          />
        </VStack>
      </Label>

      <HStack gap={2}>
        <NewButton text="Cancel" type="outline" onClick={handleCancel} />
        <NewButton
          text="Save"
          type="primary"
          onClick={onSubmit}
          disabled={
            formState.isSubmitting || !formState.isValid || !formState.isDirty
          }
        />
      </HStack>
    </VStack>
  );
};
