// Patched up version of usePermission
// Source: https://github.com/streamich/react-use/blob/master/src/usePermission.ts
import { useCallback, useEffect, useRef, useState } from 'react';

import { isAndroidWebView } from '@eluve/utils';

import { getMicrophoneDevices } from './getMicrophoneDevices';

export type MicrophonePermissionState =
  | PermissionState
  | 'unknown'
  | 'no-microphone-devices'
  | 'undetermined';

export const useMicrophonePermission = () => {
  const [state, setState] = useState<MicrophonePermissionState>('undetermined');
  const permissionStatusRef = useRef<PermissionStatus | null>(null);

  const onPermissionChange = useCallback(() => {
    if (!permissionStatusRef.current) {
      return;
    }

    setState(permissionStatusRef.current.state);
  }, []);

  const checkNavigatorPermissions = useCallback(async () => {
    // Android permissions are managed through Android Permission Model. If Eluve is launched in Webview, WebView should have RECORD_AUDIO permission.
    if (!isAndroidWebView && navigator.permissions) {
      if (permissionStatusRef.current) {
        permissionStatusRef.current.removeEventListener(
          'change',
          onPermissionChange,
        );
      }

      try {
        permissionStatusRef.current = await navigator.permissions.query({
          name: 'microphone' as PermissionName,
        });

        permissionStatusRef.current.addEventListener(
          'change',
          onPermissionChange,
        );

        onPermissionChange();

        return;
      } catch (_e) {
        // do nothing
      }
    }

    const audioDevices = await getMicrophoneDevices();

    if (audioDevices.length) {
      setState('granted');
    } else {
      setState((state) => {
        // When there are no microphone devices, and previous state was 'prompt', it returns 'no-microphone-devices' state
        if (state === 'prompt') {
          return 'no-microphone-devices';
        }

        // When there are no microphone devices, and previous state was not 'prompt', we need to prompt the user for permission
        return 'prompt';
      });
    }
  }, [onPermissionChange]);

  useEffect(() => {
    checkNavigatorPermissions();

    return () => {
      if (permissionStatusRef.current) {
        permissionStatusRef.current.removeEventListener(
          'change',
          onPermissionChange,
        );

        permissionStatusRef.current = null;
      }
    };
  }, [checkNavigatorPermissions, onPermissionChange]);

  return { state, checkNavigatorPermissions };
};
