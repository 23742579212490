import { graphql } from '@eluve/graphql.tada';

export const userSettingsFragment = graphql(`
  fragment UserSetting on UserSettings @_unmask {
    __typename
    userId
    promptTemplateId
    promptTemplateIdOrDefault
    enableSoundEffects
  }
`);

export const llmOutputTemplateFragment = graphql(`
  fragment LlmOutputTemplate on LlmOutputTemplates @_unmask {
    __typename
    id
    llmOutputType
    name
  }
`);

export const tenantUserSettingEnableSoundEffectsFragment = graphql(`
  fragment TenantUserSettingEnableSoundEffects on TenantUserSettings @_unmask {
    enableSoundEffects
  }
`);

export const tenantUserSettingFragment = graphql(`
  fragment TenantUserSetting on TenantUserSettings @_unmask {
    __typename
    tenantId
    userId
    customNoteInstructions
    enableSoundEffects
    promptTemplateId
    llmOutputTemplateId
    inputLanguage
    outputLanguage
    preferredDateFormat
    providerDetails
  }
`);

export const tenantUserSettingsFragment = graphql(
  `
    fragment TenantUserSettings on TenantUsers @_unmask {
      __typename
      tenantId
      userId
      settings {
        ...TenantUserSetting
      }
    }
  `,
  [tenantUserSettingFragment],
);

export const medicalNotesTemplatesFragment = graphql(
  `
    fragment MedicalNotesTemplates on query_root @_unmask {
      __typename
      activePromptTemplates(
        where: { outputType: { _in: ["SOAP_NOTE", "DYNAMIC_OUTPUT"] } }
        orderBy: { name: ASC }
      ) {
        __typename
        id
        isCurrentDefault
        name
        description
        outputType
      }
    }
  `,
  [llmOutputTemplateFragment],
);

export const enabledTenantLlmOutputTemplateFragment = graphql(
  `
    fragment TenantLlmOutputTemplate on Tenants {
      tenant_llm_output_templates(
        where: {
          isEnabled: { _eq: true }
          llm_output_template: { isActive: { _eq: true } }
        }
      ) {
        __typename
        tenantId
        llmOutputTemplateId
        llm_output_template {
          ...LlmOutputTemplate
          __typename
          id
        }
      }
      __typename
      id
    }
  `,
  [llmOutputTemplateFragment],
);

export const enabledUserLlmOutputTemplatesFragment = graphql(
  `
    fragment UserLlmOutputTemplates on TenantUsers {
      users_llm_output_templates(
        where: { llm_output_template: { isActive: { _eq: true } } }
      ) {
        __typename
        userId
        tenantId
        llmOutputTemplateId
        llm_output_template {
          ...LlmOutputTemplate
        }
      }
    }
  `,
  [llmOutputTemplateFragment],
);

export const getTenantUserSettingsQuery = graphql(
  `
    query getTenantUserSettings($tenantId: uuid!, $userId: uuid!) {
      tenantUsersByPk(tenantId: $tenantId, userId: $userId) {
        ...TenantUserSettings
        ...UserLlmOutputTemplates
      }
      tenantsByPk(id: $tenantId) {
        __typename
        id
        ...TenantLlmOutputTemplate
      }
      ...MedicalNotesTemplates
    }
  `,
  [
    tenantUserSettingsFragment,
    medicalNotesTemplatesFragment,
    enabledTenantLlmOutputTemplateFragment,
    enabledUserLlmOutputTemplatesFragment,
  ],
);

export const upsertPromptTemplateIdMutation = graphql(`
  mutation upsertPromptTemplateIdForUser(
    $tenantId: uuid!
    $userId: uuid!
    $promptTemplateId: uuid!
    $llmOutputTemplateId: uuid
  ) {
    updateTenantUserSettingsByPk(
      pkColumns: { tenantId: $tenantId, userId: $userId }
      _set: {
        promptTemplateId: $promptTemplateId
        llmOutputTemplateId: $llmOutputTemplateId
      }
    ) {
      __typename
      tenantId
      userId
      promptTemplateId
      llmOutputTemplateId
    }
  }
`);

export const updateProviderDetailsMutation = graphql(`
  mutation updateProviderDetails(
    $tenantId: uuid!
    $userId: uuid!
    $providerDetails: String!
  ) {
    updateTenantUserSettingsByPk(
      pkColumns: { tenantId: $tenantId, userId: $userId }
      _set: { providerDetails: $providerDetails }
    ) {
      __typename
      tenantId
      userId
      providerDetails
    }
  }
`);

export const upsertEnableSoundEffectsMutation = graphql(
  `
    mutation upsertEnableSoundEffects(
      $tenantId: uuid!
      $userId: uuid!
      $enableSoundEffects: Boolean!
    ) {
      updateTenantUserSettingsByPk(
        pkColumns: { tenantId: $tenantId, userId: $userId }
        _set: { enableSoundEffects: $enableSoundEffects }
      ) {
        __typename
        tenantId
        userId
        enableSoundEffects
      }
    }
  `,
  [tenantUserSettingFragment],
);

export const updateInputLanguageMutation = graphql(`
  mutation upsertInputLanguageMutation(
    $tenantId: uuid!
    $userId: uuid!
    $inputLanguage: String
  ) {
    updateTenantUserSettingsByPk(
      pkColumns: { tenantId: $tenantId, userId: $userId }
      _set: { inputLanguage: $inputLanguage }
    ) {
      __typename
      tenantId
      userId
      inputLanguage
    }
  }
`);

export const updateOutputLanguageMutation = graphql(`
  mutation upsertOutputLanguageMutation(
    $tenantId: uuid!
    $userId: uuid!
    $outputLanguage: String
  ) {
    updateTenantUserSettingsByPk(
      pkColumns: { tenantId: $tenantId, userId: $userId }
      _set: { outputLanguage: $outputLanguage }
    ) {
      __typename
      tenantId
      userId
      outputLanguage
    }
  }
`);

export const userFragment = graphql(`
  fragment User on Users @_unmask {
    __typename
    id
    firstName
    lastName
    email
  }
`);

export const updateUserProfileMutation = graphql(
  `
    mutation updateUserProfile(
      $userId: uuid!
      $firstName: String!
      $lastName: String!
    ) {
      updateUsersByPk(
        pkColumns: { id: $userId }
        _set: { firstName: $firstName, lastName: $lastName }
      ) {
        id
        __typename
        ...User
      }
    }
  `,
  [userFragment],
);
