import { usePostHog } from 'posthog-js/react';
import { useState } from 'react';

import { QUERY_ROOT_ID, useCompleteFragment } from '@eluve/apollo-client';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  HStack,
  InputLabel,
  Label,
  NewButton,
  P,
  Switch,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  VStack,
  textStyles,
} from '@eluve/components';
import { useAppointmentId } from '@eluve/frontend-appointment-hooks';
import { AppointmentLanguageSelector } from '@eluve/frontend-feature-appointment';
import { medicalNotesTemplatesFragment } from '@eluve/frontend-feature-user-settings';
import { LlmOutputTypesLookup } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';
import { useTenantIdFromParams } from '@eluve/session-helpers';
import {
  AppointmentUserMicSettings,
  FeatureFlaggedComponent,
  MicrophonePermissionProvider,
  NewAppointmentTemplateSelector,
  TenantOrUserFeatureFlaggedComponent,
  useSelectedMicrophoneDevice,
} from '@eluve/smart-blocks';

import { useTranscriptionMachineActor } from '../../machines/transcription/TranscriptionMachineProvider';
import { UploadAudioFileButton } from '../UploadAudioFileButton';

const AppointmentTemplateFragment = graphql(`
  fragment AppointmentTemplate on Appointments {
    __typename
    id
    initialPromptTemplateId
    initialLlmOutputTemplateId
  }
`);

export interface NewAppointmentFormDefaultProps {
  isCompactMicrophone: boolean;
  isSettingsAvailable: boolean;
}

export const NewAppointmentFormDefault: React.FC<
  NewAppointmentFormDefaultProps
> = ({ isCompactMicrophone, isSettingsAvailable }) => {
  const appointmentId = useAppointmentId();
  const tenantId = useTenantIdFromParams();
  const actor = useTranscriptionMachineActor();

  const [selectedMicId, setSelectedMicId] = useSelectedMicrophoneDevice();
  const [hasPreviouslySelectedMicId, setHasPreviouslySelectedMicId] =
    useState(true);
  const [playDisclaimer, setPlayDisclaimer] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(isSettingsAvailable);
  const postHog = usePostHog();

  const appointmentData = useCompleteFragment({
    fragment: AppointmentTemplateFragment,
    key: {
      id: appointmentId,
    },
  });

  const startSession = async () => {
    const microphoneName = (
      await navigator.mediaDevices.enumerateDevices()
    ).find((device) => device.deviceId === selectedMicId)?.label;

    postHog.capture('started_session', {
      appointmentId,
      microphoneName,
    });

    window.eluveMediaStreams.stopAll();

    actor.send({
      type: 'START',
      appointmentId,
      deviceId: selectedMicId ?? undefined,
      microphoneName,
      playDisclaimer,
    });
  };

  const medicalNotesTemplatesData = useCompleteFragment({
    fragment: medicalNotesTemplatesFragment,
    key: QUERY_ROOT_ID,
  });

  const selectedTemplateId = appointmentData?.initialPromptTemplateId;
  const templates = medicalNotesTemplatesData?.activePromptTemplates ?? [];
  const selectedTemplate = templates.find(
    (template) => template.id === selectedTemplateId,
  );

  const isTemplateSelected =
    selectedTemplate?.outputType === LlmOutputTypesLookup.DYNAMIC_OUTPUT
      ? Boolean(appointmentData?.initialLlmOutputTemplateId)
      : true;

  const canStartSession =
    Boolean(selectedMicId) && isTemplateSelected && hasPreviouslySelectedMicId;

  return (
    <VStack wFull gap={6}>
      <VStack wFull align="stretch" gap={0}>
        <Collapsible open={settingsOpen} onOpenChange={setSettingsOpen}>
          {isSettingsAvailable && (
            <HStack className="mb-5" align="stretch" justify="between" wFull>
              <P
                className={textStyles.body({
                  color: 'primary',
                  weight: 'semibold',
                  size: 'm',
                })}
              >
                Session Settings
              </P>
              <CollapsibleTrigger asChild>
                <NewButton
                  size="s"
                  text={`View ${settingsOpen ? 'Less' : 'More'} Options`}
                  type="outline"
                />
              </CollapsibleTrigger>
            </HStack>
          )}

          <MicrophonePermissionProvider>
            <AppointmentUserMicSettings
              isCompact={isCompactMicrophone}
              selectedMicId={selectedMicId}
              setSelectedMicId={setSelectedMicId}
              setHasPreviouslySelectedMicId={setHasPreviouslySelectedMicId}
            />
          </MicrophonePermissionProvider>

          <CollapsibleContent
            className='h-0 data-[state="open"]:h-auto data-[state="closed"]:overflow-hidden data-[state="open"]:pt-5'
            data-state={settingsOpen ? 'open' : 'closed'}
            forceMount
          >
            <NewAppointmentTemplateSelector
              tenantId={tenantId!}
              appointmentId={appointmentId}
            />

            <TenantOrUserFeatureFlaggedComponent flag="MULTILINGUAL_SUPPORT">
              <div className="mt-5">
                <AppointmentLanguageSelector inputLanguage outputLanguage />
              </div>
            </TenantOrUserFeatureFlaggedComponent>

            <FeatureFlaggedComponent flag="SHOW_PLAY_DISCLAIMER_OPTION">
              <InputLabel className="mt-5" label="Disclaimer">
                <HStack
                  className="rounded-md border border-input bg-background px-3 py-2"
                  gap={3}
                >
                  <Switch
                    id="play-disclaimer"
                    checked={playDisclaimer}
                    onCheckedChange={setPlayDisclaimer}
                  />
                  <Label
                    className="text-sm font-normal text-contentSecondary"
                    htmlFor="play-disclaimer"
                  >
                    Play disclaimer before recording
                  </Label>
                </HStack>
              </InputLabel>
            </FeatureFlaggedComponent>
          </CollapsibleContent>
        </Collapsible>
      </VStack>

      <HStack className="mb-8 mt-2" wFull justify="end">
        <FeatureFlaggedComponent flag="ALLOW_AUDIO_FILE_UPLOAD_FOR_APPOINTMENT">
          <UploadAudioFileButton appointmentId={appointmentId} />
        </FeatureFlaggedComponent>
        <Tooltip>
          <TooltipTrigger asChild>
            <NewButton
              onClick={startSession}
              size="l"
              disabled={!canStartSession}
              text="Start Session"
              wFull
            />
          </TooltipTrigger>
          {(!selectedMicId || !hasPreviouslySelectedMicId) && (
            <TooltipContent>
              Select a microphone to start the session.
            </TooltipContent>
          )}
        </Tooltip>
      </HStack>
    </VStack>
  );
};
