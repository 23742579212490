import { zodResolver } from '@hookform/resolvers/zod';
import { HelpCircle } from 'lucide-react';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';

import { useApiClient } from '@eluve/api-client-provider';
import { cacheUtils } from '@eluve/apollo-client';
import { TooltipLabel } from '@eluve/blocks';
import {
  Box,
  Combobox,
  ComboboxDropdown,
  ComboboxOption,
  ComboboxSelectButton,
  ComboboxSelectCheck,
  Dialog,
  DialogContent,
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  H2,
  HStack,
  Input,
  NewButton,
  VStack,
  toast,
} from '@eluve/components';
import { externalEhrsFragment } from '@eluve/frontend-feature-location-hierarchy';
import { SupportedVendors, VendorTypesLookup } from '@eluve/graphql-types';
import { getVendorDataMapper } from '@eluve/vendor';

interface NewEhrFormProps {
  open: boolean;
  onOpenChange: (o: boolean) => void;
}

const baseFormSchema = z.object({
  vendor: z.nativeEnum(VendorTypesLookup),
  domain: z
    .string()
    .min(1)
    .transform((val) => {
      const domain = val
        .replace(/^(https?:\/\/)?(www\.)?/i, '')
        .replace(/\/$/, '');
      return domain;
    }),
});

export const NewEhrForm: React.FC<NewEhrFormProps> = ({
  open,
  onOpenChange,
}) => {
  const apiClient = useApiClient();
  const [isCreating, setIsCreating] = useState(false);

  const { tenantId, locationId } = useParams() as {
    tenantId: string;
    locationId: string;
  };

  const [selectedVendor, setSelectedVendor] = useState<
    SupportedVendors | undefined
  >(undefined);

  const vendorProvider = selectedVendor
    ? getVendorDataMapper(selectedVendor)
    : undefined;

  const dynamicSchema = useMemo(() => {
    return vendorProvider?.getVendorSchema
      ? baseFormSchema.extend(vendorProvider.getVendorSchema().shape)
      : baseFormSchema;
  }, [vendorProvider]);

  type DynamicSchemaType = z.infer<typeof dynamicSchema>;

  const newEhrForm = useForm<DynamicSchemaType>({
    resolver: zodResolver(dynamicSchema),
    defaultValues: {
      vendor: undefined,
      domain: '',
    } as Partial<DynamicSchemaType>,
  });

  const fields = useMemo(() => {
    return Object.keys(dynamicSchema.shape ?? {}).filter(
      (field) => field !== 'vendor' && field !== 'domain',
    );
  }, [dynamicSchema]);

  const onSubmitNewEhr = async (data: DynamicSchemaType) => {
    const { vendor, domain, ...vendorConfiguration } = data;
    try {
      setIsCreating(true);
      const response = await apiClient.locations.addEhr({
        body: {
          vendor,
          domain,
          vendorConfiguration,
        },
        params: { tenantId, locationId },
      });

      if (response.status !== 201) {
        toast.error('Failed to update location details');
        return;
      }
      const { externalEhrId } = response.body;

      cacheUtils.updateFragment(
        {
          fragment: externalEhrsFragment,
          key: { id: tenantId },
        },
        (existing) => {
          if (
            !existing ||
            existing.externalEhrs.find((ehr) => ehr.id === externalEhrId)
          ) {
            return existing;
          }
          return {
            __typename: 'Tenants' as const,
            id: tenantId,
            externalEhrs: [
              ...(existing.externalEhrs || []),
              {
                domain,
                vendor,
                id: externalEhrId,
                __typename: 'ExternalEhr' as const,
              },
            ],
          };
        },
      );

      newEhrForm.reset({
        vendor: undefined,
        domain: '',
      });
      onOpenChange(false);
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <Box>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="flex flex-col items-center justify-center gap-5 p-10">
          <VStack wFull align="stretch">
            <H2>Create new EHR</H2>

            <Form {...newEhrForm}>
              <form onSubmit={newEhrForm.handleSubmit(onSubmitNewEhr)}>
                <VStack gap={2} wFull align="stretch">
                  <FormField
                    control={newEhrForm.control}
                    name="vendor"
                    render={({ field, fieldState: { error } }) => (
                      <FormItem>
                        <FormLabel>Vendor</FormLabel>
                        <Combobox>
                          <ComboboxSelectButton className="w-full border-transparent hover:bg-gray-3">
                            <HStack>{field.value ?? 'Select Vendor'}</HStack>
                          </ComboboxSelectButton>
                          <ComboboxDropdown searchPlaceholder="Select Vendor">
                            {(
                              Object.keys(
                                VendorTypesLookup,
                              ) as SupportedVendors[]
                            ).map((vendor) => (
                              <ComboboxOption
                                key={vendor}
                                onSelect={() => {
                                  field.onChange(vendor);
                                  setSelectedVendor(vendor);
                                }}
                              >
                                <ComboboxSelectCheck
                                  className="mr-2"
                                  selected={field.value === vendor}
                                />
                                {vendor}
                              </ComboboxOption>
                            ))}
                          </ComboboxDropdown>
                        </Combobox>
                        {error && (
                          <FormMessage className="mt-4">
                            {error.message}
                          </FormMessage>
                        )}
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={newEhrForm.control}
                    name="domain"
                    render={({ field, fieldState: { error } }) => (
                      <FormItem>
                        <FormLabel>Domain</FormLabel>
                        <Input placeholder="Enter Domain" {...field} />
                        {error && <FormMessage>{error.message}</FormMessage>}
                      </FormItem>
                    )}
                  />
                  {selectedVendor &&
                    fields.map((field) => (
                      <FormField
                        key={field}
                        control={newEhrForm.control}
                        name={field as keyof DynamicSchemaType}
                        render={({ field, fieldState: { error } }) => (
                          <FormItem>
                            <FormLabel>
                              <HStack>
                                {field.name}
                                {dynamicSchema.shape[field.name]
                                  .description && (
                                  <TooltipLabel
                                    tooltipContentProps={{
                                      align: 'start',
                                      alignOffset: -50,
                                      sideOffset: 8,
                                      className:
                                        'bg-white border-none text-gray-11 max-w-60 text-wrap shadow-md',
                                    }}
                                    label={
                                      dynamicSchema.shape[field.name]
                                        .description!
                                    }
                                  >
                                    <HelpCircle size={13} />
                                  </TooltipLabel>
                                )}
                              </HStack>
                            </FormLabel>
                            <Input
                              placeholder={`Enter ${field.name}`}
                              {...field}
                            />
                            {error && (
                              <FormMessage>{error.message}</FormMessage>
                            )}
                          </FormItem>
                        )}
                      />
                    ))}
                  <HStack gap={2}>
                    <NewButton
                      submit
                      text="Add new EHR"
                      wFull
                      size="m"
                      disabled={!newEhrForm.formState.isDirty || isCreating}
                    />
                    <NewButton
                      wFull
                      type="subtle"
                      size="m"
                      text="Cancel"
                      onClick={() => onOpenChange(false)}
                    />
                  </HStack>
                </VStack>
              </form>
            </Form>
          </VStack>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
