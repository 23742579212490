import { useEffect, useState } from 'react';
import { useMount } from 'react-use';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  NewButton,
} from '@eluve/components';
import { formatSecondsToTime } from '@eluve/date-utils';
import { useNamedLogger } from '@eluve/logger';
import { useCaptureEvent } from '@eluve/posthog-react';
import { useDialog } from '@eluve/utility-hooks';

import {
  useTranscriptionMachineActor,
  useTranscriptionMachineSelector,
} from '../TranscriptionMachineProvider';

const AreYouStillThereDialog = ({
  seconds: initialSeconds,
}: {
  seconds: number;
}) => {
  const [seconds, setSeconds] = useState<number>(initialSeconds);
  const { isDialogOpen: isOpen, toggleDialog: toggle } = useDialog(true);

  const captureEvent = useCaptureEvent();
  const logger = useNamedLogger('UserPresenceDialog');

  const { send } = useTranscriptionMachineActor();
  const appointmentId = useTranscriptionMachineSelector(
    (snapshot) => snapshot.context.sessionAppointmentId,
  );

  useMount(() => {
    logger.info('UserPresenceDialog shown', { appointmentId });
  });

  useEffect(() => {
    if (seconds === 0) {
      return;
    }

    const intervalId = setInterval(() => {
      setSeconds((previousValue) => previousValue - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [seconds]);

  const onContinue = () => {
    captureEvent('user_presence_session_continued');
    send({ type: 'CONTINUE' });
  };

  const onPause = () => {
    captureEvent('user_presence_session_paused');
    send({ type: 'PAUSE' });
  };

  const handleOpenChange = () => {
    toggle();
    captureEvent('user_presence_dismissed');
    send({ type: 'CONTINUE' });
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent close={false}>
        <DialogHeader>
          <DialogTitle>This Session Is Longer Than Usual</DialogTitle>
          <DialogDescription className="pb-1 pt-2">
            If you would like to continue recording, please click the button
            below. Otherwise, in
            <span className="font-semibold">
              {` ${formatSecondsToTime(seconds, true)} `}
            </span>
            the session will be automatically paused. You can still resume it
            later if needed.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <NewButton onClick={onContinue} text="Continue Recording" />
          <NewButton onClick={onPause} text="Pause Now" type="subtle" />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export const UserPresenceDialog = () => {
  const shouldCheckUserPresence = useTranscriptionMachineSelector((snapshot) =>
    snapshot.matches({
      InSession: { Recording: { UserPresence: 'Checking' } },
    }),
  );
  const userPresenceResponseWindowMs = useTranscriptionMachineSelector(
    (snapshot) => snapshot.context.userPresenceResponseWindowMs,
  );

  if (!shouldCheckUserPresence) {
    return null;
  }

  return (
    <AreYouStillThereDialog seconds={userPresenceResponseWindowMs / 1000} />
  );
};
