import React from 'react';
import { useParams } from 'react-router-dom';

import { useCompleteFragment } from '@eluve/apollo-client';
import { Box, H1, HStack, P, VStack, textStyles } from '@eluve/components';
import { addOneHour, getTimeDisplay } from '@eluve/date-utils';
import { activeAppointmentLayoutFragment } from '@eluve/frontend-appointment-hooks';
import { useUserName } from '@eluve/smart-blocks';

import { useChat } from './ConversationContextProvider';

interface ConversationInfoPanelProps {
  providerAvatarUrl?: string;
}

export const ConversationInfoPanel: React.FC<
  React.PropsWithChildren<ConversationInfoPanelProps>
> = ({ providerAvatarUrl, children }) => {
  const { appointmentId } = useParams() as { appointmentId: string };
  const appointment = useCompleteFragment({
    fragment: activeAppointmentLayoutFragment,
    key: {
      id: appointmentId,
    },
  });
  const userName = useUserName(appointment.userId ?? '');
  const providerName = `Dr. ${userName}`;
  const appointmentStartTime = appointment.startDate;
  const appointmentEndTime = appointment.endDate;
  const appointmentLocation = appointment.location?.name;
  const appointmentReason = appointment.patientReasonForVisit;
  const localStartTime = new Date(appointmentStartTime);
  const localEndTime = new Date(
    appointmentEndTime ? appointmentEndTime : addOneHour(localStartTime),
  );

  const calculatePosition = () => {
    if (!localStartTime || !localEndTime) return { top: '0', height: 'h-12' };

    const hour = localStartTime.getHours();
    const minutes = localStartTime.getMinutes();

    const firstDisplayedHour = localStartTime.getHours() - 1; // The first displayed hour
    const hourRelativeToFirst = hour - firstDisplayedHour;
    // 1 hour = 3rem
    const hourOffset = hourRelativeToFirst;
    const minuteOffset = Math.floor(minutes / 60); // Each 5 minutes is h-1

    const topPosition = (hourOffset + minuteOffset) * 3;

    // Calculate height based on duration
    const durationInMs = localEndTime.getTime() - localStartTime.getTime();
    const durationInMinutes = durationInMs / (1000 * 60);

    // Each 5 minutes is h-1, so 1 hour is h-12
    const heightClass = `h-${Math.min(Math.ceil(durationInMinutes / 5), 36)}`;

    return {
      top: `${topPosition}rem`,
      height: heightClass,
    };
  };

  const { top, height } = calculatePosition();

  const hoursToDisplay = 3;
  const timeSlots = Array.from({ length: hoursToDisplay }, (_, i) => {
    if (!localStartTime) return '';
    // Get the starting hour (1 hour before appointment, regardless of minutes)
    const startHour = localStartTime.getHours() - 1;
    const hour = startHour + i;
    const amPm = hour >= 12 ? 'PM' : 'AM';
    const displayHour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour; // Handle midnight as 12 AM
    return `${displayHour} ${amPm}`;
  });
  const { isConnected, isCompleted } = useChat();
  if (isConnected || isCompleted) return null;

  return (
    <VStack className="h-full p-6" gap={6}>
      <Box className="w-fit rounded-lg bg-[#ffdca5] px-2.5 py-1.5 text-xs font-semibold text-[#8F5914]">
        New Patient
      </Box>
      <H1>Initial consultation</H1>
      <HStack gap={3}>
        <img
          src={providerAvatarUrl ?? 'path-to-image'}
          alt="Dr Avatar"
          className="size-6 rounded-full bg-white"
        />
        <P className={'text-contentPrimary'}>
          {providerName ?? 'Dr. Jane Doe'}
        </P>
      </HStack>
      <HStack
        className="grid grid-cols-[55px_1fr] rounded-lg border border-brandGray200 bg-brandGray100"
        gap={0}
        justify="start"
        align="start"
      >
        <Box className="border-r border-inherit font-medium text-brandGray500">
          {timeSlots.map((timeSlot, index) => (
            <Box
              key={index}
              className="flex h-12 items-center justify-end py-2 pr-2.5"
            >
              <P className="text-xs text-contentTertiary">{timeSlot}</P>
            </Box>
          ))}
        </Box>
        <Box className="relative border-brandGray200">
          {Array.from({ length: hoursToDisplay * 2 }, (_, i) => (
            <Box
              key={i}
              className={`h-6 ${i < hoursToDisplay * 2 - 1 ? 'border-b border-inherit' : ''}`}
            />
          ))}

          {localStartTime && localEndTime && (
            <Box
              className={`absolute ${height} w-full overflow-hidden border-l-2 border-black bg-white px-2 py-1.5 shadow-sm`}
              style={{ top }}
            >
              <P className="text-sm text-contentTertiary">
                {getTimeDisplay(localStartTime, localEndTime)}
              </P>
              <Box
                className={textStyles.body({
                  weight: 'semibold',
                  size: 'm',
                  className: 'text-brandGray900',
                })}
              >
                {appointmentLocation}
              </Box>
            </Box>
          )}
        </Box>
      </HStack>
      <VStack className="rounded-lg bg-brandGray100 p-4" gap={2}>
        <P>
          <strong className="text-brandGray700">Reason for visit:</strong>
        </P>
        <P className="text-brandGray700">
          {appointmentReason ?? 'Demo Reason'}
        </P>
      </VStack>
      {children}
    </VStack>
  );
};
