import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import { NuqsAdapter } from 'nuqs/adapters/react-router';
import pdfJSWorkerURL from 'pdfjs-dist/build/pdf.worker.mjs?url';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { pdfjs } from 'react-pdf';
import { RouterProvider } from 'react-router-dom';
import SuperTokens from 'supertokens-auth-react';
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
import Passwordless from 'supertokens-auth-react/recipe/passwordless';
import Session from 'supertokens-auth-react/recipe/session';
import ThirdParty from 'supertokens-auth-react/recipe/thirdparty';
import { v7 } from 'uuid';

import { ApiClientContextProvider } from '@eluve/api-client-provider';
import { Toaster, setupEluveMediaStreams } from '@eluve/components';
import { Logger, LoggingContextProvider } from '@eluve/logger';

import { appConfig } from './config';
import { reactRouter } from './router';

pdfjs.GlobalWorkerOptions.workerSrc = pdfJSWorkerURL;

const url = window.location.pathname;
const regex = /\/tenants\/([a-fA-F0-9-]+)\//;
const regexMatch = url.match(regex);
const tenantId = regexMatch ? regexMatch[1] : null;
const isProd = appConfig.VITE_METRICS_ENV === 'production';
const datadogSite = 'us5.datadoghq.com';
const datadogClientToken = 'pub1f5d27dc59ce54610ad0852ff58ac9f2';
const datadogService = 'web-vite';
const isSiloamProd = appConfig.VITE_API_DOMAIN.includes('siloam');

setupEluveMediaStreams();

if (tenantId) {
  datadogRum.init({
    applicationId: '47b6da0d-6022-4116-b4bb-4a526a88dfa4',
    clientToken: datadogClientToken,
    site: datadogSite,
    service: datadogService,
    env: appConfig.VITE_METRICS_ENV,
    sessionSampleRate: 100, // We can adjust this based on cost and need
    sessionReplaySampleRate: 100,
    trackUserInteractions: !isProd || isSiloamProd, // tracks user interactions such as clicks, form submissions, and other interactions with the web application.
    trackResources: true, // Track resources (ajax, css, js)
    trackLongTasks: !isProd || isSiloamProd, // enable the tracking of long tasks. Long tasks are browser tasks that take longer than 50 milliseconds to execute. These tasks can block the main thread, leading to delays in processing user inputs and rendering updates, which can negatively affect the user experience by causing noticeable UI delays and jank.
    defaultPrivacyLevel: 'mask-user-input',
    trackingConsent: 'not-granted',
  });
}

if (appConfig.VITE_METRICS_ENV !== 'local-dev') {
  // Documentation: https://docs.datadoghq.com/logs/log_collection/javascript/
  datadogLogs.init({
    clientToken: datadogClientToken,
    site: datadogSite,
    forwardErrorsToLogs: false, // Do not forward console.error logs to Datadog
    sessionSampleRate: 100,
    service: datadogService,
    env: appConfig.VITE_METRICS_ENV,
  });

  try {
    Sentry.init({
      dsn: 'https://d1cb9656d1b6d1c6bfd495a2ffeb43f7@o4506339234480128.ingest.sentry.io/4506384339763200',
      // See https://docs.sentry.io/platforms/javascript/configuration/integrations/ for available integrations.
      integrations: [],
      // See https://docs.sentry.io/platforms/javascript/performance/ for Performance Monitoring options

      // https://docs.sentry.io/platforms/javascript/configuration/options/#attach-stacktrace
      attachStacktrace: true,
      environment: appConfig.VITE_METRICS_ENV,
      beforeSend: (event) => {
        event.tags = {
          ...event.tags,
          tenantId,
        };
        return event;
      },
      enabled: appConfig.VITE_ENV !== 'development',
    });
  } catch (error) {
    // Sentry requires internet connection to initialize, so we catch the error here
  }
}

const rootLogger = new Logger('eluve-root-logger', {
  metadata: { appVersion: appConfig.VITE_APP_VERSION, tabId: v7() },
});

SuperTokens.init({
  appInfo: {
    appName: 'eluve',
    websiteDomain: appConfig.VITE_SUPERTOKENS_WEBSITE_DOMAIN,
    websiteBasePath: '/login',
    apiDomain: appConfig.VITE_API_DOMAIN,
    apiBasePath: '/api/auth',
  },
  recipeList: [
    Session.init(),
    ThirdParty.init({
      signInAndUpFeature: {
        providers: [ThirdParty.Google.init()],
      },
    }),
    EmailPassword.init(),
    Passwordless.init({
      contactMethod: 'EMAIL',
    }),
  ],
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <StrictMode>
    <LoggingContextProvider loggerInput={rootLogger}>
      <ApiClientContextProvider baseUrl={appConfig.VITE_API_DOMAIN}>
        <NuqsAdapter>
          <RouterProvider router={reactRouter} />
        </NuqsAdapter>
      </ApiClientContextProvider>
      <Toaster />
    </LoggingContextProvider>
  </StrictMode>,
);
