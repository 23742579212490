import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';

import {
  Content,
  Header,
  PageTitle,
  SidebarTrigger,
  TableSkeleton,
} from '@eluve/components';
import { useCaptureEventOnMount } from '@eluve/posthog-react';

import { PatientsList } from './PatientsList';

export const PatientsListPage: React.FC = () => {
  useCaptureEventOnMount('page_view_patients_list');
  return (
    <>
      <Helmet>
        <title>Patients | Eluve</title>
      </Helmet>
      <Header>
        <SidebarTrigger />
        <PageTitle>Patients</PageTitle>
      </Header>
      <Content padded>
        <Suspense fallback={<TableSkeleton numRows={20} />}>
          <PatientsList />
        </Suspense>
      </Content>
    </>
  );
};
