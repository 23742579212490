import { useLazyQuery } from '@apollo/client';
import { ResultOf } from '@graphql-typed-document-node/core';
import debounce from 'lodash/debounce';
import { ContactRoundIcon } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';

import { useCompleteFragment } from '@eluve/apollo-client';
import { tv } from '@eluve/components';
import {
  Avatar,
  AvatarFallback,
  Box,
  Combobox,
  ComboboxDropdown,
  ComboboxSelectButton,
} from '@eluve/components';
import {
  AppointmentPatientIdFragment,
  useAppointmentId,
} from '@eluve/frontend-appointment-hooks';

import { PatientComboboxContent } from './PatientComboboxContent';
import { PatientOption } from './PatientOption';
import { patientOptionFragment, searchPatientsQuery } from './operations';

interface PatientSelectorProps {
  disabled?: boolean;
  tenantId: string;
  onPatientSelected?: (patientId?: string) => void;
}

const selectPatientVariant = tv({
  base: 'w-full px-4 hover:bg-gray-3',
  variants: {
    disabled: {
      true: 'pointer-events-none',
    },
  },
  defaultVariants: {
    disabled: false,
  },
});

export const PatientSelector: React.FC<PatientSelectorProps> = ({
  tenantId,
  onPatientSelected,
  disabled = false,
}) => {
  const [searchResults, setSearchResults] = useState<
    ResultOf<typeof patientOptionFragment>[]
  >([]);
  const [searchPatients, { loading }] = useLazyQuery(searchPatientsQuery);

  const appointmentId = useAppointmentId();

  const { patientId } = useCompleteFragment({
    fragment: AppointmentPatientIdFragment,
    key: {
      id: appointmentId,
    },
  });

  const selectedPatient = useCompleteFragment({
    fragment: patientOptionFragment,
    key: {
      id: patientId ?? '',
    },
    strict: false,
  });

  const handlePatientSelected = (patientId?: string) => {
    onPatientSelected?.(patientId);
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((query: string) => {
        setSearchResults([]);

        if (!query || query.length < 2) {
          return;
        }

        searchPatients({
          variables: { query, limit: 25, tenantId },
        }).then((result) => {
          const patients = (result.data?.searchPatients ?? []).map(
            (patient) => ({
              ...patient,
            }),
          );
          setSearchResults(patients);
        });
      }, 250),
    [searchPatients, tenantId],
  );

  useEffect(
    function cancelDebounceOnUnmount() {
      return () => debouncedSearch.cancel();
    },
    [debouncedSearch],
  );

  return (
    <Combobox disabled={disabled}>
      <ComboboxSelectButton className={selectPatientVariant({ disabled })}>
        {selectedPatient ? (
          <PatientOption {...selectedPatient} />
        ) : (
          <Box hStack>
            <Avatar>
              <AvatarFallback>
                <ContactRoundIcon size={16} />
              </AvatarFallback>
            </Avatar>
            <span className="text-gray-11">Select Patient</span>
          </Box>
        )}
      </ComboboxSelectButton>
      <ComboboxDropdown
        className="pointer-events-auto"
        searchPlaceholder="Search by name, phone, or email"
        shouldFilter={false}
      >
        <PatientComboboxContent
          selectedPatient={selectedPatient}
          searchResults={searchResults}
          loading={loading}
          handlePatientSelected={handlePatientSelected}
          tenantId={tenantId}
          debouncedSearch={debouncedSearch}
          setSearchResults={setSearchResults}
        />
      </ComboboxDropdown>
    </Combobox>
  );
};
