import React, { useState } from 'react';

import { TooltipLabel } from '@eluve/blocks';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  NewButton,
  Textarea,
  toast,
} from '@eluve/components';

type SetFactsFunction = (facts: string) => void;

interface CSVForFactsProps {
  setFacts: SetFactsFunction;
}

const CSVForFacts: React.FC<CSVForFactsProps> = ({ setFacts }) => {
  const [csvContent, setCsvContent] = useState('');
  const handleImport = () => {
    toast.success('CSV updated');
    setFacts(csvContent);
    setCsvContent('');
  };

  return (
    <AlertDialog>
      <TooltipLabel label="Import Facts">
        <AlertDialogTrigger asChild>
          <NewButton type="outline" iconOnly icon={{ name: 'FileUp' }} />
        </AlertDialogTrigger>
      </TooltipLabel>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="text-gray-11">
            TSV of Facts
          </AlertDialogTitle>
          <AlertDialogDescription>
            Paste your TSV content in the field below.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <Textarea
          value={csvContent}
          onChange={(e) => setCsvContent(e.target.value)}
          placeholder="Paste tab-separated value (TSV) content here..."
          rows={10}
        />

        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            className="bg-primary text-gray-1 hover:bg-primary/90"
            onClick={handleImport}
          >
            Update
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CSVForFacts;
