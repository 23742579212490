import { useReactTable } from '@tanstack/react-table';
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { useParams } from 'react-router-dom';

import { useApiClient } from '@eluve/api-client-provider';
import {
  ColDefBuilder,
  Dialog,
  DialogContent,
  NewButton,
  ServerDataTable,
  TableSearch,
  VStack,
  useDataTableForQuery,
} from '@eluve/components';
import { ExternalArtifactTypesLookup } from '@eluve/graphql-types';
import { ResultOf, graphql } from '@eluve/graphql.tada';
import { useDialog } from '@eluve/utility-hooks';

export interface PatientDetailsImportedNotesProps {}

const searchImportedNotesQuery = graphql(`
  query searchImportedNotes(
    $filter: ExternalPatientArtifactsBoolExp
    $offset: Int
    $orderBy: [ExternalPatientArtifactsOrderBy!]
    $limit: Int
  ) {
    externalPatientArtifacts(
      where: $filter
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      __typename
      tenantId
      id
      name
      externalArtifactType
      externalArtifactCreatedAt
      file {
        __typename
        id
        fileUrl
      }
    }
    externalPatientArtifactsAggregate(where: $filter) {
      __typename
      aggregate {
        __typename
        count
      }
    }
  }
`);

const ViewPdfButton: React.FC<{ tenantId: string; fileId: string | null }> = ({
  tenantId,
  fileId,
}) => {
  const client = useApiClient();
  const [signedUrl, setSignedUrl] = useState('');
  const { isDialogOpen: isOpen, toggleDialog: toggle } = useDialog();

  if (!fileId) {
    return null;
  }

  const downloadFile = async () => {
    const response = await client.patients.getExternalFileSignedUrl({
      params: {
        tenantId,
        fileId,
      },
    });

    if (response.status === 200 && response.body.presignedUrl) {
      setSignedUrl(response.body.presignedUrl);
      toggle();
    }
  };

  return (
    <>
      <NewButton text="View PDF" onClick={downloadFile} />
      <Dialog open={isOpen} onOpenChange={toggle}>
        <DialogContent className="max-h-[80vh] max-w-[80vw] overflow-auto">
          {signedUrl && (
            <div>
              <Document file={signedUrl}>
                <Page pageNumber={1} scale={2} />
              </Document>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

type ImportedChartsRow = {
  tenantId: string;
  id: string;
  name: string | null;
  fileId: string | null;
  fileUrl: string | null;
  externalArtifactCreatedAt: string | null;
};

const columns = new ColDefBuilder<ImportedChartsRow>()
  .defaultSortable('name')
  .dateSortable('externalArtifactCreatedAt', 'Created At')
  .colDef({
    header: 'Actions',
    cell: ({
      row: {
        original: { tenantId, fileId, fileUrl },
      },
    }) => {
      return fileUrl?.endsWith('.pdf') ? (
        <ViewPdfButton tenantId={tenantId} fileId={fileId} />
      ) : null;
    },
  })
  .build();

const convertSearchResultsToRows = (
  results: ResultOf<typeof searchImportedNotesQuery>,
): ImportedChartsRow[] => {
  return results.externalPatientArtifacts.map<ImportedChartsRow>((p) => {
    return {
      tenantId: p.tenantId,
      id: p.id,
      name: p.name,
      externalArtifactCreatedAt: p.externalArtifactCreatedAt,
      fileId: p.file?.id ?? null,
      fileUrl: p.file?.fileUrl ?? null,
    };
  });
};

export const PatientDetailsImportedNotes: React.FC<
  PatientDetailsImportedNotesProps
> = () => {
  const { tenantId, patientId } = useParams() as {
    tenantId: string;
    patientId: string;
  };

  const { data, rows, search, setSearch, reactTableOptions, isPending } =
    useDataTableForQuery({
      query: searchImportedNotesQuery,
      convertToRows: convertSearchResultsToRows,
      convertSearchParamsToVariables: ({ limit, offset, search, sorting }) => {
        return {
          filter: {
            _and: [
              {
                tenantId: { _eq: tenantId },
                patientId: { _eq: patientId },
                externalArtifactType: {
                  _eq: ExternalArtifactTypesLookup.HISTORICAL_CHART,
                },
              },

              ...(search ? [{ name: { _ilike: `%${search}%` } }] : []),
            ],
          },
          offset,
          limit,
          orderBy: sorting.map((s) => ({
            [s.id]: s.desc ? 'DESC_NULLS_LAST' : 'ASC_NULLS_LAST',
          })),
        };
      },
    });

  const rowCount = data?.externalPatientArtifactsAggregate?.aggregate?.count;

  const table = useReactTable({
    data: rows,
    columns,
    rowCount,
    ...reactTableOptions,
  });

  return (
    <VStack gap={0} className="rounded-md border">
      <TableSearch
        value={search ?? ''}
        onChange={setSearch}
        placeholder="Search by name..."
      />
      <ServerDataTable<ImportedChartsRow> table={table} isPending={isPending} />
    </VStack>
  );
};
