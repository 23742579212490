import { match } from 'ts-pattern';

import { InputLabel } from '@eluve/components';
import { useAppointmentMode } from '@eluve/frontend-appointment-hooks';
import { DbEnums } from '@eluve/graphql-types';

import { OutputTemplateSelector } from './OutputTemplateSelector';
import { PromptAndOutputTemplateSelector } from './PromptAndOutputTemplateSelector';
import { useAppointmentTemplates } from './useAppointmentTemplates';

interface NewAppointmentTemplateProps {
  tenantId: string;
  appointmentId: string;
  showHeader?: boolean;
}

export const NewAppointmentTemplateSelector: React.FC<
  NewAppointmentTemplateProps
> = ({ tenantId, appointmentId, showHeader = true }) => {
  const {
    initialLlmOutputTemplateId,
    initialPromptTemplateId,
    updateAppointmentTemplates,
    updateAppointmentOutputTemplate,
  } = useAppointmentTemplates();

  const appointmentMode = useAppointmentMode();

  return match(appointmentMode)
    .with(DbEnums.AppointmentMode.AUDIO_COPILOT, () => (
      // In audio copilot mode, we need to select both the prompt and output templates
      <InputLabel label={showHeader ? 'Template' : null}>
        <PromptAndOutputTemplateSelector
          selectedPromptTemplateId={initialPromptTemplateId ?? undefined}
          onSelectionChanged={({
            initialPromptTemplateId,
            initialOutputTemplateId,
          }) =>
            updateAppointmentTemplates({
              variables: {
                tenantId,
                id: appointmentId,
                initialPromptTemplateId,
                initialLlmOutputTemplateId: initialOutputTemplateId,
              },
            })
          }
          selectedOutputTemplateId={initialLlmOutputTemplateId ?? undefined}
        />
      </InputLabel>
    ))
    .with(DbEnums.AppointmentMode.MANUAL_CHARTING, () => (
      // In manual charting mode, we only need to select the output template
      <InputLabel label={showHeader ? 'Output Template' : null}>
        <OutputTemplateSelector
          selectedOutputTemplateId={initialLlmOutputTemplateId ?? undefined}
          onSelectionChanged={(initialOutputTemplateId) =>
            updateAppointmentOutputTemplate({
              variables: {
                tenantId,
                id: appointmentId,
                initialLlmOutputTemplateId: initialOutputTemplateId,
              },
            })
          }
        />
      </InputLabel>
    ))
    .exhaustive();
};
