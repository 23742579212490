import { CalendarX2Icon } from 'lucide-react';
import Balancer from 'react-wrap-balancer';

import { Box, Content, Divider } from '@eluve/components';
import { AppointmentName, EluveAdminOnly } from '@eluve/smart-blocks';

import { AppointmentFilesDrawer } from './AppointmentFilesDrawer';
import { appointmentLayoutStyles } from './appointmentLayoutStyles';
import { ReadonlyAppointmentDetails } from './components/ReadonlyAppointmentDetails';

interface CancelledAppointmentLayoutProps {
  appointmentId: string;
}

export const CancelledAppointmentLayout: React.FC<
  CancelledAppointmentLayoutProps
> = ({ appointmentId }) => {
  return (
    <>
      <ReadonlyAppointmentDetails appointmentId={appointmentId} />
      <Content padded>
        <Box vStack className={appointmentLayoutStyles()}>
          <Box spaceBetween fullWidth className="p-2">
            <AppointmentName readonly />
            <EluveAdminOnly>
              <AppointmentFilesDrawer />
            </EluveAdminOnly>
          </Box>
          <Divider />
          <Box vStack center fullWidth className="gap-12 p-2">
            <CalendarX2Icon size={96} className="text-gray-6" />
            <h3 className="text-center text-xl">
              <Balancer>This appointment was cancelled in your EHR.</Balancer>
            </h3>
          </Box>
        </Box>
      </Content>
    </>
  );
};
