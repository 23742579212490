import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

/**
 * Formats a given date (Date object or string) into a readable format.
 *
 * @example output:
 * "March 20, 2025"
 */
export const formatDateToReadable = (date: Date | string): string => {
  if (!date) return '';
  const parsedDate = typeof date === 'string' ? parseISO(date) : date;
  if (isNaN(parsedDate.getTime())) return '';
  return format(parsedDate, 'MMMM d, yyyy');
};
