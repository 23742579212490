import React from 'react';

import { NewButton } from '@eluve/components';

import { useChat } from './ConversationContextProvider';

export const ConversationCheckInButton: React.FC = () => {
  const { toggleConnection, isConnected, appointmentConversationId } =
    useChat();

  return (
    <NewButton
      onClick={() => toggleConnection(!isConnected, appointmentConversationId)}
      text={isConnected ? 'End Session' : 'Check In'}
      wFull
    />
  );
};
