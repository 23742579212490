import { Text, View } from '@react-pdf/renderer';

import { tw } from '../pdf-tw';
import { commonStyles } from '../styles';

import { PdfParagraph } from './PdfParagraph';

export const PdfText: React.FC<{ text?: string }> = ({ text }) => {
  if (!text) return <Text style={tw(commonStyles.text())}>No content</Text>;

  const paragraphs = text.split('\n').filter(Boolean);

  return (
    <View style={tw('gap-0')}>
      {paragraphs.map((paragraph, i) => (
        <PdfParagraph paragraph={paragraph} key={i} />
      ))}
    </View>
  );
};
