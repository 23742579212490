import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import React from 'react';
import { MultiValueRemoveProps, components } from 'react-select';

import { Icon, tv } from '@eluve/components';

export const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon className="size-3" name="X" size="xs" />
    </components.MultiValueRemove>
  );
};

// This ensures that Emotion's styles are inserted before Tailwind's styles so that Tailwind classes have precedence over Emotion
// https://react-select.com/styles#the-classnames-prop
export const EmotionCacheProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const cache = React.useMemo(
    () =>
      createCache({
        key: 'with-tailwind',
        insertionPoint: document.querySelector('title')!,
      }),
    [],
  );

  return <CacheProvider value={cache}>{children}</CacheProvider>;
};

const styles = tv({
  slots: {
    container: 'w-full',
    control: 'cursor-text rounded-xl border border-brandGray200 p-2',
    valueContainer: 'gap-2 p-0',
    menu: 'z-[9999] mt-3 rounded-2xl border border-borderPrimary p-0 shadow-xl',
    menuList: 'p-3',
    placeholder: 'py-1 text-brandGray600',
    noOptionsMessage: 'text-brandGray600',
    multiValue: 'm-0 -ml-2 -mr-1 size-6 justify-end bg-transparent',
    indicatorSeparator: 'hidden',
    dropdownIndicator: 'hidden',
    loadingIndicator: 'hidden',
    indicatorsContainer: 'hidden',
    multiValueRemove:
      '-my-1 size-8 rounded-full border-8 border-transparent bg-brandGray600 bg-clip-padding p-0.5 text-brandGray50',
    option: 'rounded-lg p-3',
  },
  variants: {
    disabled: {
      true: {
        control: 'bg-white',
      },
    },
    optionFocused: {
      true: {
        option: 'bg-brandGray200',
      },
    },
  },
});

export const searchableStyle = styles();
