import { z } from 'zod';

import { MatchingRecord } from '@eluve/utils';

import { MedicalCodeTypesEnum } from './types';

export const MedicalCodeTypesLookup = {
  CPT: 'CPT',
  ICD_10: 'ICD_10',
  SNOMED: 'SNOMED',
} as const satisfies MatchingRecord<MedicalCodeTypesEnum>;

export const MedicalCodeTypesSchema = z.nativeEnum(MedicalCodeTypesLookup);
