import { graphql } from '@eluve/graphql.tada';

export const patientOptionFragment = graphql(`
  fragment PatientOption on Patients @_unmask {
    __typename
    id
    firstName
    lastName
    dateOfBirth
    external_patients_info {
      __typename
      id
      email
      externalPatientId
    }
  }
`);

export const tenantPatientsFragment = graphql(
  `
    fragment TenantPatients on Tenants {
      __typename
      id
      patients {
        ...PatientOption
      }
    }
  `,
  [patientOptionFragment],
);

export const addNewPatientMutation = graphql(
  `
    mutation AddNewPatient($input: PatientsInsertInput!) {
      insertPatientsOne(object: $input) {
        ...PatientOption
      }
    }
  `,
  [patientOptionFragment],
);

export const searchPatientsQuery = graphql(
  `
    query searchPatients($query: String!, $tenantId: uuid!, $limit: Int) {
      searchPatients(
        args: { search_term: $query, _tenant_id: $tenantId, _limit: $limit }
      ) {
        __typename
        id
        ...PatientOption
      }
    }
  `,
  [patientOptionFragment],
);
