import { useSelector } from '@xstate/react';
import React from 'react';

import { Checkbox } from '@eluve/components';

import { useSegmentAudioContext } from './SegmentAudioContextStore';

interface MemoizedUtteranceCheckboxProps {
  utteranceId: string;
}

export const MemoizedUtteranceCheckbox = React.memo(
  ({ utteranceId }: MemoizedUtteranceCheckboxProps) => {
    const { store } = useSegmentAudioContext();

    const isChecked = useSelector(
      store,
      (state) => state.context.selectedUtteranceIds.includes(utteranceId),
      (prev, next) => prev === next,
    );

    const handleChange = React.useCallback(
      (checked: boolean) => {
        store.send({
          type: checked ? 'selectUtteranceId' : 'deselectUtteranceId',
          utteranceId: utteranceId,
        });
      },
      [store, utteranceId],
    );
    return <Checkbox checked={isChecked} onCheckedChange={handleChange} />;
  },
);
