import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { llmOutputTypesSchema } from '@eluve/graphql-types';

const c = initContract();

export const llmContract = c.router(
  {
    generateAppointmentOutput: {
      method: 'POST',
      path: ':appointmentId/output',
      // TODO(marsela)[ELU-3411]: make user inputs here typesafe
      body: z.object({
        outputType: llmOutputTypesSchema,
        userInputs: z.record(z.string(), z.string()).optional(),
      }),
      responses: {
        201: z.object({
          jobId: z.string(),
        }),
        422: z.object({ message: z.string() }),
      },
    },
    summarizeAppointment: {
      method: 'POST',
      path: ':appointmentId/summarize',
      body: z.object({}).optional(),
      responses: {
        201: z.object({
          jobId: z.string(),
        }),
      },
    },
    summarizeAppointmentWithMultipleTemplates: {
      method: 'POST',
      path: ':appointmentId/summarize-many',
      body: z
        .object({
          summarizationDetails: z.array(
            z.object({
              promptTemplateId: z.string(),
              outputTemplateId: z.string().optional(),
            }),
          ),
        })
        .required(),
      responses: {
        201: z.object({
          jobIds: z.array(z.string()),
        }),
      },
    },
    resummarizeAppointmentWithUserInstructions: {
      method: 'POST',
      path: ':appointmentId/resummarize-with-instructions',
      body: z.object({
        // TODO(marsela)[ELU-3732]: update the API to require llmOutputId
        llmOutputId: z.string().nullable(),
        userInstruction: z.string(),
      }),
      responses: {
        201: z.object({
          jobId: z.string().optional(),
        }),
      },
    },
    recommendTemplate: {
      method: 'POST',
      path: ':appointmentId/recommend-template',
      body: z.object({}).optional(),
      responses: {
        200: z.object({
          id: z.string(),
          name: z.string(),
        }),
      },
    },
  },

  { pathPrefix: '/:tenantId/llm/' },
);
