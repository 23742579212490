import { useSuspenseQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { useCompleteFragment } from '@eluve/apollo-client';
import { Box, HStack, VStack, tv } from '@eluve/components';
import {
  activeAppointmentLayoutFragment,
  getAppointmentDetailsQuery,
} from '@eluve/frontend-appointment-hooks';
import { useUserName } from '@eluve/smart-blocks';

import { ConversationActivePanel } from './ConversationActivePanel';
import { ConversationCheckInButton } from './ConversationCheckInButton';
import { ConversationCompleted } from './ConversationCompleted';
import { ConversationContextProvider } from './ConversationContextProvider';
import { ConversationInfoPanel } from './ConversationInfoPanel';

const DEFAULT_PROVIDER_AVATAR_URL =
  'https://mysiloam-api.siloamhospitals.com/storage-down/doctor/doctor_2022_02_24_10575816456750782431645675078243.jpeg';

const avatarContainerStyles = tv({
  base: 'relative h-[768px] w-112 border-2 border-gray-7 shadow-xl',
});

const bottomBarStyles = tv({
  base: 'absolute bottom-2 left-1/2 flex -translate-x-1/2 justify-center',
});

const bottomIndicatorStyles = tv({
  base: 'h-1 w-32 rounded-full bg-black',
});

type AvatarIntakeProps = {};

const AvatarIntake: React.FC<AvatarIntakeProps> = () => {
  const { tenantId, appointmentId } = useParams() as {
    tenantId: string;
    appointmentId: string;
  };

  const appointment = useCompleteFragment({
    fragment: activeAppointmentLayoutFragment,
    key: {
      id: appointmentId,
    },
  });

  const userName = useUserName(appointment.userId ?? '');
  const providerName = `Dr. ${userName}`;
  const providerAvatarUrl = DEFAULT_PROVIDER_AVATAR_URL;

  return (
    <ConversationContextProvider
      appointmentId={appointmentId}
      tenantId={tenantId}
    >
      <ConversationInfoPanel providerAvatarUrl={providerAvatarUrl}>
        <ConversationCheckInButton />
      </ConversationInfoPanel>
      <ConversationActivePanel
        providerName={providerName}
        providerAvatarUrl={providerAvatarUrl}
      />
      <ConversationCompleted providerName={providerName} />
    </ConversationContextProvider>
  );
};

// Public Components

type AvatarIntakePageProps = {
  // No props needed
};

export const AvatarIntakePage: React.FC<AvatarIntakePageProps> = () => {
  const { tenantId, appointmentId } = useParams() as {
    tenantId: string;
    appointmentId: string;
  };

  useSuspenseQuery(getAppointmentDetailsQuery, {
    variables: {
      tenantId,
      appointmentId,
    },
  });

  return (
    <HStack className="h-screen bg-gray-1" justify="center">
      <VStack className={avatarContainerStyles()}>
        <AvatarIntake />
        <Box className={bottomBarStyles()}>
          <Box className={bottomIndicatorStyles()}>&nbsp;</Box>
        </Box>
      </VStack>
    </HStack>
  );
};
