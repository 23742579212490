import uniqBy from 'lodash/uniqBy';
import promiseRetry from 'p-retry';
import { toast } from 'sonner';

export interface AudioDeviceOption {
  deviceId: string;
  label: string;
}

export const getMicrophoneDevices = async () => {
  const getDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const audioDevices = devices.filter(
      (device) => device.kind === 'audioinput' && device.deviceId,
    );

    const audioDevicesWithLabel = audioDevices
      .filter((device) => device.label)
      .map((device) => ({
        deviceId: device.deviceId,
        label: device.label,
      }));

    const deviceIds = audioDevicesWithLabel.map((device) => device.deviceId);

    const audioDevicesWithoutLabel = audioDevices
      .filter((device) => !device.label && !deviceIds.includes(device.deviceId))
      .map((device, index) => {
        if (device.deviceId === 'default') {
          return {
            deviceId: device.deviceId,
            label: 'Default Audio Input Device',
          };
        }

        return {
          deviceId: device.deviceId,
          label: `Audio Input Device ${index + 1}`,
        };
      });

    const uniqDevices = uniqBy(
      [...audioDevicesWithLabel, ...audioDevicesWithoutLabel],
      (d) => d.deviceId,
    );

    return uniqDevices;
  };

  let audioDevices = await getDevices();

  if (!audioDevices.length) {
    // When no audio devices are found, try to get the devices by requesting
    // audio access from the user.
    try {
      audioDevices = await promiseRetry(
        async () => {
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
          const audioDevices = await getDevices();
          stream.getTracks().forEach((track) => {
            track.stop();
          });

          if (!audioDevices.length) {
            throw new Error('No audio devices found');
          }

          return audioDevices;
        },
        { retries: 3 },
      );
    } catch (_e) {
      toast.error('No audio input devices found');
    }
  }

  return audioDevices satisfies AudioDeviceOption[];
};
