import { useQuery } from '@apollo/client';
import { useReactTable } from '@tanstack/react-table';
import React from 'react';
import { Link } from 'react-router-dom';
import { match } from 'ts-pattern';
import { z } from 'zod';

import { CopyTextButton } from '@eluve/blocks';
import {
  Box,
  Button,
  ColDefBuilder,
  DataTableFilterField,
  ExtendedSortingState,
  HStack,
  NewButton,
  PageTitle,
  ServerDataTable,
  TableSearch,
  VStack,
  makeDefaultSortableColumnDef,
  useDataTableForQuery,
} from '@eluve/components';
import { TenantsBoolExp, TenantsOrderBy } from '@eluve/graphql-types';
import { ResultOf, graphql } from '@eluve/graphql.tada';
import {
  useTenantIdFromSession,
  useUserIdFromSession,
} from '@eluve/session-helpers';

import { UserFragment } from './$tenantId/users/operations';
import { NewTenantAction } from './components/NewTenantAction';
import {
  TenantActionsColumn,
  usersTenantsFragment,
} from './components/TenantActionsColumn';
import { TenantApprovalToggle } from './components/TenantApprovalToggle';
import { getAdminTenantsSummaryFilterQuery } from './components/query';

const uuidSchema = z.string().uuid();

const getUsersTenantsQuery = graphql(
  `
    query getTenantUsersByUserId($userId: uuid!) {
      usersByPk(id: $userId) {
        ...UsersTenants
        ...User
      }
    }
  `,
  [usersTenantsFragment, UserFragment],
);

type TenantRow = {
  id: string;
  name: string;
  isPendingApproval: boolean;
  userCount: number;
  appointmentCount: number;
  createdAt: string;
  isCurrentUserProvisioned: boolean;
  actions: string;
};

const columns = new ColDefBuilder<TenantRow>()
  .colDef({
    id: 'id',
    accessorKey: 'id',
    header: 'Id',
    cell: ({ row }) => <CopyTextButton copyText={row.original.id} />,
  })
  .linkSortable('name', (row) => row.id)
  .defaultSortable('userCount', '# Users')
  .defaultSortable('appointmentCount', '# Appointments')
  .colDef(
    makeDefaultSortableColumnDef('isPendingApproval', {
      label: 'Is Approved',
      cellRenderer: (row) => <TenantApprovalToggle tenantId={row.id} />,
    }),
  )
  .dateSortable('createdAt', 'Created At')
  .colDef({
    id: 'actions',
    header: 'Actions',
    cell: ({ row }) => <TenantActionsColumn tenantId={row.original.id} />,
  })
  .build();

const convertToRows = (
  results: ResultOf<typeof getAdminTenantsSummaryFilterQuery>,
): TenantRow[] => {
  return (results?.tenants ?? []).map<TenantRow>((t) => {
    return {
      id: t.id,
      name: t.name,
      isPendingApproval: t.isPendingApproval,
      appointmentCount: t.appointmentsAggregate?.aggregate?.count ?? 0,
      userCount: t.tenantUsersAggregate?.aggregate?.count ?? 0,
      createdAt: t.createdAt as string,
      isCurrentUserProvisioned: t.isCurrentUserProvisioned ?? false,
      actions: '',
    };
  });
};

const defaultSort: ExtendedSortingState<TenantRow> = [
  { id: 'createdAt', desc: true },
];

const filterFields: DataTableFilterField<TenantRow>[] = [
  {
    id: 'name',
    label: 'Account Type',
    options: [
      { label: 'Show Playwrite Accounts', value: 'TEST' },
      { label: 'Show Normal Accounts', value: 'NOTEST' },
    ],
  },
  {
    id: 'isPendingApproval',
    label: 'Approval Status',
    options: [
      { label: 'Pending', value: 'PEND' },
      { label: 'Approved', value: 'APPROVED' },
    ],
  },
  {
    id: 'actions',
    label: 'Provisioned',
    options: [
      { label: 'Yes', value: 'PROV' },
      { label: 'No', value: 'NOPROV' },
    ],
  },
];

export const AdminTenants: React.FC = () => {
  const userId = useUserIdFromSession();
  const tenantId = useTenantIdFromSession();

  useQuery(getUsersTenantsQuery, {
    variables: {
      userId,
    },
  });

  const { reactTableOptions, isPending, data, rows, setSearch, search } =
    useDataTableForQuery({
      query: getAdminTenantsSummaryFilterQuery,
      filterFields,
      convertToRows,
      defaultSort,
      convertSearchParamsToVariables: ({ offset, limit, filters, sorting }) => {
        const filterConditions: TenantsBoolExp[] = [];

        if (filters.name && filters.name.length === 1) {
          if (filters.name.includes('TEST')) {
            filterConditions.push({
              name: {
                _ilike: 'playwright+testaccount%',
              },
            });
          } else {
            filterConditions.push({
              name: {
                _nilike: 'playwright+testaccount%',
              },
            });
          }
        }

        if (
          filters.isPendingApproval &&
          filters.isPendingApproval.length === 1
        ) {
          filterConditions.push({
            isPendingApproval: {
              _eq: filters.isPendingApproval.includes('PEND') ? true : false,
            },
          });
        }

        if (filters.actions && filters.actions.length === 1) {
          filterConditions.push({
            isCurrentUserProvisioned: {
              _eq: filters.actions.includes('PROV') ? true : false,
            },
          });
        }

        if (search) {
          if (uuidSchema.safeParse(search).success) {
            filterConditions.push({
              id: {
                _eq: search,
              },
            });
          } else {
            filterConditions.push({
              name: {
                _ilike: `%${search}%`,
              },
            });
          }
        }

        // always hide deleted tenants
        filterConditions.push({
          deletedAt: {
            _isNull: true,
          },
        });
        return {
          offset,
          limit,
          filter: {
            _and: filterConditions,
          },
          orderBy:
            sorting && sorting.length
              ? sorting.map(({ desc, id }) => ({
                  ...match<keyof TenantRow, TenantsOrderBy>(id)
                    .with('createdAt', () => ({
                      createdAt: desc ? 'DESC' : 'ASC',
                    }))
                    .with('userCount', () => ({
                      tenantUsersAggregate: {
                        count: desc ? 'DESC' : 'ASC',
                      },
                    }))
                    .with('appointmentCount', () => ({
                      appointmentsAggregate: {
                        count: desc ? 'DESC' : 'ASC',
                      },
                    }))
                    .otherwise(() => ({
                      [id]: desc ? 'DESC' : 'ASC',
                    })),
                }))
              : null,
        };
      },
    });

  const rowCount = data?.tenantsAggregate?.aggregate?.count;

  const table = useReactTable({
    data: rows,
    columns,
    rowCount,
    ...reactTableOptions,
  });

  return (
    <div>
      <Box spaceBetween>
        <PageTitle>All Tenants</PageTitle>
        <HStack gap={2} wFull={false}>
          {tenantId && (
            <Link to={`/admin/tenants/${tenantId}`}>
              <NewButton
                type="outline"
                icon={{ name: 'FileSymlink' }}
                text="Go to Active Tenant"
              />
            </Link>
          )}

          <NewTenantAction />
        </HStack>
      </Box>
      <VStack gap={2} className="rounded-md border">
        <TableSearch
          value={search ?? ''}
          onChange={setSearch}
          placeholder="Search by name or ID..."
        />
        <ServerDataTable<TenantRow>
          table={table}
          filterFields={filterFields}
          isPending={isPending}
        />
      </VStack>
      <div className="mt-5">
        <Button className="m-1" variant="default">
          <Link to={`audit-trail`}>View Audit Trail</Link>
        </Button>
      </div>
    </div>
  );
};
