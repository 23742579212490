type MediaStreamId = 'microphoneTester' | 'sessionRecorder' | 'waveform';

export class EluveMediaStreams extends Map<MediaStreamId, MediaStream | null> {
  stop(key: MediaStreamId) {
    this.get(key)
      ?.getTracks()
      .forEach((track) => {
        track.stop();
      });
  }

  stopAll() {
    this.forEach((mediaStream) => {
      mediaStream?.getTracks().forEach((track) => {
        track.stop();
      });
    });
  }

  set(key: MediaStreamId, value: MediaStream | null) {
    this.stop(key);
    return super.set(key, value);
  }

  clear(): void {
    this.stopAll();
    super.clear();
  }

  delete(key: MediaStreamId): boolean {
    this.stop(key);
    return super.delete(key);
  }

  getTracks(key: MediaStreamId): MediaStreamTrack[] {
    return this.get(key)?.getTracks() ?? [];
  }
}

declare global {
  interface Window {
    eluveMediaStreams: EluveMediaStreams;
  }
}

export const setupEluveMediaStreams = () => {
  window.eluveMediaStreams = new EluveMediaStreams();
};
