import React from 'react';

import { useAppointmentMode } from '@eluve/frontend-appointment-hooks';

export interface HiddenForManualChartingProps {
  children: React.ReactNode;
}

/**
 * Hides children from the UI if the relevant appoinment is set to MANUAL_CHARTING
 */
export const HiddenForManualCharting: React.FC<
  HiddenForManualChartingProps
> = ({ children }) => {
  const isManualMode = useAppointmentMode() === 'MANUAL_CHARTING';

  if (isManualMode) {
    return null;
  }

  return children;
};
