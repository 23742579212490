import { useMutation } from '@apollo/client';

import { cacheUtils } from '@eluve/apollo-client';
import { AmendmentHeader, FCC, VStack, toast } from '@eluve/components';
import {
  appointmentAmendments,
  useAmendmentActivity,
  useAppointmentId,
} from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';
import { useUserName } from '@eluve/smart-blocks';

const deleteBillingCodeAmendmentMutation = graphql(`
  mutation deleteBillingCodeAmendment($tenantId: uuid!, $id: uuid!) {
    deleteBillingCodeAmendmentsByPk(tenantId: $tenantId, id: $id) {
      __typename
      id
    }
  }
`);

export const AppointmentBillingCodesEditorContainer: FCC<{
  refetchBillingCodes: () => void;
}> = ({ children, refetchBillingCodes }) => {
  const appointmentId = useAppointmentId();
  const { isBillingCodeAmendmentInProgress, activeAmendment } =
    useAmendmentActivity();

  const activeAmendmentUserName = useUserName(activeAmendment?.userId ?? '');

  const [deleteBillingCodeAmendment] = useMutation(
    deleteBillingCodeAmendmentMutation,
    {
      optimisticResponse: () => ({
        deleteBillingCodeAmendmentsByPk: {
          __typename: 'BillingCodeAmendments' as const,
          id: activeAmendment!.billing_code_amendment!.id,
        },
      }),
      onError: () => toast.error('Failed to delete billing code amendment'),
      update: () => {
        if (!activeAmendment) {
          return;
        }

        cacheUtils.updateFragment(
          {
            fragment: appointmentAmendments,
            key: { id: appointmentId },
          },
          (existing) => {
            if (!existing) {
              return null;
            }

            return {
              ...existing,
              amendments: existing.amendments.map((amendment) =>
                amendment.id === activeAmendment!.id
                  ? {
                      ...amendment,
                      billing_code_amendment: null,
                    }
                  : amendment,
              ),
            };
          },
        );

        cacheUtils.evict({
          key: { id: activeAmendment!.billing_code_amendment!.id },
          typeName: 'BillingCodeAmendments',
        });
      },
    },
  );

  const onDeleteBillingCodeAmendment = async () => {
    await deleteBillingCodeAmendment({
      variables: {
        tenantId: activeAmendment!.tenantId,
        id: activeAmendment!.billing_code_amendment!.id,
      },
    });

    refetchBillingCodes();
  };

  if (!isBillingCodeAmendmentInProgress) {
    return children;
  }

  return (
    <VStack
      gap={6}
      className="rounded-xl border border-brandGray200 bg-white p-6"
    >
      <AmendmentHeader
        date={activeAmendment!.billing_code_amendment!.createdAt}
        userName={activeAmendmentUserName}
        isReadonly={false}
        onDelete={onDeleteBillingCodeAmendment}
      />

      {children}
    </VStack>
  );
};
