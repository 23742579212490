import format from 'date-fns/format';
import {
  CalendarIcon,
  ClockIcon,
  MapPinIcon,
  MoveHorizontalIcon,
  UserIcon,
} from 'lucide-react';
import { useState } from 'react';

import { cacheUtils, useCompleteFragment } from '@eluve/apollo-client';
import {
  Box,
  DetailsSidebar,
  Dialog,
  DialogContent,
  Divider,
  H4,
  HStack,
  NewButton,
  VStack,
  tv,
} from '@eluve/components';
import { AppointmentSuperbillPdf } from '@eluve/frontend-feature-appointment';
import { graphql } from '@eluve/graphql.tada';
import { useAssignedTenantIdFromParams } from '@eluve/session-helpers';
import { useIsFeatureFlagEnabled, useUserName } from '@eluve/smart-blocks';

import { AppointmentDetailsFragment } from './AppointmentDetails';
import { PatientDetails } from './PatientDetails';

const iconStyles = tv({
  base: 'size-4',
});

interface ReadonlyAppointmentDetailsProps {
  appointmentId: string;
}

const TimeFormat = 'h:mm a';

const LocationNameFragment = graphql(`
  fragment Location on Locations {
    __typename
    id
    name
  }
`);

export const ReadonlyAppointmentDetails: React.FC<
  ReadonlyAppointmentDetailsProps
> = ({ appointmentId }) => {
  const tenantId = useAssignedTenantIdFromParams();
  const showArtifacts = useIsFeatureFlagEnabled('BILLING_CODE_PRICES');

  const [isShowingSuperbill, setIsShowingSuperbill] = useState(false);

  const { patientId, startDate, endDate, locationId, userId } =
    useCompleteFragment({
      fragment: AppointmentDetailsFragment,
      key: {
        id: appointmentId,
      },
    });

  const locationName = locationId
    ? cacheUtils.readFragment({
        fragment: LocationNameFragment,
        key: {
          id: locationId,
        },
        strict: false,
      })?.name
    : 'No location specified';

  const username = useUserName(userId ?? '');

  return (
    <DetailsSidebar title="Patient">
      <VStack className="p-4">
        {patientId && (
          <>
            <div className="border border-b-0 border-transparent px-4 pt-2">
              <PatientDetails patientId={patientId} />
            </div>
            <Divider />
          </>
        )}
        <VStack gap={4} className="px-4 lg:gap-6 lg:px-0">
          <div className="">
            <H4>Appointment</H4>
          </div>
          <Box className="grid w-full grid-cols-[auto] items-center gap-6 text-sm lg:grid-cols-[max-content_1fr] lg:gap-y-8">
            <span className="text-gray-10">Date</span>
            <HStack className="border border-transparent">
              <CalendarIcon className={iconStyles()} />
              {startDate ? (
                format(new Date(startDate), 'PP')
              ) : (
                <span>No date specified</span>
              )}
            </HStack>
            <span className="text-gray-10">Time</span>

            <HStack className="border border-transparent">
              <ClockIcon className={iconStyles({ className: 'shrink-0' })} />
              <HStack gap={1} wrap>
                <span>
                  {startDate
                    ? format(new Date(startDate), TimeFormat)
                    : 'Start Time'}
                </span>
                <MoveHorizontalIcon className="w-3 text-gray-10" />
                <span>
                  {endDate ? format(new Date(endDate), TimeFormat) : 'End'}
                </span>
              </HStack>
            </HStack>

            <span className="text-gray-10">Location</span>
            <HStack className="truncate border border-transparent text-sm">
              <MapPinIcon className={iconStyles({ className: 'shrink-0' })} />
              {locationName ?? 'No location Specified'}
            </HStack>

            <span className="text-gray-10">Owner</span>
            <HStack className="border border-transparent">
              <UserIcon className={iconStyles()} />
              <span className="text-gray-11">{username}</span>
            </HStack>
          </Box>
        </VStack>

        {showArtifacts && <Divider className="mt-4" />}

        {showArtifacts && (
          <VStack gap={4} className="px-2 lg:px-0">
            <div className="px-2 lg:px-0">
              <H4>Artifacts</H4>
            </div>
            <NewButton
              icon={{
                name: 'FileText',
              }}
              type="subtle"
              size="m"
              text="Super Bill"
              onClick={() => setIsShowingSuperbill(true)}
            />
            <Dialog
              open={isShowingSuperbill}
              onOpenChange={setIsShowingSuperbill}
            >
              <DialogContent className="max-w-5xl">
                <AppointmentSuperbillPdf
                  appointmentId={appointmentId}
                  tenantId={tenantId}
                  userId={userId!}
                />
              </DialogContent>
            </Dialog>
          </VStack>
        )}
      </VStack>
    </DetailsSidebar>
  );
};
