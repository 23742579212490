import { z } from 'zod';

const JObjectSchema = z.record(
  z.union([z.string(), z.record(z.string()), z.null()]),
);

export const LlmResponseContentSchema = z.union([
  JObjectSchema,
  z.array(JObjectSchema),
]);

export type LlmResponseContent = z.infer<typeof LlmResponseContentSchema>;

type LlmUsage = {
  inputTokens: number;
  outputTokens: number;
  totalPrice?: number;
};

export type FailedLlmCompletionResponse = LlmUsage & {
  success: false;
  raw: string | LlmResponseContent;
  failureReason: string;
};

export type SuccessfulLlmCompletionResponse = LlmUsage & {
  success: true;
  summary: LlmResponseContent;
};

export type LlmCompletionResponse =
  | FailedLlmCompletionResponse
  | SuccessfulLlmCompletionResponse;
