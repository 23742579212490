import { Text, View } from '@react-pdf/renderer';
// eslint-disable-next-line no-restricted-imports
import { tv } from 'tailwind-variants';

import { Block } from '@eluve/llm-outputs';

import { tw } from '../pdf-tw';
import { commonStyles } from '../styles';
import { PdfAmendment } from '../types';

import { PdfBlock } from './PdfBlock';

const styles = tv({
  slots: {
    emptyContent: 'rounded bg-brandGray100 p-3',
    emptyText: 'text-center ' + commonStyles.text() + ' text-brandGray500',
  },
})();

export const ChartSummary: React.FC<{
  blocks: Block[];
  amendments: PdfAmendment[];
}> = ({ blocks, amendments }) => {
  return (
    <View style={tw('gap-5')}>
      {blocks.length ? (
        blocks.map((block, i) => (
          <PdfBlock key={i} block={block} amendments={amendments} />
        ))
      ) : (
        <View style={tw(styles.emptyContent())}>
          <Text style={tw(styles.emptyText())}>
            No content available in this chart
          </Text>
        </View>
      )}
    </View>
  );
};
