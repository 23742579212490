import { useCompleteFragment } from '@eluve/apollo-client';
import { HStack, Icon, Link } from '@eluve/components';
import { externalChartsFragment } from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';

interface ExternalChartLinkProps {
  appointmentId: string;
}

const SignedAppointmentDetailsFragment = graphql(
  `
    fragment SignedAppointmentDetails on Appointments {
      __typename
      id
      ...externalCharts
    }
  `,
  [externalChartsFragment],
);

export const ExternalChartLink: React.FC<ExternalChartLinkProps> = ({
  appointmentId,
}) => {
  const appointment = useCompleteFragment({
    fragment: SignedAppointmentDetailsFragment,
    key: {
      id: appointmentId,
    },
  });

  const externalChartUrl = appointment?.external_charts?.[0]?.chartUrl;
  if (!externalChartUrl) {
    return null;
  }

  return (
    <HStack>
      <Link
        to={externalChartUrl}
        target="_blank"
        rel="noreferrer"
        className="cursor-pointer text-sm text-brand-11 hover:underline"
      >
        View External Chart
      </Link>
      <Icon name="ExternalLink" size="sm" />
    </HStack>
  );
};
