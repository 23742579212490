import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMount } from 'react-use';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@eluve/components';
import { AudioDeviceOption, getMicrophoneDevices } from '@eluve/mic-utils';

interface MicrophoneDeviceSelectorProps {
  onDeviceValueChange?: (deviceId: string) => void;
  selectedMicId?: string | null;
  setHasPreviouslySelectedMicId?: (hasPreviouslySelectedMicId: boolean) => void;
}

export const MicrophoneDeviceSelector: React.FC<
  MicrophoneDeviceSelectorProps
> = ({ onDeviceValueChange, selectedMicId, setHasPreviouslySelectedMicId }) => {
  const [microphoneOptions, setMicrophoneOptions] = useState<
    AudioDeviceOption[]
  >([]);

  const fetchMicrophoneDevices = useCallback(async () => {
    const audioDevices = await getMicrophoneDevices();
    setMicrophoneOptions(audioDevices);
  }, []);

  useEffect(() => {
    navigator.mediaDevices.addEventListener(
      'devicechange',
      fetchMicrophoneDevices,
    );

    return () => {
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        fetchMicrophoneDevices,
      );
    };
  }, [fetchMicrophoneDevices]);

  // Fetch microphone devices on mount
  useMount(() => fetchMicrophoneDevices());

  const selectedMicrophone = useMemo(
    () => microphoneOptions.find((mic) => mic.deviceId === selectedMicId),
    [microphoneOptions, selectedMicId],
  );

  useEffect(() => {
    // Select default microphone if none is selected
    if (!selectedMicrophone) {
      const defaultMic = microphoneOptions[0];
      if (defaultMic) {
        onDeviceValueChange?.(defaultMic.deviceId);
      }
    }
  }, [microphoneOptions, onDeviceValueChange, selectedMicrophone]);

  useEffect(() => {
    // Set hasPreviouslySelectedMicId state if selectedMicId is set
    if (selectedMicrophone && setHasPreviouslySelectedMicId) {
      setHasPreviouslySelectedMicId(true);
    }
  }, [selectedMicrophone, setHasPreviouslySelectedMicId]);

  const handleMicChange = (deviceId: string) => {
    onDeviceValueChange?.(deviceId);
  };

  const selectedMicrophoneLabel = selectedMicId && selectedMicrophone?.label;

  return (
    <Select
      onValueChange={handleMicChange}
      onOpenChange={fetchMicrophoneDevices}
      defaultValue={selectedMicId ?? undefined}
    >
      <SelectTrigger aria-label="Audio Source Selector">
        {selectedMicrophoneLabel ?? (
          <span className="text-gray-9">Select Microphone</span>
        )}
      </SelectTrigger>

      <SelectContent>
        {microphoneOptions.map((device, index) => (
          <SelectItem
            key={`${device.deviceId}-${index}`}
            className="pl-7 focus:bg-brand-4"
            value={device.deviceId}
          >
            {device.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
