import { Box, Footer } from '@eluve/components';
import {
  useAppointmentId,
  useTenantId,
} from '@eluve/frontend-appointment-hooks';

import { TranscriptionWaveform } from '../machines/transcription/components/TranscriptionWaveform';

import { AppointmentTranscriptionPageControls } from './AppointmentTranscriptionPageControls';

export const AppointmentTranscriptionFooter: React.FC = () => {
  const appointmentId = useAppointmentId();
  const tenantId = useTenantId();

  return (
    <Footer>
      <Box
        spaceBetween
        className="mx-auto w-full max-w-screen-lg flex-wrap items-center gap-2"
      >
        <TranscriptionWaveform />

        <AppointmentTranscriptionPageControls
          tenantId={tenantId}
          appointmentId={appointmentId}
        />
      </Box>
    </Footer>
  );
};
