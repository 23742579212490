import { z } from 'zod';

const regex = /^\s*(?:[A-Za-z0-9]{2}(?:\s*,\s*[A-Za-z0-9]{2})*)?\s*$/;

/**
 * Validates a string that should be a comma separated list of billing code modifiers.
 * The modifiers themselves need to be two alphanumerical characters.
 * The value is transformed to uppercase and all whitespace is removed.
 */
export const billingCodeModifiersSchema = z
  .string()
  .regex(
    regex,
    'Must be a comma separated list of two alphanumerical characters.',
  )
  .transform((modifiers) => modifiers.replace(/\s/g, '').toUpperCase());
