import subDays from 'date-fns/subDays';
import random from 'lodash/random';

import { AppointmentModel } from '../../models/appointment';
import { getTempPatientId } from '../../utils/getTempPatientId';
import { GetEhrDataOptions } from '../vendor-data-mapper';

import { Appointment } from './types';

const visitStatuses: Record<number, string> = {
  1: 'Checked In Online',
  2: 'Patient Contacted',
  3: '1st Call Into Clinic',
  4: '2nd Call Into Clinic',
  5: '3rd Call Into Clinic',
  6: 'In Transit',
  7: 'Arrived',
  8: 'Coming Back',
  9: 'Canceled',
  10: 'Roomed',
  11: 'Discharged',
  12: 'Scheduled',
  13: '1st Confirmation Call',
  14: '2nd Confirmation Call',
  15: '3rd Confirmation Call',
  16: 'Walk Out',
  17: 'No Show',
  18: 'Rescheduled',
  19: 'Printed',
  20: 'eReg Complete',
  22: 'Ready',
};

const testPatientNumbers = [
  843215, 843485, 843564, 3775255, 3775029, 3677320, 2341946, 2160765, 2109341,
];

/**
 * Receives an Appointment object and mutates it in place to produce an anonymized version.
 */
const annonymizeAppointment = (data: Appointment) => {
  data.FirstName = `fn-${random(1000, 9999)}`;
  data.LastName = `ln-${random(1000, 9999)}`;
  data.LogNumber = random();
  data.KioskPin = `pin-${random(1000, 9999)}`;
  data.PatientNumber =
    testPatientNumbers[random(0, testPatientNumbers.length - 1)];

  const startTimeDate = subDays(new Date(), random(1, 30));
  const endTimeDate = new Date(startTimeDate.valueOf());
  endTimeDate.setHours(startTimeDate.getHours() + 1);

  const startTime = startTimeDate.toISOString().replace(/\.\d+Z$/, '');
  const endTime = endTimeDate.toISOString().replace(/\.\d+Z$/, '');

  data.ScheduledTime = startTime;
  data.TimeIn = startTime;
  data.PrivateTimeOut = endTime;
  data.VisitStatusStartTime = startTime;

  data.CellPhone = `+1
  ${random(600, 900)}
  ${random(100, 999)}${random(1000, 9999)}`;

  const birthday = new Date();
  birthday.setFullYear(random(1950, 2000));
  birthday.setMonth(random(0, 11));
  birthday.setDate(random(1, 28));

  data.Birthday = birthday.toISOString().replace(/\.\d+Z$/, '');
};

export const convertExperityDataToAppointmentModel = (
  data: Appointment,
  options: GetEhrDataOptions,
) => {
  if (options.mockPii) {
    annonymizeAppointment(data);
  }

  const externalId = data.AppointmentId ? `${data.AppointmentId}` : '';
  const startTime = data.ScheduledTime ?? '';

  const appointment: AppointmentModel = {
    externalId,
    externalPatientId: data.PatientNumber
      ? `${data.PatientNumber}`
      : getTempPatientId(`${data.AppointmentId}`),
    firstName: data.FirstName ?? '',
    lastName: data.LastName ?? '',
    startTime,
    name: [
      data.Clinic,
      data.ScheduledTime,
      data.VisitStatusId && visitStatuses[data.VisitStatusId],
    ]
      .filter(Boolean)
      .join(' - '),
    endTime: '',
    description: undefined,
    isArchived: !externalId,
    isImportable: Boolean(externalId) && Boolean(startTime),
    status: undefined,
    sessionType: undefined,
    rawEhrData: data,
  };

  return appointment;
};
