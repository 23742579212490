import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Passwordless from 'supertokens-auth-react/recipe/passwordless';

import { Alert, AlertDescription, AlertTitle, Icon } from '@eluve/components';
import { useNamedLogger } from '@eluve/logger';

export const MagicLinkVerifyPage = () => {
  const logger = useNamedLogger('MagicLinkVerify');
  const navigate = useNavigate();
  const location = useLocation();
  const [magicLinkFailed, setMagicLinkFailed] = useState(false);

  useEffect(() => {
    const verifyMagicLink = async () => {
      try {
        const response = await Passwordless.consumeCode();

        if (response.status === 'OK') {
          const params = new URLSearchParams(location.search);
          let redirectTo = params.get('redirectTo') ?? '/';

          if (!redirectTo.startsWith('/')) {
            redirectTo = `/${redirectTo}`;
          }

          logger.info('Login successful, redirecting to:', { redirectTo });
          navigate(redirectTo);
        } else {
          logger.error('Something went wrong. Please try again.', { response });
          setMagicLinkFailed(true);
        }
      } catch (error) {
        logger.error('Error consuming magic link', { error });
        setMagicLinkFailed(true);
      }
    };

    verifyMagicLink();
  }, [navigate, logger, location]);

  if (magicLinkFailed) {
    return (
      <Alert variant="destructive">
        <Icon name="AlertCircle" className="size-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>Failed to log in. Please try again.</AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="grid h-full place-items-center">
      <Icon
        className="animate-spin text-brand-8"
        name="Loader2Icon"
        size="lg"
      />
    </div>
  );
};
