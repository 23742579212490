import React from 'react';

import {
  InterimResultLoading,
  TranscriptText,
  TranscriptTimestampDivider,
  TranscriptionWarning,
} from '@eluve/blocks';
import { Box, HStack, P, VStack } from '@eluve/components';
import { useAppointmentTranscript } from '@eluve/frontend-appointment-hooks';
import { useTenantUserSettings } from '@eluve/frontend-feature-user-settings';
import { toSentences } from '@eluve/utils';

import { useAutoScrollContainer } from '../hooks/useAutoScrollContainer';
import { useInSessionTranscript } from '../machines/transcription/hooks/useInSessionTranscript';

import { transcriptContainerVariants } from './transcript.styles';

export const AppointmentInSessionTranscript: React.FC<{
  compact?: boolean;
  inSession?: boolean;
}> = ({ compact, inSession }) => {
  const { interimTranscript, isTranscriptionDegraded, recordingStartedAt } =
    useInSessionTranscript();

  const { segments } = useAppointmentTranscript();

  const containerRef = useAutoScrollContainer([
    interimTranscript,
    segments.map((s) => s.transcript).join(''),
  ]);

  const { settings } = useTenantUserSettings();

  return (
    <>
      {isTranscriptionDegraded && <TranscriptionWarning />}

      <Box
        className={transcriptContainerVariants({ compact })}
        ref={containerRef}
      >
        <VStack gap={6}>
          {(segments ?? []).map((s, i) => (
            <React.Fragment key={s.startedAt}>
              <TranscriptTimestampDivider
                timestamp={s.startedAt}
                action={i === 0 ? 'started' : 'resumed'}
                dateFormat={settings?.preferredDateFormat}
              />
              <VStack gap={1}>
                {s.transcript ? (
                  <TranscriptText>{toSentences(s.transcript)}</TranscriptText>
                ) : (
                  <HStack className="h-full">
                    <P className="text-contentDisabled">No transcript.</P>
                  </HStack>
                )}

                {/* Place interim transcript at the end of the last segment */}
                {Boolean(i + 1 === segments.length && inSession) && (
                  <TranscriptText interim>
                    {interimTranscript ?? <InterimResultLoading />}
                  </TranscriptText>
                )}
              </VStack>
              {s.pausedAt && (
                <TranscriptTimestampDivider
                  timestamp={s.pausedAt}
                  action="paused"
                  dateFormat={settings?.preferredDateFormat}
                />
              )}
            </React.Fragment>
          ))}

          {segments.length === 0 && (
            <TranscriptTimestampDivider
              timestamp={recordingStartedAt}
              action="started"
              dateFormat={settings?.preferredDateFormat}
            />
          )}
        </VStack>
      </Box>
    </>
  );
};
