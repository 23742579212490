import { useCallback, useState } from 'react';

/**
 * Utility hook that simplifies dealing with state related to dialog/modal lifecycles
 */
export const useDialog = (initialOpen = false) => {
  const [isDialogOpen, setIsDialogOpen] = useState(initialOpen);

  const closeDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, [setIsDialogOpen]);

  const openDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, [setIsDialogOpen]);

  const toggleDialog = useCallback(() => {
    setIsDialogOpen((old) => !old);
  }, [setIsDialogOpen]);

  return {
    isDialogOpen,
    closeDialog,
    openDialog,
    toggleDialog,
    setIsDialogOpen,
  };
};

export type UseDialog = ReturnType<typeof useDialog>;
