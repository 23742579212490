import isMatch from 'lodash/isMatch';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useApiClient } from '@eluve/api-client-provider';
import { useCompleteFragment } from '@eluve/apollo-client';
import { appointmentPatientFragment } from '@eluve/frontend-appointment-hooks';

// Constants

type TokenUsageDetails = {
  text_tokens: number;
  audio_tokens: number;
};

type InputTokenDetails = {
  text_tokens: number;
  audio_tokens: number;
  cached_tokens: number;
  cached_tokens_details: TokenUsageDetails;
};

type TokenUsage = {
  total_tokens: number;
  input_tokens: number;
  output_tokens: number;
  input_token_details: InputTokenDetails;
  output_token_details: TokenUsageDetails;
};

export type Message = {
  text: string;
  isUser: boolean;
  isPending?: boolean;
  timestamp: number;
  id: string;
};

export type onMessageEvent = (
  sessionId: string,
  role: 'user' | 'assistant',
  message: string,
  messageId: string,
  append?: boolean,
) => void;

export type ConversationContextType = {
  messages: Message[];
  handleMessageUpdate: onMessageEvent;
  sentMessage: string;
  handleSendMessage: (message: string) => void;
  isConnected: boolean;
  setIsConnected: (isConnected: boolean) => void;
  activeAppointmentId?: string;
  toggleConnection: (
    bringOnline: boolean,
    appointmentConversationId: string | null,
  ) => void;
  tokenUsage?: TokenUsage | null;
  handlePushToTalk: (start: boolean) => void;
  openChat: boolean;
  setOpenChat: (open: boolean) => void;
  isCompleted: boolean;
  setIsCompleted: (isCompleted: boolean) => void;
  appointmentConversationId: string | null;
};

// Private Components

const ConversationContext = createContext<ConversationContextType | undefined>(
  undefined,
);

type ConversationContextProviderProps = {
  appointmentId: string;
  tenantId: string;
  children: ReactNode;
  initialMessages?: Message[]; // Optional initial state
};

const calculateNewUsage = (
  prevUsage: TokenUsage | undefined | null,
  usage: TokenUsage,
): TokenUsage => {
  if (!prevUsage) return usage;
  const newUsage = {
    total_tokens: prevUsage.total_tokens + usage.total_tokens,
    input_tokens: prevUsage.input_tokens + usage.input_tokens,
    output_tokens: prevUsage.output_tokens + usage.output_tokens,
    input_token_details: {
      text_tokens:
        (prevUsage.input_token_details?.text_tokens ?? 0) +
        (usage.input_token_details?.text_tokens ?? 0),
      audio_tokens:
        (prevUsage.input_token_details?.audio_tokens ?? 0) +
        (usage.input_token_details?.audio_tokens ?? 0),
      cached_tokens:
        (prevUsage.input_token_details?.cached_tokens ?? 0) +
        (usage.input_token_details?.cached_tokens ?? 0),
      cached_tokens_details: {
        text_tokens:
          (prevUsage.input_token_details?.cached_tokens_details?.text_tokens ??
            0) +
          (usage.input_token_details?.cached_tokens_details?.text_tokens ?? 0),
        audio_tokens:
          (prevUsage.input_token_details?.cached_tokens_details?.audio_tokens ??
            0) +
          (usage.input_token_details?.cached_tokens_details?.audio_tokens ?? 0),
      },
    },
    output_token_details: {
      text_tokens:
        (prevUsage.output_token_details?.text_tokens ?? 0) +
        (usage.output_token_details?.text_tokens ?? 0),
      audio_tokens:
        (prevUsage.output_token_details?.audio_tokens ?? 0) +
        (usage.output_token_details?.audio_tokens ?? 0),
    },
  };
  return newUsage;
};

// const calculateTotalTokenCost = (tokensUsage: TokenUsage | null): number => {
//   const oneMillion = 1000000;
//   const costPerVoiceTokenInput = 40 / oneMillion;
//   const costPerVoiceTokenInputCached = 2.5 / oneMillion;
//   const costPerVoiceTokenOutput = 80 / oneMillion;
//
//   const costPerTextTokenInput = 5 / oneMillion;
//   const costPerTextTokenInputCached = 2.5 / oneMillion;
//   const costPerTextTokenOutput = 20 / oneMillion;
//
//   if (!tokensUsage) return 0;
//
//   const totalInputText =
//     tokensUsage.input_token_details.text_tokens -
//     tokensUsage.input_token_details.cached_tokens_details.text_tokens;
//   const totalInputAudio =
//     tokensUsage.input_token_details.audio_tokens -
//     tokensUsage.input_token_details.cached_tokens_details.audio_tokens;
//   const totalInputCachedText =
//     tokensUsage.input_token_details.cached_tokens_details.text_tokens;
//   const totalInputCachedAudio =
//     tokensUsage.input_token_details.cached_tokens_details.audio_tokens;
//   const totalOutputText = tokensUsage.output_token_details.text_tokens;
//   const totalOutputAudio = tokensUsage.output_token_details.audio_tokens;
//
//   const totalCosts =
//     totalInputText * costPerTextTokenInput +
//     totalInputAudio * costPerVoiceTokenInput +
//     totalInputCachedText * costPerTextTokenInputCached +
//     totalInputCachedAudio * costPerVoiceTokenInputCached +
//     totalOutputText * costPerTextTokenOutput +
//     totalOutputAudio * costPerVoiceTokenOutput;
//
//   return totalCosts;
// };

export const ConversationContextProvider = ({
  appointmentId,
  tenantId,
  children,
  initialMessages = [],
}: ConversationContextProviderProps) => {
  const activeAppointmentId = useMemo(() => appointmentId, [appointmentId]);
  const activeTenantId = useMemo(() => tenantId, [tenantId]);

  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [appointmentConversationId, setAppointmentConversationId] = useState<
    string | null
  >(null);
  const [isCompleted, setIsCompleted] = useState(false);

  const [messages, setMessages] = useState<Message[]>(initialMessages);
  const [sentMessage, setSentMessage] = useState<string>('');
  const [openChat, setOpenChat] = useState(false);

  const appointmentPatient = useCompleteFragment({
    fragment: appointmentPatientFragment,
    key: { id: appointmentId },
  });

  const audioRef = useRef<HTMLAudioElement>(null);
  const connectionRef = useRef<{
    peerConnection: RTCPeerConnection | null;
    mediaStream: MediaStream | null;
    dataStream: RTCDataChannel | null;
  }>({ peerConnection: null, mediaStream: null, dataStream: null });

  const lastSentMessage = useRef<string | null>(null);
  const [tokensUsage, setTokensUsage] = useState<TokenUsage | null>(null);

  const apiClient = useApiClient();

  const handleMessageUpdate: onMessageEvent = useCallback(
    // eslint-disable-next-line max-params
    async (sessionId, role, message, messageId, append = true) => {
      if (!messageId.trim()) return;

      // mark the message as pending if its empty or its in the process of appending
      const isPending = !message.trim() || append;

      if (!isPending || role === 'user') {
        await apiClient.avatar.addSessionMessage({
          params: {
            appointmentId: activeAppointmentId,
            tenantId: activeTenantId,
          },
          body: {
            conversationId: sessionId,
            role: role,
            content: message,
            messageId: messageId,
            messageTimestamp: Date.now(),
          },
        });
      }

      setMessages((prevMessages) => {
        const messageIndex = prevMessages.findIndex((i) => i.id === messageId);
        if (messageIndex >= 0 && prevMessages[messageIndex]) {
          // Create new array with updated message
          return [
            ...prevMessages.slice(0, messageIndex),
            {
              ...prevMessages[messageIndex],
              text: append
                ? prevMessages[messageIndex].text + message
                : message,
              isPending,
            },
            ...prevMessages.slice(messageIndex + 1),
          ];
        }

        return [
          ...prevMessages,
          {
            text: message,
            id: messageId,
            isUser: role === 'user',
            isPending,
            timestamp: Date.now(),
          },
        ];
      });
    },
    [activeAppointmentId, activeTenantId, apiClient.avatar],
  );

  const toggleConnection = useCallback(
    async (bringOnline: boolean) => {
      if (isConnected) {
        // do nothing if we are already connected
        if (bringOnline) {
          return;
        }
        setIsConnected(false);
        if (connectionRef.current.mediaStream) {
          connectionRef.current.mediaStream.getTracks().forEach((track) => {
            track.stop();
          });
        }

        if (connectionRef.current.peerConnection) {
          connectionRef.current.peerConnection.close();
        }

        if (audioRef.current) {
          audioRef.current.srcObject = null;
        }

        connectionRef.current = {
          peerConnection: null,
          mediaStream: null,
          dataStream: null,
        };
        if (appointmentConversationId && appointmentPatient?.patient) {
          await apiClient.avatar.endConversation({
            body: {
              patientId: appointmentPatient.patient.id,
            },
            params: {
              appointmentConversationId,
              appointmentId: activeAppointmentId,
              tenantId: activeTenantId,
            },
          });
        }
      } else {
        if (!activeAppointmentId || !bringOnline) return;

        const keyResponse = await apiClient.avatar.getSessionKeys({
          params: {
            appointmentId: activeAppointmentId,
            tenantId: activeTenantId,
          },
        });

        if (keyResponse.status !== 200 || !keyResponse.body) {
          return;
        }

        const { apiToken, sessionId } = keyResponse.body;

        // Initialize WebRTC connection
        const pc = new RTCPeerConnection();

        if (audioRef.current) {
          audioRef.current.autoplay = true;
          pc.ontrack = (e) => {
            if (audioRef.current && e.streams[0]) {
              audioRef.current.srcObject = e.streams[0];
            }
          };
        }

        const ms = await navigator.mediaDevices.getUserMedia({ audio: true });
        const track = ms.getTracks()[0];
        if (track) {
          pc.addTrack(track);
        }

        const dc = pc.createDataChannel('oai-events');

        dc.addEventListener('open', () => {
          const disableVAD = {
            type: 'session.update',
            session: {
              turn_detection: null,
            },
          };
          dc.send(JSON.stringify(disableVAD));
        });

        dc.addEventListener('message', async (e) => {
          const eventData = JSON.parse(e.data);
          switch (eventData.type) {
            case 'conversation.item.created': {
              const text = eventData.item.content.find(
                (content: { type: string; text: string }) =>
                  content.type === 'input_text',
              );
              if (text) {
                handleMessageUpdate(
                  sessionId,
                  'user',
                  text.text,
                  eventData.item.id,
                  false,
                );
              }

              break;
            }
            case 'input_audio_buffer.committed':
            case 'input_audio_buffer.speech_started': {
              handleMessageUpdate(
                sessionId,
                'user',
                '',
                eventData.item_id,
                false,
              );

              break;
            }
            case 'conversation.item.input_audio_transcription.completed': {
              if (eventData.transcript.trim().length > 0) {
                const content = eventData.transcript.trim();
                const msgId = eventData.item_id;
                handleMessageUpdate(sessionId, 'user', content, msgId, false);
              }
              break;
            }
            case 'response.audio_transcript.delta': {
              handleMessageUpdate(
                sessionId,
                'assistant',
                eventData.delta,
                eventData.response_id,
                true,
              );
              break;
            }
            case 'response.done': {
              const usage = eventData.response.usage as TokenUsage;
              setTokensUsage((prevUsage) =>
                calculateNewUsage(prevUsage, usage),
              );
              const { output } = eventData.response;
              if (output?.length > 0) {
                const chatResponse = output.find(
                  (item: {
                    type: string;
                    status: string;
                    role: string;
                    id: string;
                  }) =>
                    isMatch(item, {
                      type: 'message',
                      status: 'completed',
                      role: 'assistant',
                    }),
                );
                if (chatResponse) {
                  const text =
                    chatResponse.content.find(
                      (content: { type: string; text: string }) =>
                        content.type === 'text',
                    ) ??
                    chatResponse.content.find(
                      (content: { type: string; transcript: string }) =>
                        content.type === 'audio',
                    );
                  if (text) {
                    handleMessageUpdate(
                      sessionId,
                      'assistant',
                      text.text ?? text.transcript,
                      eventData.response.id,
                      false,
                    );
                  }
                }
              }
              break;
            }
            case 'session.updated':
            case 'session.created':
              break;
            default:
              break;
          }
        });

        // SDP negotiation
        const offer = await pc.createOffer();
        await pc.setLocalDescription(offer);

        const baseUrl = 'https://api.openai.com/v1/realtime';
        const model = 'gpt-4o-realtime-preview-2024-12-17';
        const sdpResponse = await fetch(`${baseUrl}?model=${model}`, {
          method: 'POST',
          body: offer.sdp,
          headers: {
            Authorization: `Bearer ${apiToken}`,
            'Content-Type': 'application/sdp',
          },
        });

        const answer: RTCSessionDescriptionInit = {
          type: 'answer',
          sdp: await sdpResponse.text(),
        };
        await pc.setRemoteDescription(answer);

        setIsConnected(true);
        setAppointmentConversationId(sessionId);
        connectionRef.current = {
          peerConnection: pc,
          mediaStream: ms,
          dataStream: dc,
        };
      }
    },
    [
      isConnected,
      activeAppointmentId,
      activeTenantId,
      apiClient.avatar,
      handleMessageUpdate,
      appointmentConversationId,
      appointmentPatient,
    ],
  );

  useEffect(() => {
    if (
      lastSentMessage.current === sentMessage ||
      !connectionRef.current.dataStream ||
      sentMessage.trim().length === 0
    )
      return;
    const addText = {
      type: 'conversation.item.create',
      item: {
        type: 'message',
        role: 'user',
        content: [
          {
            type: 'input_text',
            text: sentMessage,
          },
        ],
      },
    };
    connectionRef.current.dataStream.send(JSON.stringify(addText));

    const getResponse = {
      type: 'response.create',
      response: {
        modalities: ['text'],
      },
    };
    connectionRef.current.dataStream.send(JSON.stringify(getResponse));
  }, [sentMessage]);

  // clean up code
  useEffect(() => {
    return () => {
      if (connectionRef.current.dataStream) {
        connectionRef.current.dataStream.close();
      }

      if (connectionRef.current.peerConnection) {
        connectionRef.current.peerConnection.close();
      }

      if (connectionRef.current.mediaStream) {
        connectionRef.current.mediaStream
          .getTracks()
          .forEach((track) => track.stop());
      }
    };
  }, []);

  const handleSendMessage = useCallback((message: string) => {
    setSentMessage(message);
  }, []);

  const handlePushToTalk = useCallback((start: boolean) => {
    if (!connectionRef.current.dataStream) return;
    if (start) {
      const clearAudioBuffer = {
        type: 'input_audio_buffer.clear',
      };
      connectionRef.current.dataStream.send(JSON.stringify(clearAudioBuffer));
    } else {
      const commitAudioBuffer = {
        type: 'input_audio_buffer.commit',
      };
      connectionRef.current.dataStream.send(JSON.stringify(commitAudioBuffer));
      const getResponse = {
        type: 'response.create',
        response: {
          modalities: ['text', 'audio'],
        },
      };
      connectionRef.current.dataStream.send(JSON.stringify(getResponse));
      const disableVAD = {
        type: 'session.update',
        session: {
          turn_detection: null,
        },
      };
      connectionRef.current.dataStream.send(JSON.stringify(disableVAD));
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      messages,
      handleMessageUpdate,
      sentMessage,
      handleSendMessage,
      isConnected,
      setIsConnected,
      activeAppointmentId,
      toggleConnection,
      tokensUsage,
      handlePushToTalk,
      openChat,
      setOpenChat,
      isCompleted,
      setIsCompleted,
      appointmentConversationId,
    }),
    [
      messages,
      handleMessageUpdate,
      sentMessage,
      handleSendMessage,
      isConnected,
      setIsConnected,
      activeAppointmentId,
      toggleConnection,
      tokensUsage,
      handlePushToTalk,
      openChat,
      setOpenChat,
      isCompleted,
      setIsCompleted,
      appointmentConversationId,
    ],
  );

  return (
    <ConversationContext.Provider value={contextValue}>
      <audio ref={audioRef}></audio>
      {children}
    </ConversationContext.Provider>
  );
};

export const useChat = (): ConversationContextType => {
  const context = useContext(ConversationContext);
  if (!context) {
    throw new Error('useChat must be used within a ConversationContext');
  }
  return context;
};

// Public Components
