import { NewButton, useSidebar } from '@eluve/components';
import { useIsFeatureFlagEnabled } from '@eluve/smart-blocks';

import { useTranscriptionMachineSelector } from '../appointments/machines/transcription/TranscriptionMachineProvider';
import { useStartAnonymousSession } from '../hooks/useStartAnonymousSession';

export const NewAppointmentButton: React.FC = () => {
  const { isMobile } = useSidebar();
  const startAnonymousSession = useStartAnonymousSession();
  const shouldHideNewAppointmentButton = useIsFeatureFlagEnabled(
    'HIDE_NEW_SESSION_BUTTON',
  );

  const canStartAppointment = useTranscriptionMachineSelector(
    (s) => s.matches('Idle') || s.matches({ InSession: 'Paused' }),
  );

  if (!canStartAppointment) {
    return null;
  }

  if (shouldHideNewAppointmentButton) {
    return null;
  }

  return (
    <NewButton
      icon={isMobile ? undefined : { name: 'Plus' }}
      onClick={startAnonymousSession}
      text="New Session"
    />
  );
};
