import { DynamicSummary } from '@eluve/llm-outputs';

import { AppointmentDynamicOutputPdf } from './AppointmentDynamicOutputPdf';

export const AppointmentPdf: React.FC<{
  summary: DynamicSummary;
  tenantId: string;
  appointmentId: string;
}> = ({ summary, tenantId, appointmentId }) => {
  return (
    <AppointmentDynamicOutputPdf
      tenantId={tenantId}
      appointmentId={appointmentId}
      blocks={summary.blocks}
    />
  );
};
