import { graphql } from '@eluve/graphql.tada';

export const tenantNameFragment = graphql(`
  fragment TenantName on Tenants @_unmask {
    name
  }
`);

export const tenantFeatureFlagFragment = graphql(`
  fragment TenantFeatureFlag on TenantFeatureFlags @_unmask {
    __typename
    tenantId
    flag
    isEnabled
  }
`);

export const tenantFeatureFlagsFragment = graphql(
  `
    fragment TenantFeatureFlags on Tenants @_unmask {
      featureFlags {
        ...TenantFeatureFlag
      }
    }
  `,
  [tenantFeatureFlagFragment],
);

export const locationFeatureFlagFragment = graphql(`
  fragment LocationFeatureFlag on InheritedLocationFeatureFlags @_unmask {
    __typename
    locationId
    flag
    isEnabled
  }
`);

export const locationFragment = graphql(
  `
    fragment Location on Locations @_unmask {
      __typename
      id
      name
      path
      depth
      isRoot
      timezone
      tenantId
      externalEhrId
      address
      logoUrl
      phoneNumber
      externalId
      feature_flags {
        ...LocationFeatureFlag
      }
    }
  `,
  [locationFeatureFlagFragment],
);

export const tenantExternalEhrsFragment = graphql(`
  fragment TenantExternalEhrs on Tenants @_unmask {
    externalEhrs {
      __typename
      id
      vendor
      domain
    }
  }
`);

export const layerDataFragment = graphql(`
  fragment LayerData on Layers @_unmask {
    __typename
    id
    name
    path
    depth
  }
`);

export const tenantLocationsFragment = graphql(
  `
    fragment TenantLocations on Tenants @_unmask {
      locations {
        ...Location
      }
    }
  `,
  [locationFragment],
);

export const tenantLayersFragment = graphql(
  `
    fragment TenantLayers on Tenants @_unmask {
      __typename
      id
      layers {
        ...LayerData
      }
    }
  `,
  [layerDataFragment],
);

export const tenantSessionTypesFragment = graphql(`
  fragment TenantSessionTypes on Tenants {
    __typename
    id
    session_types {
      __typename
      id
      name
    }
  }
`);

export const externalEhrFragment = graphql(`
  fragment ExternalEhr on ExternalEhr @_unmask {
    __typename
    id
    vendor
    domain
  }
`);

export const tenantLocationsAndExternalEhrsFragment = graphql(
  `
    fragment TenantLocationsAndExternalEhrs on Tenants @_unmask {
      __typename
      id
      locations {
        ...Location
        externalEhr {
          ...ExternalEhr
        }
      }
    }
  `,
  [locationFragment, externalEhrFragment],
);

export const tenantCoreDataFragment = graphql(
  `
    fragment TenantCoreData on Tenants @_unmask {
      ...TenantFeatureFlags
      ...TenantLayers
      ...TenantLocations
      ...TenantLocationsAndExternalEhrs
      ...TenantName
      ...TenantExternalEhrs
      ...TenantSessionTypes
    }
  `,
  [
    tenantFeatureFlagsFragment,
    tenantLayersFragment,
    tenantLocationsFragment,
    tenantLocationsAndExternalEhrsFragment,
    tenantNameFragment,
    tenantExternalEhrsFragment,
    tenantSessionTypesFragment,
  ],
);

export const getCoreTenantDataQuery = graphql(
  `
    query getCoreTenantData($tenantId: uuid!) {
      tenantsByPk(id: $tenantId) {
        __typename
        id
        isPendingApproval
        ...TenantCoreData
      }
    }
  `,
  [tenantCoreDataFragment],
);

export const listenCoreTenantDataSubscription = graphql(
  `
    subscription listenCoreTenantData($tenantId: uuid!) {
      tenantsByPk(id: $tenantId) {
        ...TenantCoreData
      }
    }
  `,
  [tenantCoreDataFragment],
);
