import { useSuspenseQuery } from '@apollo/client';
import React from 'react';

import { TooltipLabel } from '@eluve/blocks';
import { ColDefBuilder, DataTable, Link, NewButton } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

export const getWorkflowsQuery = graphql(`
  query getWorkflows {
    evalFactVerificationWorkflows(orderBy: { createdAt: DESC }) {
      __typename
      id
      name
      startedById
      createdAt
      user {
        __typename
        id
        email
      }
    }
  }
`);

type WorkflowRow = {
  id: string;
  name: string;
  startedById: string;
  createdAt: string;
  user: {
    email: string;
  };
};

const columns = new ColDefBuilder<WorkflowRow>()
  .detailsLink((row) => row.id)
  .defaultSortable('name')
  .dateSortable('createdAt', 'Created At')
  .defaultSortable('startedById', {
    label: 'Started By',
    cellRenderer: (row) => row.user.email,
  })
  .colDef({
    id: 'id',
    accessorKey: 'id',
    header: 'Actions',
    cell: ({ row }) => (
      <TooltipLabel label="Duplicate">
        <Link to={`/admin/fact-verification/workflow/copy/${row.original.id}`}>
          <NewButton type="outline" size="l" icon={{ name: 'BookCopy' }} />
        </Link>
      </TooltipLabel>
    ),
  })
  .build();

export const FactVerificationWorkflowsList: React.FC = () => {
  const {
    data: { evalFactVerificationWorkflows: workflows },
  } = useSuspenseQuery(getWorkflowsQuery);

  const rows = workflows as WorkflowRow[];

  return (
    <div className="rounded-md bg-white py-2">
      <DataTable columns={columns} data={rows} />
    </div>
  );
};
