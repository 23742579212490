import { CheckIcon } from '@radix-ui/react-icons';
import isNil from 'lodash/isNil';
import partition from 'lodash/partition';
import {
  CheckCheck,
  ChevronDown,
  CircleHelp,
  FlaskConicalIcon,
  MessageSquareQuote,
  X,
} from 'lucide-react';
import React from 'react';

import { useCompleteFragment } from '@eluve/apollo-client';
import {
  Badge,
  Box,
  Button,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Divider,
  Drawer,
  DrawerContent,
  DrawerTrigger,
  H4,
  H5,
  P,
} from '@eluve/components';
import { SoapNoteOutput } from '@eluve/frontend-feature-appointment';
import { DbEnums } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';
import {
  dynamicArtifactTemplateSchema,
  hydrateDynamicArtifactTemplate,
} from '@eluve/llm-outputs';
import { DynamicArtifactRenderer } from '@eluve/smart-blocks';
import { toSentences } from '@eluve/utils';

export const inputResultsFragment = graphql(`
  fragment inputResults on EvalFactVerificationInputs @_unmask {
    __typename
    id
    sourceArtifactId
    artifact {
      __typename
      id
      name
      content
    }
    results {
      __typename
      id
      isCorrectlyIdentified
      supportingEvidence
      sourceFact {
        __typename
        id
        statement
        expectedOutput
      }
    }
    input_llm_outputs {
      __typename
      output {
        __typename
        id
        content
        metadata {
          __typename
          llmOutputId
          prompt_template {
            __typename
            id
            outputType
          }
          output_variant {
            __typename
            outputTemplateId
            variantNumber
            template
          }
        }
      }
    }
  }
`);

export interface FactVerificationInputResultsListItemProps {
  id: string;
}

export const FactVerificationInputResultsListItem: React.FC<
  FactVerificationInputResultsListItemProps
> = ({ id }) => {
  const data = useCompleteFragment({
    fragment: inputResultsFragment,
    key: {
      id,
    },
  });

  const results = data.results;

  const inputLlmOutput = data.input_llm_outputs?.[0]?.output;

  const content = inputLlmOutput?.content ?? {};

  const isDynamicOutput =
    inputLlmOutput?.metadata?.prompt_template?.outputType ===
    DbEnums.LlmOutputType.DYNAMIC_OUTPUT;
  const outputTemplate = inputLlmOutput?.metadata?.output_variant?.template;
  const outputTemplateParsed =
    isDynamicOutput && outputTemplate
      ? dynamicArtifactTemplateSchema.parse(outputTemplate)
      : null;

  const [unknown, rest] = partition(results, (r) =>
    isNil(r.isCorrectlyIdentified),
  );

  const [passed, failed] = partition(rest, (r) => r.isCorrectlyIdentified);

  return (
    <div className="w-full border border-gray-4 p-2">
      <Drawer>
        <div className="flex items-center gap-2">
          <DrawerTrigger asChild>
            <Button variant="outline" size="icon">
              <MessageSquareQuote />
            </Button>
          </DrawerTrigger>
          <H5>{data.artifact.name}</H5>
        </div>
        <DrawerContent>
          <div className="container max-h-[768px] overflow-y-auto">
            <P className="whitespace-pre-line break-words">
              {toSentences(data.artifact.content)}
            </P>
          </div>
        </DrawerContent>
      </Drawer>
      <div className="mt-2 flex items-center gap-2">
        <Badge variant="success">
          <CheckCheck className="mr-2 size-5" /> {passed.length} Passed
        </Badge>
        <Badge variant="destructive">
          <X className="mr-2 size-5" />
          {failed.length} Failed
        </Badge>
        {unknown.length > 0 && (
          <Badge variant="info">{unknown.length} Pending</Badge>
        )}
      </div>
      <Collapsible>
        <CollapsibleTrigger>
          <div className="flex items-end gap-1">
            <ChevronDown />
            <P className="mt-2 underline">Expand Detailed Results</P>
          </div>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <div className="relative grid grid-cols-2 gap-4 pt-2">
            <div className="border p-4 shadow-md">
              {isDynamicOutput ? (
                <DynamicArtifactRenderer
                  isReadonly={true}
                  blocks={
                    hydrateDynamicArtifactTemplate(
                      outputTemplateParsed!,
                      content ?? {},
                    ).blocks
                  }
                />
              ) : (
                <SoapNoteOutput
                  content={data.input_llm_outputs?.[0]?.output?.content ?? {}}
                />
              )}
            </div>
            <div>
              <Box hStack className="gap-2">
                <FlaskConicalIcon className="size-6" />
                <H4>Fact Verification Results</H4>
              </Box>
              <Divider />
              <ul className="divide-y divide-gray-4 px-4">
                {results.map((r) => {
                  const expected = r.sourceFact.expectedOutput;
                  const observed = r.isCorrectlyIdentified
                    ? expected
                    : !expected;

                  return (
                    <Box vStack className="gap-2 p-2" key={r.id}>
                      <ResultBadge pass={r.isCorrectlyIdentified} />
                      <Box hStack className="gap-2">
                        <P className="font-bold">Fact:</P>
                        <P>{r.sourceFact.statement}</P>
                      </Box>
                      {r.isCorrectlyIdentified !== null && (
                        <Box row className="gap-2">
                          <P className="font-bold">Evidence:</P>
                          <P>{r.supportingEvidence}</P>
                        </Box>
                      )}
                      <Box hStack className="gap-2">
                        <P className="font-bold">Expected:</P>
                        <ExpectedBadge isTrue={expected} />
                        {r.isCorrectlyIdentified !== null && (
                          <>
                            <P className="ml-2 font-bold">Observed:</P>
                            <ExpectedBadge isTrue={observed} />
                          </>
                        )}
                      </Box>
                    </Box>
                  );
                })}
              </ul>
            </div>
          </div>
        </CollapsibleContent>
      </Collapsible>
    </div>
  );
};

const ResultBadge: React.FC<{ pass: boolean | null }> = ({ pass }) => {
  if (pass === null) {
    return (
      <Badge variant="secondary">
        <CircleHelp className="-ml-1 mr-2 size-4" />
        PENDING
      </Badge>
    );
  }
  if (pass) {
    return (
      <Badge variant="success">
        <CheckIcon className="-ml-1 mr-1 size-4" />
        PASS
      </Badge>
    );
  }
  return (
    <Badge variant="destructive">
      <X className="-ml-1 mr-1 size-4" />
      FAIL
    </Badge>
  );
};

const ExpectedBadge: React.FC<{ isTrue: boolean }> = ({ isTrue }) => {
  if (isTrue) {
    return <P className="font-semibold text-green">True</P>;
  }
  return <P className="font-semibold text-destructive">False</P>;
};
