import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

/**
 * Formats a given date (string or Date object) into "dd MMM yyyy, h:mm a" format.
 *
 * @example
 * "18 Mar 2025, 2:45 PM"
 */
export const getFormattedLocalDateTime = (dateInput: string | Date): string => {
  const date = typeof dateInput === 'string' ? parseISO(dateInput) : dateInput;
  return format(date, 'dd MMM yyyy, h:mm a');
};
