import { useMutation } from '@apollo/client';
import { PackageMinus, PackagePlus } from 'lucide-react';
import React from 'react';
import { match } from 'ts-pattern';

import { TooltipLabel } from '@eluve/blocks';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  P,
  toast,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

// Single mutation that toggles isActive status
const togglePromptTemplateModelMutation = graphql(`
  mutation TogglePromptTemplateModel(
    $promptTemplateId: uuid!
    $modelArgsId: uuid!
    $isActive: Boolean!
  ) {
    updateLlmModelPromptTemplates(
      where: {
        _and: [
          { modelArgsId: { _eq: $modelArgsId } }
          { promptTemplateId: { _eq: $promptTemplateId } }
        ]
      }
      _set: { isActive: $isActive }
    ) {
      returning {
        __typename
        id
        isActive
      }
    }
  }
`);

export interface TogglePromptTemplateModelActionProps {
  promptTemplateId: string;
  modelArgsId: string;
  isDefault: boolean;
  isActive: boolean;
  id: string;
}

export const TogglePromptTemplateModelAction: React.FC<
  TogglePromptTemplateModelActionProps
> = ({ promptTemplateId, modelArgsId, isDefault, isActive, id }) => {
  // Single mutation hook for toggling
  const [toggleModel, { loading }] = useMutation(
    togglePromptTemplateModelMutation,
    {
      onError: () =>
        toast.error(
          `Failed to ${isActive ? 'deactivate' : 'activate'} model for this prompt`,
        ),
      optimisticResponse: ({ isActive: newIsActiveValue }) => ({
        updateLlmModelPromptTemplates: {
          returning: [
            {
              __typename: 'LlmModelPromptTemplates' as const,
              id: id,
              isActive: newIsActiveValue,
            },
          ],
        },
      }),
    },
  );

  const handleToggle = async () => {
    const newActiveState = !isActive;

    await toggleModel({
      variables: {
        promptTemplateId,
        modelArgsId,
        isActive: newActiveState,
      },
    });

    toast.success(
      `Successfully ${newActiveState ? 'activated' : 'deactivated'} model for this prompt`,
    );
  };

  const { label, description, action, Component } = match(isActive)
    .with(true, () => {
      const description = (
        <div>
          <P>
            Marking this model as inactive will make it unavailable to be used
            with prompts
          </P>
        </div>
      );

      return {
        label: 'Mark this model args as inactive',
        description,
        action: 'Deactivate',
        Component: <PackageMinus className="text-orange" />,
      };
    })
    .otherwise(() => ({
      label: 'Mark this model as active',
      description: (
        <P>
          Marking this model as active will make it available to be used with
          prompts
        </P>
      ),
      action: 'Activate',
      Component: <PackagePlus className="text-green" />,
    }));

  if (isDefault) {
    return null;
  }

  return (
    <AlertDialog>
      <TooltipLabel label={label}>
        <AlertDialogTrigger asChild>
          <Button size="icon" variant="outline" disabled={loading}>
            {Component}
          </Button>
        </AlertDialogTrigger>
      </TooltipLabel>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="text-gray-11">Confirm</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel autoFocus>Cancel</AlertDialogCancel>
          <AlertDialogAction
            className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            onClick={handleToggle}
            disabled={loading}
          >
            {action}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
