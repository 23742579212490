import noop from 'lodash/noop';
import { v4 } from 'uuid';

import {
  LogLevel,
  LogMetadata,
  Loggable,
  isDebugEnabled,
} from './loggers/base';
import { ConsoleLogger } from './loggers/console';
import { DatadogBrowserLogger } from './loggers/datadog-browser-logger';
import { DriverBasedLogger } from './loggers/driver-based-logger';

export type { LogLevel, LogMetadata, Loggable };

export { DriverBasedLogger, ConsoleLogger, DatadogBrowserLogger };

type Options = {
  logger?: Loggable;
  metadata?: LogMetadata;
};

export class Logger {
  private logger: Loggable;
  private internalMetadata: LogMetadata;

  /**
   * @deprecated Don't instantiate this class direclty unless you have a specific use case.
   * Prefer using `useNamedLogger` or `useExtendedLogger` hooks which will provide you with a logger instance
   * and will automatically include important metadata in the logs eg `tenantId`, `userId`, `appointmentId` etc.
   */
  constructor(prefix = 'eluve-logger', options: Options = {}) {
    const { logger, metadata } = options;
    this.internalMetadata = metadata || {};
    if (logger) {
      this.logger = logger;
    } else {
      this.logger = new DatadogBrowserLogger(prefix, metadata);
    }
  }

  info = (message: string, metadata?: LogMetadata) => {
    this.logger.info(message, metadata);
  };

  warn = (message: string, metadata?: LogMetadata) => {
    this.logger.warn(message, metadata);
  };

  error = (message: string, metadata?: LogMetadata) => {
    if (metadata?.error && metadata?.error instanceof Error) {
      metadata.errorDetails = {
        message: metadata.error.message,
        stack: metadata.error.stack,
      };
    }
    this.logger.error(message, metadata);
  };

  debug = (message: string, metadata?: LogMetadata) => {
    if (isDebugEnabled()) {
      this.logger.debug(message, metadata);
    }
  };

  get metadata() {
    return this.internalMetadata;
  }
}

export class RNLogger extends Logger {
  constructor(prefix = 'eluve-logger') {
    super(prefix, { logger: new ConsoleLogger(prefix) });
  }
}

export class NoopLogger extends Logger {
  constructor(prefix = 'eluve-logger') {
    super(prefix, {
      logger: {
        debug: noop,
        error: noop,
        info: noop,
        warn: noop,
      },
    });
  }
}

export type CorrelatedLogger = (
  level: LogLevel,
  message: string,
  metadata?: LogMetadata,
) => void;

export const createCorrelatedLogger = (
  logger: Logger,
  sharedContext: Record<string, string | number | boolean>,
  correlationId = v4(),
) => {
  const log: CorrelatedLogger = (level, message, metadata = {}) => {
    const payload = {
      correlationId,
      ...sharedContext,
    };

    logger[level](message, { ...payload, ...metadata });
  };

  return { correlationId, log };
};
