import React from 'react';

import { useCompleteFragment } from '@eluve/apollo-client';
import {
  Divider,
  NewButton,
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
  tv,
} from '@eluve/components';
import {
  appointmentConversationsFragment,
  baseAppointmentInfoFragment,
} from '@eluve/frontend-appointment-hooks';
import { useUserName } from '@eluve/smart-blocks';

const DEFAULT_PROVIDER_AVATAR_URL =
  'https://mysiloam-api.siloamhospitals.com/storage-down/doctor/doctor_2022_02_24_10575816456750782431645675078243.jpeg';

const messageContainerStyles = tv({
  base: 'flex',
  variants: {
    isUser: {
      true: 'ml-4 justify-end',
      false: 'justify-start',
    },
  },
});

const messageBubbleStyles = tv({
  base: 'shrink rounded-lg p-3 text-sm font-medium text-black',
  variants: {
    isUser: {
      true: 'bg-brandGray100',
      false: 'bg-white',
    },
  },
});

const avatarStyles = tv({
  base: 'mt-2 size-12 rounded-full border-2 border-brandGray100 bg-white p-0.5',
});

type ViewConversationButtonProps = {
  appointmentId: string;
};

export const ViewConversationButton: React.FC<ViewConversationButtonProps> = ({
  appointmentId,
}) => {
  const [isOpen, setOpen] = React.useState(false);
  const appointmentConversations = useCompleteFragment({
    fragment: appointmentConversationsFragment,
    key: {
      id: appointmentId,
    },
  });
  const baseAppointmentInfo = useCompleteFragment({
    fragment: baseAppointmentInfoFragment,
    key: {
      id: appointmentId,
    },
  });
  const userId = baseAppointmentInfo.userId ?? '';
  const userName = useUserName(userId);

  const conversations =
    appointmentConversations.appointment_conversations ?? [];
  if (!conversations) {
    return null;
  }
  const latestConversation = Array.isArray(conversations?.[0]?.conversation)
    ? (conversations?.[0]?.conversation as Array<{
        role: string;
        content: string;
        messageId: string;
        messageTimestamp: number;
      }>)
    : [];

  const deduplicatedConversation = latestConversation.reduce(
    (acc, message) => {
      const existingMessageIndex = acc.findIndex(
        (m) => m.messageId === message.messageId,
      );
      if (existingMessageIndex !== -1 && acc[existingMessageIndex]) {
        const updatedMessage = {
          ...acc[existingMessageIndex],
          content: message.content,
        };
        acc[existingMessageIndex] = updatedMessage;
      } else {
        acc.push({ ...message });
      }
      return acc;
    },
    [] as typeof latestConversation,
  );
  const providerAvatarUrl = DEFAULT_PROVIDER_AVATAR_URL;

  return (
    <>
      {deduplicatedConversation.length > 0 && (
        <NewButton
          onClick={() => {
            setOpen(true);
          }}
          disabled={isOpen}
          type="outline"
          text="View Intake Notes"
        />
      )}
      <Sheet modal={false} open={isOpen} onOpenChange={setOpen}>
        <SheetContent className="w-96" hasOverlay={false}>
          <SheetClose />
          <SheetHeader>
            <SheetTitle>Conversation</SheetTitle>
          </SheetHeader>
          <Divider />
          <div className="flex h-[calc(100%-84px)] flex-col gap-4 overflow-auto p-4">
            {deduplicatedConversation
              .filter((message) => (message.content?.length ?? 0) > 0)
              .map((message) => (
                <div
                  key={message.messageId}
                  className={messageContainerStyles({
                    isUser: message.role === 'user',
                  })}
                >
                  {providerAvatarUrl && message.role !== 'user' && (
                    <img
                      src={providerAvatarUrl}
                      alt=""
                      className={avatarStyles()}
                    />
                  )}
                  <div
                    className={messageBubbleStyles({
                      isUser: message.role === 'user',
                    })}
                  >
                    {userName && message.role !== 'user' && (
                      <span className="block font-semibold">{userName}</span>
                    )}
                    {message.content?.length === 0 ? '...' : message.content}
                  </div>
                </div>
              ))}
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
};
