import format from 'date-fns/format';
import React from 'react';

import { HStack, Icon, VStack, textStyles } from '@eluve/components';

export interface DuplicateChartCardProps {
  name: string;
  startDate: string;
}

export const DuplicateChartCard: React.FC<DuplicateChartCardProps> = ({
  name,
  startDate,
}) => {
  return (
    <HStack gap={4}>
      <div className="rounded-md bg-backgroundTertiary p-2">
        <Icon size="lg" name="ClipboardEdit" />
      </div>
      <VStack gap={'0.5'}>
        <p
          className={textStyles.body({
            weight: 'semibold',
            size: 'm',
          })}
        >
          {name}
        </p>
        <HStack
          className={textStyles.body({
            size: 's',
            weight: 'medium',
            color: 'tertiary',
          })}
        >
          <p>{format(new Date(startDate), 'PP')}</p>
          <div className="size-1 rounded-full bg-contentTertiary"></div>
          <p>{format(new Date(startDate), 'p')}</p>
        </HStack>
      </VStack>
    </HStack>
  );
};
