import groupBy from 'lodash/groupBy';
import React, { useMemo, useState } from 'react';

import { QUERY_ROOT_ID, useCompleteFragment } from '@eluve/apollo-client';
import {
  HStack,
  NewButton,
  P,
  SelectCommandPopover,
  VStack,
  textStyles,
} from '@eluve/components';
import { DbEnums } from '@eluve/graphql-types';

import {
  modelPromptTemplatesFragment,
  outputTemplatesFragment,
  promptTemplatesFragment,
} from './eval.operations';

export type TargetConfig = {
  modelPromptTemplateId: string;
  outputTemplateId: string | null;
};

export interface FactVerificationTargetConfigsFormProps {
  selectedConfigs: TargetConfig[];
  onSelectionChanged: (selectedConfigs: TargetConfig[]) => void;
}

export const FactVerificationTargetConfigsForm: React.FC<
  FactVerificationTargetConfigsFormProps
> = ({ onSelectionChanged, selectedConfigs }) => {
  const { promptTemplates } = useCompleteFragment({
    fragment: promptTemplatesFragment,
    key: QUERY_ROOT_ID,
  });

  const { llmModelPromptTemplates: modelPromptTemplates } = useCompleteFragment(
    {
      fragment: modelPromptTemplatesFragment,
      key: QUERY_ROOT_ID,
    },
  );

  const { llmOutputTemplates } = useCompleteFragment({
    fragment: outputTemplatesFragment,
    key: QUERY_ROOT_ID,
  });

  // State for selected items
  const [selectedPromptTemplateId, setSelectedPromptTemplateId] = useState<
    string | null
  >(null);
  const [selectedModelPromptTemplateId, setSelectedModelPromptTemplateId] =
    useState<string | null>(null);
  const [selectedOutputTemplateId, setSelectedOutputTemplateId] = useState<
    string | null
  >(null);

  const groupedByPrompt = groupBy(
    modelPromptTemplates,
    (mpt) => mpt.template.id,
  );

  // Filter model-prompt templates based on selected prompt template
  const modelPromptTemplateOptions = useMemo(() => {
    if (!selectedPromptTemplateId) return [];

    return groupedByPrompt[selectedPromptTemplateId];
  }, [selectedPromptTemplateId, groupedByPrompt]);

  const selectedPromptTemplate = modelPromptTemplates.find(
    (mpt) => mpt.template.id === selectedPromptTemplateId,
  )?.template;

  const selectedModelArgs = modelPromptTemplates.find(
    (mpt) => mpt.id === selectedModelPromptTemplateId,
  )?.model_args;
  const selectedOutputTemplate = llmOutputTemplates.find(
    (ot) => ot.id === selectedOutputTemplateId,
  );

  const isSelectedDynamicOutput =
    selectedPromptTemplate?.outputType === DbEnums.LlmOutputType.DYNAMIC_OUTPUT;

  const configAlreadyExists = selectedConfigs.some(
    (config) =>
      config.modelPromptTemplateId === selectedModelPromptTemplateId &&
      config.outputTemplateId === selectedOutputTemplateId,
  );

  const isValidConfig = Boolean(
    selectedPromptTemplateId &&
      selectedPromptTemplate &&
      selectedModelArgs &&
      selectedModelPromptTemplateId &&
      (!isSelectedDynamicOutput || selectedOutputTemplateId) &&
      !configAlreadyExists,
  );

  return (
    <>
      {/* Selected Configs */}
      <VStack gap={4}>
        {selectedConfigs.map((config) => {
          const mpt = modelPromptTemplates.find(
            (mpt) => mpt.id === config.modelPromptTemplateId,
          );

          const pt = mpt?.template;
          const ma = mpt?.model_args;
          const ot = llmOutputTemplates.find(
            (ot) => ot.id === config.outputTemplateId,
          );

          if (!pt || !ma) return null;

          return (
            <VStack
              key={`${config.modelPromptTemplateId}-${ot?.id ?? ''}`}
              gap={4}
              className="rounded-lg bg-brandGray200 p-4"
            >
              <HStack align="end">
                <P className={textStyles.title({ size: 's' })}>{pt.name}</P>
              </HStack>

              <HStack gap={5} key={pt.id} align="start">
                <VStack wFull>
                  <P>{ma.name}</P>
                  <P>{JSON.stringify(ma.args)}</P>
                </VStack>
                {pt.outputType === DbEnums.LlmOutputType.DYNAMIC_OUTPUT && (
                  <VStack className="max-h-[600px] overflow-y-auto" wFull>
                    <P>Output Template</P>
                    <P>{ot?.name}</P>
                  </VStack>
                )}
                <NewButton
                  onClick={() => {
                    onSelectionChanged(
                      selectedConfigs.filter(
                        (c) =>
                          !(
                            c.modelPromptTemplateId ===
                              config.modelPromptTemplateId &&
                            c.outputTemplateId === ot?.id
                          ),
                      ),
                    );
                  }}
                  text="Remove"
                />
              </HStack>
            </VStack>
          );
        })}
      </VStack>

      {/* Selection dropdowns */}
      <VStack gap={4}>
        {/* PROMPT TEMPLATE */}
        <VStack>
          <P>Prompt Template</P>
          <SelectCommandPopover
            items={promptTemplates}
            selectedItem={
              selectedPromptTemplateId
                ? promptTemplates.find(
                    (pt) => pt.id === selectedPromptTemplateId,
                  ) ?? null
                : null
            }
            onItemSelect={(pt) => {
              // Reset states when a new prompt template is chosen
              setSelectedPromptTemplateId(pt.id);
              setSelectedModelPromptTemplateId(null);
              setSelectedOutputTemplateId(null);
            }}
            itemKey={(pt) => pt.id}
            itemValue={(pt) => `${pt.name} (${pt.outputType})`}
            itemContent={(pt) => (
              <HStack gap={5}>
                {pt.outputType} <span className="font-bold">{pt.name}</span>
              </HStack>
            )}
            triggerText="Select a prompt template"
            placeholder="Search prompt templates..."
            noResultsMessage="No prompt templates found."
          />
        </VStack>

        {/* MODEL ARGS (only show if a prompt template is selected) */}
        {selectedPromptTemplateId && (
          <VStack>
            <P>Model Args</P>
            <SelectCommandPopover
              items={modelPromptTemplateOptions ?? []}
              selectedItem={
                selectedModelPromptTemplateId
                  ? modelPromptTemplateOptions?.find(
                      (mpt) => mpt.id === selectedModelPromptTemplateId,
                    ) ?? null
                  : null
              }
              onItemSelect={(mpt) => {
                setSelectedModelPromptTemplateId(mpt.id);
              }}
              itemKey={(mpt) => mpt.id}
              itemValue={(mpt) => mpt.model_args.name ?? ''}
              itemContent={(mpt) => (
                <VStack align="start">
                  <HStack gap={5}>
                    {mpt.model_args.modelType}
                    <span className="font-bold">{mpt.model_args.name}</span>
                  </HStack>
                  <div>{JSON.stringify(mpt.model_args.args)}</div>
                </VStack>
              )}
              triggerText="Select model args"
              placeholder="Search model args..."
              noResultsMessage="No model args found."
            />
          </VStack>
        )}

        {/* OUTPUT TEMPLATE (only show if selectedPromptTemplate is DYNAMIC_OUTPUT) */}
        {selectedPromptTemplateId && isSelectedDynamicOutput && (
          <VStack>
            <P>Output Template</P>
            <SelectCommandPopover
              items={llmOutputTemplates}
              selectedItem={
                selectedOutputTemplateId
                  ? llmOutputTemplates.find(
                      (ot) => ot.id === selectedOutputTemplateId,
                    ) ?? null
                  : null
              }
              onItemSelect={(ot) => {
                setSelectedOutputTemplateId(ot.id);
              }}
              itemKey={(ot) => ot.id}
              itemValue={(ot) => `${ot.name} (${ot.id})`}
              itemContent={(ot) => (
                <HStack>
                  <span className="font-bold">{ot.name}</span> ({ot.id})
                </HStack>
              )}
              triggerText="Select output template"
              placeholder="Search output templates..."
              noResultsMessage="No output templates found."
            />
          </VStack>
        )}

        <NewButton
          disabled={!isValidConfig}
          onClick={() => {
            if (!selectedPromptTemplate || !selectedModelArgs) return;

            onSelectionChanged([
              ...selectedConfigs,
              {
                modelPromptTemplateId: selectedModelPromptTemplateId!,
                outputTemplateId: selectedOutputTemplate?.id ?? null,
              },
            ]);
          }}
          text="Use this config"
        />
      </VStack>
    </>
  );
};
