import { useCompleteFragment } from '@eluve/apollo-client';

import { appointmentAmendments } from './amendment.operations';

type Filters = {
  draftOnly?: boolean;
  submittedOnly?: boolean;
  byAmendmentId?: string;
};

export const useAmendments = (appointmentId: string, filters: Filters = {}) => {
  const data = useCompleteFragment({
    fragment: appointmentAmendments,
    key: {
      id: appointmentId,
    },
    strict: false,
  });
  const amendments = data?.amendments ?? [];
  const filteredAmendments = amendments
    .filter(
      (amendment) =>
        !filters.byAmendmentId || amendment.id === filters.byAmendmentId,
    )
    .filter((amendment) => !filters.draftOnly || !amendment.submittedAt)
    .filter((amendment) => !filters.submittedOnly || amendment.submittedAt);

  return {
    amendments: filteredAmendments,
  };
};
