import { useMutation } from '@apollo/client';
import { useState } from 'react';

import { useApiClient } from '@eluve/api-client-provider';
import {
  Box,
  HStack,
  Icon,
  NewButton,
  P,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  VStack,
  cn,
  textStyles,
  toast,
  tv,
} from '@eluve/components';
import { PatientOverview } from '@eluve/feature-patients';
import {
  useAppointmentContext,
  useAppointmentPatient,
  useAppointmentStatus,
  useLatestAppointmentLlmOutput,
  useListenForAppointmentLlmJobStatus,
} from '@eluve/frontend-appointment-hooks';
import { useListenForAppointmentLlmJobs } from '@eluve/frontend-appointment-hooks';
import {
  AppointmentStatusTypesLookup,
  FeedbackTypeLookup,
  LlmOutputTypesLookup,
  WorkerStatusTypesLookup,
} from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';
import { RedFlagsOutputSchema } from '@eluve/llm-outputs';
import { useIsFeatureFlagEnabled } from '@eluve/smart-blocks';

import { DifferentialDiagnosesTabContent } from './DifferentialDiagnosesTabContent';
import { RedFlagsOutput } from './RedFlagsOutput';

// Constants

const insertFeedbackMutation = graphql(`
  mutation insertSummaryFeedback(
    $appointmentId: uuid!
    $llmOutputId: uuid
    $type: FeedbackTypeEnum!
    $summarySection: String
  ) {
    insertSummaryFeedbackOne(
      object: {
        appointmentId: $appointmentId
        llmOutputId: $llmOutputId
        type: $type
        summarySection: $summarySection
      }
    ) {
      __typename
      id
      type
    }
  }
`);

// Private Components

type InsightTab = 'REASON_FOR_VISIT' | 'DIFFERENTIAL_DIAGNOSES' | 'RED_FLAGS';

const InsightTabLabels: { [key in InsightTab]: string } = {
  REASON_FOR_VISIT: 'Reason for Visit',
  DIFFERENTIAL_DIAGNOSES: 'Differential Diagnoses',
  RED_FLAGS: 'Considerations',
};

const insightTabButtonStyles = tv({
  base: textStyles.label({
    color: 'supporting',
    className:
      '-my-px border-b-2 border-transparent px-2 py-3 uppercase hover:text-contentTertiary',
  }),
  variants: {
    active: {
      true: 'border-contentTertiary text-contentTertiary',
    },
  },
});

type InsightTabButtonProps = {
  isActive?: boolean;
  onClick: (tab: InsightTab) => void;
  tab: InsightTab;
};

const InsightTabButton: React.FC<InsightTabButtonProps> = ({
  isActive = false,
  onClick,
  tab,
}) => {
  const styles = insightTabButtonStyles({ active: isActive });
  return (
    <button className={styles} onClick={() => onClick(tab)}>
      {InsightTabLabels[tab]}
    </button>
  );
};

const insightTabContentStyles = tv({
  base: '',
  variants: {
    hidden: {
      true: 'hidden',
    },
  },
});

type InsightTabContentProps = React.PropsWithChildren<{
  className?: string;
  id?: string;
  isActive?: boolean;
}>;

const InsightTabContent: React.FC<InsightTabContentProps> = ({
  children,
  className,
  id,
  isActive = false,
}) => {
  const styles = insightTabContentStyles({ className, hidden: !isActive });
  return (
    <div id={id} className={styles}>
      {children}
    </div>
  );
};

export const AppointmentInsights: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<InsightTab>('REASON_FOR_VISIT');
  const apiClient = useApiClient();
  const { appointmentId, tenantId } = useAppointmentContext();
  const appointmentStatus = useAppointmentStatus();
  const patient = useAppointmentPatient();
  const patientOverviews = patient?.patient_overviews ?? [];

  const isAppointmentActive =
    appointmentStatus === AppointmentStatusTypesLookup.ACTIVE ||
    appointmentStatus === AppointmentStatusTypesLookup.NOT_STARTED;

  const [insertFeedback] = useMutation(insertFeedbackMutation);
  const isPatientOverviewEnabled = useIsFeatureFlagEnabled('PATIENT_OVERVIEW');

  const { appointmentLlmOutput: redFlagsOutput, refetch } =
    useLatestAppointmentLlmOutput({
      outputType: LlmOutputTypesLookup.DIFFERENTIAL_DIAGNOSES_PLUS_RED_FLAGS,
    });

  const { status } = useListenForAppointmentLlmJobStatus({
    outputType: LlmOutputTypesLookup.DIFFERENTIAL_DIAGNOSES_PLUS_RED_FLAGS,
    shouldSubscribe: isAppointmentActive,
  });

  const { status: patientOverviewStatus } = useListenForAppointmentLlmJobStatus(
    {
      outputType: LlmOutputTypesLookup.PATIENT_OVERVIEW,
      shouldSubscribe: isPatientOverviewEnabled && isAppointmentActive,
    },
  );

  useListenForAppointmentLlmJobs({
    outputType: LlmOutputTypesLookup.DIFFERENTIAL_DIAGNOSES_PLUS_RED_FLAGS,
    lastJobCompletedAt: redFlagsOutput?.completedAt ?? null,
    onDataReceived: refetch,
  });

  const insights = redFlagsOutput?.content
    ? (redFlagsOutput.content as RedFlagsOutputSchema)
    : null;

  const hasContent = insights !== null || patientOverviews.length > 0;
  const hasDiagnoses =
    insights !== null && insights.differential_diagnoses.length > 0;

  const isGenerating = [patientOverviewStatus, status].includes(
    WorkerStatusTypesLookup.IN_PROGRESS,
  );

  const handleClick = async () => {
    try {
      const result = await apiClient.llm.generateAppointmentOutput({
        params: { tenantId, appointmentId },
        body: {
          outputType:
            LlmOutputTypesLookup.DIFFERENTIAL_DIAGNOSES_PLUS_RED_FLAGS,
        },
      });

      if (result.status !== 201) {
        toast.error(
          'We could not generate your insights. Please try again later!',
        );
        return;
      }
    } catch (e) {
      toast.error(
        'We could not generate your insights. Please try again later!',
      );
    }
  };

  const handleFeedback = async (isHelpful: boolean, content: string) => {
    await insertFeedback({
      variables: {
        appointmentId,
        summarySection: content,
        type: isHelpful
          ? FeedbackTypeLookup.POSITIVE
          : FeedbackTypeLookup.NEGATIVE,
        llmOutputId: redFlagsOutput?.llmOutputId,
      },
    });
  };

  if (!hasContent) {
    return (
      <HStack
        id="insights-container"
        className={cn(
          'mb-4 mt-2 rounded-lg bg-brandGray100 p-3 pr-4',
          hasContent && 'h-[400px] max-h-[400px]',
        )}
        gap={3}
      >
        <div className="rounded-md bg-purple p-2.5 text-purpleContrast">
          <Icon name="Lightbulb" size="sm" />
        </div>
        <VStack gap={0}>
          <P
            className={textStyles.body({
              weight: 'bold',
              size: 'l',
              className: 'text-contentSecondary',
            })}
          >
            Eluve Insights
          </P>
          <P
            className={textStyles.body({
              weight: 'medium',
              size: 's',
              className: 'text-contentTertiary',
            })}
          >
            Improve diagnostic accuracy and patient outcomes with AI-powered
            insights.
          </P>
        </VStack>
        {isGenerating && (
          <Icon
            name="LoaderCircle"
            className="mr-2 animate-spin text-contentSecondary"
          />
        )}
        {!isGenerating && (
          <NewButton
            disabled={!isAppointmentActive}
            onClick={handleClick}
            size="s"
            text="Run Insights"
            type="outline"
          />
        )}
      </HStack>
    );
  }

  return (
    <VStack
      align="stretch"
      id="insights-container"
      className="mb-4 mt-2 h-[400px] max-h-[400px] rounded-lg border border-gray-5 bg-backgroundPrimary"
      gap={0}
    >
      <HStack
        className="h-[52px] shrink-0 rounded-t-[7px] bg-purpleContrast pl-3 pr-4"
        gap={3}
      >
        <div className="rounded-md bg-purple p-1.5 text-purpleContrast">
          <Icon name="Lightbulb" size="xs" />
        </div>
        <VStack>
          <P
            className={textStyles.body({
              weight: 'bold',
              size: 'l',
              className: 'text-purple',
            })}
          >
            Eluve Insights
          </P>
        </VStack>
        {isGenerating && (
          <Icon name="LoaderCircle" className="mr-1 animate-spin text-purple" />
        )}
        {!isGenerating && hasContent && (
          <Tooltip>
            <TooltipTrigger asChild>
              <NewButton
                disabled={!isAppointmentActive}
                icon={{ name: 'RefreshCw' }}
                onClick={handleClick}
                size="s"
                type="outline"
              />
            </TooltipTrigger>
            {isAppointmentActive && (
              <TooltipContent sideOffset={4}>Re-run Insights</TooltipContent>
            )}
          </Tooltip>
        )}
      </HStack>
      {hasContent && (
        <>
          <HStack className="border-b border-gray-5 px-4 pt-2" gap={4}>
            {Object.keys(InsightTabLabels).map((tab) => {
              const insightsTab = tab as InsightTab;
              if (!isPatientOverviewEnabled && tab === 'PATIENT_OVERVIEW') {
                return null;
              }
              return (
                <InsightTabButton
                  key={insightsTab}
                  isActive={currentTab === insightsTab}
                  onClick={setCurrentTab}
                  tab={insightsTab}
                />
              );
            })}
          </HStack>
          <Box className="overflow-y-auto">
            <InsightTabContent
              className="p-6"
              isActive={currentTab === 'DIFFERENTIAL_DIAGNOSES'}
              id="differential-diagnosis-output"
            >
              <DifferentialDiagnosesTabContent />
            </InsightTabContent>
            <InsightTabContent
              className="py-4"
              isActive={currentTab === 'RED_FLAGS'}
              id="considerations-output"
            >
              {hasDiagnoses && (
                <RedFlagsOutput
                  content={insights.red_flags}
                  onFeedback={handleFeedback}
                  onShowDiagnoses={() =>
                    setCurrentTab('DIFFERENTIAL_DIAGNOSES')
                  }
                />
              )}
            </InsightTabContent>
            <InsightTabContent
              className="py-4"
              isActive={currentTab === 'REASON_FOR_VISIT'}
              id="read"
            >
              {isPatientOverviewEnabled && (
                <Box className="px-4">
                  <PatientOverview />
                </Box>
              )}
            </InsightTabContent>
          </Box>
        </>
      )}
      {!hasContent && (
        <VStack className="p-6">
          <P
            className={textStyles.body({
              weight: 'semibold',
              size: 'm',
              className: 'text-contentPrimary',
            })}
          >
            No insights yet
          </P>
          <P
            className={textStyles.body({
              weight: 'regular',
              size: 'm',
              className: 'mb-3 text-contentSecondary',
            })}
          >
            We couldn&apos;t generate any insights based on the information
            currently available. Try again once the conversation is longer.
          </P>
          <NewButton
            disabled={!isAppointmentActive || isGenerating}
            onClick={handleClick}
            size="s"
            text="Re-run Insights"
            type="outline"
          />
        </VStack>
      )}
    </VStack>
  );
};
