import { TriangleAlertIcon } from 'lucide-react';
import { useEffect } from 'react';
import { match } from 'ts-pattern';

import { Box, Button } from '@eluve/components';
import {
  MicrophonePermissionState,
  useMicrophonePermission,
} from '@eluve/mic-utils';

interface MicrophoneRequestAlertProps {
  onPermissionStateChange?: (state: MicrophonePermissionState) => void;
}

export const MicrophoneRequestAlert: React.FC<MicrophoneRequestAlertProps> = ({
  onPermissionStateChange,
}) => {
  const { state: microphonePermissionStatus, checkNavigatorPermissions } =
    useMicrophonePermission();

  useEffect(() => {
    onPermissionStateChange?.(microphonePermissionStatus);
  }, [microphonePermissionStatus, onPermissionStateChange]);

  const requestMicrophoneAccess = async () => {
    const device = await navigator.mediaDevices.getUserMedia({ audio: true });

    checkNavigatorPermissions();

    device.getTracks().forEach((track) => {
      track.stop();
    });
  };

  return match(microphonePermissionStatus)
    .with('denied', () => (
      <Box
        vStack
        center
        fullWidth
        className="rounded-lg bg-negative100 p-4 text-negative600"
      >
        <TriangleAlertIcon size={24} />
        Microphone permissions denied
        <span className="text-xs">
          Please enable microphone permissions in your browser settings to
          enable audio input.
        </span>
      </Box>
    ))
    .with('no-microphone-devices', () => (
      <Box
        vStack
        center
        fullWidth
        className="rounded-lg bg-warning100 p-4 text-warning600"
      >
        <TriangleAlertIcon size={24} />
        Microphone devices required
        <span className="text-xs">
          There are no microphone devices connected to your system, please grant
          microphone permissions or retry.
        </span>
        <Button
          onClick={requestMicrophoneAccess}
          variant="gray"
          size="sm"
          className="cursor-pointer"
        >
          Retry Permission
        </Button>
      </Box>
    ))
    .with('prompt', () => (
      <Box
        vStack
        center
        fullWidth
        className="rounded-lg bg-warning100 p-4 text-warning600"
      >
        <TriangleAlertIcon size={24} />
        Microphone permissions required
        <span className="text-xs">
          Please grant microphone permissions to enable audio input.
        </span>
        <Button
          onClick={requestMicrophoneAccess}
          variant="gray"
          size="sm"
          className="cursor-pointer"
        >
          Request Permission
        </Button>
      </Box>
    ))
    .otherwise(() => null);
};
