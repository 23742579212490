import { useMutation } from '@apollo/client';
import React from 'react';

import { useCompleteFragment } from '@eluve/apollo-client';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  NewButton,
  Textarea,
} from '@eluve/components';
import { activeAppointmentLayoutFragment } from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';

const updateAppointmentReasonMutation = graphql(`
  mutation UpdateAppointmentReasonMutation(
    $tenantId: uuid!
    $id: uuid!
    $patientReasonForVisit: String
  ) {
    updateAppointmentsByPk(
      pkColumns: { tenantId: $tenantId, id: $id }
      _set: { patientReasonForVisit: $patientReasonForVisit }
    ) {
      __typename
      id
      patientReasonForVisit
    }
  }
`);

type StartVirtualIntakeProps = {
  tenantId: string;
  appointmentId: string;
};

export const StartVirtualIntake: React.FC<StartVirtualIntakeProps> = ({
  tenantId,
  appointmentId,
}) => {
  const appointment = useCompleteFragment({
    fragment: activeAppointmentLayoutFragment,
    key: {
      id: appointmentId,
    },
    strict: false,
  });
  const [showReason, setShowReason] = React.useState(false);
  const [reason, setReason] = React.useState(
    appointment?.patientReasonForVisit ?? '',
  );

  const [updateAppointmentReason] = useMutation(
    updateAppointmentReasonMutation,
    {
      optimisticResponse: (variables) => ({
        updateAppointmentsByPk: {
          id: variables.id,
          patientReasonForVisit: variables.patientReasonForVisit ?? null,
          __typename: 'Appointments' as const,
        },
      }),
      onError: () => {
        alert('Failed to update reason for visit');
      },
    },
  );

  return (
    <Dialog open={showReason} onOpenChange={setShowReason} modal>
      <DialogTrigger asChild>
        <NewButton
          type="primary"
          text="Start Virtual Intake"
          icon={{ name: 'User' }}
        />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Start Virtual Intake</DialogTitle>
        </DialogHeader>
        <Textarea
          placeholder="Enter reason for visit ..."
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
        <DialogFooter>
          <NewButton
            type="primary"
            text="Start"
            onClick={async () => {
              await updateAppointmentReason({
                variables: {
                  tenantId: tenantId,
                  id: appointmentId,
                  patientReasonForVisit: reason,
                },
              });
              window.open(
                `${window.location.origin}/patient-tenant/${tenantId}/${appointmentId}`,
                '_blank',
                'noopener,noreferrer',
              );
              setShowReason(false);
            }}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default StartVirtualIntake;
