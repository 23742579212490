import { VariantProps } from 'tailwind-variants';

import { SidebarProvider } from './Sidebar';
import { TooltipProvider } from './Tooltip';
import { tv } from './cn';

const layoutStyles = tv({
  base: 'flex min-h-svh w-full overflow-y-hidden bg-backgroundSecondary',
  variants: {
    type: {
      default: '',
      simple: 'pl-3',
    },
  },
  defaultVariants: {
    type: 'default',
  },
});

type LayoutStyleProps = VariantProps<typeof layoutStyles>;

function Layout({
  children,
  type,
}: {
  children: React.ReactNode;
  type?: LayoutStyleProps['type'];
}) {
  const className = layoutStyles({ type });

  if (type === 'simple') {
    return (
      <TooltipProvider delayDuration={0}>
        <div className={className}>
          <Main>{children}</Main>
        </div>
      </TooltipProvider>
    );
  }

  return (
    <TooltipProvider delayDuration={0}>
      <SidebarProvider>
        <div className={className}>{children}</div>
      </SidebarProvider>
    </TooltipProvider>
  );
}

function Main({ children }: { children: React.ReactNode }) {
  return (
    <main className="grid h-svh flex-1 grid-cols-[1fr_auto] grid-rows-[auto_1fr_auto] border-borderPrimary bg-backgroundPrimary md:m-3 md:ml-0 md:h-[calc(100svh-theme(spacing.6))] md:rounded-2xl md:border">
      {children}
    </main>
  );
}

function Header({ children }: { children: React.ReactNode }) {
  return (
    <header className="col-span-2 row-start-1 flex h-16 items-center border-b border-borderPrimary p-3">
      {children}
    </header>
  );
}

const contentStyles = tv({
  slots: {
    section: 'col-start-1 row-start-2 flex justify-center overflow-auto',
    container: 'w-full',
  },
  variants: {
    padded: {
      true: { container: 'p-3' },
    },
    size: {
      default: { container: 'max-w-none' }, // N/A
      sm: { container: 'max-w-[40rem]' }, // 640px
      xl: { container: 'max-w-7xl' }, // 1280px
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

type ContentStyleProps = VariantProps<typeof contentStyles>;

function Content({
  children,
  padded,
  size,
}: {
  children: React.ReactNode;
  padded?: boolean;
  size?: ContentStyleProps['size'];
}) {
  const { section, container } = contentStyles({ padded, size });
  return (
    <section className={section()}>
      <div className={container()}>{children}</div>
    </section>
  );
}

function Footer({ children }: { children: React.ReactNode }) {
  return (
    <footer className="col-span-1 row-start-3 flex min-h-16 items-center border-t border-borderPrimary p-3">
      {children}
    </footer>
  );
}

export { Content, Footer, Header, Layout, Main };
