// eslint-disable-next-line no-restricted-imports
import { tv } from 'tailwind-variants';

export const commonStyles = tv({
  slots: {
    // Text styles
    text: 'text-sm font-normal',
    boldText: 'text-sm font-bold',
    headingText: 'text-base font-bold',
    subheadingText: 'text-sm font-bold',
    sectionTitle: 'text-xl font-normal text-brandGray700',

    // Layout styles
    content: 'flex flex-col gap-3',
    flexRow: 'flex-row',
    flexCol: 'flex flex-col',

    // Colors and states
    muted: 'text-brandGray500',
    subtle: 'text-brandGray700',

    // Specific component patterns
    amendment: 'flex flex-col gap-1',
    amendmentHeader: 'text-sm text-brandGray700',

    // List styles
    bullet: 'mr-1.5 text-sm font-normal',
    bulletItem: 'ml-2 flex-row',
    nestedBulletItem: 'ml-4 mt-0 flex-row',
  },
})();
