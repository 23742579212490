import React, { useEffect, useRef } from 'react';

import {
  Box,
  Button,
  HStack,
  Icon,
  Input,
  P,
  VStack,
  textStyles,
} from '@eluve/components';

import { useChat } from './ConversationContextProvider';

interface ConversationHistoryProps {
  providerAvatarUrl?: string;
  providerName?: string;
}

export const ConversationHistory: React.FC<ConversationHistoryProps> = ({
  providerAvatarUrl,
  providerName,
}) => {
  const [manualMessage, setManualMessage] = React.useState<string | null>(null);
  const { messages, handleSendMessage, isConnected, openChat } = useChat();
  const sendMessage = () => {
    const text = manualMessage?.trim();
    if (!text) return;
    handleSendMessage(text);
    setManualMessage('');
  };

  const bottomRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (openChat && messages.length > 0) {
      setTimeout(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 300);
    }
  }, [messages, openChat]);

  return (
    <VStack className="h-full" justify="between">
      <VStack
        className="max-h-[calc(100%-70px)] shrink grow overflow-y-auto"
        gap={3}
      >
        {messages.map((message) => (
          <HStack
            key={message.id}
            className={
              message.isUser
                ? 'w-full justify-end pr-1'
                : 'w-full justify-start'
            }
            gap={2}
          >
            {providerAvatarUrl && !message.isUser && (
              <img
                src={providerAvatarUrl}
                alt=""
                className="mt-2 size-12 shrink-0 rounded-full border-2 border-brandGray100 bg-white p-0.5"
              />
            )}
            <Box
              className={`rounded-lg ${message.isUser ? 'bg-brandGray100' : 'bg-white'} max-w-[70%] shrink p-3 ${textStyles.body(
                {
                  weight: 'medium',
                  size: 's',
                  className: 'text-black',
                },
              )}`}
            >
              {providerName && !message.isUser && (
                <P className="text-contentPrimary">{providerName}</P>
              )}
              <P className="text-contentSecondary">
                {message.text?.length === 0 ? '...' : message.text}
              </P>
            </Box>
          </HStack>
        ))}
        <Box ref={bottomRef} />
      </VStack>
      <HStack justify="between" className="mt-2 shrink-0">
        <Input
          type="text"
          className="flex-1 rounded-full border border-brandGray200"
          placeholder="Type your answer here"
          disabled={!isConnected}
          value={manualMessage ?? ''}
          onChange={(e) => setManualMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              sendMessage();
            }
          }}
        />
        <Button
          className="flex size-12 rounded-full bg-brandGray800 text-white hover:bg-brandGray800 hover:opacity-75"
          onClick={sendMessage}
          disabled={!isConnected}
        >
          <Icon name="ArrowUp" size="md" />
        </Button>
      </HStack>
    </VStack>
  );
};
