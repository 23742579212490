import { useMutation } from '@apollo/client';

import { graphql } from '@eluve/graphql.tada';

const updateAppointmentModeMutation = graphql(`
  mutation updateAppointmentMode(
    $tenantId: uuid!
    $appointmentId: uuid!
    $mode: AppointmentModeTypesEnum!
  ) {
    updateAppointmentsByPk(
      pkColumns: { tenantId: $tenantId, id: $appointmentId }
      _set: { mode: $mode }
    ) {
      __typename
      id
      mode
    }
  }
`);

export const useUpdateAppointmentMode = () => {
  const [updateAppointmentMode] = useMutation(updateAppointmentModeMutation, {
    optimisticResponse: ({ appointmentId, mode }) => {
      return {
        updateAppointmentsByPk: {
          __typename: 'Appointments' as const,
          id: appointmentId,
          mode,
        },
      };
    },
  });

  return updateAppointmentMode;
};
