import { useMutation, useSuspenseQuery } from '@apollo/client';
import React from 'react';

import { eluveAdminHasuraContext } from '@eluve/apollo-client';
import { LanguageSelectorComboBox } from '@eluve/blocks';
import { H3, H5, P, VStack, toast } from '@eluve/components';
import { useTenantIdFromParams } from '@eluve/session-helpers';

import { PreferredDateFormatSetting } from './PreferredDateFormatSetting';
import {
  getDefaultTenantUserSettingsQuery,
  setDefaultTenantUserLanguageMutation,
} from './operations';

export interface AdminTenantUserDefaultSettingsPageProps {}

export const AdminTenantUserDefaultSettingsPage: React.FC<
  AdminTenantUserDefaultSettingsPageProps
> = () => {
  const tenantId = useTenantIdFromParams()!;

  const { data } = useSuspenseQuery(getDefaultTenantUserSettingsQuery, {
    variables: {
      tenantId,
    },
    context: eluveAdminHasuraContext,
  });

  const [setDefaultTenantUserLanguage] = useMutation(
    setDefaultTenantUserLanguageMutation,
    {
      onError: () => toast.error('Failed to update language'),
      optimisticResponse: (input) => ({
        updateDefaultTenantUserSettingsByPk: {
          __typename: 'DefaultTenantUserSettings' as const,
          tenantId,
          inputLanguage: input.inputLanguage,
          outputLanguage: input.outputLanguage,
          preferredDateFormat:
            data.tenantsByPk?.default_tenant_user_settings
              ?.preferredDateFormat ?? 'P',
        },
      }),
    },
  );

  const tenantDefaults = data.tenantsByPk?.default_tenant_user_settings;

  if (!tenantDefaults) {
    return (
      <span className="font-bold text-negative600">
        This tenant has no entry in default_tenant_user_settings. Something went
        wrong.
      </span>
    );
  }

  const inputLanguageCode = tenantDefaults.inputLanguage;
  const outputLanguagCode = tenantDefaults.outputLanguage;

  const handleLanguageUpdate = async (input: string, output: string) => {
    await setDefaultTenantUserLanguage({
      variables: {
        tenantId,
        inputLanguage: input,
        outputLanguage: output,
      },
      context: eluveAdminHasuraContext,
    });
  };

  return (
    <VStack gap={5}>
      <div>
        <H3>Default Tenant User Settings</H3>
        <P>
          When new users are added to this tenant, these will be the default
          settings granted to them.
        </P>
      </div>

      <VStack>
        <H5>Spoken Language</H5>
        <LanguageSelectorComboBox
          selectButtonStyles="w-full"
          selectedLanguageCode={inputLanguageCode}
          onSelectedLanguageCode={async (newCode) => {
            await handleLanguageUpdate(newCode, outputLanguagCode);
          }}
        />
      </VStack>
      <VStack>
        <H5>Notes Language</H5>
        <LanguageSelectorComboBox
          selectButtonStyles="w-full"
          selectedLanguageCode={outputLanguagCode}
          onSelectedLanguageCode={async (newCode) => {
            await handleLanguageUpdate(inputLanguageCode, newCode);
          }}
        />
      </VStack>
      <VStack>
        <H5>Preferred Date Format</H5>
        <PreferredDateFormatSetting />
      </VStack>
    </VStack>
  );
};
