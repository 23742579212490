import { Text, View } from '@react-pdf/renderer';
// eslint-disable-next-line no-restricted-imports
import { tv } from 'tailwind-variants';

import { formatDateToReadable } from '@eluve/date-utils';
import { getUserName } from '@eluve/utils';

import { tw } from '../pdf-tw';
import { commonStyles } from '../styles';

const styles = tv({
  slots: {
    doctorInfoContainer: 'flex-row items-center gap-1',
  },
})();

export const ChartUserDetails: React.FC<{
  user: {
    firstName?: string;
    lastName?: string;
    email: string;
  };
  noteSignedAt?: string;
}> = ({ user, noteSignedAt }) => {
  const doctorName = getUserName(user);
  const dateSignedOn = noteSignedAt
    ? formatDateToReadable(noteSignedAt)
    : undefined;
  return (
    <View style={tw(styles.doctorInfoContainer())}>
      <View style={tw('flex flex-col gap-1')}>
        {noteSignedAt ? (
          <>
            <Text style={tw(commonStyles.text())}>
              <Text style={tw('font-bold')}>Signed by:</Text> {doctorName}
            </Text>
            <Text style={tw(commonStyles.text())}>
              <Text style={tw('font-bold')}>Signed on:</Text> {dateSignedOn}
            </Text>
          </>
        ) : (
          <>
            <Text style={tw(commonStyles.text())}>
              <Text style={tw('font-bold')}>Created by:</Text> {doctorName}
            </Text>
            <Text style={tw(commonStyles.text())}>
              <Text style={tw('font-bold')}>Signed on:</Text> This chart has not
              been signed
            </Text>
          </>
        )}
      </View>
    </View>
  );
};
