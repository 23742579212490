import { match } from 'ts-pattern';

import { SupportedVendors } from '@eluve/graphql-types';

import { BlvdVendorDataMapper } from './blvd/blvd.vendor-data-mapper';
import { ExperityVendorDataMapper } from './experity/experity.vendor-data-mapper';
import { JaneVendorDataMapper } from './jane/jane.vendor-data-mapper';
import { MovementXVendorDataMapper } from './movement-x/movement-x.vendor-data-mapper';
import { SiloamVendorDataMapper } from './siloam/siloam.vendor-data-mapper';
import { TebraVendorDataMapper } from './tebra/tebra.vendor-data-mapper';
import { VendorDataMapper } from './vendor-data-mapper';

export {
  BlvdVendorDataMapper,
  ExperityVendorDataMapper,
  JaneVendorDataMapper,
  MovementXVendorDataMapper,
  SiloamVendorDataMapper,
  TebraVendorDataMapper,
};

export const getVendorDataMapper = (
  vendor: SupportedVendors,
): VendorDataMapper => {
  return match(vendor)
    .with('JANE', () => new JaneVendorDataMapper())
    .with('EXPERITY', () => new ExperityVendorDataMapper())
    .with('TEBRA', () => new TebraVendorDataMapper())
    .with('MOVEMENT_X', () => new MovementXVendorDataMapper())
    .with('BLVD', () => new BlvdVendorDataMapper())
    .with('SILOAM', () => new SiloamVendorDataMapper())
    .exhaustive();
};
