import { useMutation, useSuspenseQuery } from '@apollo/client';
import { Check, ChevronsUpDown, Plus, Trash } from 'lucide-react';
import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import {
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  Form,
  FormField,
  FormItem,
  FormMessage,
  H2,
  H3,
  H4,
  Input,
  P,
  PageTitle,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Textarea,
  cn,
  toast,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { useNamedLogger } from '@eluve/logger';

const getArtifactsQuery = graphql(`
  query getArtifacts {
    evalSourceArtifact {
      __typename
      id
      name
      type
      description
      createdAt
    }
  }
`);

const insertDatasetAndArtifactsMutation = graphql(`
  mutation createDataset($dataset: EvalDatasetsInsertInput!) {
    insertEvalDatasetsOne(object: $dataset) {
      __typename
      id
    }
  }
`);

const formSchema = z.object({
  name: z.string().min(3),
  description: z.string(),
  artifacts: z.array(
    z.object({
      id: z.string(),
      name: z.string().nullable(),
      description: z.string().nullable(),
    }),
  ),
});

type FormData = z.infer<typeof formSchema>;

const defaultValues: Partial<FormData> = {
  name: '',
  description: '',
  artifacts: [],
};

export const CreateDatasetPage: React.FC = () => {
  const logger = useNamedLogger('create-dataset-page');
  const navigate = useNavigate();

  const form = useForm<FormData>({
    defaultValues,
    mode: 'onChange',
  });

  const {
    data: { evalSourceArtifact: allArtifacts },
  } = useSuspenseQuery(getArtifactsQuery);

  // Filter out artifacts that are already selected
  const availableArtifacts = allArtifacts.filter(
    (artifact) =>
      !form.getValues('artifacts').some((a) => a.id === artifact.id),
  );

  const [artifactSelectPopoverOpen, setArtifactSelectPopoverOpen] =
    useState(false);
  const [selectedArtifactId, setSelectedArtifactId] = useState('');
  const selectedArtifact = availableArtifacts.find(
    (artifact) => artifact.id === selectedArtifactId,
  );

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'artifacts',
  });

  const [insertDatasetAndArtifacts] = useMutation(
    insertDatasetAndArtifactsMutation,
    {
      onCompleted: (data) => {
        toast.success('Successfully created dataset');
        form.reset(defaultValues);

        if (data.insertEvalDatasetsOne?.id) {
          navigate(
            `/admin/fact-verification/datasets/${data.insertEvalDatasetsOne.id}`,
          );
        }
      },

      onError: () => {
        toast.error('Failed to create dataset');
      },
    },
  );

  const onSubmit = async ({ name, description, artifacts }: FormData) => {
    await insertDatasetAndArtifacts({
      variables: {
        dataset: {
          name,
          description,
          datasetSourceArtifacts: {
            data: artifacts.map((artifact) => ({
              sourceArtifactId: artifact.id,
            })),
          },
        },
      },
    });
  };

  return (
    <div>
      <PageTitle>Create a Dataset</PageTitle>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit, (invalid) =>
            logger.debug(
              `Invalid form submission attempted: ${JSON.stringify(invalid)}`,
            ),
          )}
        >
          <H2>Info</H2>
          <FormField
            control={form.control}
            name="name"
            render={({ field, fieldState: { error } }) => (
              <FormItem className="max-w-lg">
                <label htmlFor="name" className="sr-only">
                  Name
                </label>
                <Input className="bg-white/5" placeholder="Name" {...field} />
                {error && (
                  <FormMessage className="mt-4">{error.message}</FormMessage>
                )}
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field, fieldState: { error } }) => (
              <FormItem className="max-w-lg">
                <label htmlFor="description" className="sr-only">
                  Description
                </label>
                <Textarea
                  className="bg-white/5"
                  placeholder="Description"
                  {...field}
                />
                {error && (
                  <FormMessage className="mt-4">{error.message}</FormMessage>
                )}
              </FormItem>
            )}
          />

          <H2 m="mt-3">Select source artifacts</H2>

          <H4>Select source artifacts to add to this dataset</H4>

          <div className="grid gap-2">
            {fields.map((field, index) => (
              <div
                className="flex items-start justify-between gap-2 rounded-md p-3"
                key={field.id}
              >
                <div className="flex w-full flex-col gap-2">
                  <H3>{field.name}</H3>
                  <P>{field.description}</P>

                  <div className="h-0.5 rounded-sm"></div>
                </div>

                <Button
                  variant="destructive"
                  size="icon"
                  onClick={() => remove(index)}
                >
                  <Trash />
                </Button>
              </div>
            ))}
            <div className="flex flex-row gap-3 align-top">
              <Popover
                open={artifactSelectPopoverOpen}
                onOpenChange={setArtifactSelectPopoverOpen}
              >
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={artifactSelectPopoverOpen}
                    className="w-[500px] justify-between"
                  >
                    {selectedArtifactId
                      ? selectedArtifact?.name
                      : 'Select artifact...'}
                    <ChevronsUpDown className="ml-2 size-4 shrink-0 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[500px] p-0">
                  <Command>
                    <CommandInput placeholder="Search by artifact name..." />
                    <CommandEmpty>No artifacts found.</CommandEmpty>
                    <CommandGroup>
                      {availableArtifacts.map((artifact) => (
                        <CommandItem
                          key={artifact.id}
                          value={artifact.name ?? artifact.id}
                          onSelect={() => {
                            setSelectedArtifactId(
                              artifact.id === selectedArtifactId
                                ? ''
                                : artifact.id,
                            );
                            setArtifactSelectPopoverOpen(false);
                          }}
                        >
                          <Check
                            className={cn(
                              'mr-2 h-4 w-4',
                              selectedArtifactId === artifact.id
                                ? 'opacity-100'
                                : 'opacity-0',
                            )}
                          />

                          <div>
                            <P className="text-black">{artifact.name}</P>
                            <P>{artifact.description}</P>
                          </div>
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </Command>
                </PopoverContent>
              </Popover>
              <Button
                className="w-fit px-4"
                type="button"
                variant="secondary"
                disabled={!selectedArtifact}
                onClick={() => {
                  append({
                    id: selectedArtifactId,
                    name: selectedArtifact!.name ?? null,
                    description: selectedArtifact!.description ?? null,
                  });
                  setSelectedArtifactId('');
                }}
              >
                <Plus className="mr-2" />
                Add source artifact
              </Button>
            </div>
            <Button className="mt-8 w-fit" disabled={!form.formState.isDirty}>
              Create Dataset
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};
