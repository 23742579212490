import { useSubscription } from '@apollo/client';
import { Sparkles } from 'lucide-react';
import React from 'react';
import { Link } from 'react-router-dom';

import { useCompleteFragment } from '@eluve/apollo-client';
import { Button, Content, HStack, VStack } from '@eluve/components';
import {
  appointmentTranscriptFragment,
  listenAppointmentStatusSubscription,
  useAppointmentMode,
  useAppointmentStatus,
  useAppointmentTranscriptionSync,
  useIsAppointmentReadonly,
  useSummary,
} from '@eluve/frontend-appointment-hooks';
import { BillingCodeStoreProvider } from '@eluve/frontend-feature-appointment';
import { AppointmentStatusTypesLookup, DbEnums } from '@eluve/graphql-types';
import {
  AppointmentName,
  FeatureFlaggedComponent,
  MicrophoneRequestAlert,
  useIsFeatureFlagEnabled,
} from '@eluve/smart-blocks';

import { AdditionalNotes } from './AdditionalNotes';
import { EluveAdminControls } from './EluveAdminControls';
import { NewAppointmentForm } from './NewAppointmentForm';
import { appointmentLayoutStyles } from './appointmentLayoutStyles';
import { AppointmentChiefComplaint } from './components/AppointmentChiefComplaint';
import { AppointmentDetails } from './components/AppointmentDetails';
import { AppointmentSummaryFooter } from './components/AppointmentSummaryFooter';
import { AppointmentSummaryResult } from './components/AppointmentSummaryResult';
import {
  AppointmentTabs,
  SummaryTabContent,
  TranscriptTabContent,
} from './components/AppointmentTabs';
import { AppointmentTranscription } from './components/AppointmentTranscription';
import { AppointmentTranscriptionFooter } from './components/AppointmentTranscriptionFooter';
import { HiddenForManualCharting } from './components/HiddenForManualCharting';
import { AppointmentInsights } from './components/Insights/AppointmentInsights';
import { useAppointmentTabNav } from './useAppointmentTabNav';
import { useListenForAppointmentEnd } from './useListenForAppointmentEnd';
import { useListenForSummary } from './useListenForSummary';

export interface ActiveAppointmentLayoutProps {
  tenantId: string;
  appointmentId: string;
}
export const ActiveAppointmentLayout: React.FC<
  ActiveAppointmentLayoutProps
> = ({ appointmentId, tenantId }) => {
  const isInsightsEnabled = useIsFeatureFlagEnabled('INSIGHTS');
  const skipNotStartedAppointmentLayout = useIsFeatureFlagEnabled(
    'SKIP_NOT_STARTED_APPOINTMENT_LAYOUT',
  );

  const appointmentTranscript = useCompleteFragment({
    fragment: appointmentTranscriptFragment,
    key: {
      id: appointmentId,
    },
    strict: false,
  });

  const { tab } = useAppointmentTabNav();
  const isReadonly = useIsAppointmentReadonly();

  useListenForSummary();
  useListenForAppointmentEnd();

  // Listen for meaningful status changes for this appointment
  useSubscription(listenAppointmentStatusSubscription, {
    variables: {
      tenantId,
      appointmentId,
    },
  });

  // Sync chunked transcript data into the apollo cache for use across the component tree
  useAppointmentTranscriptionSync(
    appointmentTranscript?.transcription?.updatedAt,
  );

  const appointmentStatus = useAppointmentStatus();
  const { isSummaryAvailable } = useSummary();
  const appointmentMode = useAppointmentMode();

  const summaryTabEnabled =
    (appointmentMode === DbEnums.AppointmentMode.AUDIO_COPILOT &&
      (appointmentStatus !== AppointmentStatusTypesLookup.ACTIVE ||
        isSummaryAvailable)) ||
    appointmentMode === DbEnums.AppointmentMode.MANUAL_CHARTING;

  return (
    <BillingCodeStoreProvider
      initializer={() => ({
        highlightedId: null,
        highlightedTranscriptExcerpts: [],
      })}
    >
      <>
        <AppointmentDetails appointmentId={appointmentId} tenantId={tenantId} />
        <Content padded>
          <VStack className={appointmentLayoutStyles()}>
            <VStack wFull>
              <HStack wFull justify="between" className="flex-wrap">
                <AppointmentName readonly={isReadonly} />
                {appointmentStatus === 'COMPLETED' && (
                  <FeatureFlaggedComponent flag="LLM_OUTPUT_DETAILS">
                    <Link
                      to={`details/${DbEnums.LlmOutputType.BILLING_CODE_RECOMMENDATION}`}
                    >
                      <Button variant="outline" size="sm">
                        <Sparkles className="mr-1" />
                        Experiments
                      </Button>
                    </Link>
                  </FeatureFlaggedComponent>
                )}
              </HStack>
              <EluveAdminControls />
            </VStack>
            <FeatureFlaggedComponent flag="CHIEF_COMPLAINT">
              <AppointmentChiefComplaint />
            </FeatureFlaggedComponent>
            <AppointmentTabs disabledSummary={!summaryTabEnabled} />
            <TranscriptTabContent>
              <MicrophoneRequestAlert />
              <FeatureFlaggedComponent flag="INSIGHTS">
                <AppointmentInsights />
              </FeatureFlaggedComponent>
              {skipNotStartedAppointmentLayout &&
                appointmentStatus === 'NOT_STARTED' && (
                  <NewAppointmentForm
                    appointmentId={appointmentId}
                    tenantId={tenantId}
                    isCompactMicrophone
                    isSettingsAvailable
                  />
                )}
              {appointmentStatus !== 'NOT_STARTED' && (
                <AppointmentTranscription compact={isInsightsEnabled} />
              )}
              <HiddenForManualCharting>
                <AdditionalNotes />
              </HiddenForManualCharting>
            </TranscriptTabContent>
            <SummaryTabContent>
              <AppointmentSummaryResult />
            </SummaryTabContent>
          </VStack>
        </Content>
        {tab === 'transcript' && appointmentStatus !== 'NOT_STARTED' && (
          <HiddenForManualCharting>
            <AppointmentTranscriptionFooter />
          </HiddenForManualCharting>
        )}

        {tab === 'summary' && <AppointmentSummaryFooter />}
      </>
    </BillingCodeStoreProvider>
  );
};
