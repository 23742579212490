import { useApolloClient, useQuery } from '@apollo/client';
import format from 'date-fns/format';
import eruda from 'eruda';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { redirectToAuth } from 'supertokens-auth-react';
import Session from 'supertokens-auth-react/recipe/session';

import {
  DropdownMenu,
  DropdownMenuAnchorItem,
  DropdownMenuContent,
  DropdownMenuCustomItem,
  DropdownMenuHeading,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuLinkItem,
  DropdownMenuSelectableItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
  SidebarMenuButton,
  TenantCard,
  useSidebar,
} from '@eluve/components';
import {
  useAssignedTenantIdFromParams,
  useIsTenantAdminFromSession,
  useSession,
  useUserDisplayFromSession,
} from '@eluve/session-helpers';
import {
  FeatureFlaggedComponent,
  useIsFeatureFlagEnabled,
  useTenantName,
} from '@eluve/smart-blocks';

import { appConfig } from '../../../../config';
import { GetTenantsList } from '../operations';

const DEVELOPER_CLICK_THRESHOLD = 5;
const DEVELOPER_CLICK_TIMEOUT_MS = 2000;
const DROPDOWN_OFFSET_AND_PADDING = 8;
const TENANT_LIST_LIMIT = 10;

export const UserDropdownMenu = () => {
  const [clickCount, setClickCount] = useState(0);

  const canManageLocalFiles = useIsFeatureFlagEnabled('LOCAL_FILES_MANAGEMENT');
  const client = useApolloClient();
  const isTenantAdmin = useIsTenantAdminFromSession();
  const navigate = useNavigate();
  const tenantId = useAssignedTenantIdFromParams();
  const tenantName = useTenantName(tenantId);

  const { data } = useQuery(GetTenantsList);
  const { email } = useUserDisplayFromSession();
  const { isMobile, open: isOpen } = useSidebar();
  const { isEluveAdmin, isPromptTemplateCreator, isEluveEvalUser, isReviewer } =
    useSession();

  const date = format(Date.now(), 'yyyy-MM-dd');
  const isCollapsed = !isOpen && !isMobile;
  const tenants = (data?.assignedTenantUsers ?? []).filter(
    (tenant) => tenant.tenantId != null,
  );

  let timeout: NodeJS.Timeout;
  const handleDeveloperClick = () => {
    setClickCount((count) => count + 1);
    clearTimeout(timeout);

    if (clickCount > DEVELOPER_CLICK_THRESHOLD) {
      setClickCount(0);
      eruda.init();
    } else {
      timeout = setTimeout(() => setClickCount(0), DEVELOPER_CLICK_TIMEOUT_MS);
    }
  };

  const handleLogoutClick = async () => {
    await Session.signOut();
    redirectToAuth();
  };

  const handleTenantClick = (newTenantId: string) => {
    client.resetStore();
    navigate(`/tenants/switch/${newTenantId}`);
  };

  return (
    <DropdownMenu modal={!isMobile}>
      <DropdownMenuTrigger asChild>
        <SidebarMenuButton className="mr-auto mt-auto" size="lg">
          <TenantCard
            icon={{ name: 'ChevronsUpDown' }}
            tenantName={tenantName}
            userEmail={email}
          />
        </SidebarMenuButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="start"
        alignOffset={isCollapsed ? 0 : DROPDOWN_OFFSET_AND_PADDING}
        className="min-w-[200px]"
        onCloseAutoFocus={(event) => event.preventDefault()}
        sideOffset={isCollapsed ? DROPDOWN_OFFSET_AND_PADDING : 0}
      >
        <DropdownMenuHeading>Workspace</DropdownMenuHeading>
        <DropdownMenuCustomItem>
          <TenantCard tenantName={tenantName} userEmail={email} />
        </DropdownMenuCustomItem>
        {tenants.length > 1 && tenants.length <= TENANT_LIST_LIMIT && (
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>Switch Tenant</DropdownMenuSubTrigger>
            <DropdownMenuSubContent
              collisionPadding={DROPDOWN_OFFSET_AND_PADDING}
            >
              {tenants.map((tenant) => (
                <DropdownMenuSelectableItem
                  currentValue={tenantId}
                  key={tenant.tenantId}
                  onClick={handleTenantClick}
                  text={tenant.name ?? 'Unnamed Tenant'}
                  value={tenant.tenantId as string}
                />
              ))}
            </DropdownMenuSubContent>
          </DropdownMenuSub>
        )}
        {tenants.length > 1 && tenants.length > TENANT_LIST_LIMIT && (
          <DropdownMenuLinkItem text="Switch Tenant" to={`/tenants`} />
        )}
        {isTenantAdmin && (
          <FeatureFlaggedComponent flag="LOCATIONS">
            <DropdownMenuLinkItem
              text="Tenant Settings"
              to={`/tenants/${tenantId}/admin`}
            />
          </FeatureFlaggedComponent>
        )}
        {(canManageLocalFiles || isPromptTemplateCreator) && (
          <DropdownMenuSeparator />
        )}
        {(canManageLocalFiles || isPromptTemplateCreator) && (
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>Eluve Tools</DropdownMenuSubTrigger>
            <DropdownMenuSubContent>
              {isPromptTemplateCreator && (
                <DropdownMenuLinkItem
                  text="Create Prompt Template"
                  to="/prompts"
                />
              )}
              {canManageLocalFiles && (
                <DropdownMenuLinkItem
                  text="Manage Device Files"
                  to={`/tenants/${tenantId}/files`}
                />
              )}
            </DropdownMenuSubContent>
          </DropdownMenuSub>
        )}
        {(isEluveAdmin || isEluveEvalUser || isReviewer) && (
          <DropdownMenuSeparator />
        )}
        {isEluveAdmin && (
          <DropdownMenuLinkItem text="Admin Dashboard" to="/admin" />
        )}
        {isEluveEvalUser && (
          <DropdownMenuLinkItem
            text="Eval Dashboard"
            to="/eval/source-artifact-review"
          />
        )}
        {isReviewer && (
          <DropdownMenuLinkItem text="Review Dashboard" to="/review" />
        )}
        <DropdownMenuSeparator />
        <DropdownMenuAnchorItem
          href={`mailto:feedback@eluve.com?subject=Eluve Feedback - ${date}`}
          text="Contact Us"
        />
        <DropdownMenuItem onClick={handleLogoutClick}>Log Out</DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuLabel onClick={handleDeveloperClick}>
          {appConfig.VITE_APP_VERSION}
        </DropdownMenuLabel>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
