import { ResultOf } from 'gql.tada';
import debounce from 'lodash/debounce';
import { CheckIcon, LoaderCircleIcon, XIcon } from 'lucide-react';
import { useEffect, useRef } from 'react';

import {
  Box,
  ComboboxOption,
  useComboboxInputValue,
  useSetComboboxInputValue,
} from '@eluve/components';

import { NewPatientOption } from './NewPatientOption';
import { PatientOption } from './PatientOption';
import { patientOptionFragment } from './operations';

export interface PatientComboboxContentProps {
  selectedPatient: ResultOf<typeof patientOptionFragment> | null;
  searchResults: ResultOf<typeof patientOptionFragment>[];
  loading: boolean;
  handlePatientSelected: (patientId?: string) => void;
  tenantId: string;
  debouncedSearch: ReturnType<typeof debounce>;
  setSearchResults: React.Dispatch<
    React.SetStateAction<ResultOf<typeof patientOptionFragment>[]>
  >;
}

export const PatientComboboxContent: React.FC<PatientComboboxContentProps> = ({
  selectedPatient,
  searchResults,
  loading,
  handlePatientSelected,
  tenantId,
  debouncedSearch,
  setSearchResults,
}) => {
  const inputValue = useComboboxInputValue();
  const setInputValue = useSetComboboxInputValue();

  const prevInputRef = useRef(inputValue);

  useEffect(
    function searchOnInputChange() {
      if (prevInputRef.current !== inputValue) {
        prevInputRef.current = inputValue;

        if (!inputValue || inputValue.length < 2) {
          setSearchResults([]);
        } else {
          debouncedSearch(inputValue);
        }
      }
    },
    [inputValue, debouncedSearch, setSearchResults],
  );

  useEffect(
    function clearResultsOnMount() {
      setSearchResults([]);
    },
    [setSearchResults],
  );

  return (
    <>
      {selectedPatient?.id && (
        <button
          className="mb-2 w-full rounded-md px-4 py-2 text-left hover:bg-gray-3"
          onClick={() => {
            handlePatientSelected(undefined);
            setInputValue('');
          }}
        >
          <Box hStack className="text-gray-10">
            <XIcon size={24} className="m-1" />
            <span>Clear Selected Patient</span>
          </Box>
        </button>
      )}

      {loading && (
        <Box vStack className="items-center justify-center py-4">
          <LoaderCircleIcon className="size-6 animate-spin text-brand-9" />
          <span className="mt-2 text-gray-11">Searching patients...</span>
        </Box>
      )}

      {inputValue.length >= 2 || searchResults.length === 0 ? null : (
        <div className="px-4 py-2 text-gray-10">
          Type at least 2 characters to search
        </div>
      )}

      {searchResults.map((patient) => {
        return (
          <ComboboxOption
            key={patient.id}
            onSelect={() => {
              handlePatientSelected(patient.id);
              setInputValue('');
              setSearchResults([]);
            }}
          >
            <Box hStack fullWidth>
              <Box className="flex-1">
                <PatientOption {...patient} />
              </Box>
              {selectedPatient?.id === patient.id && (
                <CheckIcon className="w-max text-brand-9" />
              )}
            </Box>
          </ComboboxOption>
        );
      })}

      {inputValue.length >= 2 && (
        <NewPatientOption
          tenantId={tenantId}
          onCreateNewPatient={(patientId) => {
            handlePatientSelected(patientId);
            setInputValue('');
          }}
        />
      )}
    </>
  );
};
